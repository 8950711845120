import React, { useContext, useCallback, useEffect, useMemo, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';

import useAuth from './useAuth';
import usePause from './usePause';

import { traceError } from 'utils';

const AccountContext = React.createContext({});

const initialState = {
  email: '',
  name: 'New User',
  uid: null,
};

const AccountContextProvider = ({ children }) => {
  const { loading, uid, ruid, detach } = useAuth();
  const [state, setState] = useState(initialState);
  const [dataLoading, setDataLoading] = useState(true);
  const [paused] = usePause();

  useEffect(() => {
    if (!loading && uid) {
      if (uid === 'visitor') {
        setDataLoading(false);
        return;
      }
      const listener = (snapshot) => {
        if (paused.current) {
          return;
        }
        if (snapshot.val()) {
          setState((state) => ({ ...state, ...snapshot.val() }));
        }
        setDataLoading(false);
      };
      const ref = firebase.database().ref(`/users/${uid}/account`);
      ref.on('value', listener);
      return () => {
        ref.off('value', listener);
      };
    }
  }, [loading, paused, uid, ruid]);

  useEffect(() => {
    if (detach) {
      firebase.database().ref(`/users/${detach}/account`).off();
      setState(initialState);
    }
  }, [detach]);

  const update = useCallback((account) => {
    if (dataLoading || !uid) {
      return;
    }
    const ref = firebase.database().ref(`/users/${uid}/account`);
    ref.update({ ...account }, (error) => {
      if (error) {
        traceError('useAccount: error updating account to: %o: %o', account, error);
      }
    });
  }, [dataLoading, uid]);

  const value = useMemo(() => ({ account: state, update, loading: dataLoading }), [state, update, dataLoading]);
  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};

const useAccount = () => useContext(AccountContext);

export default useAccount;
export { AccountContextProvider };
