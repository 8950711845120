import firebase from 'firebase/app';
import 'firebase/database';

import { trace, traceError } from 'utils';

// Do not use this in render funcs, only in effects
// If key is passed, the value will be:
//  { key: data: <the data> }
// Otherwise, the value will just be the data.
const load = async ({ uid, key, path, set, defaultValue, orderBy, limit, source }) => new Promise((resolve, reject) => {
  try {
    const ref = path[0] === '/' ? path : `/users/${uid}/${path}`;
    let reference;
    if (orderBy && limit) {
      reference = firebase.database().ref(ref).orderByChild(orderBy).limitToLast(limit);
    } else {
      reference = firebase.database().ref(ref);
    }
    trace('dbLoader[%s]: setting ref for %s', source, ref);
    reference.once('value', (snapshot) => {
      // trace('dbLoader[%s]: snapshot: %o %o -> %o', source, key||'no key', path, snapshot.val());
      if (set) {
        if (key) {
          set({ key, data: snapshot.val() || defaultValue });
        } else {
          set(snapshot.val());
        }
        resolve();
      }
      if (key) {
        resolve({ key, data: snapshot.val() || defaultValue });
      } else {
        // trace('dbLoader[%s]: path: %s, resolving with %o', source, path, snapshot.val()||defaultValue);
        resolve(snapshot.val() || defaultValue);
      }
    }).catch((err) => {
      traceError(err);
      reject(err);
    });
  } catch (err) {
    traceError(err);
    reject(err);
  }
});

export default load;
