import React, { useEffect, useState } from 'react';

import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';

import EdamamIcon from 'assets/Edamam_Badge_Transparent.svg';
import { ReactComponent as GandalfTheWhite } from 'assets/wizard_icon_white.svg';
import { ReactComponent as GandalfTheBlue } from 'assets/wizard_icon_purple.svg';
import MealCard from 'components/meal/MealCard';
import MemberAddSignUp from 'components/members/MemberAddSignUp';
import RecipeModal from 'components/recipes/RecipeModal';
import useAuth from 'hooks/useAuth';
import useNav from 'hooks/useNav';
import { dbSet } from 'hooks/utils/dbUpdater';
import dbWatcher from 'hooks/utils/dbWatcher';
import { addEvent, hasEvent } from 'hooks/utils/events';
import { trace } from 'utils';
import Wizard from 'wizards/plan';
import MealPlanningDrawing from 'assets/MealplanningDrawing.png';
import { Button } from '@material-ui/core';

import RecipesFromChatGPT from 'components/recipes/RecipesFromChatGPT';
//import GPTMenuSearchBar from 'components/chatGPT/gptMenuSearchBar';
import GPTShoppingListDialog from 'components/recipes/ImportChatGPTRecipeDialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RecipeSearch from 'components/recipes/RecipeSearch';
import dbLoader from 'hooks/utils/dbLoader';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
    paddingBottom:80,
  },
  fab: {
    zIndex: 1,
    position: 'relative',
    top: 180,
    right: 50,
    [theme.breakpoints.up('md')]: {
      position: 'absolute', // Change this value to place the FAB closer to the right for md screens and above
    },
    [theme.breakpoints.down('sm')]: {
      top: 120,
      right: 0,
    },
    [theme.breakpoints.down('xs')]: {
      top: 120,
      right: 0,
    },
  },
}));

const Menu = () => {
  const { ruid, uid, anonymous } = useAuth();
  const history = useHistory();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [plan, setPlan] = useState({ loading: true });
  const [preview, setPreview] = useState();
  const [showWizard, setShowWizard] = useState(false);
  const [showHousehold, setShowHousehold] = useState(false);
  const { setNav } = useNav();
  const [recipePreferences, setRecipePreferences] = useState('loading');

  const [searching, setSearching] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [closeSearch, setCloseSearch] = useState(false);



  const updatePlan = () => {
    dbSet({ path: `/users/${uid}/plan`, value: plan.value });
  };

  const insertRecipes = (toBeInserted) => {
    let planRecipes = [];

    // Handle the case where toBeInserted contains a list of recipes
    if (toBeInserted.recipes) {
      let recipes = toBeInserted.recipes;
      recipes.forEach(recipe => {
        recipe.slug = recipe.name.replaceAll(' ', '-');
        recipe.uri = 'made-by-ai';
        recipe.label = recipe.name;
        recipe.source = "ChatGPT";
        recipe.url = "";
        let recipeEasyInsert = { courseId: 'dinner', recipe: recipe, status: { action: "none", type: "new" } };
        planRecipes.push(recipeEasyInsert);
      });
    }

    // Handle the case where toBeInserted contains a single recipe
    else if (toBeInserted.recipe) {
      setCloseSearch(true);
      let recipe = toBeInserted.recipe;
      let recipeEasyInsert = { courseId: 'dinner', recipe: recipe, status: { action: "none", type: "new" } };
      planRecipes.push(recipeEasyInsert);

    }

    // Get the current plan
    const currentPlan = plan; // Assuming 'plan' is your current state

    // Create a set of existing recipe slugs
    const existingSlugs = new Set(currentPlan?.value?.dinners?.map(dinner => dinner.recipe.slug) || []);

    // Filter out recipes that are already in the plan
    const uniqueRecipes = planRecipes.filter(recipe => !existingSlugs.has(recipe.recipe.slug));

    // If all recipes are already in the plan, do nothing
    if (uniqueRecipes.length === 0) {
      return;
    }

    // Update the plan with the new (unique) recipes
    setPlan(prevPlan => {
      if (prevPlan && prevPlan.value && prevPlan.value.dinners) {
        let newDinners = [...prevPlan.value.dinners, ...uniqueRecipes];
        return { ...prevPlan, value: { ...prevPlan.value, dinners: newDinners } };
      }
      return prevPlan;
    });


    /*       // Update the plan with the new recipes
          setPlan(prevPlan => {
            if (prevPlan && prevPlan.value && prevPlan.value.dinners) {
              let newDinners = [...prevPlan.value.dinners, ...planRecipes];
              return {...prevPlan, value: {...prevPlan.value, dinners: newDinners}};
            }
            return prevPlan;
          }); */

  }

  const actionProps = {
    title: undefined,
    course: 'dinner',
    actions: [
      { action: insertRecipes, text: 'Add To Menu', course: 'dinner', icon: <AddCircleOutlineIcon />, ...origin }
    ],
  };

  dbWatcher({ path: 'plan', set: setPlan, source: 'pages/menu/index' });
  let menuMode = '';
  if (anonymous) menuMode = 'fullWeekPlanning';

  useEffect(() => {
    if (plan && plan.value) {
      dbSet({ path: `/users/${uid}/plan`, value: plan.value });
    }
  }, [plan]);

  useEffect(() => {
    if (closeSearch) {
      setSearching(false);
      setSearchActive(false);
      setCloseSearch(false);
    }
  }, [closeSearch]);

  useEffect(() => {
    dbLoader({ uid, path: 'household/recipePreferences' }).then((value) => {
      setRecipePreferences(value || {});
    });
  }, [uid, setRecipePreferences]);

  useEffect(() => {
    setNav((nav) => ({
      ...nav,
      pageName: 'Current Menu',
      //stickyChildren: <GPTMenuSearchBar onSearch={handleGPTSearch}/>,
      stickyChildren: <RecipeSearch
        setSearching={setSearching} setSearchActive={setSearchActive} setPreview={setPreview}
        recipePreferences={recipePreferences} actionProps={actionProps} defaulTerm={''}
        chatGPTEnabled={true} executeClose={closeSearch} searchLabel='Extra meal on the fly? Enter ingredients on hand'
      />,
      actions: (
        <>
          {/* {!showWizard && (
            <Fab
              className={classes.fab} color="primary" size="medium" variant="extended"
              onClick={() => setShowWizard(true)}
            >
              <SvgIcon><GandalfTheWhite /></SvgIcon>
              Plan New Week
            </Fab>
          )}
 */}
        </>
      )
    }));
  }, [setNav, setShowWizard, classes.fab, showWizard, recipePreferences, setSearching, setSearchActive, closeSearch]);

  useEffect(() => {
    const fn = async () => {
      // Show the wizard if the user has never opened it
      if (ruid === uid && !(await hasEvent({ category: 'activity', action: 'household', uid }))) {
        setShowHousehold(false); // switched to false always until we implement premium plan
      }
    };
    fn();
  }, [uid, ruid]);

  useEffect(() => {
    const fn = async () => {
      // Show the wizard if the user has never opened it
      if (ruid === uid && !(await hasEvent({ category: 'activity', action: 'startPlan', uid }))) {
        setShowWizard(true);
      }
    };
    fn();
  }, [uid, ruid]);






  const close = () => {
    setShowHousehold(false);
    addEvent({ action: 'household', category: 'activity', uid });
  };


  const cookMeal = async ({ slug, recipe }) => {
    trace('Menu: cooking meal %s -> %o, keys: %o', slug, recipe);
    console.log(plan);
    let meal;
    let mealType = 'searching';
    // finding the meal
    if (plan.value.dinners) {
      meal = plan.value.dinners.find((m) => m.recipe.slug === slug);
      mealType = meal.courseId;
    }
    if (plan.value.lunches && (mealType === 'searching')) {
      meal = plan.lunches.find((m) => m.recipe.slug === slug);
      mealType = meal.courseId;
    }

    if (plan.value.breakfasts && (mealType === 'searching')) {
      meal = plan.breakfasts.find((m) => m.recipe.slug === slug);
      mealType = meal.courseId;
    }
    console.log(meal);
    // toggling the meal cooked status
    meal.cooked = !meal.cooked;
    updatePlan();
  };

  // setShowHousehold(true);

  const { dinners, lunches, breakfasts } = (plan.value || {});

  useEffect(() => {
    let test = (!showWizard && (dinners || lunches || breakfasts));
    if (searchActive) test = false;
    setNav((nav) => ({
      ...nav,
      pageName: 'Current Menu',
      actions: (
        <>
          {test && (
            <Fab
              className={classes.fab}
              color="secondary"
              size="medium"
              variant="extended"
              onClick={() => setShowWizard(true)}
            >
              <SvgIcon><GandalfTheWhite /></SvgIcon>
              Plan Menu/Groceries
            </Fab>
          )}
        </>
      )
    }));
  }, [setNav, setShowWizard, classes.fab, showWizard, dinners, breakfasts, lunches, searchActive]);


  if (plan.loading) {
    return <LinearProgress />;
  }



  return (
    <Grid container direction="column" alignItems="center" style={{ position: 'relative', minHeight: '90vh', overflow:'auto'}}>
      {!(dinners || lunches || breakfasts) && (
        <Grid container direction="column" alignItems="center">
          <img src={MealPlanningDrawing} alt='mealDrawing' style={{ height: '35vh', marginTop: 20 }} />
          <Button color='secondary' variant='outlined' startIcon={<GandalfTheBlue />} onClick={() => setShowWizard(true)}>Click here to create your menu</Button>

        </Grid>
      )}
      <MemberAddSignUp isOpen={showHousehold} close={close} />
      <Wizard isOpen={showWizard} close={(path) => { setShowWizard(false); if (path) { history.push(path); } }} source="menu" mode={menuMode} />
      <div style={{ position: 'relative', textAlign: 'left', width: '100%', border: '0px solid blue' }}>
        <RecipeModal key="recipe-modal" readonly recipe={preview} close={() => setPreview(null)} />
        <Grid container direction="column" className={classes.container} justifyContent="flex-start" alignItems="flex-start">
          {dinners && (
            <>
              <h2>Dinners</h2>
              <Grid container item justifyContent={xs ? 'flex-start' : 'flex-start'}>
                {dinners.map((meal) => (
                  <MealCard key={`dinner-${meal.recipe.slug}`} meal={meal} count={dinners.length} lg={lg} preview={setPreview} cook={cookMeal} />
                ))}
              </Grid>
            </>
          )}
          {lunches && (
            <>
              <h2>Lunches</h2>
              <Grid container item spacing={0}>
                {lunches.map((meal) => (
                  <MealCard key={`lunch-${meal.recipe.slug}`} meal={meal} count={lunches.length} lg={lg} preview={setPreview} cook={cookMeal} />
                ))}
              </Grid>
            </>
          )}
          {breakfasts && (
            <>
              <h2>Breakfasts</h2>
              <Grid container item>
                {breakfasts.map((meal) => (
                  <MealCard key={`bkfst-${meal.recipe.slug}`} meal={meal} count={breakfasts.length} lg={lg} preview={setPreview} cook={cookMeal} />
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <Grid><img alt="Edamam attribution" style={{ height: 20 }} src={EdamamIcon} /></Grid>
    </Grid>
  );
};

export default Menu;
