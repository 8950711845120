import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import img1 from 'assets/carouselImg1.jpg'
import img2 from 'assets/carouselImg2.jpg'
import img3 from 'assets/carouselImg3.jpg'
import landingImage from 'assets/landing-image.png';
import { ReactComponent as WizardIcon } from 'assets/wizard_icon_white.svg';
import useAuth from 'hooks/useAuth';
import { trace } from 'utils';
import Wizard from 'wizards/plan';
import InitialChoice from 'components/preferences/InitialChoice';

const Landing = ({ ...props }) => {
  const history = useHistory();
  const { loading, uid } = useAuth();
  const [component, setComponent] = useState('landing');
  const [, setLoggingIn] = useState(false);
  const [userMode,setUserMode]=useState('');
  const url_string = window.location;
  const url = new URL(url_string);
  const mealPlanId = url.searchParams.get('mealplan');

  useEffect(() => {
    if (mealPlanId !== null) handleStart();
  }, [mealPlanId]);

  const login = () => {
    setLoggingIn(true);
    firebase.auth().signInAnonymously().then((user) => {
      trace('signed in anon: %o', user);
      setLoggingIn(false);
    });
  };

  const handleStart = () => {
    if (uid === 'visitor') {
      login();
    }
    setComponent('initialChoice');
  };

  const handleLogin = () => {
    const referrer = '/landing';
    props.history.push({ pathname: '/login', state: { referrer } });
  };

  const closeWizard = (path) => {
    setComponent('landing');
    if (path) {
      history.push(path);
    }
  };

  const handleUserChoice=(value)=>{
    setUserMode(value);
    if (value !== 'browseRecipes') {
      setComponent('wizard');
    } else {
      history.push('/recipes');
    }
  }

  if (loading) {
    return null;
  }

  let hb= '66vw';
  let wb='90%';
  if (window.innerWidth>window.innerHeight) {
      hb='40vh';
      wb='60vh';
  };

  switch (component) {
    case 'landing':
      return (
        <>
          <Box sx={{ height: hb, width:wb, overflow: 'hidden' }}>
          <Swiper
              modules={[Navigation,Autoplay]}
              spaceBetween={10}
              slidesPerView={1}
              loop={true}
              autoplay={{ delay: 2500 }}
            >
              <SwiperSlide><img src={landingImage} alt="Landing" style={{objectFit: 'contain', width: '100%', height: '100%'}}/></SwiperSlide>
              <SwiperSlide><img src={img1} alt="Meal 1" style={{objectFit: 'contain', width: '100%', height: '100%'}}/></SwiperSlide>
              <SwiperSlide><img src={img3} alt="Meal 3" style={{objectFit: 'contain', width: '100%', height: '100%'}}/></SwiperSlide>
            </Swiper>
          </Box>

          <Box sx={{width:wb, margin: 'auto'}}>
          <Grid container justifyContent='center' direction='column' alignItems='center'>
          <Typography variant="h5" gutterBottom color='secondary' style={{paddingBottom:15, marginTop:15}}>
            Simplify your life!
          </Typography>

          <Typography gutterBottom>
            Discover a world of personalized, easy-to-cook recipes and never forget a grocery item again. We're here to simplify your life, one delicious meal at a time.
          </Typography>
          </Grid>
          </Box>

          <Box sx={{ marginTop: 60, width:wb, margin: 'auto'}}>
            <Grid container direction='column' sx={{marginTop:20}}>
          <Button variant="contained" color="secondary" onClick={handleStart} startIcon={<SvgIcon><WizardIcon /></SvgIcon>} style={{ borderRadius: 23, backgroundColor:'#483D8B', color:'#fff' }}>
              New User? Start Here!
            </Button>
            <Button variant="text" onClick={handleLogin} style={{color:'#483D8B'}}>
              Log In
            </Button>
            <div style={{paddingBottom:60}}/>
            </Grid>
          </Box>
        </>
      );
    case 'initialChoice':
      return <InitialChoice onUserChoice={handleUserChoice} />;
    case 'wizard':
      return <Wizard key="wizard" isOpen={true} close={closeWizard} source="landing" mode={userMode}/>;
    default:
      return null;
  }
};

export default Landing;
