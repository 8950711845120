import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import callChatGPTFunction from 'utils/chatgptUtils';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core'



function cleanString(jsonString) {
  let str = jsonString;
  const replacedStr = str.replace(/(\d+)?\s*(\d+)\s*\/\s*(\d+)/g, function (_, whole, numerator, denominator) {
    let wholeNumber = whole ? parseFloat(whole) : 0;
    let fraction = parseFloat(numerator) / parseFloat(denominator);
    let result = wholeNumber + fraction;
    let decimalPlaces = (result.toString().split('.')[1] || []).length;

    if (decimalPlaces > 2) {
      result = result.toFixed(2);
    }

    return result;
  });
  return replacedStr;
}




const GPTShoppingListDialog = ({ open, onClose, setOpen, recipes }) => {
  const [skipIngredients, setSkipIngredients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayIngredients, setDisplayIngredients] = useState(false);
  const [recipesDis, setRecipesDis] = useState([]);
  const [error, setError] = useState(false);


  // ask chatGPT to convert the ingredients in a recipe (under ingredientsDirect) to a format that the rest of Guustav uses
  // ingredientsDirect contains what will become the recipe text
  const transformIngredients = async (recipe) => {
    let prompt = 'without any additional comments, convert this list of ingredients to a json array of objects describing each ingredient for a recipe in the format {text provided, quantity, unit of measure, product, store_section}, do not leave store sections blank and use numbers and only numbers for quantity, response has to be in json and only json, do not add any text:';
    recipe.ingredientsDirect.forEach((el) => {
      prompt = prompt + ' ' + el + ';';
    });
    try {
      let response = await callChatGPTFunction(prompt);
      let cleanResponse = response.replaceAll("store_section", "department");
      cleanResponse = cleanResponse.replaceAll("product", "food");
      cleanResponse = cleanResponse.replaceAll("text provided", "text");
      cleanResponse = cleanString(cleanResponse);
      let arr = JSON.parse(cleanResponse);
      arr.forEach((ingr) => {
        let slug = ingr.food.replaceAll(' ', '-');
        ingr.slug = slug.toLowerCase();
      })
      let result = recipe;
      result.ingredients = arr;
      return result;
    } catch (error) {
      setLoading(false);
      console.error('Error fetching ingredients:', error);
    };
  }


  const handleIngredientToggle = (ingredient) => {
    if (skipIngredients.includes(ingredient)) {
      setSkipIngredients(skipIngredients.filter(i => i !== ingredient));
    } else {
      setSkipIngredients([...skipIngredients, ingredient]);
    }
  };

  const handleSave = () => {
    console.log('Ingredients that wont be saved: ', skipIngredients);
    console.log(recipesDis);
    console.log(skipIngredients);
    let ingredientsList = [];
    recipesDis.forEach((recipe) => {
      ingredientsList = ingredientsList.concat(recipe.ingredients);
    })
    // removing deselected elements from array
    skipIngredients.forEach((ingr) => {
      const index = ingredientsList.indexOf(ingr);
      if (index !== -1) {
        ingredientsList.splice(index, 1);
      }
    })
    onClose(
      { recipes: recipesDis, shoppingList: ingredientsList }
    );
    setOpen(false);
    setDisplayIngredients(false);
  };

  const handleAddIngredients = async () => {
    setLoading(true);
    try {
      let tempRecipes = recipes;
      const promises = tempRecipes.map(transformIngredients);
      const results = await Promise.all(promises);
      setRecipesDis(results);

      setLoading(false);
      setDisplayIngredients(true);
    }
    catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    onClose({recipes:recipes, shoppingList:[]});
  }

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <>
          {/*  first we show a dialog asking if the user even wants to add the ingredients */}
          {(!displayIngredients && !loading) && (
            <>
              <DialogTitle id="form-dialog-title">Would you like to add the ingredients for these recipes to your shopping list?</DialogTitle>
              <DialogContent>
                {recipes.map(recipe => (<Typography key={recipe.name}>- {recipe.name}</Typography>))}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Just add recipes
                </Button>
                <Button onClick={handleAddIngredients} color="primary">
                  Yes
                </Button>
              </DialogActions>
            </>
          )}
          {/*  If the user says they want to add the ingredients to the shopping list, we ask them to pick which ingredients they want to add */}
          {(displayIngredients && !loading) && (<><DialogTitle>Select the ingredients you want to add to your shopping list</DialogTitle><DialogContent>
            {recipesDis.map(recipe => (
              <>
                <Typography style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{recipe.name}:</Typography>
                <div key={recipe.name + 'div'} style={{ display: 'flex', flexDirection: 'column' }}>
                  {recipe.ingredients.map(ingredient => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!skipIngredients.includes(ingredient)}
                          onChange={() => handleIngredientToggle(ingredient)}
                          name={ingredient}
                          color="primary"
                        />
                      }
                      label={`${ingredient.text}`}
                      key={`${ingredient.text}+${recipe.name}`}
                    />
                  ))}
                </div></>))}
          </DialogContent><DialogActions>
              <Button onClick={handleSave} color="primary">
                Add
              </Button>
            </DialogActions></>)}
          {loading && (<DialogContent>
            <Grid container direction='column' justifyContent='center' alignContent='center' alignItems='center'>
              <Typography>Processing Ingredients... </Typography>
              <CircularProgress color="inherit" />
            </Grid>
          </DialogContent>)}
          {error && (<><DialogContent>
            <Typography>An error occured with ChatGPT, please try again </Typography>
          </DialogContent>
            <DialogActions>
              <Button onClick={() => { setError(false) }} color="primary">
                Ok
              </Button>
            </DialogActions></>)}

        </>
      </Dialog>

    </div>
  );
};

export default GPTShoppingListDialog;
