import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import { fullMeal, keywords } from 'guustav-shared';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';

import { GET_EDAMAM_RECIPE_BY_URI } from 'hooks/queries';
import { dbUpdate } from 'hooks/utils/dbUpdater';
import { timeStamp, traceError } from 'utils';

const products = keywords.filter((k) => !k.cacheOnly).map((k) => k.product).sort();

const ApproveRecipeDialog = ({ recipe, open, onSave, onClose, person }) => {
  const [product, setProduct] = useState(recipe ? recipe.product || '' : '');
  const [approved, setApproved] = useState(false);
  const [message, setMessage] = useState(null);
  const [fullMealResult, setFullMealResult] = useState(null);
  const [edamamData, setEdamamData] = useState(null);

  const { data, error } = useQuery(GET_EDAMAM_RECIPE_BY_URI, {
    variables: { uri: recipe ? recipe.uri : null },
    skip: !recipe || !recipe.uri,
  });

  useEffect(() => {
    setEdamamData(null);
    setFullMealResult(null);
    setMessage(null);
    setProduct('');
    setApproved(recipe ? !!recipe.approved : false);
  }, [recipe]);

  useEffect(() => {
    if (error) {
      traceError(error);
      setMessage('Could not load recipe details. You can still approve it if you are sure.');
      return;
    }
    if (data && data.getEdamamRecipeByURI) {
      const r = JSON.parse(data.getEdamamRecipeByURI);
      setEdamamData(r);
      const fm = fullMeal(r);
      setFullMealResult(fm);
      if (!fm.fullMeal) {
        setMessage('Warning: this is not a full meal based on our algorithm.');
      }
      setProduct((current) => {
        if (current) {
          return current;
        }
        if (recipe.product) {
          return recipe.product;
        }
        const main = products.find((p) => {
          const pLower = p.toLowerCase();
          const exact = r.ingredients.find((ingr) => ingr.food.toLowerCase() === pLower);
          if (exact) {
            return exact;
          }
          const partial = r.ingredients.find((ingr) => ingr.food.toLowerCase().split(' ').some((i) => i === pLower));
          if (partial) {
            return partial;
          }
          return null;
        });
        if (main) {
          return main;
        }
        return '';
      });
    }
  }, [data, error, recipe]);

  const save = async () => {
    if (!product) {
      setMessage('You need to pick a main ingredient');
      return;
    }
    // This is a new item so we need to add it to the cache
    const summary = pick(edamamData, ['label', 'source', 'uri', 'image', 'dietLabels', 'healthLabels', 'url']);
    summary.time = edamamData.totalTime;
    summary.ingredients = edamamData.ingredients.length;
    summary.product = product;
    const keyword = keywords.find((k) => k.product === product);
    summary.gourmet = keyword ? keyword.gourmet : false;
    summary.slug = recipe.slug;
    summary.fullMeal = fullMealResult;
    summary.approved = { [person]: timeStamp() };
    const updates = {};
    updates[`/cache/recipes/${product}/${recipe.slug}`] = summary;
    if (approved) {
      updates[`/approved/${recipe.slug}`] = summary;
    }
    updates[`/review/${recipe.slug}`] = null;
    await dbUpdate({ path: '/', updates });
    if (onSave) {
      onSave(summary);
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
        <div>Add Recipe to Approved List</div>
        <DialogContentText>{startCase((recipe || {}).label)}</DialogContentText>
      </DialogTitle>
      <DialogContent dividers>
        {message && (
          <Alert severity="error">
            {message}
          </Alert>
        )}
        <Grid container>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel id="product-label">Choose Main Ingredient</InputLabel>
              <Select
                required labelId="product-label" autoWidth
                value={product}
                onChange={(ev) => setProduct(ev.target.value)}
              >
                <MenuItem value="" />
                {products.map((product) => (
                  <MenuItem key={product} value={product}>{product}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormControlLabel
                value="approved" label="Mark as Guustav Approved" labelPlacement="end"
                control={(
                  <Checkbox
                    color="primary" name="approved"
                    checked={approved} onChange={(ev) => setApproved(ev.target.checked)}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        {edamamData && (
          <div style={{ width: '100%', marginTop: 10 }}>
            <h5 style={{ padding: 0, margin: 0 }}>Ingredients:</h5>
            <ul>
              {edamamData.ingredients.map((ingr, idx) => (
                <li key={`ingr-${idx}`} style={{ fontWeight: product && product.toLowerCase() === ingr.food.toLowerCase() ? 'bold' : 'normal' }}>{ingr.text} ({ingr.food})</li>
              ))}
            </ul>
            <h5 style={{ padding: 0, margin: 0 }}>Full Meal:</h5>
            {fullMealResult && (
              <ul style={{ listStyle: 'none' }}>
                {Object.keys(fullMealResult).map((key) => (
                  <li key={key}>{startCase(key)}: {JSON.stringify(fullMealResult[key])}</li>
                ))}
              </ul>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={save} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveRecipeDialog;
