import firebase from 'firebase/app';
import 'firebase/auth';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';

import dbLoad from 'hooks/utils/dbLoader';
import { dbSet, dbUpdate } from 'hooks/utils/dbUpdater';
import { timeStamp, trace, traceError } from 'utils';

const signInWithMerge = async (credential) => {
  try {
    trace('signInWithMerge credential: %o', credential);
    const oldUser = firebase.auth().currentUser;
    if (!oldUser) {
      trace('signInWithMerge: no older user, just signing in');
      const authInfo = await firebase.auth().signInWithCredential(credential);
      trace('signInWithMerge: authInfo: %o', authInfo);
      return authInfo;
    }
    trace('signInWithMerge oldUser: %o', oldUser);
    let fromData;
    if (oldUser.isAnonymous) {
      fromData = await dbLoad({ uid: oldUser.uid, path: '', source: 'signInWithMerge' });
    }
    // This will trigger a background function to delete the user
    await dbSet({ uid: oldUser.uid, path: 'merged', value: 'true', source: 'signInWithMerge' });
    const authInfo = await firebase.auth().signInWithCredential(credential);
    trace('signInWithMerge: authInfo: %o', authInfo);
    const { uid } = authInfo.user;
    trace('signInWithMerge: success uid: %s, %o', uid, authInfo);
    if (fromData && oldUser.uid !== uid) {
      trace('signInWithMerge: needs merge');
      const toData = await dbLoad({ uid, path: '', defaultValue: {}, source: 'signInWithMerge' });
      const updates = mergeUsers({ fromData, fromUid: oldUser.uid, toData, toUid: uid });
      await dbUpdate({ uid, path: '', updates, source: 'signInWithMerge' });
      trace('signInWithMerge: merge complete, attempting to delete anonymous user');
      try {
        await oldUser.delete();
      } catch (ex) {
        trace('signInWithMerge: error delete anonymous user; will let function do it: [%s]', ex.message);
      }
    } else {
      trace('signInWithMerge: merge not needed');
    }
    return authInfo;
  } catch (ex) {
    trace(ex);
    traceError('signInWithMerge: error [%s]', ex.message);
    return null;
  }
};

/*
 * There are two situations.
 * 1. We are a regular user but did stuff anonymously and now need to get all that into
 *    the regular user. They may have added custom recipes, changed preferences, etc.
 *
 * 2. We are on the app and just started with the wizard, but have now logged in with
 *    a new account and need to get everything from the anonymous user into the new
 *    user. In this case, the anon user should only have a plan, ingredients, and shopping list.
 *
 * We can probably just treat them the same - worst-case, the fromUser won't have any data
 * to copy.
 *
 * It really shouldn't be possible to do much besides create a plan/ingredients/shopping list
 * anonymously, but let's copy everything over just in case.
 */
const mergeUsers = ({ fromData, fromUid, toData, toUid }) => {
  trace('mergeUsers: from: %o, to: %o', fromData, toData);
  const updates = {};

  // Plan
  // Save off the existing user plan if necessary
  if (toData.plan) {
    updates[`planHistory/${timeStamp()}`] = toData.plan;
  }
  // Always use the most recent plan
  if (fromData.plan) {
    updates.plan = fromData.plan;
  }

  // Shopping list
  // Keep any original shopping list items that were added manually, i.e.
  // didn't come from a recipe, since we are replacing all the recipe ones.
  const newShoppingList = {};
  if (toData.plan && toData.shoppingList) {
    Object.entries(toData.shoppingList).forEach(([slug, item]) => {
      if (item.recipes) {
        newShoppingList[slug] = null;
      } else {
        newShoppingList[slug] = item;
      }
    });
  }
  Object.assign(newShoppingList, { ...fromData.shoppingList });
  updates.shoppingList = newShoppingList;

  // Account
  // Assume the new user has it's account info set from the login process
  if (get(fromData, 'account.preferences') && !get(toData, 'account.preferences')) {
    updates.account = { preferences: fromData.account.preferences };
  }

  // If they changed their household anonymously, too bad
  if (fromData.household && !toData.household) {
    updates.household = fromData.household;
  }

  // Recipes
  // This could include likes and even custom recipes created anonymously
  updates.recipes = merge(fromData.recipes, toData.recipes);

  // Events
  // Merge all the events together
  const customizer = (objValue, srcValue) => {
    if (isArray(objValue)) {
      return objValue.concat(srcValue);
    }
    // Change the UIDs
    if (srcValue === fromUid) {
      return toUid;
    }
  };
  updates.events = mergeWith(toData.events, fromData.events, customizer);

  // Actions
  updates.actions = mergeWith(toData.actions, fromData.actions, customizer);

  trace('mergeUsers: updates: %o', updates);
  return updates;
};

export { signInWithMerge };
export default mergeUsers;
