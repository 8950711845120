import React, { useContext, useMemo, useState } from 'react';

const ErrorContext = React.createContext({});

const ErrorContextProvider = ({ children }) => {
  const [error, setError] = useState({});
  const value = useMemo(() => [error, setError], [error, setError]);
  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};

const useError = () => useContext(ErrorContext);

export default useError;
export { ErrorContextProvider };
