import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';

import useStyles from './assets/useStyles';
import BottomNav from './components/nav/BottomNav';
import TopNav from './components/nav/TopNav';
import Router from './components/Router';
import useAuth from './hooks/useAuth';
// needs to be after material imports, included nested in any component referenced from here
import useSnack from './hooks/useSnack';

import { isNativeModal, trace } from 'utils';

const StyleContext = React.createContext();

const App = ({ history, ...props }) => {
  const classes = useStyles();
  const { loading } = useAuth();
  const { snack, setSnack } = useSnack();
  const [pub, setPub] = useState(true);

  useEffect(() => {
    trace('App mounted');
    const timer = setInterval(() => console.log('ping'), 3600000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <StyleContext.Provider value={classes}>
      <Paper elevation={3} className={classes.root}>
        {!isNativeModal() && (
          <TopNav pub={pub} {...props} />
        )}

        <div className={classes.contents} style={{ border: '0px solid green' }}>
          {loading && (
            <LinearProgress style={{ width: '100%' }} />
          )}
          {!loading && (
            <Grid
              id="routerWrapper" container justifyContent="center" alignItems="center" direction="column"
              style={{ border: '0px solid orange', position: 'relative' }}
            >
              <Router setPub={setPub} {...props} />
            </Grid>
          )}
        </div>
        {!isNativeModal() && (
          <BottomNav {...props} />
        )}
      </Paper>
      <Snackbar
        message={snack.message} open={!!snack.message} autoHideDuration={snack.undo ? 10000 : 5000}
        onClose={() => setSnack({})}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        action={(
          <>
            {snack.undo && (
              <Button color="secondary" size="small" onClick={snack.undo}>
                UNDO
              </Button>
            )}
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnack({})}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      />
    </StyleContext.Provider>
  );
};

export default withRouter(App);
export { StyleContext };
