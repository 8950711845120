import React, { useEffect, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Navigation from '@material-ui/icons/Navigation';

// import tourSteps from './tourSteps';

import ProductList from 'components/productList';
import SortMenu from 'components/productList/SortMenu';
import ProductSearchForm from 'components/productSearch/ProductSearchForm';
// import useTour from 'hooks/useTour';
import { buildShoppingList } from 'hooks/utils/shoppingListUtils';
import { trace, traceError } from 'utils';
import GroceryFavorites from 'components/productList/GroceryFavorites';
import { Button,Dialog,DialogContent, IconButton, DialogActions,DialogTitle, DialogContentText } from '@material-ui/core';
import GroceryIcon from '@material-ui/icons/FlashOn';
import { ReactComponent as GandalfTheWhite } from 'assets/wizard_icon_blue.svg';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: 4,
    paddingRight: 4,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
}));

const displayProps = {
  type: 'switch',
  recipeIcon: true,
  quantityText: true,
  notes: 'none',
  lastPurchased: true,
  frequency: true,
  quantity: 'editable', // readonly, none, or see if setQuantity exists
  source: 'wizard-ingredients',
};

const Ingredients = ({ state, setState, step, uid, email, finishStep, enableNext }) => {
  const pageClasses = useStyles();
  const [pageState, setPageState] = useState('loading');
  // const [toggledOff, setToggledOff] = useState(state.toggledOff);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [sort, setSort] = useState('Pantry');
  // const tour = useTour({ id: 'wizard-ingredients', steps: tourSteps, active: step.id === 'ingredients', skipReference: 'plan' });

  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpen = () => {
    setDialogOpen(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (items) => {
    //compare items to selectedItems to see what's been added or removed
    let addedItems=items;
    let removedItems=[];
    if (selectedItems!==[]){
      addedItems = items.filter(item => !selectedItems.includes(item));
      removedItems = selectedItems.filter(item => !items.includes(item));}
  
    // Update pageState with added and removed items
    setPageState(ps => {
      let updatedState = [];
      if (ps!=='loading') updatedState= { ...ps };
  
      // Add new items with quickPick flag set to true
      addedItems.forEach(item => {
        // The structure of your item object may vary.
        // Adjust the following line to suit your needs.
        updatedState[item.slug] = { ...item, needed: true, added: true, quickPick: true };
      });
  
      // Fully remove quickPick items
      removedItems.forEach(item => {
        if (updatedState[item.slug] && updatedState[item.slug].quickPick) {
          delete updatedState[item.slug];
        }
      });
  
      return updatedState;
    });
  
    setSelectedItems(items);
    setOpen(false);
  };
  

  /* useEffect(() => () => {
    if (tour) {
      tour.cancel();
    }
  }, [tour]);
 */
  const topListener = () => {
    setShowBackToTop(document.getElementById('plan-wizard-content').scrollTop > 200);
  };

    // Prompt the user asking if they want to use Quick Grocery select the first time they land on the page
    useEffect(() => {
      if (step.id === 'ingredients' && !localStorage.getItem('askedQuickGrocery')) {
        localStorage.setItem('askedQuickGrocery', 'true');
        setDialogOpen(true);
      }
    }, [step.id]);

  useEffect(() => {
    if (step.id === 'ingredients') {
      const el = document.getElementById('plan-wizard-content');
      if (el) {
        el.addEventListener('scroll', topListener);
        return () => {
          if (el) {
            el.removeEventListener('scroll', topListener);
          }
        };
      }
    }
  }, [step.id]);

  useEffect(()=>{
    if (pageState!=='loading') enableNext(true);
  },[pageState,enableNext]);

  useEffect(() => {
    trace('Wizard.Ingredients ue: step: %o, plan: %o, qp: %o', step, state.plan, state.quantityPreferences);
    if (step.id === 'ingredients' && step.entering && state.plan) {
      const { breakfasts, lunches, dinners } = state.plan;
      console.log(state.plan);
      // Need to save off not needed so when we come back we can restore the user's choices
      buildShoppingList({ uid,
        toggledOff: state.toggledOff,
        dinners,
        lunches,
        breakfasts,
        quantityPreferences: state.quantityPreferences }).then((items) => {
        trace('Wizard.Ingredients: Initial grocery list: %o', items);
        setPageState(items);
        enableNext(true);
        /*
          // All ingredients will be needed unless user already toggled some
          // Previous purchases will be based on due date
          return Object.values(items).filter((i) => i.needed).reduce((map, i) => Object.assign(map, { [i.slug]: true }), {});
        */
      }).catch((err) => {
        enableNext(true);
        traceError(err);
        return {};
      });
    }
  }, [enableNext, step, state.plan, uid, state.quantityPreferences, state.toggledOff, setPageState]);

  useEffect(() => {
    if (step.id === 'ingredients' && step.exiting) {
      trace('Wizard.Ingredients: Updating ingredients: %o', pageState);
      // Keep track of not needed so we don't keep undoing the users toggles
      setState((state) => ({
        ...state,
        items: Object.values(pageState || {})
          .filter((item) => !!item.needed)
          .reduce((map, item) => Object.assign(map, { [item.slug]: { ...item, needed: true } }), {}),
        toggledOff: Object.values(pageState || {})
          .filter((item) => !item.needed)
          .reduce((map, item) => Object.assign(map, { [item.slug]: { ...item, needed: false } }), {}),
      }));
      finishStep();
    }
  }, [step, pageState, setState, finishStep]);

  if (step.id !== 'ingredients') {
    return null;
  }

  const toggle = ({ products, on }) => {
    // TODO: if this is too slow, we'll use a toggle map
    setPageState((ps) => {
      products.forEach((product) => {
        ps[product.slug].needed = on;
      });
      return { ...ps };
    });
  };

  const selectProduct = (product) => {
    setPageState((ps) => ({
      ...ps,
      [product.slug]: { ...product, needed: true, added: true },
    }));
  };

  const setQuantity = ({ product, value }) => {
    setPageState((ps) => ({
      ...ps,
      [product.slug]: { ...product, actual: value },
    }));
  };

  let items = {};
  console.log(pageState);
  if (pageState !== 'loading') { items = { ...state.picks, ...pageState }; } else { items = state.picks; }
  console.log(items);
  console.log(pageState);

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={0}>
        <ProductSearchForm selectProduct={selectProduct} productList={items} />
      

      <Grid container item direction="row" alignitems="center" justifyContent="space-around" >
        <Grid item xs={9}>
          <Typography>Here are the items that will go into your shopping list, remove what you have in the house or add anything you need</Typography>
        </Grid>
        <Grid item xs={1}>
          <SortMenu onSort={(sort) => setSort(sort)} sort={sort} />
          <IconButton onClick={handleOpen} aria-label="quick-grocery">
            <GroceryIcon />
          </IconButton>
        </Grid>
      </Grid>
      </AppBar>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <GroceryFavorites onSelected={handleSelect} open={open} uid={uid}/>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Quick Grocery Select"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to use the Quick Grocery Select feature? You can select it anytime by clicking on the lightning icon!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            No
          </Button>
          <Button onClick={handleOpen} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container item direction="column" justifyContent="flex-start" alignItems="center" className={pageClasses.container}>
        <ProductList
          displayProps={displayProps} products={pageState} sort={sort} source="ingredientsLikely"
          toggle={toggle} uid={uid} email={email} setQuantity={setQuantity}
        />
        <Fab
          size="small" aria-label="back to top" variant="extended"
          style={{ display: showBackToTop ? '' : 'none', position: 'absolute', right: 10, bottom: 100 }}
          onClick={() => document.getElementById('plan-wizard-content').scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <Navigation />
          Back to Top
        </Fab>
      </Grid>
    </>
  );
};

export default Ingredients;
