import { useEffect } from 'react';

import grocers from 'data/grocers';

let w = null;

const OnlineStore = ({ store, onClose, item }) => {
  useEffect(() => {
    const url = grocers[store].url(item);
    if (w) {
      w.location = url;
    } else {
      w = window.open(url);
      window.guustavStoreWindow = w;
    }
  }, [store, item]);

  return null;
};

export default OnlineStore;
