import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Skipped from '@material-ui/icons/NotInterested';
import Skip from '@material-ui/icons/NotInterestedOutlined';
import Repeated from '@material-ui/icons/Repeat';
import Repeat from '@material-ui/icons/RepeatOutlined';
import Disliked from '@material-ui/icons/ThumbDown';
import Dislike from '@material-ui/icons/ThumbDownOutlined';
import Liked from '@material-ui/icons/ThumbUp';
import Like from '@material-ui/icons/ThumbUpOutlined';

const customRatings = {
  skipped: {
    label: 'Did not have/skip',
    Icon: Skip,
    FilledIcon: Skipped,
  },
  disliked: {
    label: 'Disliked - never show it again',
    Icon: Dislike,
    FilledIcon: Disliked,
  },
  liked: {
    label: 'Show similar recipes',
    Icon: Like,
    FilledIcon: Liked,
  },
  repeated: {
    label: 'Repeat periodically',
    Icon: Repeat,
    FilledIcon: Repeated,
  }
};

const useStyles = makeStyles((theme) => ({
  filledIcon: {
    cursor: 'pointer',
    color: 'orange',
    fontSize: 28,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      marginLeft: 14,
    }
  },
  emptyIcon: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0.26)',
    fontSize: 28,
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      marginLeft: 14,
    }
  },
  mealLabel: {
    paddingLeft: 8,
    paddingBottom: 4,
    fontWeight: 'bold',
    fontSize: '.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.0rem',
    },
  },
  helpText: {
    fontSize: '.7rem',
    paddingLeft: 8,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.0rem',
    },
  },
}));

const MealRating = ({ meal, value, setValue }) => {
  const [hover, setHover] = useState(-1);
  const classes = useStyles();

  return (
    <Grid container item style={{ borderTop: '1px solid #ccc' }} alignItems="center">
      <Grid item xs={12} style={{ paddingBottom: 8 }}>
        <div className={classes.mealLabel}>{meal.recipe.label}</div>
      </Grid>
      <Grid item xs={6}>
        {Object.entries(customRatings).map(([key, { label, Icon, FilledIcon }]) => (
          <span key={`icon-${key}`}>
            {value === key && (
              <FilledIcon
                onMouseOver={() => setHover(key)}
                onMouseOut={() => setHover(-1)}
                onClick={() => setValue(null)}
                className={(value === key && hover < 0) || hover === key ? classes.filledIcon : classes.emptyIcon}
              />
            )}
            {value !== key && (
              <Icon
                onMouseOver={() => setHover(key)}
                onMouseOut={() => setHover(-1)}
                onClick={() => setValue(key)}
                className={(value === key && hover < 0) || hover === key ? classes.filledIcon : classes.emptyIcon}
              />
            )}
          </span>
        ))}
      </Grid>
      <Grid item xs={6}>
        <span className={classes.helpText}>
          {hover === -1 && <span>{(customRatings[value] || { label: '' }).label}</span>}
          {hover !== -1 && <span>{customRatings[hover].label}</span>}
        </span>
      </Grid>
    </Grid>
  );
};

export default MealRating;
