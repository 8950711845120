import firebase from 'firebase/app';
import 'firebase/database';
import { populateOneProfileRecipeCache, populateOneUserRecipeExcluded, utils } from 'guustav-shared';

const refreshUserRecipeExcluded = async ({ uid }) => populateOneUserRecipeExcluded({ uid, db: firebase.database(), refresh: false });

const refreshProfileRecipeCache = async ({ uid, recipePreferences }) => {
  const key = utils.recipePreferencesToKey(recipePreferences);
  const db = firebase.database();
  const profileRecipes = (await db.ref(`/profiles/${key}/recipes`).once('value')).val();
  if (!profileRecipes || !Object.keys(profileRecipes).length) {
    // First time we've seen this profile, so populate it from global recipe cache
    await populateOneProfileRecipeCache({ db, recipePreferences });
  }
};

export { refreshProfileRecipeCache, refreshUserRecipeExcluded };
