import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import camelCase from 'lodash/camelCase';

import Header from './Header';
import NotesDialog from './NotesDialog';
import ProductRow from './ProductRow';
import useStyles from './styles';
import { dueSort, nameSort, departmentSort, recipeSort, likelySort } from './utils';

import RecipeModal from 'components/recipes/RecipeModal';

/*
const displayProps = {
  type: 'switch',  // checkbox, none
  recipeIcon: true,
  quantityText: true,
  notes: 'editable', // readonly, none
  lastPurchased: false,
  frequency: false,
  quantity: 'editable', // readonly, none, or see if setQuantity exists
};

sort:
  name - will sort into one list by name
  department - will group into sorted departments
  recipe = will group into sorted recipes
toggle: ({ products, on }) =...
toggled: { slug: true/false } # optional, will use product.needed otherwise
*/

const sorts = {
  'By Department': departmentSort,
  'By Recipe': recipeSort,
  Pantry: likelySort,
  Alphabetical: nameSort,
  'Due for Purchase': dueSort,
};

const defaultProps = {
  type: 'switch', // checkbox, none
  recipeIcon: true,
  quantityText: true,
  notes: 'editable', // readonly, none
  lastPurchased: false,
  frequency: false,
  quantity: 'editable', // readonly, none, or see if setQuantity exists
  remove: false,
  source: '', // for debugging
};

/*
const findFirst = (hash) => {
  if (!hash) {
    return null;
  }
  const items = Object.values(hash);
  if (items.length === 0) {
    return null;
  }
  if (items[0] instanceof Array) {
    return findFirst(items[0]);
  }
  return items[0];
};
*/

const ProductList = ({ displayProps, products, remove, saveNote, setQuantity, sort, source, toggle, toggled, uid, email, setSortedItems }) => {
  const classes = useStyles();
  const [currentSort, setCurrentSort] = useState('loading');
  const [previewRecipe, setPreviewRecipe] = useState(null);
  const [noteProduct, setNoteProduct] = useState(null);

  displayProps = { ...defaultProps, ...(displayProps || {}), setPreviewRecipe, setNoteProduct };

  useEffect(() => {
    if (products === 'loading') {
      return;
    }
    let mounted = true;
    const fn = sorts[sort] || nameSort;
    fn({ products: products || {}, source }).then((sorted) => {
      if (mounted) {
        setCurrentSort({ actualSort: sort, sorted });
        if (setSortedItems) {
          const flat = [];
          Object.values(sorted).forEach((dept) => {
            flat.push(...Object.values(dept));
          });
          setSortedItems(flat);
          /*
        if (setTopItem) {
          const firstItem = findFirst(sorted)
          setTopItem(firstItem);
          */
        }
      }
    });
    return () => {
      mounted = false;
    };
  }, [products, sort, source, setCurrentSort, setSortedItems]);

  let { actualSort, sorted } = currentSort;
  if (currentSort === 'loading' || !actualSort) {
    return <LinearProgress />;
  }
  if (Object.keys(products || {}).length === 0) {
    return null;
  }
  const rows = [];
  rows.push(<RecipeModal key="recipe-modal" readonly recipe={previewRecipe} close={() => setPreviewRecipe(null)} />);
  rows.push(
    <NotesDialog
      key="product-notes" open={!!noteProduct} note={noteProduct ? noteProduct.notes : ''} slug={noteProduct ? noteProduct.slug : null}
      title={`Notes for ${noteProduct ? noteProduct.name : ''}`} onClose={() => setNoteProduct(null)} onSave={saveNote}
    />
  );

  if (email === 'retaildemo@guustav.com') {
    Object.values(products).forEach((product, idx) => {
      product.price = Math.round((Math.random() * 1000)) / 100;
    });
  }

  console.debug('current sort: %o, sorted: %o', currentSort, sorted);
  if (actualSort === 'Pantry') {
    const { likely, unlikely } = sorted;
    if (Object.keys(likely).length) {
      rows.push(
        <Grid container item key="likely" justifyContent="flex-start" style={{ marginBottom: 20, marginTop: 0 }}>
          <Grid item>
            <Header
              displayProps={displayProps} group="Recipe ingredients you likely already have."
              products={likely} toggle={toggle} toggled={toggled}
            />
          </Grid>
        </Grid>
      );
      Object.values(likely).forEach((product, idx) => {
        rows.push(
          <ProductRow
            key={`product-${product.slug}`} classes={classes} product={product}
            displayProps={displayProps} remove={remove} setQuantity={setQuantity} toggle={toggle} toggled={toggled}
            uid={uid}
          />
        );
      });
    }
    if (Object.keys(unlikely).length) {
      rows.push(
        <Grid container item key="unlikely" justifyContent="flex-start" style={{ marginBottom: 20, marginTop: 30 }}>
          <Grid item>
            <Header
              displayProps={displayProps}
              group="Items you probably need to buy."
              products={unlikely} toggle={toggle} toggled={toggled}
            />
          </Grid>
        </Grid>
      );
      sorted = unlikely;
      actualSort = 'By Department';
    }
  }
  if (actualSort.match(/By Recipe|By Department/)) {
    Object.keys(sorted).forEach((group, idx) => {
      const items = sorted[group];
      rows.push(
        <Grid container item key={`group-${camelCase(group)}`} justifyContent="flex-start" style={{ marginBottom: 20, marginTop: idx > 0 ? 30 : 0 }}>
          <Grid item>
            <Header displayProps={displayProps} group={group} products={items} toggle={toggle} toggled={toggled} />
          </Grid>
        </Grid>
      );
      items.forEach((product, idx) => {
        rows.push(
          <ProductRow
            key={`product-${product.slug}`} classes={classes} product={product}
            displayProps={displayProps} remove={remove} setQuantity={setQuantity} toggle={toggle} toggled={toggled}
            uid={uid}
          />
        );
      });
    });
  } else {
    (sorted || []).forEach((product, idx) => {
      rows.push(
        <ProductRow
          key={`product-${product.slug}`} classes={classes} product={product}
          displayProps={displayProps} remove={remove} setQuantity={setQuantity} toggle={toggle} toggled={toggled}
          uid={uid}
        />
      );
    });
  }
  rows.push(<div key="divider" style={{ marginTop: 20, marginBottom: 20 }} />);
  return rows;
};

export default ProductList;
