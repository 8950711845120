import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', /* 16:9 */
    /* paddingTop: '56%', */
    /* borderRadius: '50%', */
  },
  content: {
    padding: 0,
    flexGrow: 1,
    '& p': {
      padding: 0,
      marginTop: 10,
      marginBottom: 0,
      textAlign: 'center',
      overflow: 'hidden',
    }
  },
  cardHeader: {
    padding: 8,
    alignItems: 'flex-start',
    // borderBottom: '1px solid #ccc',
  },

  avatar: {
    marginRight: 0,
    marginTop: 0,
  },
  toggle: {
    padding: '0!important',
    marginRight: 8,
    border: 'none!important',
    backgroundColor: '#ffffff!important',
    /*
    borderRadius: '20%',
    border: '2px solid #bbb',
    */
  },
  toggleSelected: {
    border: 'none!important',
    color: `${theme.palette.success.main}!important`,
  },
  action: {
    position: 'relative',
    top: 0,
    left: 0,
  },
  deleteIcon: {
    /* background: theme.palette.background.default, */
    color: theme.palette.error.main,
  },
  /*
  headerContent: {
    '& span': {
      display: '-webkit-box',
      '-webkitLineClamp': 2,
      '-webkitBoxOrient': 'vertical',
      lineHeight: '1.6rem',
      fontSize: '1.0rem',
      height: '3.2rem',
      overflow: 'hidden',
      fontWeight: 'bold',
      [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        fontSize: '1.1rem',
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        fontSize: '1.0rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.0rem',
      },
    },
  },
  */
  headerContent: {
    '& span': {
      display: '-webkit-box',
      '-webkitLineClamp': 3,
      '-webkitBoxOrient': 'vertical',
      top: '50%',
      lineHeight: '1rem',
      fontSize: '0.5rem',
      height: '3.6em',
      overflow: 'hidden',
      textAlign: 'center',
      fontWeight: '500',
      [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        fontSize: '1rem',
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        fontSize: '1em',
      },
    }
  },
  headerContentSmall: {
    '& span': {
      lineHeight: '1rem',
      fontSize: '1rem',
    },
  },
  headerContentNormal: {
    '& span': {
      lineHeight: '1.2rem',
      fontSize: '1rem',
      height: '3.6em',
      [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        fontSize: '1rem',
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        fontSize: '1rem',
      },
      [`${theme.breakpoints.down('sm')} and (orientation: portrait)`]: {
        fontSize: '1rem',
        lineHeight: '1rem',
      },

    }
  },
  card: {
    overflow: 'visible',
    /* background: theme.palette.background.default, */
    border: '1px solid red',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  navIcon: {
    fontSize: '1.25rem',
    color: '#3498EE',
  },
  bottomNav: {
    /* background: theme.palette.background.default, */
    flexGrow: 1,
  },
  bottomNavSmall: {
    lineHeight: '1rem',
    fontSize: '0.7rem',
  },
  bottomNavActionLabel: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#3498EE',
    },
  },
  progress: {
    width: '80%!important',
    height: '80%!important',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  banner: {
    paddingTop: 18,
    width: 50,
    height: 50,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 12,
    borderRadius: '50%',
    position: 'absolute',
    top: -24,
    right: 5,
    transform: 'rotate(15deg)',
  },
  bannerRed: {
    background: '#fe4d4d',
    color: '#ffffff',
  },
  bannerGreen: {
    background: '#28a745',
    color: '#ffffff',
  },
}));

export default useStyles;
