import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// import tourSteps from './tourSteps';

import ProductList from 'components/productList';
import SortMenu from 'components/productList/SortMenu';
// import useTour from 'hooks/useTour';
import suggestedProducts from 'hooks/utils/suggestedProducts';
import { trace } from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {

    paddingLeft: 4,
    paddingRight: 4,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
}));

const displayProps = {
  type: 'switch',
  recipeIcon: false,
  quantityText: true,
  notes: 'editable',
  lastPurchased: false,
  frequency: true,
  quantity: 'editable', // readonly, none, or see if setQuantity exists
  source: 'wizard-quickpicks',
};

const QuickPicks = ({ state, setState, step, uid, finishStep, enableNext }) => {
  const pageClasses = useStyles();
  const [pageState, setPageState] = useState('loading');
  const [toggled, setToggled] = useState({});
  const [sort, setSort] = useState('By Department');
  /*  const tour = useTour({ id: 'wizard-quickpicks', steps: tourSteps, active: step.id === 'quickPicks', skipReference: 'plan' });

  useEffect(() => () => {
    if (tour) {
      tour.cancel();
    }
  }, [tour]); */

  useEffect(() => {
    if (uid && step.id === 'quickPicks' && step.entering) {
      const needed = Object.keys(state.picks || {}).reduce((map, slug) => Object.assign(map, { [slug]: true }), {});
      suggestedProducts({ uid, excluded: state.items, includeCurrent: false, needed }).then((items) => {
        setPageState(items);
        enableNext(true);
      });
    } else {
      enableNext(true);
    }
  }, [enableNext, step, uid, setPageState, state.items, state.picks]);

  useEffect(() => {
    if (step.id === 'quickPicks' && step.exiting) {
      trace('Updating quick picks: %o', pageState);
      setState((state) => ({
        ...state,
        picks: Object.values(pageState)
          .filter((item) => !!toggled[item.slug])
          .reduce((map, item) => Object.assign(map, { [item.slug]: { ...item, needed: true } }), {}),
      }));
      finishStep();
    }
  }, [step, pageState, setState, toggled, finishStep]);

  if (step.id !== 'quickPicks') {
    return null;
  }

  const toggle = ({ products, on }) => {
    setToggled((toggled) => {
      products.forEach((product) => {
        toggled[product.slug] = on;
      });
      return { ...toggled };
    });
  };

  const setQuantity = ({ product, value }) => {
    setPageState((ps) => ({
      ...ps,
      [product.slug]: { ...product, actual: value },
    }));
  };

  return (
    <>
      <Grid container item direction="row" alignitems="center" justifyContent="space-between" style={{ marginRight: 10, marginLeft: 15 }}>
        <Grid item xs={11}>
          <div><h4>Here are some commonly needed items to easily create your first shopping list.</h4> </div>
        </Grid>
        <Grid item xs={1}>
          <SortMenu onSort={(sort) => setSort(sort)} sort={sort} />
        </Grid>
      </Grid>

      <Grid container item direction="column" justifyContent="flex-start" alignItems="center" className={pageClasses.container}>

        <Grid container item direction="column" justifyContent="flex-start" alignItems="center" className={pageClasses.container}>
          <ProductList
            displayProps={displayProps} products={pageState} sort={sort} source="quickPicks"
            toggle={toggle} toggled={toggled} uid={uid} setQuantity={setQuantity}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default QuickPicks;
