import React, { useState } from 'react';

import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SearchIcon from '@material-ui/icons/Search';

import { ReactComponent as WizardIcon } from 'assets/wizard_icon.svg';

const useStyles = makeStyles((theme) => ({
  fab: {
  },
  addButton: {
    /*
    color: 'white',
    background: '#2196F3', //'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    '&:hover': {
      background: '#21CBF3',
    },
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    */
    zIndex: 9999,
    position: 'absolute',
    right: 20,
    top: 200,
    [theme.breakpoints.down('sm')]: {
      right: 18,
      top: 140,
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '50%',
      minWidth: 60,
      width: 60,
      height: 60,
    },
  },
  menuIcon: {
    minWidth: 36
  }
}));

const Add = ({ course, add }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoose = (action) => () => {
    setAnchorEl(null);
    add(action);
  };

  return (
    <div>
      <Fab
        aria-label="add meal" variant="extended" color="primary"
        onClick={handleOpen}
        className={classes.addButton}
        data-tour="add-meal"
      >
        <AddIcon />
        <Hidden xsDown>
          Add {course}
        </Hidden>
      </Fab>
      <Menu
        id="add-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
      >
        {course !== 'Breakfast' && (
          <MenuItem onClick={handleChoose('pick')}>
            <ListItemIcon className={classes.menuIcon}>
              <WizardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Suggestions" />
          </MenuItem>
        )}
        <MenuItem onClick={handleChoose('search')}>
          <ListItemIcon className={classes.menuIcon}>
            <SearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Browse & Search" />
        </MenuItem>
        <MenuItem onClick={handleChoose('enter')}>
          <ListItemIcon className={classes.menuIcon}>
            <PlaylistAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Enter recipe" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Add;
