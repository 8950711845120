import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import ToggleAll from './ToggleAll';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 10,
    marginBottom: 0,
    fontSize: '0.8rem',
  },
  header: {
    margin: 0,
    marginBottom: 4,
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  count: {
    fontSize: '0.8rem',
    verticalAlign: 'middle',
    marginLeft: 4,
    fontWeight: 'normal',
  },
}));

const Header = ({ displayProps, group, products, toggle, toggled, styleProps }) => {
  const classes = useStyles();
  const items = Object.keys(products).length;
  const text = (
    <span>
      {group}
      {items > 1 && <span className={classes.count}>({items} item{items !== 1 && 's'})</span>}
    </span>
  );
  return (
    <Grid container item direction="column" className={classes.root}>
      <Grid item className={classes.header} style={styleProps}>
        {text}
      </Grid>
      {Object.keys(products).length > 1 && (
        <Grid item>
          <ToggleAll displayProps={displayProps} products={Object.values(products)} toggle={toggle} toggled={toggled} title={`Toggle all in ${group}`} />
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
