import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmCloseDialog = ({ isOpen, handleReject, handleConfirm }) => (
  <Dialog open={isOpen} onClose={handleReject} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
    <DialogTitle id="form-dialog-title">Cancel Wizard</DialogTitle>
    <DialogContent>
      This will abandon the new plan. Are you sure?
    </DialogContent>
    <DialogActions>
      <Button onClick={handleConfirm} color="primary">
        Yes
      </Button>
      <Button onClick={handleReject} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmCloseDialog;
