import { useReducer, useEffect } from 'react';

import { useQuery } from '@apollo/client';
import produce, { current } from 'immer';
import get from 'lodash/get';

import { SEARCH_GENERIC_PRODUCTS } from './queries';

import { trace, traceError } from 'utils';

const reducer = produce((draft, payload) => {
  trace('useProductSearch: reducer in: %o, payload: %o', current(draft), payload);
  const { action, ...rest } = payload;
  switch (action) {
    case 'search':
      draft.searchTerm = rest.searchTerm;
      draft.searchCount = 0;
      draft.error = null;
      break;
    case 'addToSearch': {
      draft.results = [];
      let showNew = true;
      rest.data.products.forEach((product) => {
        draft.results.push(product);
        if (product.name.toLowerCase() === draft.searchTerm.toLowerCase()) {
          showNew = false;
        }
      });
      if (showNew) {
        draft.results.unshift({ newItem: true, name: 'Add New Item' });
      }
      draft.searchLoaded = true;
      draft.searchCount = rest.data.hits;
      break;
    }
    case 'error': {
      draft.error = rest.error;
      draft.searchCount = 0;
      draft.searchLoaded = false;
      draft.results = [];
      // not sure why i'm doing this; maybe so if they try again?
      draft.refresh = (draft.refresh || 1) + 1;
      break;
    }
    case 'remove': { // used by admin
      draft.results = draft.results.filter((r) => r.id !== rest.id);
      break;
    }
    case 'replace': { // used by admin
      const idx = draft.results.findIndex((r) => r.id === rest.item.id);
      if (idx >= 0) {
        draft.results[idx] = rest.item;
      }
      break;
    }
    default:
  }
  trace('useProductSearch: reducer out: %o', current(draft));
});

const initialState = {};

const useProductSearch = ({ searchTerm, course, ...props }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, size: props.size || 50, searchTerm, refresh: 1 });
  const skip = state.error || (!state.searchTerm || state.searchTerm.length < 3);

  const { error, data, loading: searchLoading } = useQuery(SEARCH_GENERIC_PRODUCTS, {
    variables: { searchParams: { query: state.searchTerm, size: state.size, refresh: state.refresh } },
    skip,
    fetchPolicy: 'no-cache',
  });
  if (error) {
    traceError('useProductSearch: error searching: %o', error);
  }

  useEffect(() => {
    if (error) {
      dispatch({ action: 'error', error });
    } else if (!searchLoading && data) {
      dispatch({ action: 'addToSearch', data: get(data, 'searchGenericProducts') });
    }
  }, [data, searchLoading, error, state.error]);

  return { state, dispatch, loading: state.loading, error: state.error };
};

export default useProductSearch;
