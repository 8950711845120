import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import suggestedProducts from 'hooks/utils/suggestedProducts';
import { Typography, Box, Button, DialogActions } from '@material-ui/core';

const GroceryFavorites = ({ uid, open, onSelected }) => {
  const [products, setProducts] = useState({});
  const [checked, setChecked] = useState([]);
  const [showOnlySelected, setShowOnlySelected] = useState(false);

  useEffect(() => {
    if (uid && open) {
      suggestedProducts({ uid, excluded: {}, includeCurrent: false, needed: {} })
        .then(items => {
          const departments = Object.values(items).reduce((depMap, item) => {
            if (!depMap[item.department]) depMap[item.department] = [];
            depMap[item.department].push(item);
            return depMap;
          }, {});

          // Sorting each department's products
          for (let department in departments) {
            departments[department].sort((a, b) => a.name.localeCompare(b.name));
          }

          setProducts(departments);
        })
        .catch((error) => {
          console.error("Error fetching suggested products: ", error);
        });
    }
  }, [uid, open]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const toggleShowSelected = () => {
    setShowOnlySelected(!showOnlySelected);
  };

  const handleClose = () => {
    onSelected(checked);
  };

  // Get sorted department keys
  const sortedDepartments = Object.keys(products).sort();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>Groceries Quick Picks ({checked.length} Selected)</span>
          <Box display="flex">
            <IconButton onClick={toggleShowSelected}>
              {showOnlySelected ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {sortedDepartments.map(department => {
          const departmentProducts = products[department];
          const selectedProducts = departmentProducts.filter(product => checked.indexOf(product.slug) !== -1);
          if (showOnlySelected && selectedProducts.length === 0) return null;

          return (
            <div key={department}>
              <Typography variant="h6">{department}</Typography>
              <List>
                {departmentProducts.map((product) => {
                  const isSelected = checked.some(item=>item.slug===product.slug);
                  if (showOnlySelected && !isSelected) return null;
                  return (
                    <ListItem key={product.slug} role={undefined} dense button onClick={handleToggle(product)}>
                      <Checkbox checked={isSelected} tabIndex={-1} disableRipple />
                      <ListItemText primary={product.name} />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Done
        </Button>
      </DialogActions>

    </Dialog>
  );
};

export default GroceryFavorites;
