import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';

import inventory from './inventoryUtils';

import synonyms from 'data/foodSynonyms';
import { normalizeQuantities, volumes, weights } from 'data/measurements';
import { trace } from 'utils';

const depluralizers = [[/(ies)$/, 'y'], [/(es)$/, 'e'], [/s$/, '']];

const normalizeFood = (food) => {
  const slug = food.toLowerCase();
  const name = startCase(slug);
  for (let i = 0; i < depluralizers[0]; ++i) {
    const depluralizer = depluralizers[i];
    if (slug.match(depluralizer[0])) {
      return { slug: kebabCase(slug.replace(depluralizer[0], depluralizer[1])), name };
    }
  }
  const match = Object.entries(synonyms).find(([rootFood, matches]) => {
    const strippedFood = slug.replace(/[^a-z]/g, '');
    return matches.includes(strippedFood);
  });
  if (match) {
    return { slug: kebabCase(match[0]), name: match[0] };
  }
  return { slug: kebabCase(slug), name };
};

// See if we need to normalize these, e.g. if they use lbs vs pounds or different convertible measures
const measureKey = (measure) => {
  if (!measure) {
    return 'each';
  }
  const unit = volumes[measure.toLowerCase()] || weights[measure.toLowerCase()];
  return unit ? measure.toLowerCase() : 'each';
};

const combineQuantities = (ingredients) => {
  ingredients.forEach((food) => {
    const recipeQuantities = Object.values(food.recipes).map((r) => r.quantity);
    food.quantity = normalizeQuantities(recipeQuantities);
    if (food.quantity.quantity === 0) {
      food.quantity.quantity = 1;
    }
    // food.quantity.text = Object.values(food.recipes).map(r => r.quantity.text).join(' and ');
    if (!food.actual) {
      food.actual = food.quantity.quantity;
    }
  });
};

const adjustForYields = ({ ingredients }) => {
  // { department: [ { slug: { ...foodInfo, actual, quantity: { measure, quantity }, recipes: [ { ...recipeInfo, quantity: { measure, quantity } } ]
  ingredients.forEach((food) => {
    Object.values(food.recipes).forEach((recipe) => {
      const eaters = recipe.eaters || 1;
      if (recipe.yield !== eaters) {
        const ratio = (eaters) / (recipe.yield || 1);
        recipe.quantity.original = recipe.quantity.quantity;
        recipe.quantity.quantity = Number(Number(recipe.quantity.quantity * ratio).toFixed(2));
        const measure = volumes[recipe.quantity.measure || 'each'] || weights[recipe.quantity.measure] || { symbols: 'ea' };
        recipe.quantity.notes = `
          ${recipe.quantity.quantity} ${measure.symbols} for ${eaters} serving${eaters === 1 ? '' : 's'}
          (originally ${(Math.round(100.0 * recipe.quantity.original)) / 100} ${measure.symbols}
          for ${recipe.yield} serving${recipe.yield === 1 ? '' : 's'})
        `;
        /*
        recipe.quantity.notes = `originally ${(Math.round(100.0*recipe.quantity.original))/100} ${measure.symbols}
          for ${recipe.yield} serving${recipe.yield === 1 ? '' : 's'},
          adjusted to ${recipe.quantity.quantity} ${measure.symbols} for ${eaters} serving${eaters === 1 ? '' : 's'}`;
        */
      }
    });
  });
};

const ignoredIngredients = [
  'water'
];

// needed is a map of items that the user previously toggled on, so if they change
// recipes, we don't forget what they said they needed.
const buildShoppingList = async ({ uid, toggledOff, dinners, lunches, breakfasts, quantityPreferences, client }) => {
  trace('buildShoppingList: dinners: %o, toggledOff: %o, qp: %o', dinners, toggledOff, quantityPreferences);
  let recipes = [];
  if (dinners) {
    recipes = recipes.concat(dinners.filter((d) => d && !d.notFound)
      .map((d) => ({ ...d.recipe, eaters: quantityPreferences.dinnerEaters || quantityPreferences.eaters })));
  }
  if (lunches) {
    recipes = recipes.concat(lunches.filter((d) => d && !d.notFound)
      .map((d) => ({ ...d.recipe, eaters: quantityPreferences.lunchEaters || quantityPreferences.eaters })));
  }
  if (breakfasts) {
    recipes = recipes.concat(breakfasts.filter((d) => d && !d.notFound)
      .map((d) => ({ ...d.recipe, eaters: quantityPreferences.breakfastEaters || quantityPreferences.eaters })));
  }

  trace('buildShoppingList: recipes: %o', recipes);
  const inventoryChecker = await inventory({ uid });
  const shoppingList = [];
  const idx = {};
  recipes.forEach((recipe) => {
    (recipe.ingredients || []).forEach((ingr) => {
      const department = ingr.department || 'other'; // department is already converted to keys
      const { slug, name } = normalizeFood(ingr.food);
      trace('food: %s, normalized: %s', ingr.food, slug);
      if (!ignoredIngredients.includes(slug)) {
        const idxKey = `${department}|${slug}`;
        const existingFoodIdx = idx[idxKey];
        const r = { label: recipe.label,
          uri: recipe.uri,
          yield: recipe.yield || 4,
          onMenu: true,
          slug,
          eaters: recipe.eaters,
          source: recipe.source,
          quantity: { measure: measureKey(ingr.measure), quantity: ingr.quantity, text: ingr.text || ingr.food || '' } };
        const rKey = recipe.slug;
        if (existingFoodIdx) {
          shoppingList[existingFoodIdx].recipes[rKey] = r;
        } else {
          const item = { slug, name, department, onMenu: true, text: ingr.text || '', recipes: { [rKey]: r } };
          item.likelyHave = inventoryChecker.likelyHaveIngredient(item);
          // If needed is null, the user hasn't toggled anything yet so default it to needed
          item.needed = !(toggledOff && toggledOff[slug]);
          /*
          item.due = inventoryChecker.due(item);
          if (needed && needed[slug]) {
            item.needed = true;
          } else {
            item.needed = item.due;
          }
          */
          shoppingList.push(item);
          idx[idxKey] = shoppingList.length - 1;
        }
      }
    });
  });

  adjustForYields({ ingredients: shoppingList });
  combineQuantities(shoppingList);

  // Now do previous purchases
  Object.values(inventoryChecker.purchases).forEach((item) => {
    const idxKey = `${item.department}|${item.slug}`;
    const existingFoodIdx = idx[idxKey];
    if (!existingFoodIdx) {
      item.onMenu = false;
      item.needed = item.due;
      if (toggledOff && toggledOff[item.slug]) {
        item.needed = false;
      }
      shoppingList.push(item);
    }
  });

  const map = shoppingList.reduce((map, item) => Object.assign(map, { [item.slug]: item }), {});
  trace('buildShoppingList: returning %o', map);
  return map;
};

export { buildShoppingList };
