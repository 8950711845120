import React, { useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';

import useAuth from 'hooks/useAuth';
import mergeUsers from 'hooks/utils/mergeUtils';
import { trace, traceError } from 'utils';

const MergeDialog = ({ history }) => {
  const { uid, mergeData, setMergeData } = useAuth();
  const [error, setError] = useState();

  useEffect(() => {
    if (uid && mergeData && mergeData.uid && mergeData.uid !== uid && !mergeData.merged) {
      setTimeout(() => {
        trace('in merge: new uid: %o, merge data: %o', uid, mergeData);
        firebase.database().ref(`/users/${uid}`).once('value', (snapshot) => {
          const userData = snapshot.val();
          const updates = mergeUsers({ fromData: mergeData.data, toData: userData });
          setMergeData({ uid: mergeData.uid, data: null, merged: true });
          firebase.database().ref(`/users/${uid}`).update(updates, (error) => {
            if (error) {
              traceError(error);
              setError('There was a problem merging your data. ');
            }
          });
        });
      }, 5000);
    }
  }, [uid, mergeData, setMergeData]);

  const open = !!mergeData;
  const success = open && mergeData.merged && !error;
  const inProgress = open && !error && !success;
  const close = (path) => {
    setMergeData(null);
    if (path) {
      history.push(path);
    }
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" disableBackdropClick disableEscapeKeyDown>
      <DialogTitle id="form-dialog-title">Found existing Guustav account</DialogTitle>
      <DialogContent dividers>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
        {success && (
          <Alert severity="success">Your data has been successfully updated!</Alert>
        )}
        <div style={success ? { color: 'rgba(0,0,0,0.3)', marginTop: 10 } : undefined}>
          You already had a Guustav account. We are updating it with your new plan.
        </div>
        {inProgress && (
          <LinearProgress />
        )}
      </DialogContent>
      <DialogActions>
        {success && !history.location.pathname.match(/\/menu/) && (
          <Button onClick={() => close('/menu')} color="primary">
            Go to menu
          </Button>
        )}
        {!inProgress && (
          <Button onClick={close} color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MergeDialog;
