import * as dayjs from 'dayjs';
import kebabCase from 'lodash/kebabCase';

import { load } from './dbLoaderMulti';

const paths = [
  'shoppingList',
  'inventory',
  '/global/supplies',
  '/global/departments'
];

const minimumFrequencyDays = 3;
const notNeededNoSuggestDays = 3;

const convertSupply = (s) => ({
  slug: kebabCase(s.product),
  name: s.product,
  department: s.department,
  actual: 1,
  quantity: { measure: 'each', quantity: 1 },
  onMenu: false,
});

const suggestedProducts = async ({ uid, excluded, includeCurrent, needed }) => {
  const results = await load({ uid, paths, source: 'suggestionUtils' });
  const { shoppingList = {}, inventory = {}, '/global/supplies': supplies, '/global/departments': departments } = results;
  const purchases = Object.entries(inventory || {})
    .filter(([slug, data]) => !!(data.dates && Object.keys(data.dates).length))
    .reduce((result, [slug, data]) => Object.assign(result, {
      [slug]: {
        ...data,
        lastPurchased: Object.keys(data.dates).sort().reverse()[0],
      }
    }), {});

  const list = Object.values(purchases).length ? Object.values(purchases) : Object.values(supplies).map((s) => convertSupply(s));

  const suggestions = includeCurrent ? { ...shoppingList } : {};
  list.forEach((item) => {
    let due = false;
    if (item.lastPurchased) {
      if (typeof item.frequency === 'undefined') {
        if (supplies[item.slug] && typeof supplies[item.slug].frequency !== 'undefined') {
          item.frequency = supplies[item.slug].frequency;
        } else if (departments[item.department] && typeof departments[item.department].frequency !== 'undefined') {
          item.frequency = departments[item.department].frequency;
        } else {
          item.frequency = 0; // one-time purchase
        }
      }
      if (item.frequency > 0) {
        if (dayjs().diff(dayjs(item.lastPurchased), 'd') < minimumFrequencyDays) {
          due = false;
        } else {
          const weeksAgo = dayjs().diff(dayjs(item.lastPurchased), 'w');
          due = weeksAgo + 1 >= item.frequency;
        }
      }
    } else if (shoppingList[item.slug]) {
      if (item.onMenu) {
        due = false;
      } else {
        due = true; // added manually
      }
    } else {
      item.quickPick = true;
      due = false; // quick pick
    }
    item.due = due;
    if (shoppingList[item.slug]) {
      item.needed = true;
    } else if (item.lastNotNeeded && dayjs().diff(dayjs(item.lastNotNeededDate), 'd') < notNeededNoSuggestDays) {
      item.needed = false;
    } else {
      item.needed = item.due;
    }
    if (needed[item.slug]) {
      item.needed = true;
    }
    if (includeCurrent || !shoppingList[item.slug]) {
      if (!excluded || !excluded[item.slug]) {
        suggestions[item.slug] = { ...(shoppingList[item.slug] || {}), ...item };
      }
    }
  });

  return suggestions;

/*
  items = current shopping list
  add previous purchases to items
  if no previous purchases, add quick picks to items
  for each item
    # Figure out due
    if previously purchased
      if user frequency set
        due = last purchase date within frequency
      else if global default frequency
        due = last purchase date within global default frequency
      end
    else if already on shopping list
      if on menu
        due = false
      else
        # must have been manually added so start the clock now
        due = true
      end
    else
      # must be a quick pick
      due = false
    end

    # Figure out toggle state
    if already on shopping list
      toggled = true
    elsif toggled off within 3 days
      toggled = false
    else
      toggled = due
    end
    */
};

export default suggestedProducts;
