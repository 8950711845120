import React from 'react';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import MobileStepper from '@material-ui/core/MobileStepper';
import { KeyboardArrowLeft, KeyboardArrowRight, Save } from '@material-ui/icons';

const WizardProgressBottom = ({ step, steps, mobile, nextEnabled, onClickBack, onClickNext, save }) => {
  const maxSteps = Object.keys(steps).length;
  let nextButton;
  let nextButtonLabel='Next';
  if (step.id==='feedback') nextButtonLabel='Create Menu';
  if (step.id==='menu') nextButtonLabel='Continue';
  if (step.id==='ingredients') nextButtonLabel='Finalize List';
  console.log('the step is %o', step);
  if (save) {
    nextButton = (
      <Button size="small" onClick={save} color="secondary" variant="contained">
        <Save />
        Done selecting items needed
      </Button>
    );
  } else if (step.current < maxSteps - 1) {
    nextButton = (
      <Button color="secondary" variant='contained' className="wizard-plan-advance" size="small" onClick={onClickNext} disabled={!nextEnabled} style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    }}>
        {nextButtonLabel}
        <KeyboardArrowRight />
      </Button>
    );
  }

  return (
    <>
      <MobileStepper
        position="static" steps={maxSteps} activeStep={step.current} variant="text"
        style={{ background: 'white' }}
        backButton={step.current > 0
          ? (
            <Button color="secondary" size="small" onClick={onClickBack}>
              <KeyboardArrowLeft />
              Back
            </Button>
          )
          : undefined}
        nextButton={nextButton}
      />
      <LinearProgress style={{ visibility: step.exiting ? '' : 'hidden' }} />
    </>
  );
};

export default WizardProgressBottom;
