import firebase from 'firebase/app';
import 'firebase/functions';

const callChatGPTFunction = async (messages) => {
  try {
    console.log('sending %o to chatgpt', messages);
    // Get a reference to the Firebase Cloud Function
    const chatGPTFunction = firebase.functions().httpsCallable('chatGPTFunction');
    // Call the Firebase function with the provided messages
    const response = await chatGPTFunction([messages]);
    console.log('reponse from chatgpt: %o', response);
    console.log(response);
    // Extract the generated response from the function response
    const generatedResponse = response.data.response;

    // Return the generated response
    return generatedResponse;
  } catch (error) {
    console.error('Error calling Firebase function:', error);
    throw error;
  }
};
 export default callChatGPTFunction;

