import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Add from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';

import MemberTable from 'components/members/MemberTable';
import useAuth from 'hooks/useAuth';
import dbLoad from 'hooks/utils/dbLoader';
import { generateAccessKey } from 'utils';

const defaultValue = {};

const MemberAddSignUp = ({ isOpen, close }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { ruid, uid } = useAuth();
  const [household, setHousehold] = useState();
  const [currentMember, setCurrentMember] = useState();

  useEffect(() => {
    dbLoad({ path: 'household', uid, defaultValue }).then((household) => {
      setHousehold(household);
    });
  }, [uid, setHousehold]);

  if (!household) {
    return null;
  }

  const add = () => {
    setCurrentMember({ accessKey: generateAccessKey(uid) });
  };

  return (
    <Dialog
      fullScreen={mobile} fullWidth={!mobile} maxWidth="md"
      open={isOpen} onClose={close} aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" onClose={close}>
        <Grid container justifyContent="space-between">
          <Grid item>
            Household
            <Alert severity="info">
              Give members of your household access to Guustav so they can help you cook, plan or shop. We recommend doing this especially if you used Facebook, Google or apple to login.
            </Alert>
          </Grid>

        </Grid>
      </DialogTitle>
      <DialogContent dividers style={{ paddingLeft: 8, paddingRight: 8 }}>
        <MemberTable household={household} setHousehold={setHousehold} currentMember={currentMember} setCurrentMember={setCurrentMember} />
        <Grid>{ruid === uid && (
        <Button onClick={add} startIcon={<Add />}>Add</Button>
        )}
        </Grid>
      </DialogContent>
      <DialogActions>

        <Button onClick={close}>Finish</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MemberAddSignUp;
