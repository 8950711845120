import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import RepeatIcon from '@material-ui/icons/Repeat';

import useStyles from 'assets/useStyles';
import useRepeats from 'hooks/useRepeats';

const RepeatToggle = ({ course, recipe }) => {
  const classes = useStyles();
  const { repeats, repeat, unrepeat } = useRepeats();

  const repeated = !!repeats[recipe.slug];

  const toggle = () => {
    if (repeated) {
      unrepeat(recipe);
    } else {
      repeat({ course, recipe });
    }
  };

  return (
    <IconButton className={classes.repeatButton} onClick={toggle}>
      <Tooltip
        arrow enterDelay={500} enterNextDelay={500}
        title={repeated ? 'Remove this recipe from your rotation' : 'Add this recipe to your regular rotation'}
      >
        {repeated
          ? <RepeatIcon className={classes.repeated} />
          : <RepeatIcon className={classes.unrepeated} />}
      </Tooltip>
    </IconButton>
  );
};

export default RepeatToggle;
