import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Note, NoteAdd, RemoveCircle } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import kebabCase from 'lodash/kebabCase';
import uniq from 'lodash/uniq';

import IngredientSearch from 'components/IngredientSearch';
import NotesDialog from 'components/NotesDialog';
import Tip from 'components/Tip';
import { volumes, weights } from 'data/measurements';
import useAuth from 'hooks/useAuth';
import dbLoader from 'hooks/utils/dbLoader';
import { dbUpdate } from 'hooks/utils/dbUpdater';
import { traceError } from 'utils';

const defaultIngredient = { food: '', measure: '', quantity: '' };
const defaultRecipe = {
  uri: '',
  label: '',
  instructions: '',
  ingredients: [{ ...defaultIngredient }],
  yield: 4,
  tags: '',
  slug: '',
  courses: [],
  source: 'Guustav Custom',
};

// TODO: use = function that knows what to do with the recipe
const RecipeDialog = ({ open, setOpen, recipe, course, slug, use }) => {
  const [error, setError] = useState();
  const [data, setData] = useState(recipe || { ...defaultRecipe, courses: course ? [course.toLowerCase()] : [] });
  const [note, setNote] = useState({ ingredient: null, open: false, idx: null, name: null });
  const { uid } = useAuth();

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));

  let fullScreenV = true;
  if (md) {
    fullScreenV = false;
  }

  const handleReset = () => {
    if (recipe) {
      setData({ ...recipe });
    } else {
      setData({ ...defaultRecipe });
    }
  };

  const handleClose = () => {
    setError(null);
    setOpen(false);
    handleReset();
  };

  const handleSave = async () => {
    const newData = { ...data };
    newData.slug = kebabCase(newData.label);
    newData.uri = `/users/${uid}/recipes/custom/${newData.slug}`;
    newData.id = `g_${Math.random().toString(36).substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`;
    newData.ingredients = (newData.ingredients || []).filter((i) => !!i.food).map((ingredient) => {
      if (!ingredient.department) {
        ingredient.department = 'other';
      }
      if (!ingredient.slug) {
        ingredient.slug = kebabCase(ingredient.food);
      }
      return ingredient;
    });
    const updates = {};
    if (newData.slug !== data.slug) {
      const existing = await dbLoader({ uid, path: `recipes/custom/${newData.slug}`, source: 'RecipeDialog' });
      if (existing) {
        setError('A recipe with the same name already exists.');
        return;
      }
      if (data.slug) {
        updates[`${data.slug}`] = null;
      }
      updates[`${newData.slug}`] = newData;
    } else {
      updates[`${newData.slug}`] = newData;
    }
    try {
      await dbUpdate({ uid, path: 'recipes/custom', updates });
      if (use) {
        use({ recipe: newData });
      }
      setOpen(false);
      setError(null);
      handleReset();
    } catch (err) {
      traceError(err);
      setError('Could not save the recipe');
    }
  };

  const updateIngredient = (idx, value) => {
    const newData = { ...data };
    newData.ingredients = [...(data.ingredients || [])];
    newData.ingredients[idx] = { ...newData.ingredients[idx], ...value };
    setData(newData);
    closeNotes();
  };

  const removeIngredient = (idx) => {
    const newData = { ...data };
    newData.ingredients = [...data.ingredients];
    newData.ingredients.splice(idx, 1);
    setData(newData);
  };

  const openNotes = (idx, ingredient) => {
    setNote({ ingredient, name: ingredient.food || 'New Ingredient', open: true, text: ingredient.text, idx });
  };

  const closeNotes = () => {
    setNote({ ingredient: null, open: false, name: null, text: null, idx: null });
  };

  const ingredients = (data.ingredients || []).filter((i) => i.food && i.slug.length).concat({ ...defaultIngredient });

  const setCourse = (course, checked) => {
    if (checked) {
      setData({
        ...data,
        courses: uniq([...data.courses, course])
      });
    } else {
      setData({
        ...data,
        courses: (data.courses || []).filter((c) => c === course)
      });
    }
  };

  return (
    <Dialog fullScreen={fullScreenV} maxWidth="sm" open={!!open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <NotesDialog
        key="ingredient-notes" open={note.open} note={note.text}
        title={`Notes for ${note.name}`}
        onClose={closeNotes}
        onSave={(text) => updateIngredient(note.idx, { text })}
      />,
      <DialogTitle id="form-dialog-title">
        Custom Recipe
      </DialogTitle>
      <DialogContent dividers>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
        <Grid container justifyContent="space-between">
          <Grid item>
            {data.slug.length ? 'Update Recipe' : 'Add Recipe'}
          </Grid>
          <Grid container item justifyContent="space-between" sm={12}>
            <FormControlLabel
              value="top" label={<span style={{ fontSize: '.8rem' }}>Dinner</span>} labelPlacement="top"
              control={(
                <Checkbox
                  color="primary" name="dinner"
                  checked={(data.courses || []).includes('dinner')} onChange={(ev) => setCourse('dinner', ev.target.checked)}
                />
              )}
            />
            <FormControlLabel
              value="top" label={<span style={{ fontSize: '.8rem' }}>Lunch</span>} labelPlacement="top"
              control={(
                <Checkbox
                  color="primary" name="lunch"
                  checked={(data.courses || []).includes('lunch')} onChange={(ev) => setCourse('lunch', ev.target.checked)}
                />
              )}
            />
            <FormControlLabel
              value="top" label={<span style={{ fontSize: '.8rem' }}>Breakfast</span>} labelPlacement="top" fontSize="2px"
              control={(
                <Checkbox
                  color="primary" name="breakfast"
                  checked={(data.courses || []).includes('breakfast')} onChange={(ev) => setCourse('breakfast', ev.target.checked)}
                />
              )}
            />
          </Grid>
        </Grid>
        <TextField
          autoFocus variant="outlined" margin="dense" id="label" label="Recipe Name"
          fullWidth value={data.label}
          onChange={(ev) => setData({ ...data, label: ev.target.value })}
        />
        <TextField
          margin="dense" variant="outlined" id="yield" label="Yield" value={data.yield}
          onChange={(ev) => setData({ ...data, yield: ev.target.value })}
        />
        <h3>
          <Grid container spacing={1}>
            <Grid item>Ingredients</Grid>
            <Grid item>
              <Tip message="Specifying ingredients will help us build your shopping list" />
            </Grid>
          </Grid>
        </h3>
        <Grid container direction="column">
          <Grid container item direction="row" spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={6}>Ingredient</Grid>
            <Grid item xs={2}>Qty</Grid>
            <Grid item xs={3}>Unit</Grid>
          </Grid>
          {ingredients.map((ingredient, idx) => (
            <Grid item container direction="row" key={`ingredient-${idx}`} spacing={2}>
              <Grid item xs={1} style={{ alignSelf: 'center' }}>
                {idx < ingredients.length - 1 && (
                  <RemoveCircle onClick={() => removeIngredient(idx)} />
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={11}>
                    <IngredientSearch ingredient={ingredient} setIngredient={(ingredient) => updateIngredient(idx, ingredient)} placeholder="Type to search or enter..." />
                  </Grid>
                  <Grid item xs={1}>
                    {!!ingredient.food && (
                      <Tooltip arrow title={ingredient.text || ''}>
                        <IconButton
                          size="small" aria-label="close" color="inherit"
                          onClick={() => openNotes(idx, ingredient)}
                        >
                          {!!ingredient.text && <Note fontSize="small" />}
                          {!ingredient.text && <NoteAdd fontSize="small" />}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={!ingredient.food.length} margin="dense" variant="outlined"
                  value={ingredient.quantity}
                  size="small"
                  onChange={(ev) => updateIngredient(idx, { quantity: ev.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  select
                  size="small"
                  fullWidth
                  disabled={!ingredient.food.length}
                  variant="outlined" margin="dense"
                  value={ingredient.measure || 'each'}
                  onChange={(ev) => updateIngredient(idx, { measure: ev.target.value })}
                >
                  {Object.entries({ ...volumes, ...weights }).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

            </Grid>
          ))}
        </Grid>
        <h3>
          <Grid container spacing={1}>
            <Grid item>Instructions (Optional)</Grid>
          </Grid>
        </h3>
        <TextField
          margin="dense" variant="outlined" id="instructions" label="We know you know how to make your staple recipes" fullWidth
          value={data.instructions} multiline rows={3}
          onChange={(ev) => setData({ ...data, instructions: ev.target.value })}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleReset} color="primary">
          Reset
        </Button>
        {data.label && data.ingredients && data.ingredients.length && (
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RecipeDialog;
