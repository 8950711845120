import React from 'react';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import HighlightOffIcon from '@material-ui/icons/DeleteOutline';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './menuCardStyles';

import { ReactComponent as WizardIcon } from 'assets/wizard_icon.svg';

const MealSkeleton = ({ count, lg, change, action }) => {
  const classes = useStyles();

  let width;
  let lgSize = 3;
  if (count > 4 && lg) {
    lgSize = 2;
    width = '20%';
  }
  return (
    <Grid
      item xs={6} sm={3} md={3} lg={lgSize}
      style={{ maxWidth: width, flexBasis: width, paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}
    >
      <Card
        className={lgSize === 2 ? classes.card : classes.cardSmall}
        raised elevation={2} style={{ border: '0px solid red' }}
      >
        <CardHeader
          classes={
            {
              root: classes.cardHeader,
              content: `${classes.headerContent} ${lgSize === 2 ? classes.headerContentSmall : classes.headerContentNormal}`,
              action: classes.action,
              avatar: classes.avatar,
            }
          }
          action={action
            ? (
              <Tooltip arrow title="Remove this meal from your menu.">
                <IconButton className={classes.deleteIconButton}>
                  <Skeleton><HighlightOffIcon className={classes.deleteIcon} /></Skeleton>
                </IconButton>
              </Tooltip>
            )
            : undefined}
          title={<Skeleton variant="text" />}
        />

        <CardActionArea>
          <Skeleton variant="rect" className={classes.media} />
        </CardActionArea>

        {!!change && (
          <CardActions disableSpacing>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item><h4 style={{ margin: 0 }}><Skeleton variant="text" width={100} /></h4></Grid>
              <Grid item xs={12}>
                <BottomNavigation showLabels className={`${classes.bottomNav} ${lgSize === 2 ? classes.bottomNavSmall : classes.bottomNavNormal}`}>
                  <BottomNavigationAction
                    label={<><Skeleton variant="text" width={40} /><Skeleton variant="text" width={40} /></>}
                    icon={<Skeleton><SvgIcon className={classes.navIcon}><WizardIcon /></SvgIcon></Skeleton>}
                  />
                  <BottomNavigationAction
                    label={<><Skeleton variant="text" width={40} /><Skeleton variant="text" width={40} /></>}
                    icon={
                      <Skeleton><SearchIcon className={classes.navIcon} /></Skeleton>
                    }
                  />
                </BottomNavigation>
              </Grid>
            </Grid>
          </CardActions>
        )}

      </Card>
    </Grid>
  );
};

export default MealSkeleton;
