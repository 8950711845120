import React, { useEffect, useState } from 'react';

import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';

// import FoodOff from 'mdi-material-ui/FoodVariantOff';
import Poison from 'mdi-material-ui/BottleTonicSkull';
import Scale from 'mdi-material-ui/ScaleBalance';

import DietaryFilters from 'components/preferences/DietaryFilters';
import DietBalancing from 'components/preferences/DietBalancing';
import Gourmet from 'components/preferences/Gourmet';
import Simple from 'components/preferences/Simple';
// import tourSteps from './tourSteps';

import useAuth from 'hooks/useAuth';
// import useTour from 'hooks/useTour';
import { refreshProfileRecipeCache } from 'hooks/utils/cacheUtils';
import { dbSet } from 'hooks/utils/dbUpdater';
import { trace } from 'utils';

// import DislikedIngredients from './DislikedIngredients';

// import { preCacheMeals } from 'hooks/utils/fillMeals';

const useStyles = makeStyles((theme) => ({
  input: {
    width: 42,
  },
  label: {
    fontSize: '1.2em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1em',
    },
  },
  icon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  chip: {
    minWidth: 100,
    marginBottom: 8,
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      minWidth: 70,
      fontSize: '.7em',
      marginRight: 12,
    },
  },
  activeChip: {
    marginRight: 8,
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
  sectionHeader: {
    marginTop: 30,
  },
  sectionBody: {
    marginTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
}));

const RecipePreferences = ({ state, setState, step, finishStep, enableNext }) => {
  const classes = useStyles();
  // const tour = useTour({ id: 'wizard-recipe-preferences', steps: tourSteps, active: step.id === 'recipePreferences', skipReference: 'plan' });
  const { uid } = useAuth();
  const [pageState, setPageState] = useState('loading');
  const [expandedHealth, setExpandedHealth] = React.useState(false);
  const [expandedDiet, setExpandedDiet] = React.useState(false);

  const handleExpandHealthClick = () => {
    setExpandedHealth(!expandedHealth);
  };

  const handleExpandDietClick = () => {
    setExpandedDiet(!expandedDiet);
  };

  /* useEffect(() => () => {
    if (tour) {
      tour.cancel();
    }
  }, [tour]); */

  useEffect(() => {
    if (step.id === 'recipePreferences' && step.entering) {
      trace('Loading food preferences: %o', state.recipePreferences);
      setPageState((pageState) => ({
        ...pageState,
        recipePreferences: { ...state.recipePreferences }
      }));
      enableNext(true);
    }
  }, [enableNext, step, uid, setPageState, state.recipePreferences]);

  useEffect(() => {
    if (step.id === 'recipePreferences' && step.exiting) {
      dbSet({ uid, path: 'household/recipePreferences', value: pageState.recipePreferences });
      refreshProfileRecipeCache({ uid, recipePreferences: pageState.recipePreferences });
      setState((state) => {
        if (!isEqual(pageState.recipePreferences, state.recipePreferences)) {
          trace('Actually updating food preferences: %o != %o', pageState.recipePreferences, state.recipePreferences);
          return { ...state, plan: null, recipePreferences: pageState.recipePreferences };
        }
        return state;
      });
      finishStep();
    }
  }, [step, pageState, setState, uid, finishStep]);

  /*
  const reset = () => {
    setPageState((pageState) => ({
      ...pageState,
      recipePreferences: { ...recipePreferences }
    }));
  };
  */

  if (step.id !== 'recipePreferences') {
    return null;
  }

  if (pageState === 'loading') {
    return null;
  }

  return (
    <Grid container item direction="column" justifyContent="flex-start" className={classes.container}>
      <h4>Guustav suggests menus to save you having to spend time looking for recipes (you can still browse recipes if you prefer). Please tell us how you like your meals so we can adjust our suggestions to your needs.</h4>
      <Grid item container>
        <Simple classes={classes} state={pageState} setState={setPageState} />
        <Gourmet classes={classes} state={pageState} setState={setPageState} />
      </Grid>

      <Grid item container alignItems="flex-start" className={classes.sectionHeader}>
        <Grid item container alignItems="center" direction="row">
          <Grid item xs={1}>
            <Scale className={classes.icon} />
          </Grid>
          <Grid item>
            <h3 style={{ margin: 0, padding: 0 }}>Diet </h3>
          </Grid>
          <Grid>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedDiet,
              })}
              onClick={handleExpandDietClick}
              aria-expanded={expandedDiet}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container className={classes.sectionBody}>
          <Collapse in={expandedDiet} timeout="auto" unmountOnExit>
            <DietBalancing classes={classes} state={pageState} setState={setPageState} />
          </Collapse>
        </Grid>
      </Grid>
      <Grid item container alignItems="flex-start" className={classes.sectionHeader}>
        <Grid item container alignItems="center" direction="row">
          <Grid item xs={1}>
            <Poison className={classes.icon} />
          </Grid>
          <Grid item>
            <h3 style={{ margin: 0, padding: 0 }}>Dietary Restrictions </h3>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedHealth,
              })}
              onClick={handleExpandHealthClick}
              aria-expanded={expandedHealth}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container className={classes.sectionBody}>
          <Collapse in={expandedHealth} timeout="auto" unmountOnExit>
            <DietaryFilters classes={classes} state={pageState} setState={setPageState} />
          </Collapse>
        </Grid>
      </Grid>

      {/* Not doing disliked foods for now
        <Grid item container alignItems="flex-start" className={classes.sectionHeader}>
          <Grid item xs={1} >
            <FoodOff className={classes.icon}/>
          </Grid>
          <Grid item>
            <h3 style={{ margin: 0, padding: 0 }}>Disliked Foods</h3>
          </Grid>
          <Grid item container className={classes.sectionBody}>
            <DislikedIngredients classes={classes} state={pageState} setState={setPageState} />
          </Grid>
        </Grid>
      */}
    </Grid>
  );
};

export default RecipePreferences;
