import React, { useContext, useCallback, useEffect, useMemo, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';

import useAuth from './useAuth';
import usePause from './usePause';

import { timeStamp, traceError } from 'utils';

const ShoppingListContext = React.createContext([]);

const initialState = {};

const ShoppingListContextProvider = ({ children }) => {
  const { loading, uid, detach } = useAuth();
  const [state, setState] = useState(initialState);
  const [dataLoading, setDataLoading] = useState(true);
  const [paused] = usePause();

  useEffect(() => {
    if (!loading && uid) {
      const listener = (snapshot) => {
        if (paused.current) {
          return;
        }
        const shoppingList = snapshot.val();
        // trace('useShoppingList: value changed: %o', shoppingList);
        if (shoppingList) {
          setState({ ...snapshot.val() });
        }
        setDataLoading(false);
      };
      const ref = firebase.database().ref(`/users/${uid}/shoppingList`);
      ref.on('value', listener);
      return () => {
        ref.off('value', listener);
      };
    }
  }, [loading, paused, setState, uid]);

  useEffect(() => {
    if (detach) {
      firebase.database().ref(`/users/${detach}/shoppingList`).off();
    }
  }, [detach]);

  const setQuantity = useCallback(({ product, value }) => {
    if (dataLoading || !uid) {
      return;
    }
    // trace('useShoppingList: update quantity of %o to %o', product, value);
    const ref = firebase.database().ref(`/users/${uid}/shoppingList/${product.slug}/actual`);
    ref.set(value);
  }, [dataLoading, uid]);

  const setNotes = useCallback(({ product, notes }) => {
    if (dataLoading || !uid) {
      return;
    }
    // trace('useShoppingList: update notes of %o to %o', product, notes);
    const ref = firebase.database().ref(`/users/${uid}/shoppingList/${product.slug}/notes`);
    ref.set(notes);
  }, [dataLoading, uid]);

  const update = useCallback(({ products, remove, toggle, ingredient, onError, onSuccess }) => {
    if (dataLoading || !uid) {
      return;
    }
    setState((state) => {
      const newState = { ...state };
      products.forEach((product) => {
        if (toggle) {
          if (state[product.slug]) {
            newState[product.slug] = null;
          } else {
            newState[product.slug] = { ...product, addedOn: timeStamp(), needed: true };
          }
        } else if (remove) {
          newState[product.slug] = null;
        } else {
          newState[product.slug] = { ...product, addedOn: timeStamp(), needed: true };
        }
      });
      return newState;
    });
    const ref = firebase.database().ref(`/users/${uid}/`);
    const updates = {};
    products.forEach((product) => {
      if (toggle) {
        if (state[product.slug]) {
          updates[`shoppingList/${product.slug}`] = null;
        } else {
          updates[`shoppingList/${product.slug}`] = { ...product, addedOn: timeStamp(), needed: true };
          /*
          if (!ingredient) {
            updates[`inventory/${product.slug}`] = { ...product, lastPurchased: timeStamp() };
          }
          */
        }
      } else if (remove) {
        // updates[`shoppingList/${product.slug}/needed`] = false;
        updates[`shoppingList/${product.slug}`] = null;
      } else {
        updates[`shoppingList/${product.slug}`] = { ...product, addedOn: timeStamp(), needed: true };
      }
    });
    // trace('useShoppingList: update (remove: %o) updates: %o', remove, updates);
    ref.update(updates, (error) => {
      if (error) {
        traceError('useShoppingList: error updating: %o', error);
        if (onError) {
          onError(error);
        }
      } else if (onSuccess) {
        onSuccess(products);
      }
    });
  }, [dataLoading, uid, setState, state]);

  const value = useMemo(() => ({
    loading: dataLoading,
    setNotes,
    setQuantity,
    shoppingList: state,
    update,
  }), [state, dataLoading, update, setQuantity, setNotes]);

  return <ShoppingListContext.Provider value={value}>{children}</ShoppingListContext.Provider>;
};

const useShoppingList = () => useContext(ShoppingListContext);

export default useShoppingList;
export { ShoppingListContextProvider };
