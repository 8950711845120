import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmActionDialog = ({ noAction, open, text, title, yesAction }) => (
  <Dialog open={open} onClose={noAction} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={noAction} color="primary">
        Cancel
      </Button>
      <Button onClick={yesAction} color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmActionDialog;
