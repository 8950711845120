import React, { useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CheckBox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Cancel from '@material-ui/icons/Cancel';
import isEqual from 'lodash/isEqual';

import grocers from 'data/grocers';
import useAuth from 'hooks/useAuth';
import useSnack from 'hooks/useSnack';
import load from 'hooks/utils/dbLoader';
import { dbSet } from 'hooks/utils/dbUpdater';
import { addEvent } from 'hooks/utils/events';


const useStyles = makeStyles((theme) => ({
  input: {
    width: 42,
  },
  label: {
    fontSize: '1.2em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1em',
    },
  },
  icon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  chip: {
    minWidth: 100,
    marginBottom: 8,
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      minWidth: 70,
      fontSize: '.7em',
      marginRight: 12,
    },
  },
  activeChip: {
    marginRight: 8,
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.up('md')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
    marginBottom: 100,
  },
  sectionHeader: {
    marginTop: 30,
  },
  sectionBody: {
    marginTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  buttons: {
    marginTop: 5,
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: 10,
      top: 10,
      width: 'auto',
    },
  },
  saveButton: {
    marginRight: 10
  }
}));

const defaultValue = {};

const OnlinePreferences = ({ close }) => {
  const classes = useStyles();
  const { uid } = useAuth();
  const { setSnack } = useSnack();
  const [pageState, setPageState] = useState('loading');

  useEffect(() => {
    if (uid) {
      load({ path: 'household/onlinePreferences', uid, defaultValue }).then((result) => {
        setPageState({ onlinePreferences: result });
      });
    }
  }, [uid, setPageState]);

  const toggleGrocer = (id, checked) => {
    setPageState((current) => {
      const newState = {
        onlinePreferences: { ...current.onlinePreferences, [id]: checked }
      };
      console.log('new: %o', newState);

      dbSet({ uid, path: 'household/onlinePreferences', value: newState.onlinePreferences }).then(() => {
        addEvent({ action: 'saveOnlinePreferences', category: 'activity', uid, data: newState.onlinePreferences });
        setSnack({ message: 'Online preferences have been updated' });
      });
      
      return newState;
    });
  };

  const getGrocerLogoPath = (grocerId) => {
    try {
      return require(`assets/GroceryLogos/${grocerId}.png`);
    } catch (error) {
      // If the logo file is not found, return a default image or handle the error in a desired way.
      return require('assets/GroceryLogos/missinglogo.png');
    }
  };

  if (pageState === 'loading') {
    return <LinearProgress />;
  }

  return (
    <Grid container item direction="column" justifyContent="flex-start" className={classes.container}>
        {close && (
          <Grid item>
            <IconButton onClick={close}><Cancel /></IconButton>
          </Grid>
        )}
      <Divider />
      <h3>Select which grocers you want to shop through online</h3>
      <Grid item container spacing={1}>
        {Object.values(grocers).map((g) => (
          <Grid key={g.id} item xs={6} sm={4} lg={3}>
            <Card
              variant="outlined"
              style={{ height: '140px', minHeight: '140px', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}
            >
              <CheckBox
                checked={!!pageState.onlinePreferences[g.id]}
                onChange={(ev) => toggleGrocer(g.id, ev.target.checked)}
                style={{ position: 'absolute', top: '5px', left: '5px', zIndex: 1 }}
              />
              <CardContent style={{ flex: 1 , display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img
                  src={getGrocerLogoPath(g.id)}
                  alt={g.name}
                  style={{ height: '100px', width: '100px', objectFit: 'contain' }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default OnlinePreferences;
