import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Repeat from '@material-ui/icons/Repeat';
import RepeatOne from '@material-ui/icons/RepeatOne';

import frequencies from 'data/frequencies';
import useSnack from 'hooks/useSnack';
import { load } from 'hooks/utils/dbLoaderMulti';
import { dbUpdate } from 'hooks/utils/dbUpdater';
import inventoryUtils from 'hooks/utils/inventoryUtils';
import { dateStamp, trace } from 'utils';

const options = Object.entries(frequencies).map(([key, value], idx) => (
  <MenuItem key={`option-${idx}`} value={key} dense>{value}</MenuItem>
));

const useStyles = makeStyles((theme) => ({
  root: {
    '&:focus': {
      background: 'none'
    }
  },
  select: { paddingRight: '0!important' },
  icon: { display: 'none' },
}));

const Frequency = ({ mobile, product, toggle, uid }) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const { setSnack } = useSnack();

  useEffect(() => {
    if (typeof product.frequency !== 'undefined') {
      setFrequency(product.frequency);
      return;
    }
    const paths = [
      `inventory/${product.slug}/frequency`,
      `/global/supplies/${product.slug}/frequency`,
    ];
    if (product.department) {
      paths.push(`/global/departments/${product.department}/frequency`);
    }
    load({ uid, paths, source: 'Frequency' }).then((result) => {
      // if (snapshot.val() && (snapshot.val().frequency || snapshot.val().frequency === 0)) {
      const userVal = result[paths[0]];
      const supplyVal = result[paths[1]];
      const deptVal = result[paths[2]];
      if (userVal || userVal === 0) {
        setFrequency(userVal);
      } else if (supplyVal || supplyVal === 0) {
        setFrequency(supplyVal);
      } else if (deptVal || deptVal === 0) {
        setFrequency(deptVal);
      } else {
        setFrequency(0);
      }
    });
  }, [product, uid]);

  const update = async (value) => {
    const inv = await inventoryUtils({ uid });
    const inventory = inv.repeats[product.slug];
    const oldFrequency = Number(frequency || 0);
    const newFrequency = Number(value || 0);
    const wasNeeded = inventory && oldFrequency > 0 && inv.due(inventory);
    const isNeeded = inv.due({ ...(inventory || {}), ...product, frequency: newFrequency });
    const ds = dateStamp();
    const updates = {
      [product.slug]: {
        ...(inventory || {}),
        slug: product.slug,
        name: product.name,
        actual: product.actual || 1,
        quantity: product.quantity || null,
        department: product.department || 'other',
        frequency: newFrequency,
        updatedAt: ds,
        lastPurchased: null,
      }
    };
    // setInventory(updates[product.slug]);
    if (inventory && inventory.dates) {
      if (wasNeeded) {
        if (isNeeded) {
          setSnack({ message: `Frequency updated. ${product.name} is still due` });
        } else if (newFrequency) {
          setSnack({ message: `Frequency updated. ${product.name} is no longer due` });
        } else {
          setSnack({ message: `Frequency updated. ${product.name} will no longer be suggested for repeat purchases` });
        }
      } else if (isNeeded) {
        setSnack({ message: `Frequency updated. ${product.name} is now due` });
      } else if (newFrequency) {
        if (oldFrequency) {
          setSnack({ message: `Frequency updated. ${product.name} is still not due` });
        } else {
          setSnack({ message: `Frequency updated. ${product.name} is not due` });
        }
      } else {
        setSnack({ message: `Frequency updated. ${product.name} will not be suggested for repeat purchases` });
      }
    } else {
      setSnack({ message: `Purchase frequency for ${product.name} has been updated` });
    }
    trace('updates: %o, uid: %o', updates, uid);
    await dbUpdate({ uid, path: 'inventory', updates });
    setFrequency(value);
  };

  /*
  if (!inventory) {
    return null;
  }
  */

  let text = 'One-time purchase';
  if (frequency !== null) {
    text = `${mobile ? '' : ' '}${frequencies[frequency || 0]}`;
  }

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid item>
        <Select
          key="freq-menu"
          classes={{ root: classes.root, select: classes.select, icon: classes.icon }}
          disableUnderline
          renderValue={(value) => (
            <div key="freq" onClick={(ev) => (anchor ? setAnchor(null) : setAnchor(ev.target))} style={{ textAlign: 'center', fontSize: '.8em', opacity: frequency !== null ? 1 : 0.5 }}>
              {!frequency && (
                <RepeatOne size="small" style={{ verticalAlign: 'middle', marginRight: 3, opacity: frequency !== null ? 1 : 0.5 }} />
              )}
              {frequency > 0 && (
                <Repeat size="small" style={{ verticalAlign: 'middle', marginRight: 3, opacity: frequency !== null ? 1 : 0.5 }} />
              )}
              {!mobile && (
                <div>{text.replace('Every', '')}</div>
              )}
              {mobile && (
                <div>{text.replace('Every', '')}</div>
              )}
            </div>
          )}
          open={!!anchor}
          onClose={() => setAnchor(null)}
          onChange={(ev) => update(ev.target.value)}
          value={frequency || '0'}
          style={{marginRight:50}}
        >
          {options}
        </Select>
      </Grid>
    </Grid>
  );
};

export default Frequency;
