import { useEffect, useMemo, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';

import useAuth from 'hooks/useAuth';
import { traceError } from 'utils';

const useDb = ({ path, defaultValue, set, setDirect, skip, source }) => {
  const { loading, uid, detach } = useAuth();
  const [value, setValue] = useState({ value: defaultValue, loading: true });

  useEffect(() => {
    if (skip) {
      return;
    }
    let p = path;
    if (typeof path === 'function') {
      p = path();
    }
    // trace("dbwatcher[%s]: path: %s (%s)", source, p, path);
    if (p !== '' && p[0] !== '/' && !uid && !loading) {
      traceError('dbWatcher[%s]: Trying to get relative path: [%s] without a uid', source, p);
      return;
    }
    const fullPath = p[0] === '/' ? p : `/users/${uid}/${p}`;
    const ref = firebase.database().ref(fullPath);
    const listener = (snapshot) => {
      // trace('dbWatcher[%s]: set %s -> %o', source, path, snapshot.val());
      if (setDirect) {
        setDirect(snapshot.val() || defaultValue);
      } else if (set) {
        set({ value: snapshot.val() || defaultValue, loading: false });
      } else {
        setValue({ value: snapshot.val() || defaultValue, loading: false });
      }
    };
    ref.on('value', listener);
    return () => {
      // trace('dbWatcher[%s]: cleanup path %s', source, path);
      if (ref) {
        ref.off('value', listener);
      }
    };
  }, [loading, uid, path, defaultValue, set, setDirect, setValue, skip, source]);

  useEffect(() => {
    if (detach) {
      let p = path;
      if (typeof path === 'function') {
        p = path();
      }
      if (p[0] !== '/') {
        const fullPath = `/users/${detach}/${p}`;
        firebase.database().ref(fullPath).off();
      }
    }
  }, [detach, path]);

  return useMemo(() => ({ set, ...value }), [set, value]);
};

export default useDb;
