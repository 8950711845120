import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Food from '@material-ui/icons/Fastfood';

import Tip from 'components/Tip';

const Content = () => (
  <div>
    <h3>Recipe Review Instructions</h3>
    <h4>Legend</h4>
    <ul>
      <li>Guustav-Approved: count of recipes we explicitly marked as Guustav-Approved. They will show up in Pick For Me more often.</li>
      <li>Cached: count of recipes in the cache that will show up in Pick for Me.</li>
      <li>Green Outline: Someone has approved it</li>
      <li>Red Outline: Someone has quarantined it</li>
      <li>Green Thumbs Up: You have approved it</li>
      <li>Red Thumbs Up: You have quarantined it</li>
    </ul>
    <h4>Main Page</h4>
    <div>This is showing recipes that are in our cache, organized by main ingredient/keyword.</div>
    <ul style={{ listStyle: 'disc' }}>
      <li>Click Thumbs Down to quarantine. These recipes will never show up on the site.</li>
      <li>Click Thumbs Up to classify. Verify/set the main ingredient. These recipes will be used in Pick for Me and Let Me Pick.</li>
      <li>On Thumbs Up dialog, check Guustav Approved box if it's really good. These recipes will be "Guustav-Approved" and will be highlighted and used more often in Pick for Me.</li>
      <li>Classified recipes that are left alone will show up in Pick for Me and Let Me Pick if the main ingredient is one of the keywords.</li>
    </ul>
    <h4>Search Page</h4>
    <div>Search for whatever you want. These recipes may or may not be in the cache. The general idea here is to find new recipes that are good to add to the cache.</div>
    <ul style={{ listStyle: 'disc' }}>
      <li>Full Meals Only - check this to filter out non-full meals. This has to hit the Edamam API for every recipe so only use when needed.</li>
      <li>Un-Reviewed Only - check this to filter out recipes that are already in the cache.</li>
      <li>Thumbs Up/Down is the same as on the main page.</li>
      <li>The difference is searched recipes will not be used in Pick for Me unless they were already in the cache. You must Keep and classify it to add it to the cache so it will be included in Pick for Me. Unless explicitly quarantined, these recipes may show up in Let Me Pick.</li>
    </ul>
  </div>
);

const Help = ({ approved, approvedForProduct, keptCount, keptForProduct, showPlans, plan }) => (
  <Grid container key="tip" justifyContent="space-between" style={{ marginTop: 8 }}>
    <Grid item>
      <Tip message={<Content />} title="Click for instructions" paperStyles={{ minWidth: 600 }} placement="bottom-end" />
    </Grid>
    <Grid item>
      {approved && (
      <span>Guustav-Approved: {Object.keys(approved).length}{approvedForProduct ? `/${approvedForProduct}` : ''}</span>
      )}
      {keptCount !== null && (
      <span style={{ paddingLeft: 10 }}>Cached: {keptCount}{keptForProduct ? `/${keptForProduct}` : ''}</span>
      )}
    </Grid>
    <Grid item container style={{ width: 'auto' }} alignItems="flex-start" spacing={2}>
      {plan && (
      <Grid item>
        <Link href="#" onClick={(e) => { e.preventDefault(); showPlans(); }}>
          Current Menu: <b>{plan.name}</b> (Recipes: {Object.keys(plan.recipes || {}).length})
        </Link>
      </Grid>
      )}
      <Grid item style={{ paddingTop: 0 }}>
        <IconButton onClick={showPlans} size="small" style={{ marginRight: 10 }}>
          <Food />
        </IconButton>
      </Grid>
    </Grid>
  </Grid>
);

export default Help;
