import React from 'react';

import Alert from '@material-ui/lab/Alert';
import get from 'lodash/get';

import { traceError } from 'utils';

const errorInfo = (error) => {
  if (error && error.graphQLErrors && error.graphQLErrors.length) {
    const gError = error.graphQLErrors[0];
    const errorData = get(gError, 'extensions', 'exception');
    const errorCode = get(gError, 'extensions', 'code');
    return { errorCode, errorData };
  }
  if (error.networkError && error.networkError.result) {
    return { errorCode: get(error, 'networkError.result.errors[0].message') || get(error, 'networkError.result.error.message') };
  }
  if (error.message) {
    return { errorCode: error.message };
  }
  return {};
};

const Error = ({ error }) => {
  const ei = errorInfo(error);
  traceError(ei);
  return <Alert severity="error">{typeof ei.errorCode === 'string' ? ei.errorCode : ei.errorCode.code}</Alert>;
};

export default errorInfo;
export { Error };
