import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import startCase from 'lodash/startCase';

import aisles from 'data/aisles';
import { volumes, weights } from 'data/measurements';
import useAppStyles from 'hooks/useAppStyles';
import dbWatcher from 'hooks/utils/dbWatcher';

const formErrors = (data) => {
  const errors = {};
  if (!data.name) {
    errors.email = 'Product name is required';
  }
  return errors;
};

const EditProduct = ({ product, onClose, onDelete, onSave }) => {
  const classes = useAppStyles();
  const [data, setData] = useState({ ...product });
  const [depts, setDepts] = useState({ loading: true });

  dbWatcher({ path: '/global/departments', set: setDepts, source: 'components/NewProduct' });
  const departments = depts.value || {};

  if (!product) {
    return null;
  }

  const onBlur = () => {
    setData({ ...data });
  };

  const handleSave = async () => {
    onSave(data);
  };

  const handleDelete = async () => {
    onDelete(data.id);
  };

  const change = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const errors = formErrors(data, false);

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" scroll="body" disableBackdropClick>
      <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
        <input type="submit" style={{ display: 'none' }} />
        <DialogTitle id="form-dialog-title">Edit Product {data.id}</DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" spacing={2} style1={{ padding: 8 }}>
            <Grid item>
              <TextField
                id="product-slug" autoFocus fullWidth variant="outlined" label="Slug"
                value={data.slug} onChange={(ev) => change('slug', ev.target.value)} onBlur={onBlur}
                required error={!!errors.slug} helperText={errors.slug || ' '} size="small"
              />
            </Grid>
            <Grid item>
              <TextField
                id="product-name" autoFocus fullWidth variant="outlined" label="Product"
                value={data.name} onChange={(ev) => change('name', ev.target.value)} onBlur={onBlur}
                required error={!!errors.name} helperText={errors.name || ' '} size="small"
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ minWidth: 120, marginBottom: 20 }}>
                <InputLabel
                  shrink htmlFor="department-label"
                  style={{ backgroundColor: '#ffffff'/* this is to fix a bug in material where the line goes through until you click */ }}
                >
                  Department
                </InputLabel>
                <Select
                  required
                  native
                  inputProps={{
                    name: 'department',
                    id: 'department-label'
                  }}
                  value={data.department || ''}
                  onChange={(ev) => change('department', ev.target.value)}
                >
                  {Object.keys(departments).map((key) => (
                    <option key={key} value={key}>{departments[key].name}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ minWidth: 120, marginBottom: 20 }}>
                <InputLabel
                  shrink htmlFor="aisle-label"
                  style={{ backgroundColor: '#ffffff'/* this is to fix a bug in material where the line goes through until you click */ }}
                >
                  Aisle
                </InputLabel>
                <Select
                  native
                  inputProps={{
                    name: 'aisle',
                    id: 'aisle-label'
                  }}
                  value={data.aisle || ''}
                  onChange={(ev) => change('aisle', ev.target.value)}
                >
                  {aisles.map((aisle) => (
                    <option key={aisle} value={aisle}>{startCase(aisle)}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ minWidth: 120, marginBottom: 20 }}>
                <InputLabel
                  shrink htmlFor="unit-label"
                  style={{ backgroundColor: '#ffffff'/* this is to fix a bug in material where the line goes through until you click */ }}
                >
                  Default Unit
                </InputLabel>
                <Select
                  native
                  inputProps={{
                    name: 'unit',
                    id: 'unit-label'
                  }}
                  value={data.defaultMeasurementUnit || ''}
                  onChange={(ev) => change('defaultMeasureUnit', ev.target.value)}
                >
                  {Object.entries({ ...volumes, ...weights }).map(([key, unit]) => (
                    <option key={key} value={key}>{unit.name} ({unit.unitType})</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                fullWidth variant="outlined" label="Default Quantity" placeholder="Default Quantity"
                value={data.defaultQuantity} onChange={(ev) => change('defaultQuantity', ev.target.value)}
                error={!!errors.defaultQuantity} helperText={errors.defaultQuantity || ' '} size="small"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Button onClick={handleDelete} color="primary" className={classes.dangerButton}>
              Delete
            </Button>
            <Grid item>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSave} color="primary" disabled={!!Object.keys(formErrors(data, true)).length}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditProduct;
