import React, { useCallback, useEffect, useState } from 'react';
import { ListItem, List } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import dayjs from 'dayjs';
import EditIcon from 'mdi-material-ui/PlaylistEdit';
//import ShopOnline from 'mdi-material-ui/Web';
import { ReactComponent as ShopOnline } from 'assets/shop_online.svg';
import { ReactComponent as ShopOnlinePurple } from 'assets/shop_online_purple.svg';

import OnlineStore from './OnlineStore';
import OnlineStoreConfirmModal from './OnlineStoreConfirmModal';
import OnlineStoreHelpModal from './OnlineStoreHelpModal';
import PurchaseHistory from './PurchaseHistory';

import ProductList from 'components/productList';
import SortMenu from 'components/productList/SortMenu';
import ProductSearch from 'components/productSearch';
import grocers from 'data/grocers';
import useAppStyles from 'hooks/useAppStyles';
import useAuth from 'hooks/useAuth';
import useNav from 'hooks/useNav';
import useSnack from 'hooks/useSnack';
import load from 'hooks/utils/dbLoader';
import { dbSet, dbUpdate } from 'hooks/utils/dbUpdater';
import dbWatcher from 'hooks/utils/dbWatcher';
import { addEvent } from 'hooks/utils/events';
import { dateStamp, isNative, postNativeEvent, timeStamp, trace, traceError } from 'utils';
import Wizard from 'wizards/plan';
import EasyShoppingDrawing from 'assets/EasyshoppingDrawing.png';
import { ReactComponent as GandalfTheWhite } from 'assets/wizard_icon_purple.svg';
import inventory from 'hooks/utils/inventoryUtils';
import { Badge, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: 'orange',  // the color you want
    color: '#fff',
    top: '15%', 
  },
}))(Badge);

const displayProps = {
  type: 'checkbox', // checkbox, none
  recipeIcon: true,
  quantityText: true,
  notes: 'editable', // readonly, none
  lastPurchased: false,
  frequency: false,
  quantity: 'editable', // readonly, none, or see if setQuantity exists
  remove: true,
  source: 'shopping',
  toggleAllText: 'Mark All as Purchased',
  toggleNoneText: '-',
};

const isInventory = (product) => {
  if (!product.recipes) {
    return true;
  }
  return product.quantity.quantity < product.actual;
};

const defaultValue = {};

const convertToProduct = (item) => {
  const grocer = grocers[item.store];
  return {
    slug: item.slug,
    name: item.name,
    measure: item.quantity.measure || '',
    quantity: String(item.quantity.quantity || 1),
    department: item.department || '',
    lastPurchased: item.lastPurchased ? dayjs(item.lastPurchased).format('MM/DD/YYYY') : '',
    recipes: Object.values(item.recipes || {}).map((r) => ({
      label: r.label || '',
      notes: (r.quantity.notes || '').split('\n').map((n) => n.trim()).filter((n) => !!n).join(' '),
      text: r.quantity.text || '',
    })),
    notes: (item.notes || '').split('\n').map((n) => n.trim()).filter((n) => !!n).join(' '),
    store: item.store,
    url: grocer.url(item),
    purchased: item.purchased,
    back: item.back,
    next: item.next,
  };
};

const ShoppingList = ({ history }) => {
  const classes = useAppStyles();
  const { email, uid, anonymous, admin } = useAuth();
  const { setNav } = useNav();
  const { setSnack } = useSnack();
  const [pantryChecklistOpen, setPantryChecklistOpen] = useState(false);
  const [shoppingList, setShoppingList] = useState({ loading: true });
  const [sort, setSort] = useState('By Department');
  const [online, setOnline] = useState({ status: 'closed', store: '', items: [], purchased: [] });
  const [sortedItems, setSortedItems] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [onlineGrocers, setOnlineGrocers] = useState();
  const [, setHasPurchases] = useState(false);
  const [dueCount, setDueCount] = useState(0);
  //const [showWizard, setShowWizard] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [openStoreSelection, setOpenStoreSelection] = useState(false);



  /* // Set the first store as the default selected store */
  useEffect(() => {
    if (onlineGrocers && onlineGrocers.length > 0) {
      setSelectedStore(onlineGrocers[0]);
    }
  }, [onlineGrocers]);

  let menuMode = 'pantry';
  if (anonymous) menuMode = 'manageInventory';
  dbWatcher({ path: 'shoppingList', set: setShoppingList, defaultValue, source: 'ShoppingList' });

  // loading up what grocery stores they have selected for online shopping
  useEffect(() => {
    if (uid && uid !== 'visitor') {
      load({ path: 'household/onlinePreferences', uid }).then((result) => {
        if (result) {
          const enabled = Object.keys(result).filter((id) => !!result[id]);
          const og = enabled.map((id) => grocers[id]).filter((g) => !!g).sort((a, b) => a.name.localeCompare(b.name));
          setOnlineGrocers(og);
        }
      });
    }
  }, [setOnlineGrocers, uid]);



  // trace('online: %o, sorted item: %o', { ...online, items: online.items.map((i) => i.slug) }, (sortedItems||[]).map((i) => i.slug));
  useEffect(() => {
    // Note: I had this in the setNav effect, but whenever the list changed, it was
    // scrolling to the top. If you are debugging why the page is not scrolling to the
    // top onload, then you need to figure out some way to detect an initial load
    // versus a re-render due to the list changing. Maybe just check for shoppinglist.loading,
    // but not sure if that gets set to true whenever we are reloading it.
    window.scrollTo(0, 0);
  }, []);



  useEffect(() => {
    if (uid && uid !== 'visitor') {
      const messages = [];
      inventory({ uid, source: 'PantryChecklist', messages }).then((inventoryChecker) => {
        console.log(inventoryChecker);
        console.log(shoppingList);
        
        if (inventoryChecker.purchases && Object.keys(inventoryChecker.purchases).length > 0) {
          setHasPurchases(true);

          // If shopping list is loading or is empty, just calculate due items without comparison
          if (shoppingList==='loading' || !shoppingList.value || Object.keys(shoppingList.value).length === 0) {
            const dueItems = Object.values(inventoryChecker.purchases).filter((i) => i.due);
            setDueCount(dueItems.length);
          } else {
            // Extract slugs from shopping list for easy checking later
            const shoppingListSlugs = Object.keys(shoppingList.value);

            // Filter items that are due and NOT in the shopping list
            const dueItems = Object.values(inventoryChecker.purchases)
                                .filter((i) => i.due && !shoppingListSlugs.includes(i.slug));

            setDueCount(dueItems.length);
          }
        }
      });
    }
  }, [uid, shoppingList]);




  // does the purchasing
  const toggle = useCallback(({ products, on, cb }) => {
    trace('products to toggle: %o', products);
    const date = dateStamp();
    const time = timeStamp();
    const count = Object.keys(products).length;
    const updates = {};
    products.forEach((product) => {
      const { slug } = product;
      updates[`purchaseHistory/date/${date}/${slug}`] = { ...product, lastPurchased: time };
      updates[`purchaseHistory/product/${product.slug}/${date}`] = { ...product, lastPurchased: time };
      updates[`shoppingList/${product.slug}`] = null;
      if (isInventory(product)) {
        // Doing this to avoid updating frequency
        updates[`inventory/${slug}/slug`] = slug;
        updates[`inventory/${slug}/name`] = product.name;
        updates[`inventory/${slug}/department`] = product.department;
        updates[`inventory/${slug}/actual`] = product.actual || 1;
        updates[`inventory/${slug}/quantity`] = product.quantity || null;
        updates[`inventory/${slug}/dates/${date}`] = product.actual || 1;
      }
    });
    trace('updates: %o', updates);
    dbUpdate({ uid, path: '', updates }).then(() => {
      // TODO include number of items left
      addEvent({ category: 'shopping', uid, action: on ? 'purchase' : 'unpurchase', data: products });
      setSnack({
        message: `${count} item${count === 1 ? '' : 's'}
        ha${count === 1 ? 's' : 've'} been marked as purchased`
      });
      if (cb) {
        cb(false);
      }
    }).catch((err) => {
      traceError(err);
      setSnack({ message: 'An error occurred while purchasing the products' });
      if (cb) {
        cb(true);
      }
    });
  }, [uid, setSnack]);

  // mobile app action on an item, navigation or purchase
  const nativeStoreAction = useCallback((e) => {
    const { detail } = e;
    const { slug } = detail;
    const { action } = detail;
    trace('flutter-action data: %o, %o', slug, action);
    if (action === 'buy') {
      setOnline((current) => {
        const idx = current.items.findIndex((i) => i.slug === slug);
        const currentItem = current.items[idx];
        const newIdx = idx === current.items.length - 1 ? idx : idx + 1;
        const last = newIdx === current.items.length - 1;
        const first = newIdx === 0;
        const nextItem = current.items[newIdx];
        const nextPurchased = currentItem.slug === nextItem.slug ? true : current.purchased.some((i) => i.slug === nextItem.slug);
        const item = convertToProduct({ ...nextItem, store: current.store, purchased: nextPurchased, back: !first, next: !last });
        let purchasedCount = current.purchased.length;
        if (!current.purchased.some((i) => i.slug === currentItem.slug)) {
          purchasedCount += 1;
        }
        postNativeEvent({ action: 'shopping-update', item, purchasedCount, idx: newIdx + 1, count: current.items.length });
        return { ...current, item: nextItem, purchased: [...current.purchased, currentItem] };
      });
    } else if (action === 'unbuy') {
      setOnline((current) => {
        const idx = current.items.findIndex((i) => i.slug === slug);
        const currentItem = current.items[idx];
        const last = idx === current.items.length - 1;
        const first = idx === 0;
        const nextItem = currentItem;
        const purchased = false;
        const item = convertToProduct({ ...nextItem, store: current.store, purchased, back: !first, next: !last });
        let purchasedCount = current.purchased.length;
        if (current.purchased.some((i) => i.slug === currentItem.slug)) {
          purchasedCount -= 1;
        }
        postNativeEvent({ action: 'shopping-update', item, purchasedCount, idx: idx + 1, count: current.items.length });
        return { ...current, item: nextItem, purchased: current.purchased.filter((i) => i.slug !== nextItem.slug) };
      });
    } else if (action === 'skip') {
      setOnline((current) => {
        const idx = current.items.findIndex((i) => i.slug === slug);
        const newIdx = idx === current.items.length - 1 ? idx : idx + 1;
        const last = newIdx === current.items.length - 1;
        const first = newIdx === 0;
        const nextItem = current.items[newIdx];
        const nextPurchased = current.purchased.some((i) => i.slug === nextItem.slug);
        const item = convertToProduct({ ...nextItem, store: current.store, purchased: nextPurchased, back: !first, next: !last });
        postNativeEvent({ action: 'shopping-update', item, purchasedCount: current.purchased.length, idx: newIdx + 1, count: current.items.length });
        return { ...current, item: nextItem };
      });
    } else if (action === 'back') {
      setOnline((current) => {
        const idx = current.items.findIndex((i) => i.slug === slug);
        const newIdx = idx === 0 ? idx : idx - 1;
        const prevItem = current.items[newIdx];
        const last = newIdx === current.items.length - 1;
        const first = newIdx === 0;
        const purchased = current.purchased.some((i) => i.slug === prevItem.slug);
        const item = convertToProduct({ ...prevItem, store: current.store, purchased, back: !first, next: !last });
        postNativeEvent({ action: 'shopping-update', item, purchasedCount: current.purchased.length, idx: newIdx + 1, count: current.items.length });
        return { ...current, item: prevItem };
      });
    } else if (action === 'close') {
      setOnline((current) => {
        trace('closeStore: %s, purchased: %d', current.store, current.purchased.length);
        window.removeEventListener('flutter-store-action', nativeStoreAction);
        postNativeEvent({ action: 'shopping-close', item: null, purchasedCount: current.purchased.length, idx: 1, count: current.items.length });
        if (current.purchased.length) {
          return { ...current, status: 'confirm' };
          // toggle({ products: current.purchased, on: true });
        }
        return { store: null, status: 'close', item: null, items: [], purchased: [] };
      });
    }
  }, [setOnline]);

  useEffect(() => {
    setOnline((current) => {
      if (current.store && current.status === 'open' && !isNative()) {
        return { ...current, item: sortedItems[0] };
      }
      return current;
    });
  }, [sortedItems, setOnline]);

  const openStore = useCallback(({ store, status }) => {
    trace('openStore: %s, status: %s', store, status);
    setOnline((current) => {
      if (status === 'help') {
        return { ...current, store, status };
      }
      const nextItem = sortedItems[0];
      if (isNative() && status === 'open') {
        const item = convertToProduct({ ...nextItem, store, purchased: false, back: false, next: sortedItems.length > 1 });
        trace('sorteditems.length: %d', sortedItems.length);
        postNativeEvent({ action: 'shopping-open', item, idx: 1, count: sortedItems.length });
        window.addEventListener('flutter-store-action', nativeStoreAction);
      }
      return { store, status, item: nextItem, items: [...sortedItems], purchased: [] };
    });
  }, [setOnline, sortedItems, nativeStoreAction]);

  const startOnline = useCallback((store) => {
    setAnchorEl(null);
    trace('Starting online: store: %s', store);
    if (store === 'amazon') {
      // leave the page the way it is since we're navigating away
      setOnline({ store: null, status: 'closed', item: null });
      if (isNative()) {
        trace('going to shop/amazon in webview');
        postNativeEvent({ action: 'modalWebView', uri: `${window.location.origin}/shop/amazon` });
      } else {
        history.push('/shop/amazon');
      }
      return;
    }
    openStore({ store, status: isNative() ? 'open' : 'help' });
  }, [history, setOnline, setAnchorEl, openStore]);

  const handleShopOnline = () => {
    if (onlineGrocers&&onlineGrocers.length === 1) {
      startOnline(onlineGrocers[0].id);
    } else {
      setOpenStoreSelection(true);
    }
  };

  // if called from the summary shop online button, opening the popup
  useEffect(() => {
    if (window.location.hash && window.location.hash.slice(1) === 'launchOnline') {
      if (onlineGrocers&&onlineGrocers.length === 1) {
        startOnline(onlineGrocers[0].id);
      }
      else setOpenStoreSelection(true);
    } 
  }, [onlineGrocers]);

  const setQuantity = ({ product, value }) => {
    dbSet({ uid, path: `shoppingList/${product.slug}/actual`, value });
  };

  const saveNote = ({ slug, note }) => {
    dbSet({ uid, path: `shoppingList/${slug}/notes`, value: note });
  };

  const getGrocerLogoPath = (grocerId) => {
    try {
      return require('assets/GroceryLogos/' + grocerId.id + '.png');
    } catch (error) {
      // If the logo file is not found, return a default image or handle the error in a desired way.
      return require('assets/GroceryLogos/missinglogo.png');
    }
  };

  const remove = ({ products }) => {
    trace('products to toggle: %o', products);
    const count = Object.keys(products).length;
    const updates = {};
    products.forEach((product) => {
      updates[`shoppingList/${product.slug}`] = null;
    });
    trace('updates: %o', updates);
    dbUpdate({ uid, path: '', updates }).then(() => {
      addEvent({ category: 'shopping', uid, action: 'remove', data: products });
      setSnack({
        message: `${count} item${count === 1 ? '' : 's'}
        ha${count === 1 ? 's' : 've'} been added removed from your shopping list`
      });
    }).catch((err) => {
      traceError(err);
      setSnack({ message: 'An error occurred while removing the product' });
    });
  };

  let count = 0;
  if (shoppingList && !shoppingList.loading) count = (Object.keys(shoppingList.value) || []).length;




  useEffect(() => {
    setNav((nav) => ({
      up: true,
      pageName: 'Shopping List',
      stickyChildren: <ProductSearch />,
      actions:
        <>
          {(count !== 0) && (<>
            <Hidden smDown>
              <Grid container spacing={1}>
                <Grid item>
                  <StyledBadge badgeContent={dueCount} anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
                    <Button
                      color="secondary" size="medium" variant="contained"
                      onClick={() => setPantryChecklistOpen(true)}
                    >
                      <EditIcon />
                      &nbsp;Pantry Checklist
                    </Button>
                  </StyledBadge>
                </Grid>
                <Grid item>
                  <Button color="secondary" size="medium" variant="contained" onClick={handleShopOnline} startIcon={<ShopOnline />}>

                    &nbsp;Shop Online
                  </Button>
                </Grid>
              </Grid>
            </Hidden>

            <Hidden mdUp xsDown>
              <StyledBadge badgeContent={dueCount} anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
                <Button
                  color="secondary" size="small" variant="contained"
                  onClick={() => setPantryChecklistOpen(true)}
                >
                  <EditIcon fontSize="small" />
                  &nbsp;Pantry
                </Button>,
              </StyledBadge>
              <Button color="secondary" size="small" variant="contained" onClick={handleShopOnline} startIcon={<ShopOnline />}>
                &nbsp;Shop Online
              </Button>
              <Menu
                anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                {(onlineGrocers || []).map((grocer) => <MenuItem key={grocer.id} onClick={() => startOnline(grocer.id)}>
                  <img
                    src={getGrocerLogoPath(grocer.id)}
                    alt={grocer.name}
                    style={{ height: '25px' }}
                  />
                </MenuItem>)}
                <MenuItem onClick={() => history.push('/preferences#online')}>Select Stores</MenuItem>
              </Menu>
            </Hidden>

            <Hidden smUp>
              <div>
                <StyledBadge badgeContent={dueCount} anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
                  <IconButton size="medium" color="secondary" onClick={() => setPantryChecklistOpen(true)}>
                    <EditIcon />
                  </IconButton>
                </StyledBadge>
               {/*  <IconButton size="medium" color="secondary" onClick={handleShopOnline}>
                  <ShopOnlinePurple />
                </IconButton> */}
              <Button color="secondary" size="small" variant="contained"  style={{ borderRadius: '20px' }} onClick={handleShopOnline} startIcon={<ShopOnline />}>
                &nbsp;Shop Online
              </Button>
                <Menu
                  anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={() => setAnchorEl(null)}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  {(onlineGrocers || []).map((grocer) => <MenuItem key={grocer.id} onClick={() => startOnline(grocer.id)}>
                    <img
                      src={getGrocerLogoPath(grocer.id)}
                      alt={grocer.name}
                      style={{ height: '25px' }}
                    />
                  </MenuItem>)}
                  <MenuItem onClick={() => history.push('/preferences#online')}>Select Stores</MenuItem>
                </Menu>
              </div>
            </Hidden>
          </>)}
        </>
    }));
  }, [anonymous, history, setNav, anchorEl, setAnchorEl, admin, startOnline, onlineGrocers, count, dueCount]);

  if (shoppingList.loading) {
    return (
      <Grid container direction="column">
        <LinearProgress />
      </Grid>
    );
  }

  console.debug('shopping list: %o', shoppingList);



  const handleCloseStoreSelection = () => {
    setOpenStoreSelection(false);
  };


  console.log(openStoreSelection);
  return (
    <Grid
      container item xs={12} sm={11} direction="column"
      justifyContent="flex-start"
      alignContent="stretch" className={classes.listPageContainer} style={{ paddingBottom: 50 }}
    >
      {count === 0 && (
        <Grid container direction="column" alignItems="center">
          <img src={EasyShoppingDrawing} alt='shoppingDrawing' style={{ height: '35vh', marginTop: 20 }} />
          <Button color='secondary' variant='outlined' startIcon={<GandalfTheWhite />} style={{ maxWidth: '80%' }} onClick={() => setPantryChecklistOpen(true)}>Click here to create your shopping list</Button>
          {dueCount > 0 && (
            <Typography>You have {dueCount} item{dueCount > 1 ? "s" : ""} due</Typography>
          )}

        </Grid>
      )}
      {count > 0 && (
        <Grid container item justifyContent="space-between" style={{ marginBottom: 10 }}>
          <Grid container item direction="column" className={classes.containerAutoWidth}>
            <h2 style={{ padding: 0, margin: 0 }}>
              Shopping List
              <span style={{ fontWeight: 'normal', fontSize: '.8rem', verticalAlign: 'middle' }}> ({count} {`item${count === 1 ? '' : 's'}`})</span>
            </h2>
            <Link
              href="#" key="toggle-on" style={{ verticalAlign: 'middle' }} color="primary"
              onClick={(ev) => { ev.preventDefault(); toggle({ products: Object.values(shoppingList.value), on: true }); }}
            >
              Mark All as Purchased
            </Link>
          </Grid>
          <Grid style={{ padding: 6 }}>
            <SortMenu sort={sort} onSort={(sort) => setSort(sort)} />
            <IconButton onClick={() => { window.open('/print', '_blank'); }}><PrintOutlinedIcon /></IconButton>
          </Grid>
        </Grid>
      )}
      {online.store && online.store !== 'amazon' && online.status === 'help' && !isNative() && (
        <OnlineStoreHelpModal
          store={online.store}
          onOpen={() => openStore({ store: online.store, status: 'open' })}
          onClose={() => setOnline({ store: null, status: 'closed', item: null, items: [], purchased: [] })}
        />
      )}
      {online.store && online.store !== 'amazon' && online.status === 'open' && !isNative() && (
        <OnlineStore
          item={online.item} store={online.store}
          onClose={() => setOnline({ store: null, status: 'closed', item: null, items: [], purchased: [] })}
        />
      )}
      {online.status === 'confirm' && isNative() && (
        <OnlineStoreConfirmModal
          purchases={online.purchased} toggle={toggle}
          onClose={() => setOnline({ store: null, status: 'closed', item: null, items: [], purchased: [] })}
        />
      )}
      <ProductList
        displayProps={displayProps} products={shoppingList.value} sort={sort} saveNote={saveNote}
        source="shopping" remove={remove} setQuantity={setQuantity} toggle={toggle} uid={uid}
        email={email} setSortedItems={setSortedItems}
      />
      <PurchaseHistory uid={uid} />
      {pantryChecklistOpen && (
        <Wizard isOpen close={() => setPantryChecklistOpen(false)} source="shopping" mode={menuMode} />
      )}
      <Dialog open={openStoreSelection} onClose={handleCloseStoreSelection}>
        <DialogTitle>Pick a store</DialogTitle>
        <DialogContent>
          <List>
            {(onlineGrocers || []).map((store, index) => (
              <ListItem
                button
                key={index}
                onClick={() => { 
                  setOpenStoreSelection(false);
                  startOnline(store.id);
                }}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <img
                  src={getGrocerLogoPath(store)}
                  alt={store}
                  style={{ height: '25px', marginRight: '10px' }}
                />
              </ListItem>
            ))}
            <ListItem onClick={() => history.push('/preferences#online')}>Select Stores</ListItem>

          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStoreSelection} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>

    </Grid>
  );
};

export default ShoppingList;
