import React from 'react';

import { LOG_CLIENT_ERROR } from 'hooks/queries';
import { traceError } from 'utils';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    this.client = props.client;
    this.uid = props.uid;
  }

  componentDidUpdate() {
    const { client, uid } = this.props;
    this.client = client;
    this.uid = uid;
  }

  componentDidCatch(error, errorInfo) {
    traceError(error);
    traceError(errorInfo);
    this.setState({
      error,
      errorInfo
    });
    try {
      const message = {
        error: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
        errorInfo,
      };
      this.client.mutate({ mutation: LOG_CLIENT_ERROR, variables: { message: JSON.stringify(message), uid: this.uid, pathname: window.location.pathname } });
    } catch (err) {
      traceError('Error sending error');
      console.error(err);
    }
  }

  render() {
    const { error, errorInfo } = this.state;
    if (error || errorInfo) {
      return (
        <div>
          <h2>Something went wrong. Please contact support@guustav.com to let us know so we can fix the problem.</h2>
        </div>
      );
    }
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
