import React, { useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BasketImage from 'assets/basket.svg';
import PlateImage from 'assets/plate.svg';
import { ReactComponent as GandalfTheWhite } from 'assets/wizard_icon_white.svg';
import ProductSearch from 'components/productSearch';
import useAuth from 'hooks/useAuth';
import useNav from 'hooks/useNav';
import useShoppingList from 'hooks/useShoppingList';
import dbWatcher from 'hooks/utils/dbWatcher';
import { hasEvent } from 'hooks/utils/events';
import Wizard from 'wizards/plan';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  headerTitle: {
    padding: 0,
    width: '100%',
    textAlign: 'center',
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#999',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.9rem',
    },
  },
  cardMediaLower: {
    height: '28vh',
    borderBottom: '1px solid #aaa',
    [theme.breakpoints.down('sm')]: {
      height: '24vh',
    },
  },
  cardMedia: {
    height: '28vh',
    borderTop: '1px solid #aaa',
    [theme.breakpoints.down('sm')]: {
      height: '24vh',
    },
  },
  actionTitle: {
    padding: 5,
    fontSize: '.8rem',
    textAlign: 'center',
    color: '#999',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.7rem',
    },
  },
  fab: {
    zIndex: 1,
    position: 'absolute',
    top: 150,
    right: 50,
    [theme.breakpoints.down('sm')]: {
      top: 100,
      right: 16,
    },
    [theme.breakpoints.down('xs')]: {
      top: 100,
      right: 16,
    },

  },
}));

const Home = ({ ...props }) => {
  const classes = useStyles();
  const { setNav } = useNav();
  const { ruid, uid } = useAuth();
  const [plan, setPlan] = useState({});
  const [showWizard, setShowWizard] = useState(false);
  const { shoppingList } = useShoppingList();

  dbWatcher({ path: 'plan', set: setPlan, source: 'pages/Home' });

  useEffect(() => {
    setNav((nav) => ({
      mode: undefined,
      back: null,
      next: null,
      pageName: 'Home',
      stickyChildren: <ProductSearch />,
      actions: (
        <>
          {!showWizard && (
          <Fab
            variant="extended"
            aria-label="add"
            color="secondary"
            className={classes.fab}
            size="medium"
            onClick={() => setShowWizard(true)}
          >
            <GandalfTheWhite className={classes.extendedIcon} />
            Plan New Week
          </Fab>
          )}
        </>
      ),
    }));
  }, [setNav, showWizard, setShowWizard, classes]);

  useEffect(() => {
    const fn = async () => {
      // Show the wizard if the user has never opened it
      if (ruid === uid && !(await hasEvent({ category: 'activity', action: 'startPlan', uid }))) {
        setShowWizard(true);
      }
    };
    fn();
  }, [uid, ruid]);

  const go = (path, mode) => {
    setNav((nav) => ({ ...nav, mode }));
    props.history.push(path);
  };

  // const theme = useTheme();
  // const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const meals = plan && plan.value ? (plan.value.dinners || []).length + (plan.value.lunches || []).length + (plan.value.breakfasts || []).length : 0;
  let ScDirection = 'row';
  let XsGrid = 4;
  if (useMediaQuery('(orientation: portrait)')) {
    ScDirection = 'column';
    XsGrid = 10;
  }

  return (
    <>
      <Wizard isOpen={showWizard} close={(path) => { setShowWizard(false); if (path) { props.history.push(path); } }} source="home" />
      <Grid container direction={ScDirection} item spacing={2} xs={12} md={8} alignItems="center" justifyContent="center" style={{ marginTop: 50, border: '0px solid red' }}>
        <Grid item xs={XsGrid}>
          <Card raised style={{ width: '100%' }}>
            <CardHeader classes={{ root: classes.header, title: classes.headerTitle }} title="Menu" />
            <CardActionArea onClick={() => go('/menu', 'shopping')}>
              <CardContent className={`${classes.actionTitleMenu} ${classes.actionTitle}`}>
                Recipes in your menu: {meals}
              </CardContent>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                image={PlateImage}
                title="Menu"

              />
            </CardActionArea>
          </Card>

        </Grid>
        <Grid item xs={XsGrid}>
          <Card raised style={{ width: '100%' }}>
            <CardHeader classes={{ root: classes.header, title: classes.headerTitle }} title="Shopping List" />
            <CardActionArea onClick={() => go('/shopping', 'shopping')}>
              <CardContent className={`${classes.actionTitleShopping} ${classes.actionTitle}`}>
                Items in your list: {Object.keys(shoppingList || {}).filter((slug) => !!shoppingList[slug].needed).length}
              </CardContent>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                image={BasketImage}
                title="Shopping List"
              />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
