import React, { useContext, useMemo, useState } from 'react';

const SnackContext = React.createContext({});

const SnackContextProvider = ({ children }) => {
  const [snack, setSnack] = useState({});
  const value = useMemo(() => ({ snack, setSnack }), [snack, setSnack]);
  return <SnackContext.Provider value={value}>{children}</SnackContext.Provider>;
};

const useSnack = () => useContext(SnackContext);

export default useSnack;
export { SnackContextProvider };
