import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Cancel from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router-dom';

import RecipeDrawer from 'components/recipes/RecipeDrawer';
import { recipePreferences } from 'data/defaultPreferences';
import useAuth from 'hooks/useAuth';
import { dbSet } from 'hooks/utils/dbUpdater';
import dbWatcher from 'hooks/utils/dbWatcher';

const Trending = ({ ...props }) => {
  const history = useHistory();
  const { admin } = useAuth();
  const [trending, setTrending] = useState('loading');
  const [search, setSearch] = useState(false);

  dbWatcher({ path: '/trending', set: setTrending, source: 'admin/trending' });

  if (!admin) {
    alert('You are not allowed to do this.');
    history.push('/');
    return null;
  }

  const remove = (slug) => {
    dbSet({ path: `/trending/${slug}`, value: null });
  };

  const add = (recipe) => {
    dbSet({ path: `/trending/${recipe.slug}`, value: recipe });
  };

  const actionProps = {
    title: 'Trending',
    course: 'dinner',
    actions: [
      { action: add, text: 'Add To Trending', icon: <AddCircleOutlineIcon />, ...origin }
    ],
  };

  if (trending === 'loading') {
    return <LinearProgress />;
  }

  const recipes = Object.values(trending.value || {});

  return (
    <Grid container direction="column" alignItems="center">
      <Button color="primary" variant="contained" onClick={() => setSearch(true)} style={{ position: 'absolute', right: 10, top: 10 }}>
        Add to Trending
      </Button>
      <h3>Trending Recipes</h3>
      {!recipes.length && (
        <div>No trending recipes</div>
      )}

      {!!recipes.length && (
        <Grid item container xs={10}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Keyword</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recipes.sort((a, b) => a.label.localeCompare(b.label)).map((r) => (
                  <TableRow key={`${r.slug}`}>
                    <TableCell>{r.label}</TableCell>
                    <TableCell>{r.mealType || ''}</TableCell>
                    <TableCell>{r.keyword}</TableCell>
                    <TableCell>{r.source}</TableCell>
                    <TableCell>
                      <IconButton size="small" onClick={() => remove(r.slug)}>
                        <Cancel />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <RecipeDrawer
        key="recipe-drawer" isOpen={!!search} close={() => setSearch(null)} actionProps={actionProps}
        exclude={['trending']} recipePreferences={recipePreferences}
      />
    </Grid>
  );
};

export default Trending;
