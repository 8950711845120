/*
 * How to use:
 *
 * 1. Create a JS file that exports your tour steps like: https://github.com/shipshapecode/react-shepherd.
 *
 * 2. Import that file and this one into your component:
 *     import steps from './tourSteps';
 *     import useTour from 'hooks/useTour';
 *
 * 3. Do this in your component:
 *
 *      useTour({ id, steps, active: <some-condition>, skipReference, onStart };
 *
 *      id: a unique id for the tour
 *      steps: normal shepherd steps
 *      active: like step.id === 'menu' or just true if it's for a full page.
 *      skipReference: firebase path that will cause it to be skipped if exists (relative to /users/<uid>)
 *      onStart: callback for when the tour starts; return false to not start it
 *
 * There are a few short-cuts in creating a tour:
 *
 *   If you don't specify any buttons, it will create back/next/exit as appropriate.
 *   If you don't specify attachTo.element, it will look for an element with data-tour="<id>".
 *   Example:
 *     const steps = [ { id: 'foo', text: 'bar' } ];
 *     ...
 *     <div data-tour="foo">...</div>
 *
 *   It will automatically associate the tour step with that element. You can still use attachTo and showOn,
 *   but you don't have to.
 *
 * Starting a tour logs an event with category 'tour' and action 'start' and key, the id of the tour.
 * So your skipReference can be 'events/tour/keys/<id>/summary/start' to only show the tour once.
 * Or you could record something in another path using onStart and use that as your path.
 */
import { useEffect, useState } from 'react';

import Shepherd from 'shepherd.js';

import useAuth from 'hooks/useAuth';
import load from 'hooks/utils/dbLoader';
import { addEvent } from 'hooks/utils/events';
import { trace } from 'utils';

const tourOptions = {
  defaultStepOptions: {
    classes: 'guustav-shepherd',
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: false,
};

const setupTour = ({ steps }) => {
  const tour = new Shepherd.Tour(tourOptions);
  steps.forEach((step, idx) => {
    if (!step.buttons) {
      step.buttons = [{ classes: 'shepherd-button-secondary', text: 'Exit', type: 'cancel' }];
      if (idx !== steps.length - 1) {
        step.buttons.push({ classes: 'shepherd-button-primary', text: 'Next', type: 'next' });
      }
      if (idx > 0) {
        step.buttons.push({ classes: 'shepherd-button-secondary', text: 'Back', type: 'back' });
      }
    }
    step.buttons.forEach((button) => {
      if (button.type === 'cancel') {
        button.action = function () { this.cancel(); };
      } else if (button.type === 'next') {
        button.action = function () { this.next(); };
      } else if (button.type === 'back') {
        button.action = function () { this.back(); };
      }
    });
    if (step.id !== 'intro') {
      if (step.attachTo) {
        if (!step.attachTo.element) {
          step.attachTo.element = `[data-tour='${step.id}']`;
          if (!step.showOn) {
            step.showOn = () => !!document.querySelector(`[data-tour='${step.id}']`);
          }
        }
      } else {
        step.attachTo = { element: `[data-tour='${step.id}']` };
        if (!step.showOn) {
          step.showOn = () => !!document.querySelector(`[data-tour='${step.id}']`);
        }
      }
    }
    tour.addStep(step);
  });
  trace('currentTour: %o', tour);
  return tour;
};

const useTour = ({ id, steps, active, skipReference, onStart }) => {
  const [tour, setTour] = useState();
  const { loading, uid } = useAuth();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (skipReference) {
      load({ uid, path: skipReference, source: 'useTour' }).then((result) => {
        if (!result) {
          const currentTour = setupTour({ steps });
          setTour(currentTour);
        }
      });
    } else {
      const currentTour = setupTour({ steps });
      setTour(currentTour);
    }
    return () => {
      setTour((currentTour) => {
        if (currentTour) {
          currentTour.cancel();
        }
        return null;
      });
    };
  }, [loading, uid, steps, setTour, skipReference]);

  useEffect(() => {
    if (tour) {
      if (active) {
        if (!onStart || onStart()) {
          tour.start();
          addEvent({ category: 'tour',uid, action: 'start', key: id });
        }
      } else {
        tour.cancel();
      }
    }
  }, [active, tour, onStart, id]);

  return tour;
};

export default useTour;
