const synonyms = {
  Salt: ['salt', 'coarse salt', 'sea salt', 'kosher salt'],
  'Black Pepper': ['pepper', 'black pepper'],
  'Olive Oil': ['olive oil', 'virgin olive oil', 'extra virgin olive oil', 'non-virgin olive oil'],
};

// ignore spaces and punctuation for matching
const matches = Object.entries(synonyms).reduce((h, [k, v]) => {
  Object.assign(h, { [k]: v.map((vv) => vv.toLowerCase().replace(/[^a-z]/g, '')) });
  return h;
}, {});

export default matches;
