import React, { useEffect, useMemo, useState } from 'react';

import { Collapse, Divider } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Person } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { Rating } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

import MergeDialog from '../MergeDialog';

import Nav from './Nav';

import logo from 'assets/logo_small.png';
import Tip from 'components/Tip';
import useAccount from 'hooks/useAccount';
import useAppStyles from 'hooks/useAppStyles';
import useAuth from 'hooks/useAuth';
import useError from 'hooks/useError';
import useNav from 'hooks/useNav';
import dbLoad from 'hooks/utils/dbLoader';
import { isNative, isQA, trace, postNativeEvent } from 'utils';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '1.4rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    }

  },
}));

const TopNav = () => {
  const location = useLocation();
  const classes = useAppStyles();
  const pageClasses = useStyles();
  const history = useHistory();
  const { loading, anonymous, uid, ruid, logout, mergeData, rname, admin } = useAuth();
  const { loading: aloading, account } = useAccount();
  const { error: globalError } = useError();
  const { nav } = useNav();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [loggingOut, setLoggingOut] = useState(false);
  const [, setNext] = useState();
  const [household, setHousehold] = useState();
  const [needsRefresh, setNeedsRefresh] = useState();
  const [collapseApp, setCollapseApp] = useState(true);

  const androidAppStoreUrl = 'https://play.google.com/store/apps/details?id=com.guustav.app';
  const IOSAppStoreUrl = 'https://apps.apple.com/us/app/guustav-plan-shop-and-eat/id1573452801';

  useEffect(() => {
    const fn = async () => {
      const expectedVersion = await dbLoad({ path: '/global/clientVersion/version', source: 'TopNav' });
      const actualVersion = process.env.REACT_APP_CLIENT_VERSION;
      trace('Expected version in db: %s, actual from React env: %s', expectedVersion, actualVersion);
      setNeedsRefresh(expectedVersion && actualVersion && expectedVersion !== actualVersion);
    };
    fn();
  }, [history.location.pathname, setNeedsRefresh]);

  useEffect(() => {
    if (ruid && uid && uid !== ruid) {
      dbLoad({ uid: ruid, path: 'account/primary/household', set: setHousehold, source: 'TopNav' });
    } else if (uid && uid !== 'visitor' && !anonymous) {
      dbLoad({ uid, path: 'household/name', set: setHousehold, source: 'TopNav' });
    }
  }, [uid, ruid, setHousehold, anonymous]);

  const openMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const handleLogout = () => {
    setLoggingOut(true);
    setMenuAnchor(null);
    logout();
    /* history.push('/'); */
  };

  const print = (nav && nav.print) || history.location.pathname.match(/\/print/);

  const displayName = useMemo(() => {
    if (uid === 'visitor' || anonymous) {
      return 'Guest';
    }
    if (ruid !== uid && rname && household) {
      return `${rname} (${household})`;
    }
    return household;
  }, [household, ruid, uid, anonymous, rname]);

  if (print || loggingOut) {
    return null;
  }

  if (loading || aloading) {
    // return <LinearProgress />
  }

  const onClick = (next) => () => setNext(next);

  /* eslint no-unused-vars : "off" */
  const store = () => {
    if (isNative()) {
      trace('Sending to store');
      setMenuAnchor(null);
      const qa = isQA() ? 'QA' : '';
      postNativeEvent({ action: 'subscribe' });
    } else {
      setMenuAnchor(null);
      history.push('/subscribe');
    }
  };

  // detect if we are running on a mobile device
  const os = navigator.userAgent;
  let mobileOS = '';
  let mobile = false;
  if (os.includes('iPad')) mobileOS = 'Apple';
  if (os.includes('iPhone')) mobileOS = 'Apple';
  if (os.includes('iPod')) mobileOS = 'Apple';
  if (os.includes('Android')) mobileOS = 'Android';
  mobile = !isNative() && (mobileOS !== '');
  let storeUrl = IOSAppStoreUrl;
  let getAppStoreText = 'Free - On the App Store';
  if (mobileOS === 'Android') {
    storeUrl = androidAppStoreUrl;
    getAppStoreText = 'Free - In Google Play';
  }

  return (
    <AppBar
      key="app-bar" elevation={0} position="sticky" className={classes.topNav} color="default"
      style={{ borderBottom: nav.hideHeaderBottom ? 'none' : undefined, border:'none' }}
    >
      {false && (
        <Grid container justifyContent="center" alignItems="center" style={{ backgroundColor: '#c00', color: 'white', minHeight: 30, padding: 8 }}>
          <Grid item>
            The version of Guustav you are using is out of date.  Please
            <Button
              size="small" variant="outlined"
              style={{ margin: '0px 6px 0px 6px', paddingTop: 2, paddingBottom: 2, borderRadius: 4, borderColor: 'white', color: 'white' }}
              onClick={() => window.location.reload()}
            >
              refresh
            </Button> to get the latest.
          </Grid>
        </Grid>
      )}
      {mobile&&(location.pathname !== '/shop/amazon') && (
        <Collapse in={collapseApp}>
          <Grid container direction="row" alignItems="center" alignContent="stretch" style={{ marginTop: 5 }}>
            <Grid item xs={1} style={{ marginRight: 10 }}><IconButton onClick={() => setCollapseApp(false)}><CloseIcon style={{ fontSize: 20 }} /></IconButton></Grid>
            <Grid item container xs={8} alignItems="center">
              <Grid item xs={1}><img alt="logo" src={logo} style={{ width: '30px' }} /></Grid>
              <Grid container item direction="column" xs={8} style={{ height: '60px', marginLeft: 10 }}>
                <Typography style={{ fontWeight: 'bold' }}>Guustav</Typography>
                <Typography style={{ fontSize: 10 }}>{getAppStoreText}</Typography>
                <Rating name="read-only" value={4.5} precision={0.5} readOnly style={{ fontSize: 10 }} />
              </Grid>
            </Grid>
            <Grid item xs={2}><Link underline="none" target="_blank" href={storeUrl}><Button variant="contained" color="secondary">View</Button></Link></Grid>
          </Grid>
          <Divider />
        </Collapse>
      )}

      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item container alignItems="center" className={classes.containerAutoWidth}>
          <Grid item>
            <img
              src={logo} className={classes.logo} alt="logo" onClick={() => {
                history.push('/');
                onClick(0);
              }}
            />
          </Grid>
          <Grid item>
            <div className={pageClasses.pageTitle}>
              {isQA() && (
                <span id="env-indicator" style={{ fontSize: '.5rem' }}> QA</span>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" direction="column" className={classes.containerAutoWidth}>
          {globalError && globalError.message && (
            <Grid item>
              <Alert severity={globalError.severity || 'error'} elevation={1} variant="filled" action={globalError.action}>{globalError.message}</Alert>
            </Grid>
          )}
        </Grid>
        <Grid container item alignItems="center" className={classes.containerAutoWidth} spacing={0}>{/* changed spacing from 2 to 0 because it was causing horz. scroll */}
          <Grid item>{nav.actions}</Grid>
          {uid !== 'visitor' && !anonymous && (
            <Menu open={!!menuAnchor} onClose={closeMenu} anchorEl={menuAnchor} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} getContentAnchorEl={null}>
              {admin && (
                <MenuItem onClick={() => { history.push('/admin'); setMenuAnchor(null); }}>Admin</MenuItem>
              )}
              {/* commenting out until we implement subscriptions and premium model
              {(uid !== 'visitor' && !anonymous && uid === ruid) && (
                <MenuItem onClick={() => { history.push('/account/join'); setMenuAnchor(null); }}>Join Household</MenuItem>
              )}
              {(uid !== 'visitor' && !anonymous && uid !== ruid) && (
                <MenuItem onClick={() => { history.push('/account/leave'); setMenuAnchor(null); }}>Leave Household</MenuItem>
              )}
              {(uid !== 'visitor' && !anonymous) && (
                <MenuItem onClick={store}>Subscription</MenuItem>
              )} */}
              {(uid !== 'visitor' && !anonymous) && (
                <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
              )}
              <MenuItem onClick={(e) => {
                window.open('https://www.guustav.com/contact-us');
                e.preventDefault();
              }}
              >Get Help
              </MenuItem>
            </Menu>
          )}
          {(!anonymous)&&(
            <>
          <Hidden smDown>
            <Grid item style={{ marginRight: 20, fontWeight: 'bold' }}>
              <Button variant="text" onClick={openMenu} startIcon={<Person />}>
                {displayName}
              </Button>
              {(uid === 'visitor' || anonymous) && (
                <Tip
                  iconStyles={{ marginLeft: 0 }}
                  message="Please sign up or login to continue using Guustav"
                />
              )}
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <IconButton edge={false} variant="text" onClick={openMenu} style={{ paddingLeft: 0 }}>
              <Person />
            </IconButton>
          </Hidden> 
          </>
          )}
        </Grid>
      </Grid>
      <>
        <Nav bottom={false} />
        {nav.stickyChildren}
      </>
      {mergeData && (
        <MergeDialog history={history} />
      )}
    </AppBar>
  );
};

export default TopNav;
