import { dbUpdate } from 'hooks/utils/dbUpdater';
import { addEvent, addRecipeEvent } from 'hooks/utils/events';
import { timeStamp, trace, traceError } from 'utils';

const save = async ({ close, path, recipeEvents, setState, state, uid, source }) => {
  try {
    //const items = { ...state.items, ...state.picks };
    const items = { ...state.items };
    const plan = state.lastPlan || {};
    trace('saving wizard: %o, plan: %o, source: %s', state, plan, source);
    const heldover = Object.entries(state.shoppingList || {})
      .filter(([slug, item]) => !item.onMenu || !!items[slug])
      .reduce((map, [slug, item]) => (
        Object.assign(map, { [slug]: item })
      ), {});
    const shoppingList = { ...items, ...heldover };
    const ts = timeStamp();
    ['dinners', 'lunches', 'breakfasts'].forEach((courseId) => {
      if (state.plan&&state.plan[courseId]) {
        state.plan[courseId].forEach((meal) => {
          addRecipeEvent({ action: 'planned', recipe: meal.recipe, uid, courseId, source: 'save' });
        });
      }
    });
    Object.values(recipeEvents).forEach((e) => {
      addRecipeEvent({ action: e.action, recipe: e.recipe, uid, source: e.source });
    });
    addEvent({ action: 'plan', category: 'activity', uid });
    const updates = {
      [`planHistory/${ts}`]: {
        ...plan
      },
      recipePreferences: state.recipePreferences,
      quantityPreferences: state.quantityPreferences,
      plan: {
        recipePreferences: state.recipePreferences,
        quantityPreferences: state.quantityPreferences,
        ...state.plan,
      },
      shoppingList,
    };
    trace('updates: %o', updates);
    await dbUpdate({ uid, path: '', updates });
    close(path);
  } catch (err) {
    // TODO: tell the user
    traceError(err);
  }
};

export default save;
