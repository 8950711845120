import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Brightness5 from '@material-ui/icons/Brightness5';
import Brightness6 from '@material-ui/icons/Brightness6';
import Brightness7 from '@material-ui/icons/Brightness7';
import Cancel from '@material-ui/icons/Cancel';

import { quantityPreferences as defaultValue } from 'data/defaultPreferences';
import useAuth from 'hooks/useAuth';
import useRepeats from 'hooks/useRepeats';
import useSnack from 'hooks/useSnack';
import load from 'hooks/utils/dbLoader';
import { dbSet } from 'hooks/utils/dbUpdater';
import { addEvent } from 'hooks/utils/events';
import Breakfasts from 'wizards/plan/quantityPreferences/Breakfasts';
import Dinners from 'wizards/plan/quantityPreferences/Dinners';
import Lunches from 'wizards/plan/quantityPreferences/Lunches';
import NewDinners from 'wizards/plan/quantityPreferences/NewDinners';
import NewLunches from 'wizards/plan/quantityPreferences/NewLunches';

const useStyles = makeStyles((theme) => ({
  icon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  container: {
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.up('md')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
    marginBottom: 50,
  },
  buttons: {
    marginTop: 5,
    flexGrow: 1,
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: 10,
      top: 10,
      width: 'auto',
    },
  },
  saveButton: {
    marginRight: 10
  }
}));

const QuantityPreferences = ({ close }) => {
  const classes = useStyles();
  const [pageState, setPageState] = useState('loading');
  const { repeats } = useRepeats();
  const { setSnack } = useSnack();
  const { uid } = useAuth();

  useEffect(() => {
    if (uid) {
      load({ path: 'household/quantityPreferences', uid, defaultValue }).then((result) => {
        setPageState({ quantityPreferences: result });
      });
    }
  }, [uid, setPageState]);

  if (pageState === 'loading') {
    return null;
  }
  const showNew = Object.keys(repeats || {}).length > 0;

  const updateStateAndSave = (updatedState) => {
    setPageState(updatedState);
    dbSet({ uid, path: 'household/quantityPreferences', value: updatedState.quantityPreferences }).then(() => {
      addEvent({ action: 'saveQuantityPreferences', category: 'activity', uid, data: updatedState.quantityPreferences });
      setSnack({ message: 'Quantity preferences have been updated' });
    });
  };

  return (
    <Grid container item direction="column" justifyContent="flex-start" className={classes.container}>
      <Grid container item justifyContent="space-between">
        {close && (
          <Grid item>
            <IconButton onClick={close}><Cancel /></IconButton>
          </Grid>
        )}
      </Grid>
      <Hidden mdUp>
        <Divider />
      </Hidden>
      <h3>Tell us about how many meals you want us to plan for you for each menu.</h3>
      <Grid item container alignItems="flex-start">
        <Grid item xs={1}>
          <Brightness7 className={classes.icon} />
        </Grid>
        <Grid item>
          <h3 style={{ margin: 0, padding: 0 }}>Dinners</h3>
        </Grid>
      </Grid>
      <Dinners classes={classes} state={pageState} setState={updateStateAndSave} />
      {showNew && <NewDinners classes={classes} state={pageState} setState={updateStateAndSave} />}

      <Grid item container alignItems="flex-start">
        <Grid item xs={1}>
          <Brightness6 className={classes.icon} />
        </Grid>
        <Grid item>
          <h3 style={{ margin: 0, padding: 0 }}>Lunches</h3>
        </Grid>
      </Grid>
      <Lunches classes={classes} state={pageState} setState={updateStateAndSave} />
      {showNew && <NewLunches classes={classes} state={pageState} setState={updateStateAndSave} />}

      {repeats && Object.values(repeats).some((f) => f.course === 'breakfast') && (
        <>
          <Grid item container alignItems="flex-start">
            <Grid item xs={1}>
              <Brightness5 className={classes.icon} />
            </Grid>
            <Grid item>
              <h3 style={{ margin: 0, padding: 0 }}>Breakfasts</h3>
            </Grid>
          </Grid>

          <Breakfasts classes={classes} state={pageState} setState={updateStateAndSave} />
        </>
      )}

    </Grid>
  );
};

export default QuantityPreferences;
