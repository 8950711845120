const steps = [
  {
    id: 'intro',
    beforeShowPromise() {
      return new Promise((resolve) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    highlightClass: 'highlight',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Menu',
    text: 'Here is the first menu we picked for you.<BR> <BR>You can adjust how the menu is generated including adding food allergies by pressing the settings button. <BR><BR>If you want to manually select recipes, hit browse on any of the suggested meals to replace with your own pick.',
    /* buttons: [
      {
          classes: 'shepherd-button-primary',
          text: 'Next',
          type: 'next'
      }
    ],
    },
    {
        id: 'recipes',
        attachTo: { element: '.middle' },
        //showOn: () => !!document.querySelector('.wizard-plan-step-icon'),
        title: 'Recipe choices',
        text: 'We use your taste to generate a menu with easy to cook meals. <BR> You can add your own or search for recipes as well. <BR> Our recipes come from the top recipe websites so the options are limitless.',
        buttons: [

            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next'
            }
        ],
    },
  {
    id: 'pick-for-me',
    attachTo: { on: 'top' },
    title: 'Pick for Me',
    text: "Don't like the suggestion? Click here and we'll suggest a different recipe.",
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
    },
      buttons: [

          {
              classes: 'shepherd-button-primary',
              text: 'Next',
              type: 'next'
          }
      ],

    },
  {
    id: 'let-me-pick',
    attachTo: { on: 'top' },
    title: 'Pick for Me',
    text: 'Click here to search for a recipe to replace this one.',
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
    },
      buttons: [

          {
              classes: 'shepherd-button-primary',
              text: 'Next',
              type: 'next'
          }
      ],
  },
    {
        id: 'add-meal',
        attachTo: { on: 'bottom' },
        title: 'Add Meal',
        text: 'Click here to add a new meal to your menu, you can:<BR>- Get Guustav to suggest something<BR>- Search a recipe on the internet<BR>- Add one of your own',
        popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
        },
        */
    buttons: [

      {
        classes: 'shepherd-button-primary',
        text: 'I got it',
        type: 'cancel'
      }
    ],
  },

];

export default steps;
