// InventoryManagementIntro.js
import React, { useEffect } from 'react';
import { Typography, Grid, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import Image from 'assets/EasyshoppingDrawing.png';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  img: {
    height: '40vh',
    [theme.breakpoints.down('xs')]: {
      height: '20vh',
    },
  },
}));

const InventoryManagementIntro = ({ step, finishStep, enableNext }) => {
  const classes = useStyles();

  useEffect(() => {
    enableNext(true);
  }, [enableNext]);

  useEffect(() => {
    if (step.id === 'inventoryManagementIntro' && step.exiting) {
      finishStep();
    }
  }, [step, finishStep]);

  return (
    <Grid container direction="column" justifyContent='center' alignItems='center' style={{ width: '90%', marginLeft: 20 }}>
      <div className={classes.imgContainer}>
      <img src={Image} alt="Inventory Management" className={classes.img}  />
      </div>

      <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', color: '#483D8B' }}>Effortless Shopping with Automatic Grocery Lists</Typography>
      <Typography variant="body1" style={{ marginBottom: '16px' }}>
        Discover how Guustav can streamline your grocery shopping experience by predicting what you need based on your previous purchases.
      </Typography>
      <List>
        {['Kickstart your Guustav journey by manually adding items to your grocery list or selecting them from our curated templates.',
          'For every item, Guustav assigns a default buying frequency based on average consumption patterns.',
          'Personalize your experience by setting your own buying intervals for items you purchase regularly.',
          'When it\'s time for your next shopping trip, Guustav will automatically prepare your list based on your preferences and consumption habits.'].map((text, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: '#4CAF50', color: '#fff' }}>{index + 1}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="body1">{text}</Typography>}
              />
            </ListItem>
          ))}
      </List>
    </Grid>
  );
};
export default InventoryManagementIntro;

