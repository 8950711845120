import React, { useEffect } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Add';
import ScissorsIcon from '@material-ui/icons/AttachFile';

import cheese from 'assets/000081324.jpg';
import orangecara from 'assets/000375170.jpg';
import ribeye from 'assets/000443870-1.jpeg';
import chocolatemilk from 'assets/001838035.jpg';
import goldfish from 'assets/002000373.jpg';
import toiletpaper from 'assets/002067989.jpg';
import papertowels from 'assets/002210786.jpg';

const PromoCoupons = ({ step, enableNext, finishStep }) => {
  useEffect(() => {
    if (step.id === 'promocoupons' && step.entering) {
      enableNext(true);
    }
  }, [enableNext, step]);

  useEffect(() => {
    if (step.id === 'promocoupons' && step.exiting) {
      finishStep();
    }
  }, [step, finishStep]);

  const trendingProducts = [
    // {name:'Ribeye',image:ribeye},
    { name: 'Goldfish Crakers', image: goldfish },
    { name: 'Cheese Sticks', image: cheese },
    { name: 'Cara Oranges', image: orangecara },
    { name: 'Chocolate Milk', image: chocolatemilk },
    { name: 'Toilet Paper', image: toiletpaper },
    // {name:'Paper Towels',image:papertowels}
  ];

  const trendingProductsLine = [];
  trendingProducts.forEach((el, idx) => {
    trendingProductsLine.push(
      <Grid item xs={2} key={idx}>
        <Card id={idx} raised elevation={2} style={{ border: '0px solid red' }}>
          <CardHeader
            title={el.name}
            // titleTypographyProps={{ variant: 'h7' }}
          />
          <CardMedia
            component="img"
            height="75"
            width="75"
            image={el.image}
            alt={el.name}
            sx={{ objectFit: 'contain' }}
          />
          <CardActions>
            <IconButton aria-label="add to shopping list">
              <FavoriteIcon />
              Add
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    );
  });

  const couponProducts = [
    { name: 'Ribeye: $5 off per pound', image: ribeye },
    { name: 'Paper Towels: buy one get one free', image: papertowels }
  ];
  const couponProductsLine = [];
  couponProducts.forEach((el, idx) => {
    couponProductsLine.push(
      <Grid item xs={2} key={idx}>
        <Card id={idx} raised elevation={2} style={{ border: '0px solid red' }}>
          <CardHeader
            title={el.name}
            // titleTypographyProps={{ variant: 'h7' }}
          />
          <CardMedia
            component="img"
            height="75"
            width="75"
            image={el.image}
            alt={el.name}
            sx={{ objectFit: 'contain' }}
          />
          <CardActions>
            <IconButton aria-label="Clip coupon">
              <ScissorsIcon />
              Clip
            </IconButton>
          </CardActions>
        </Card>
      </Grid>
    );
  });
  return (
    <>
      <div style={{ paddingLeft: '10%' }}><h2>Trending this week</h2></div>
      <Grid container item direction="row" justifyContent="flex-start" alignItems="flex-start" xs={12} sx={{ height: 200 }}>
        {trendingProductsLine}
      </Grid>

      <div style={{ paddingLeft: '10%' }}><h2>Some products you selected qualify for coupons, please clip them</h2></div>
      <Grid container item direction="row" justifyContent="flex-start" alignItems="flex-start" xs={12} sx={{ height: 200 }}>
        {couponProductsLine}
      </Grid>
    </>
  );
};

export default PromoCoupons;
