import React, { useEffect, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { Navigation } from '@material-ui/icons';

import Debug from './Debug';
import Nav from './Nav';

import useNav from 'hooks/useNav';

// const locations = ['/', '/menu', '/shopping', '/recipes', '/settings'];

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    background: theme.palette.background.default,
  },
  nav: {
    height: 'inherit',
    background: theme.palette.background.default,
  },
  action: {
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'inherit',
    },
    [theme.breakpoints.up('sm')]: {
      '& svg': {
        width: '2.5rem',
        height: '2.5rem',
      },
    },
  },
  selected: {
    '& svg': { fill: 'orange' }
  },
  selectedLabel: {
    fontWeight: 'bold',
  },
}));

/*
const currentPage = (path) => {
  if (path === '/') {
    return 0;
  }
  const p = path.split('/')[1];
  if (p === 'menu') {
    return 1;
  }
  if (p === 'shopping') {
    return 2;
  }
  if (p === 'recipes') {
    return 3;
  }
  if (p === 'settings') {
    return 4;
  }
  return null;
};
*/

const BottomNav = () => {
  const classes = useStyles();
  const { nav } = useNav();
  const [showBackToTop, setShowBackToTop] = useState(false);

  const topListener = () => {
    setShowBackToTop(window.scrollY > 200);
  };

  useEffect(() => {
    window.addEventListener('scroll', topListener);
    return () => {
      window.removeEventListener('scroll', topListener);
    };
  }, []);

  /*
  useEffect(() => {
    if (current !== null) {
      history.push(locations[current]);
    }
  }, [current, history]);
  */

  if (nav.print) {
    return null;
  }

  return (
    <>
      {!!nav.up && showBackToTop && (
        <Fab
          size="small" aria-label="back to top" variant="extended" style={{ position: 'fixed', right: 10, bottom: 80 }}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <Navigation />
          Back to Top
        </Fab>
      )}
      <AppBar position="fixed" className={classes.appBar}>
        <Nav bottom />
        {window.location.port === '3000' && (
          <Debug source="app" />
        )}
      </AppBar>
    </>
  );
};

export default BottomNav;
