import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  root: {
    background: theme.palette.background.default,
    minHeight: '100vh',
    /*
        border: '1px solid #999',
        borderBottom: 'none',
        */
    /* flexGrow: 1, */
    margin: '0 auto',
    /* height: '100%', */
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: 1280,
    },
    '> img': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    '& .MuiInputBase-input': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '.75rem',
      },
    },
  },
  contents: {
    flexGrow: 1,
    /*
        marginBottom: 20,
        paddingBottom: 20,
        */
    /*
        paddingLeft: 24,
        paddingRight: 24,
        */
  },
  listPageContainer: {
    marginTop: 20,
    marginBottom: 80, /* so the bottom isn't hidden by the nav bar */
  },
  centerOnXs: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  centerOnSm: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  containerAutoWidth: {
    width: 'auto',
  },
  containerAutoWidthGrow: {
    width: 'auto',
    flexGrow: 1,
  },
  paperElevation1: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  paperElevation3: {
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
  },
  stickySearch: {
    '& .Mui-focused': {
      '& fieldset': {
        borderLeft: '1px solid',
        borderRight: '1px solid',
      },
    },
    '& fieldset': {
      borderLeft: 'none',
      borderRight: 'none',
      '&:focus': {
        border: '1px solid #c80000',
      },
    },
  },
  blue: {
    color: 'blue',
  },
  smallLabel: {
    color: '#c80000',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignCenter: {
    textAlign: 'center',
  },
  alignLeft: {
    textAlign: 'left',
  },
  grow: {
    flexGrow: 1,
  },
  centerSelf: {
    alignSelf: 'center',
  },
  flexStartSelf: {
    alignSelf: 'flex-start',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  danger: {
    color: theme.palette.error.main,
    /* color: '#ffffff', */
  },
  dangerButton: {
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
  },
  successBackground: {
    backgroundColor: '#81c784',
  },
  darkButton: {
    background: theme.palette.primary.dark,
    color: '#ffffff',
    borderRadius: 0,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  leftStartIcon: {
    position: 'absolute',
    left: 0,
    marginLeft: '1rem',
  },
  topNav: {
    background: theme.palette.background.default,
    borderBottom: '1px solid #ccc',
  },
  blend: {
    background: theme.palette.background.default,
  },
  bottomNav: {
    top: 'auto',
    bottom: 0,
    borderTop: '1px solid #ccc',
    background: theme.palette.background.default,
  },
  bottomBarItem: {
    alignSelf: 'stretch',
    minWidth: 80,
    textAlign: 'center',
  },
  navButton: {
    background: theme.palette.secondary.main,
    color: '#ffffff',
    borderRadius: 0,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  title: {
    flexGrow: 1,
    margin: '8px 0 0',
  },
  logo: {
    height: 'auto',
    maxHeight: 40,
    maxWidth: '8vh',
    margin: 12,
    [theme.breakpoints.down('sm')]: {
      margin: 6,
    },
    cursor: 'pointer',
    position: 'relative',
  },
  logoName: {
    maxWidth: '20vh',
    padding: 4,
  },
  logoBottom: {
    height: 'auto',
    maxHeight: 20,
    cursor: 'pointer',
  },
  h1: { ...theme.typography.h1 },
  h2: { ...theme.typography.h2 },
  h3: { ...theme.typography.h3 },
  h4: { ...theme.typography.h4 },
  h5: { ...theme.typography.h5 },
  h6: { ...theme.typography.h6 },
  subtitle1: { ...theme.typography.subtitle1 },
  subtitle2: { ...theme.typography.subtitle2 },
  body1: { ...theme.typography.body1 },
  body2: { ...theme.typography.body2 },
  button: { ...theme.typography.button },
  caption: {
    ...theme.typography.caption,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0vw',
    },
  },
  overline: { ...theme.typography.overline },
  pageName: {
    ...theme.typography.h4,
    fontSize: '2.3vw',
    [theme.breakpoints.down('sm')]: {
      fontSize: '4.5vw',
    },
  },
  cardTitle: {
    ...theme.typography.subtitle1,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75vw',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      fontSize: '1.5vw',
    },
  },
  cardTitle2: {
    ...theme.typography.subtitle2,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.25vw',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      fontSize: '2.25vw',
    },
  },
  cardContent: {
    background: theme.palette.background.default,
    height: 100,
    [theme.breakpoints.down('xs')]: {
      height: 100,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      height: 120,
      paddingTop: 12,
    },
  },
  cardBody: {
    ...theme.typography.body1,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.75vw',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      fontSize: '1.5vw',
    },
  },
  cardBody2: {
    ...theme.typography.body2,
    [theme.breakpoints.down('sm')]: { fontSize: '2.0vw' },
    [theme.breakpoints.down('xs')]: { fontSize: '1.8vw' },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: { fontSize: '1.8vw' },
    [`${theme.breakpoints.down('xs')} and (orientation: landscape)`]: { fontSize: '1.8vw' },
  },
  cardMedia: {
    minHeight: '30vh',
    maxHeight: '50vh',
    [theme.breakpoints.down('xs')]: {
      minHeight: '20vh',
      maxHeight: '40vh',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      minHeight: '40vh',
      maxHeight: '40vh',
    },
  },
  halfDrawerPaper: {
    width: '50vw',
    [theme.breakpoints.down('md')]: {
      width: '80vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
  },
  repeatButton: {
    padding: 6,
    marginTop: -4,
    marginLeft: -4,
  },
  repeat: {
    width: '1.5em',
    fontSize: '2.0rem',
  },
  unrepeated: {
    color: '#bbb',
    fontWeight: 'normal',
  },
  repeated: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
  negative: {
    color: theme.palette.error.main,
  },
  positive: {
    color: theme.palette.success.main,
  },
  pulseRow: {
    animation: '$pulse 2.5s 3',
  },
  '@keyframes pulse': {
    '0%': {
      backgroundColor: theme.palette.background.default,
    },
    '50%': {
      backgroundColor: theme.palette.warning.light,
    },
    '100%': {
      backgroundColor: theme.palette.background.default,
    },
  },
  fadeRow: {
    animation: '$fadeOut 2.5s 3',
  },
  '@keyframes fadeOut': {
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
}));

export default useStyles;
