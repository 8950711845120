import firebase from 'firebase/app';

import 'firebase/database';
import 'firebase/analytics';
import { dateStamp, timeStamp, trace, traceError } from 'utils';

/*
 * event structure:
 *   category
 *    actions
 *      action1
 *        date1: event
 *        date2: event
 *      action2
 *        date1: event
 *        date2: event
 *    keys
 *      key
 *        log
 *          list of events
 *        summary
 *          action1: { count: n, last: date }
 *          action2: { count: n, last: date }
 *
 * category can be: activity, shopping, recipe
 * actions are specific to each category, listed below for recipe.
 * key is uid for activity or shopping, recipe slug for recipe.
 */

/*
 * recipe actions:
 *   swapped (user got another suggestion)
 *   replaced (same as swapped, except user picked)
 *   removed
 *   viewed
 *   favorited - use repeated
 *   unfavorited - use unrepeated
 *   planned
 *   liked - from feedback
 *   disliked -  from feedback
 *   skipped
 *   repeated
 *   unrepeated
 *   saved
 *   unsaved
 */
const addRecipeEvent = ({ action, recipe, uid, ...other }) => {
  const data = { id: recipe.id, slug: recipe.slug, uri: recipe.uri, ...other };
  addEvent({ category: 'recipe', action, key: recipe.slug, data, uid });
};

/*
 * categories:
 *   recipe
 *   activity
 *     login
 *     plan
 *     shop
 *     add
 */
const addEvent = ({ category, action, key, data, uid, email, ...other }) => {
  const ds = dateStamp();
  const ts = timeStamp();
  const event = { category, time: ts, action, key: key || uid, data: typeof data === 'string' ? data : { ...(data || {}), ...other } };
  trace('addEvent: event: %o', { action, key, data });
  const path = `/users/${uid}/events`;
  const eventKey = firebase.database().ref(path).child('log').push().key;
  firebase.analytics().logEvent(`${category}_${action}`, event);
  return firebase.database().ref(`${path}/${category}/summary/${key || uid}/${action}`).once('value', (snapshot) => {
    const count = Number(snapshot.val() || '0') + 1;
    const updates = {
      [`log/${eventKey}`]: event,
      [`${category}/keys/${key || uid}/summary/${action}`]: { count, last: ts },
      [`${category}/keys/${key || uid}/log/${eventKey}`]: event,
      [`${category}/actions/${action}/${ds}/${eventKey}`]: event,
    };
    return firebase.database().ref(path).update(updates, (error) => {
      if (error) {
        traceError('addEvent: error updating: %o -> %o', error, updates);
      }
      return error;
    });
  });
};

const addGlobalEvent = async ({ category, action, data, email, uid }) => {
  const ts = timeStamp();
  const event = { category, time: ts, action, data, email, uid };
  trace('addGlobalEvent: event: %o', event);
  firebase.analytics().logEvent(`global_${category}_${action}`, event);
  try {
    await firebase.database().ref('/events/global/').push(event);
    if (email) {
      await firebase.database().ref(`/events/${email.replace(/[.#$[\]]/g, '_')}`).push(event);
    }
  } catch (ex) {
    trace('addGlobalEvent failed: %o', ex.message);
  }
};

// Example: get all recipe events
const eventsByCategorySince = async ({ date, category, uid }) => {
  const path = `/users/${uid}/events/log`;
  const snapshot = await firebase.database().ref(path).orderByChild('time').startAt(timeStamp(date))
    .once('value');
  const events = snapshot.val() || [];
  return Object.values(events).filter((e) => e.category === category);
};

// Example: get all swapped recipes
const eventsByAction = async ({ action, category, uid }) => {
  const path = `/users/${uid}/log`;
  const snapshot = await firebase.database().ref(path).orderByChild('action').startAt(action)
    .endAt(action)
    .once('value');
  const events = Object.values(snapshot.val() || {});
  return events.filter((e) => e.category === category).reduce((h, e) => Object.assign(h, { [e.key]: e.data }), {});
};

// Example: get all swap events since a date
const eventsByActionSince = async ({ action, category, date, uid }) => {
  const path = `/users/${uid}/events/${category}/actions/${action}`;
  const snapshot = await firebase.database().ref(path).orderByKey().startAt(dateStamp(date))
    .endAt(dateStamp())
    .once('value');
  return Object.values(snapshot.val() || {});
};

// Example: get all events for one recipe, date is optional
const eventsForItem = async ({ category, key, uid, date }) => {
  const path = `/users/${uid}/events/${category}/keys/${key}/log`;
  let snapshot;
  if (date) {
    snapshot = await firebase.database().ref(path).orderByChild('time').startAt(timeStamp(date))
      .once('value');
  } else {
    snapshot = await firebase.database().ref(path).once('value');
  }
  return snapshot.val() || [];
};

const hasEvent = async ({ category, action, uid }) => {
  const path = `/users/${uid}/events/${category}/actions/${action}`;
  const snapshot = await firebase.database().ref(path).once('value');
  return !!snapshot.val();
};

export { addEvent, addGlobalEvent, addRecipeEvent, eventsByAction, eventsByCategorySince, eventsByActionSince, eventsForItem, hasEvent };
