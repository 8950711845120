import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';

import NewProduct from 'components/productSearch/NewProduct';
import useAppStyles from 'hooks/useAppStyles';
import useProductSearch from 'hooks/useProductSearch';

const IngredientSearch = ({ ingredient, setIngredient, setInput, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState(ingredient ? (ingredient.food || '') : '');
  const [selected, setSelected] = useState(null);
  const [adding, setAdding] = useState(false);
  const [options, setOptions] = useState([]);
  const { state, dispatch } = useProductSearch({});
  const classes = useAppStyles();

  useEffect(() => {
    if (setInput) {
      setInput(searchTerm);
    }
  }, [setInput, searchTerm]);

  useEffect(() => {
    setSearchTerm(ingredient ? (ingredient.food || '') : '');
  }, [ingredient]);

  useEffect(() => {
    if (searchTerm === '') {
      setOptions(selected ? [selected] : []);
      return undefined;
    }
    dispatch({ action: 'search', searchTerm });
  }, [dispatch, selected, searchTerm]);

  useEffect(() => {
    if (state.results && state.results.length) {
      setOptions(state.results);
      if (selected && !state.results.some((r) => r.slug === selected.slug)) {
        setSelected('');
      }
    } else {
      setOptions(selected ? [selected] : []);
    }
  }, [state.results, selected]);

  const addToList = (ingredient) => {
    if (!ingredient) {
      return;
    }
    setIngredient({
      slug: ingredient.slug,
      food: ingredient.name,
      department: ingredient.department || 'other',
      measure: ingredient.defaultMeasureUnit || 'each',
      quantity: ingredient.defaultQuantity || 1,
    });
    setAdding(false);
  };

  return (
    <>
      {adding && (
        <NewProduct name={searchTerm} ingredient addToList={addToList} handleClose={() => setAdding(false)} />
      )}
      <Autocomplete
        style={{ width: '100%' }}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        freeSolo
        value={searchTerm}
        onChange={(event, product) => {
          if (product && product.newItem) {
          } else {
            setOptions(product ? [product, ...options] : options);
            setSelected(product);
            if (product) {
              setIngredient({ food: product.name, slug: product.slug, department: product.department, measure: product.defaultMeasureUnit, quantity: product.defaultQuantity });
            }
          }
        }}
        onInputChange={(event, newInputValue) => {
          if (newInputValue !== 'Add New Item') {
            setSearchTerm(newInputValue);
          }
        }}
        classes={{ paper: classes.blend }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            variant="outlined"
            size="small"
            placeholder={placeholder || 'Search or enter your own...'}
            fullWidth
          />
        )}
        renderOption={(product) => {
          if (product.newItem) {
            return (
              <Button
                size="small" variant="contained" endIcon={<AddIcon />} color="primary"
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); setAdding(true); }}
              >
                Add New Item
              </Button>
            );
          }

          return (
            <Grid container alignItems="center">
              <Grid item>{product.name}</Grid>
            </Grid>
          );
        }}
      />
    </>
  );
};

export default IngredientSearch;
