import dbLoad from 'hooks/utils/dbLoader';
import { addEvent } from 'hooks/utils/events';
import { trace } from 'utils';

const checkMembership = async ({ uid, source }) => {
  const account = await dbLoad({ uid, path: 'account', source: 'checkMembership' });
  trace('checkMembership: uid: %o, account: %o', uid, account);
  if (!account || !account.primary) {
    return null;
  }
  try {
    // Check to see if they are still allowed by the account owner.
    // Probably this will error out if they aren't allowed but just in case
    const allowed = await dbLoad({ uid: account.primary.uid, path: `household/members/${account.primary.accessKey}/allowed`, source: `${source}/checkMembership` });
    trace('checkMembership: allowed: %o', allowed);
    if (allowed !== 'on') {
      return null;
    }
    return account.primary;
  } catch (ex) {
    trace('setupAccount: got error checking membership status: %o', ex);
    // If they get a read error because of a rule, then they aren't allowed
    return null;
  }
};

const onLogin = async ({ authInfo, priorUid, action }) => {
  try {
    const { uid, displayName, email, isAnonymous: anonymous } = authInfo;
    // Detach is a message to all the firebase listeners to stop listening when the user
    // logs out. It is the old uid so they can detach their listeners.
    const detach = priorUid && priorUid !== 'visitor' && uid !== priorUid ? priorUid : null;

    // See if they are part of another account
    const primary = await checkMembership({ uid, source: 'onLogin' });

    // Record the event
    if (priorUid !== uid) {
      addEvent({ action: 'login', category: 'activity', uid, data: { email, displayName, anonymous, primary } });
    }

    // Set this so we can remind them if they come back that they have used it before
    if (localStorage && localStorage.setItem) {
      localStorage.setItem('guustav.lastLogin', new Date());
      if (!anonymous) {
        localStorage.setItem('guustav.email', email);
        if (authInfo.providerData && authInfo.providerData.length) {
          localStorage.setItem('guustav.provider', authInfo.providerData[0].providerId);
        }
        trace(authInfo);
      }
    }
    const admin = email && /patrick@guustav.com|brian@guustav.com|francois@guustav.com/.test(email);

    return { uid: primary ? primary.uid : uid, ruid: uid, rname: displayName, email, displayName, anonymous, detach, primary, admin };
  } catch (error) {
    trace(`web: Error updating firebase ${error}`);
    return { error };
  }
};

export { onLogin };
