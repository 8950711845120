import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import { fullMeal } from 'guustav-shared';
import startCase from 'lodash/startCase';

import QuarantineRecipeDialog from './QuarantineRecipeDialog';
import RecipeDialog from './RecipeDialog';
import RecipeImage from './RecipeImage';

import ConfirmActionDialog from 'components/ConfirmActionDialog';
import RepeatToggle from 'components/RepeatToggle';
import { GET_EDAMAM_RECIPE_BY_URI, GET_GUUSTAV_RECIPE_BY_URI } from 'hooks/queries';
import useAppStyles from 'hooks/useAppStyles';
import useAuth from 'hooks/useAuth';
import dbLoad from 'hooks/utils/dbLoader';
import { dbSet } from 'hooks/utils/dbUpdater';
import { isNative, postNativeEvent, traceError } from 'utils';
import { current } from 'immer';
import RecipeChatGPTDialog from './RecipeChatGPTInstructions';
import { Badge } from '@material-ui/core';
import ChefHat from 'mdi-material-ui/Crown';
import usePremium from 'components/usePremium';

const Transition = React.forwardRef((props, ref) => <Fade ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
  },
  premiumBadge: {
    //backgroundColor: 'maroon',
    color: '#8476D1',
    borderRadius: '50%',
    fontSize: '0.6em',
    right: '-10px',
    fontWeight: 'bold',
  },
}));

const RecipeModal = ({ close, readonly, recipe, food, onUpdateRecipe, use, noToggle }) => {
  const classes = useAppStyles();
  const [editing, setEditing] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [currentRecipe, setCurrentRecipe] = useState(recipe);
  const [fullMealResult, setFullMealResult] = useState((recipe || {}).fullMeal);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [loadEdamam, setLoadEdamam] = useState(false);
  const [edamamData, setEdamamData] = useState();
  const { admin, uid } = useAuth();
  const [plans, setPlans] = useState();
  const [plan, setPlan] = useState(null);
  const [newName, setNewName] = useState('');
  const [addingPlan, setAddingPlan] = useState(false);
  const [openFood52, setOpenFood52] = useState(false);
  const [gptOpen, setGPTOpen] = useState(false);
  const classesBadge = useStyles();
  const { handlePremiumAccess, PremiumPromptComponent } = usePremium();

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  console.log(recipe);
  const my = recipe && (recipe.uri.match(/\/users/) || recipe.uri === 'made-by-ai');

  const full = recipe && !my && !!recipe.ingredients && typeof (recipe.ingredients) === 'object';

  const { data, error, loading, networkStatus } = useQuery(GET_GUUSTAV_RECIPE_BY_URI, {
    variables: { uri: recipe && recipe.uri && recipe.uri.match(/edamam/) ? recipe.uri : 'wutever' },
    skip: !recipe || my || full,
  });

  const { data: eData, error: eError } = useQuery(GET_EDAMAM_RECIPE_BY_URI, {
    variables: { uri: recipe && recipe.uri && recipe.uri.match(/edamam/) ? recipe.uri : 'wutever' },
    skip: !admin || !loadEdamam || !recipe || my,
  });

  // trace('my: %o, full: %o, recipe: %o, skip: %o, uri: %o', my, full, recipe, (!recipe || my || full), recipe ? recipe.uri : 'none');
  // trace('uri: %s, edata: %o', recipe ? recipe.uri : 'none', eData);
  // trace('data: %o', data);

  if (eError) {
    traceError(eError);
  }

  useEffect(() => {
    setEdamamData(null);
  }, [recipe]);

  useEffect(() => {
    if (!recipe || loading) {
      return;
    }
    if (my || full) {
      setCurrentRecipe(recipe);
    } else if (data) {
      if (admin && recipe.product) {
        const fn = async () => {
          const extended = await dbLoad({ path: `/cache/recipes/${recipe.product}/${recipe.slug}`, source: 'RecipeModal' });
          if (typeof extended.fullMeal === 'object') {
            setFullMealResult(extended.fullMeal);
          }
          setCurrentRecipe({ ...recipe, ...extended, ...data.getGuustavRecipeByURI });
        };
        fn();
      } else {
        setCurrentRecipe({ ...recipe, ...data.getGuustavRecipeByURI });
      }
    }
  }, [loading, recipe, data, full, my, admin]);

  useEffect(() => {
    console.log('eData: %o', eData);
    if (eData && eData.getEdamamRecipeByURI) {
      setEdamamData(eData.getEdamamRecipeByURI);
      const e = JSON.parse(eData.getEdamamRecipeByURI);
      if (e) {
        const newRecipe = { calories: e.calories, yield: e.yield, time: e.totalTime };
        setCurrentRecipe((c) => ({ ...c, ...newRecipe }));
      }
    } else if (loadEdamam) {
      setEdamamData(JSON.stringify({ not_exists: recipe.uri }));
    }
    setLoadEdamam(false);
  }, [eData, setEdamamData, recipe, loadEdamam]);

  useEffect(() => {
    if (recipe) {
      const fn = async () => {
        const plans = (await dbLoad({ path: '/cache/plans' })) || {};
        setPlans(plans);
        Object.values(plans).find((p) => {
          if (p.recipes && p.recipes[recipe.slug]) {
            setPlan(p);
            return true;
          }
          return false;
        });
      };
      fn();
    }
  }, [admin, recipe]);

  const deleteTheRecipe = () => {
    dbSet({ uid, path: `recipes/custom/${currentRecipe.slug}`, value: null });
    close();
    setConfirmDelete(false);
    if (use) {
      use({ recipe: null });
    } else if (onUpdateRecipe) {
      onUpdateRecipe({ recipe, remove: true });
    }
  };

  const updateFullMeal = () => {
    try {
      setFullMealResult(fullMeal(currentRecipe));
    } catch (e) {
      if (edamamData) {
        setFullMealResult({
          error: e.message,
        });
      } else {
        setLoadEdamam(true);
        setFullMealResult({
          error: e.message,
          tip: 'Loading Edamam data. Try again when you see it.',
        });
      }
    }
  };

  const addNewPlan = async () => {
    const newPlan = {
      name: newName,
      defaultForNewUsers: false,
      uses: 0,
      recipes: { [currentRecipe.slug]: currentRecipe },
    };
    await dbSet({ path: `/cache/plans/${newName}`, value: newPlan });
    setPlans((oldPlans) => {
      oldPlans[newName] = newPlan;
      return { ...oldPlans };
    });
    setAddingPlan(false);
    setNewName('');
    setPlan(newPlan);
  };

  const addToPlan = async (chosenPlan) => {
    if (!chosenPlan) {
      alert('Pick a menu or Add Menu');
      return;
    }
    await dbSet({ path: `/cache/plans/${chosenPlan.name}/recipes/${currentRecipe.slug}`, value: currentRecipe });
    const refreshedPlans = await dbLoad({ path: '/cache/plans' });
    setPlans(refreshedPlans);
    setPlan(refreshedPlans[chosenPlan.name]);
    setAddingPlan(false);
    setNewName('');
  };

  const removeFromPlan = async () => {
    if (!plan) {
      alert('Recipe is not on a menu');
      return;
    }
    await dbSet({ path: `/cache/plans/${plan.name}/recipes/${currentRecipe.slug}`, value: null });
    setPlans(await dbLoad({ path: '/cache/plans' }));
    setPlan(null);
    setAddingPlan(false);
    setNewName('');
  };

  const copyToClipboard = async () => {
    copy(currentRecipe.url);
  };

  if (!recipe || !currentRecipe) {
    return null;
  }

  if (error) {
    alert('There was an error loading the recipe.');
    return null;
  }

  const local = currentRecipe && (recipe.uri.match(/\/users/) || recipe.uri === 'made-by-ai');
  const mine = !readonly && local && currentRecipe.uri.split('/')[2] === uid;

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open>
        <CircularProgress className={classes.progress} size={80} fontSize="inherit" style={{ position: 'absolute', left: '50vw', top: '50vh' }} />
      </Backdrop>
    );
  }

  const handleClose = () => {
    setOpenFood52(false);
  };

  const doChatGPTInstructions = () => {
    handlePremiumAccess();
    setGPTOpen(true);
  };


  const components = [
    <Dialog
      key="recipe-modal" maxWidth="sm"
      TransitionComponent={Transition}
      open={!!recipe} onClose={close} aria-labelledby="form-dialog-title"
    >
      {loading && (<Backdrop className={classes.backdrop} open>
        <CircularProgress className={classes.progress} size={80} fontSize="inherit" style={{ position: 'absolute', left: '50vw', top: '50vh' }} />
      </Backdrop>)}
      {!loading && (
        <>
          <RecipeChatGPTDialog open={gptOpen} setOpen={setGPTOpen} recipe={currentRecipe} />
          <Dialog
            open={openFood52}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Opening recipe from Food52.com
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This recipe is provided by our partner Food52. Unfortunately we have noticed that their recipes do not open well in Guustav so we suggest you use the Copy Link button and open the recipe in your browser instead of from here
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                  copyToClipboard();
                }} autoFocus
              >Copy Recipe Address
              </Button>
              <Button onClick={() => {
                handleClose();
                postNativeEvent({ action: 'modalWebView', uri: currentRecipe.url });
              }}
              >Go to Recipe
              </Button>
            </DialogActions>
          </Dialog>

          <DialogTitle id="form-dialog-title" onClose={close} style={{ minWidth: 350 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={11}>

                {currentRecipe && !noToggle && (
                  <RepeatToggle recipe={currentRecipe} />
                )}
                <span>{currentRecipe.label}</span>
              </Grid>
              <Grid item xs={1} style={{ flexGrow: 1 }}>
                <IconButton aria-label="close" onClick={close}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            {loading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress className={classes.progress} fontSize="inherit" />
              </div>
            )}

            {currentRecipe.courses && currentRecipe.courses.length > 0 && (
              <h3 style={{ marginTop: 0, marginBottom: 6 }}>Courses: <span style={{ fontSize: 'inherit', fontWeight: 'normal' }}>{(currentRecipe.courses || []).join(', ')}</span></h3>
            )}

            <Grid container direction="column">
              <Grid container alignItems="center">
                {!xs && (<RecipeImage key={'img-' + currentRecipe.label} recipe={currentRecipe} sizeControl desiredWidth="450px" />)}
                {xs && (<RecipeImage recipe={currentRecipe} />)}
              </Grid>
            </Grid>
            {currentRecipe.yield && (
              <p>Yield: {currentRecipe.yield}</p>
            )}
            {currentRecipe.calories && (
              <p>Calories per serving: {Math.round(currentRecipe.calories / currentRecipe.yield)}</p>
            )}
            {currentRecipe.source && (
              <p>Source: {currentRecipe.source}</p>
            )}
            {currentRecipe.instructions && (
              <div style={{ marginBottom: 6, marginTop: 0 }}>
                <h3 style={{ marginBottom: 0, marginTop: 0 }}>Instructions:</h3>
                <div>{currentRecipe.instructions}</div>
              </div>
            )}
            {currentRecipe.instructionsStr && (
              <div style={{ marginBottom: 6, marginTop: 0 }}>
                <h3 style={{ marginBottom: 0, marginTop: 0 }}>Instructions:</h3>
                {currentRecipe.instructionsStr.split('\n').map((instruction, index) => (
                  <div key={index}>
                    {instruction}
                  </div>
                ))}
              </div>

            )}
            <Grid container justifyContent="space-between">
              <Grid item xs={mine ? 12 : 7}>
                <h3 style={{ marginTop: 0, marginBottom: 0 }}>Ingredients: </h3>
                <small><small><i>note: Quantities here are for recipe's default number of people, we will adjust them in your shopping list</i></small></small>
                <ul style={{ marginTop: 0, marginLeft: 4 }}>
                  {typeof currentRecipe.ingredients !== 'number' && (currentRecipe.ingredients || []).map((ingredient, idx) => (
                    <li key={`ingredient-${idx}`}>
                      <div key={`ingredientdiv-${idx}`} style={{
                        border: food && food.slug === ingredient.slug ? '1px solid #aaa' : 'inherit',
                        padding: food && food.slug === ingredient.slug ? 4 : 'inherit',
                        background: food && food.slug === ingredient.slug ? 'yellow' : 'inherit'
                      }}
                      >
                        <div>{ingredient.food}</div>
                        <div style={{ fontSize: ingredient.food ? '.8em' : 'default' }}>{ingredient.text}</div>
                        {!!(food && food.slug === ingredient.slug && food.recipes && food.recipes[recipe.slug].quantity.notes) && (
                          <div style={{ fontSize: '.8em' }}>({food.recipes[recipe.slug].quantity.notes}</div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>

                {currentRecipe.url && (
                  <Button variant="outlined" startIcon={<FileCopyIcon />} onClick={copyToClipboard}>
                    Copy Link to Recipe
                  </Button>
                )}
              </Grid>
              {admin && (
                <Grid item container direction="column" xs={12} spacing={1}>
                  <div style={{ width: '100%' }}>
                    <hr />
                    <h3>Extended Attributes (Admin Only)</h3>
                    {!currentRecipe.rejected && (
                      <Grid container spacing={1} style={{ paddingBottom: 8, marginBottom: 8, borderBottom: '1px solid #ccc' }} alignItems="flex-end">
                        <Grid item>
                          <FormControl style={{ minWidth: 200 }}>
                            <InputLabel id="plan-label">Pre-defined Menus</InputLabel>
                            <Select
                              required labelId="plan-label"
                              value={plan || ''}
                              renderValue={(value) => value.name || ''}
                              onChange={(ev) => { addToPlan(ev.target.value); }}
                            >
                              {(Object.values(plans || [])).sort().map((plan) => (
                                <MenuItem key={plan.name} value={plan}>{plan.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {plan && plan.recipes && plan.recipes[currentRecipe.slug] && (
                          <Grid item>
                            <Button size="small" color="primary" variant="contained" onClick={removeFromPlan}>Remove from Menu</Button>
                          </Grid>
                        )}
                        {!plan && !addingPlan && (
                          <Grid item>
                            <Button size="small" variant="contained" onClick={() => { setPlan(null); setNewName(''); setAddingPlan(true); }} color="primary" startIcon={<AddIcon />}>
                              Add New Menu
                            </Button>
                          </Grid>
                        )}
                        {addingPlan && (
                          <Grid item>
                            <TextField size="small" variant="outlined" label="Menu Name" value={newName} onChange={(ev) => setNewName(ev.target.value)} />
                            <IconButton style={{ marginBottom: -8 }} onClick={addNewPlan}><AddIcon /></IconButton>
                          </Grid>
                        )}
                      </Grid>
                    )}
                    {currentRecipe.approved && (
                      <h4>Approved By: {Object.keys(currentRecipe.approved).join(', ')}</h4>
                    )}
                    {currentRecipe.rejected && (
                      <h4>Quarantined By: {Object.keys(currentRecipe.rejected).join(', ')}</h4>
                    )}
                    {!plan && (
                      <Button size="small" color="primary" variant="contained" onClick={() => setReviewOpen(true)}>Quarantine</Button>
                    )}
                    <ul>
                      <li>Slug: {currentRecipe.slug}</li>
                      {currentRecipe.product && (
                        <li>Product: {currentRecipe.product}</li>
                      )}
                      {currentRecipe.products && (
                        <li>Products: {currentRecipe.products.join(', ')}</li>
                      )}
                      {currentRecipe.time && (
                        <li>Time: {currentRecipe.time}</li>
                      )}
                      {typeof (currentRecipe.gourmet) !== 'undefined' && (
                        <li>Gourmet: {currentRecipe.gourmet ? 'Yes' : 'No'}</li>
                      )}
                      {currentRecipe.dietLabels && (
                        <li>Diet: {([...currentRecipe.dietLabels]).sort().join(', ')}</li>
                      )}
                      {currentRecipe.healthLabels && (
                        <li>Health: {([...currentRecipe.healthLabels]).sort().join(', ')}</li>
                      )}
                      {currentRecipe.uri && (
                        <li>URI: {currentRecipe.uri}</li>
                      )}
                    </ul>
                  </div>
                  <div style={{ width: '100%', marginBottom: 10 }}>
                    <h5>Full Meal:</h5>
                    <Button size="small" color="primary" variant="contained" onClick={updateFullMeal}>{fullMealResult ? 'Re-c' : 'C'}alculate Full Meal</Button>
                    {fullMealResult && (
                      <div>
                        <ul style={{ listStyle: 'none' }}>
                          {Object.keys(fullMealResult).map((key) => (
                            <li key={key}>{startCase(key)}: {JSON.stringify(fullMealResult[key])}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div style={{ width: '100%' }}>
                    <Button size="small" color="primary" variant="contained" onClick={() => setLoadEdamam(true)}>Load Edamam Data</Button>
                    {edamamData && (
                      <div style={{ width: '100%', overflowX: 'hidden' }}>
                        <h5>Raw Edamam Data:</h5>
                        <pre>{JSON.stringify(JSON.parse(edamamData), null, 2)}</pre>
                      </div>
                    )}
                  </div>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {!local && <Button variant='outlined' onClick={doChatGPTInstructions}>
              <Badge badgeContent={<ChefHat style={{ fontSize: '2em' }} />} classes={{ badge: classesBadge.premiumBadge }}> <span>ChatGPT's take</span></Badge></Button>}
            {!local && !isNative() && (
              <Link underline="none" target="_blank" href={currentRecipe.url}><Button color="secondary" variant="contained">Directions</Button></Link>
            )}
            {!local && isNative() && (currentRecipe.source === 'Food52') && (
              <Button color="secondary" variant="contained" onClick={() => setOpenFood52(true)}>Directions</Button>
            )}
            {!local && isNative() && (currentRecipe.source !== 'Food52') && (
              <Button
                color="secondary" variant="contained"
                onClick={() => postNativeEvent({ action: 'modalWebView', uri: currentRecipe.url })}
              >
                Directions
              </Button>
            )}
            {!!mine && !readonly && (
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button elevation={1} variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => setEditing(true)}>Edit</Button>
                </Grid>
                <Grid item>
                  <Button elevation={1} variant="contained" className={classes.dangerButton} startIcon={<DeleteIcon />} onClick={() => setConfirmDelete(true)}>Delete</Button>
                </Grid>
              </Grid>
            )}
          </DialogActions></>)}
      <PremiumPromptComponent featureText="Getting ChatGPT's take on a recipe" />
    </Dialog>
  ];
  if (!readonly) {
    if (mine) {
      components.push(<RecipeDialog key="recipe-dialog" recipe={currentRecipe} open={editing} setOpen={setEditing} use={onUpdateRecipe} />);
    }
    components.push(
      <ConfirmActionDialog
        key="recipe-delete"
        open={confirmDelete}
        title={`Delete ${currentRecipe.label}`}
        text="Are you sure you want to delete this recipe?"
        noAction={() => setConfirmDelete(false)}
        yesAction={deleteTheRecipe}
      />
    );
  }
  if (admin) {
    components.push(
      <QuarantineRecipeDialog key="review" recipe={currentRecipe} open={reviewOpen} onClose={() => setReviewOpen(false)} />
    );
  }
  return components;
};

export default RecipeModal;
