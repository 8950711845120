import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import * as dayjs from 'dayjs';

import Tip from 'components/Tip';
import useAppStyles from 'hooks/useAppStyles';
import useAuth from 'hooks/useAuth';
import { dbUpdate } from 'hooks/utils/dbUpdater';
import { timeStamp } from 'utils';

const validEmail = (email) => email && email.includes('@');

const defaultData = {
  name: '',
  email: '',
  uid: '',
  age: '',
  gender: '',
  invite: true,
};

const formErrors = (data, ignoreFocus) => {
  const errors = {};
  if (data) {
    if (data.email && data.email.length && !validEmail(data.email)) {
      if (ignoreFocus || document.activeElement.getAttribute('id') !== 'member-email') {
        errors.email = 'E-mail is invalid';
      }
    }
    if (data.age && data.age.length) {
      if (!data.age.match(/^\d+$/)) {
        errors.age = 'Age must be numeric';
      } else if (Number(data.age) > 130) {
        errors.age = "Guustav does help you eat healthier, but we can't extend your life by that much. Yet!";
      }
    }
  }
  return errors;
};

const MemberModal = ({ member, handleClose, household, setHousehold }) => {
  const classes = useAppStyles();
  const [data, setData] = useState({});
  const { uid, email } = useAuth();

  useEffect(() => {
    setData({ ...defaultData, ...member });
  }, [member, setData]);

  useEffect(() => {
    setData((currentData) => {
      if (currentData.email && validEmail(currentData.email) && typeof currentData.invite === 'undefined' && !currentData.invitedAt) {
        currentData.invite = true;
      }
      return { ...currentData };
    });
  }, [setData, data.email]);

  const onBlur = () => {
    setData({ ...defaultData, ...data });
  };

  const handleReset = () => {
    setData({ ...defaultData, ...member });
  };

  const handleSave = () => {
    setHousehold((priorHousehold) => {
      const newHousehold = { ...(priorHousehold || {}) };
      if (!newHousehold.members) {
        newHousehold.members = {};
      }
      const updates = {};
      if (data.invite) {
        data.invitedAt = timeStamp();
        data.allowed = 'on';
        updates[`/invitations/${member.accessKey}`] = {
          uid,
          accessKey: member.accessKey,
          from: email,
          to: data.email,
          household: newHousehold.name,
          invitedAt: timeStamp(),
          allowed: 'on',
          sentAt: null,
          acceptedAt: null,
        };
      }
      updates[`/users/${uid}/household/members/${member.accessKey}`] = { ...data };
      dbUpdate({ path: '/', updates }).then(() => {
        handleClose();
      });
      newHousehold.members[member.accessKey] = { ...data };
      return newHousehold;
    });
  };

  const change = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const errors = formErrors(data, false);
  const invitable = data.email && validEmail(data.email) && data.email !== email && !(member && (member.invitedAt || member.uid));

  return (
    <Dialog open={!!member} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" scroll="body">
      <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
        <input type="submit" style={{ display: 'none' }} />
        <DialogTitle id="form-dialog-title">Set Up Household Member</DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" spacing={3} style={{ padding: 8 }}>
            <Grid item container direction="column">
              <Grid item>
                <TextField
                  autoFocus fullWidth variant="outlined" label="E-mail (optional)" placeholder="E-mail (optional)"
                  helperText={errors.email || (member && uid === member.uid ? '' : 'Only used if you choose to invite the person to be a member of your household. Double check junk mail for the invitation.')}
                  value={data.email} onChange={(ev) => change('email', ev.target.value)}
                  onBlur={onBlur} error={!!errors.email} size="small"
                  autoComplete="random-field1"
                />
              </Grid>
              {member && member.uid !== uid && (
                <Grid item>
                  <FormControlLabel
                    label="Invite this person to join your household?"
                    disabled={!invitable}
                    control={(
                      <Checkbox
                        disabled={!invitable}
                        checked={data.invite || false}
                        color="primary"
                        onChange={(ev) => setData({ ...data, invite: ev.target.checked })}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item container>
                {member && member.invitedAt && (
                  <Grid item>
                    Invited: {dayjs(member.invitedAt).format('MM/DD/YYYY')}
                  </Grid>
                )}
                {member && member.acceptedAt && (
                  <Grid item style={{ marginLeft: 8 }}>Accepted: {dayjs(member.acceptedAt).format('MM/DD/YYYY')}</Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                fullWidth variant="outlined" label="Name" placeholder="Name"
                value={data.name || 'Member'} onChange={(ev) => change('name', ev.target.value)}
                error={!!errors.name} helperText={errors.name || ' '} size="small"
                autoComplete="random-field2"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined" placeholder="Age" label="Age" InputProps={{ style: { width: 70 } }}
                value={data.age} onChange={(ev) => change('age', ev.target.value)}
                error={!!errors.age} helperText={errors.age || ' '} size="small"
                autoComplete="random-field3"
              />
            </Grid>
            <Grid container item justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item xs={5}>
                <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ minWidth: 120 }}>
                  <InputLabel
                    shrink htmlFor="gender-label"
                    style={{ backgroundColor: '#ffffff'/* this is to fix a bug in material where the line goes through until you click */ }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    native
                    inputProps={{
                      name: 'gender',
                      id: 'gender-label'
                    }}
                    value={data.gender || ''}
                    onChange={(ev) => change('gender', ev.target.value)}
                  >
                    <option value="" />
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    <option value="Both">Non-specific</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Tip message="This is used to help me suggest personal items to add to your grocery list." />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleReset} color="primary">
            Reset
          </Button>
          <Button onClick={handleSave} color="primary" disabled={!!Object.keys(formErrors(data, true)).length}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default MemberModal;
