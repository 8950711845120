const grocers = {

  
  
  albertsons: { id: 'albertsons', name: 'Albertsons', url: (item) => `https://www.albertsons.com/shop/search-results.html?q=${encodeURI(item.name)}` },
  aldi: { id: 'aldi', name: 'Aldi', url: (item) => `https://shop.aldi.us/store/aldi/search/${encodeURI(item.name)}` },
  amazon: { id: 'amazon', name: 'Amazon', url: (item) => `https://www.amazon.com/s?k=${encodeURI(item.name)}` },
  brookshirescompany: { id: 'brookshirescompany', name: 'Brookshires company', url: (item) => `https://www.brookshires.com/shop/?query=${encodeURI(item.name)}` },
  centralmarket: { id: 'centralmarket', name: 'Central Market', url: (item) => `https://www.centralmarket.com/search?q=${encodeURI(item.name)}` },
  costco: { id: 'costco', name: 'Costco', url: (item) => `https://sameday.costco.com/search?search_term=${encodeURI(item.name)}` },
  fairwaymarket: { id: 'fairwaymarket', name: 'Fairway Market', url: (item) => `https://shop.fairwaymarket.com/store/fairway/search/${encodeURI(item.name)}` },
  fareway: { id: 'fareway', name: 'Fareway', url: (item) => `https://shop.fareway.com/124#/search?q=${encodeURI(item.name)}` },
  festivalfoods: { id: 'festivalfoods', name: 'Festival Foods', url: (item) => `https://www.festfoods.com/shop#!/?q=${encodeURI(item.name)}` },
  foodlion: { id: 'foodlion', name: 'Food Lion', url: (item) => `https://www.foodlion.com/product-search${encodeURI(item.name)}` },
  foodtown: { id: 'foodtown', name: 'Foodtown', url: (item) => `https://www.foodtown.com/shopping#!/?q=${encodeURI(item.name)}` },
  freshdirect: { id: 'freshdirect', name: 'Freshdirect', url: (item) => `https://www.freshdirect.com/search?search=${encodeURI(item.name)}` },
  giantfoodstores: { id: 'giantfoodstores', name: 'Giant Food Stores', url: (item) => `https://giantfoodstores.com/product-search/${encodeURI(item.name)}` },
  giantfood: { id: 'giantfood', name: 'Giant Food', url: (item) => `https://giantfood.com/product-search/${encodeURI(item.name)}` },
  hannaford: { id: 'hannaford', name: 'Hannaford', url: (item) => `https://www.hannaford.com/product-search/${encodeURI(item.name)}` },
  harmons: { id: 'harmons', name: 'Harmons Neighborhood Grocer', url: (item) => `https://shop.harmonsgrocery.com/search?search_term=${encodeURI(item.name)}` },
  harveys: { id: 'harveys', name: 'Harveys Supermarkets', url: (item) => `https://shop.harveyssupermarkets.com/shop/?query=${encodeURI(item.name)}` },
  heb: { id: 'heb', name: 'H-E-B', url: (item) => `https://www.heb.com/search/?q=${encodeURI(item.name)}` },
  hyvee: { id: 'hyvee', name: 'HyVee', url: (item) => `https://www.hy-vee.com/aisles-online/search?search=${encodeURI(item.name)}` },
  ingles: { id: 'ingles', name: 'Ingles', url: (item) => `https://shop.ingles-markets.com/shop#!/?q=${encodeURI(item.name)}` },
  // instacart: { id: 'instacart', name: 'Instacart', url: (item) => `https://www.instacart.com/store/s?k=${encodeURI(item.name)}` },
  kroger: { id: 'kroger', name: 'Kroger', url: (item) => `https://www.kroger.com/search?query=${encodeURI(item.name)}` },
  loblaws: { id: 'loblaws', name: 'loblaws', url: (item) => `https://www.loblaws.ca/search?search-bar=${encodeURI(item.name)}` },
  lowesfoods: { id: 'lowesfoods', name: 'Lowes Foods', url: (item) => `https://shop.lowesfoods.com/search?search_term=${encodeURI(item.name)}` },
  lundsbyerlys: { id: 'lundsbyerlys', name: 'Lunds & Byerlys', url: (item) => `https://shop.lundsandbyerlys.com/search?search_term=${encodeURI(item.name)}` },
  //marketbasket: { id: 'marketbasket', name: 'Market Basket', url: (item) => `https://www.marketbasketpa.com/shop#!/?q=${encodeURI(item.name)}` },
  meijer: { id: 'meijer', name: 'Meijer', url: (item) => `https://www.meijer.com/shopping/search.html?text=${encodeURI(item.name)}` },
  metro: { id: 'metro', name: 'Metro', url: (item) => `https://www.metro.ca/en/search?filter=${encodeURI(item.name)}` },
  pigglywiggly: { id: 'pigglywiggly', name: 'Piggly Wiggly', url: (item) => `https://www.shoppigglywiggly.com/shop#!/?q=${encodeURI(item.name)}` },
  publix: { id: 'publix', name: 'Publix', url: (item) => `https://www.publix.com/search?searchTerm=${encodeURI(item.name)}` },
  raleys: { id: 'raleys', name: 'Raleys', url: (item) => `https://shop.raleys.com/search?search_term=${encodeURI(item.name)}` },
  randalls: { id: 'randalls', name: 'Randalls', url: (item) => `https://www.randalls.com/shop/search-results.html?q=${encodeURI(item.name)}` },
  rochebros: { id: 'rochebros', name: 'Roche Bros', url: (item) => `https://shopping.rochebros.com/search?search_term=${encodeURI(item.name)}` },
  safeway: { id: 'safeway', name: 'Safeway', url: (item) => `https://www.safeway.com/shop/search-results.html?q=${encodeURI(item.name)}` },
  saveonfoods: { id: 'saveonfoods', name: 'Save on foods', url: (item) => `https://www.saveonfoods.com/sm/pickup/rsid/4425/results?q=${encodeURI(item.name)}` },
  schnucks: { id: 'schnucks', name: 'Schnucks', url: (item) => `https://nourish.schnucks.com/web-ext/search?s=${encodeURI(item.name)}` },
  sendiks: { id: 'sendiks', name: 'Sendiks', url: (item) => `https://www.sendiks.com/shop#!/?q=${encodeURI(item.name)}` },
  shoprite: { id: 'shoprite', name: 'Shoprite', url: (item) => `https://www.shoprite.com/sm/pickup/rsid/3000/results?q=${encodeURI(item.name)}` },
  spartannash: { id: 'spartannash', name: 'Spartan Nash', url: (item) => `https://www.shopthefastlane.com/search?search_term=${encodeURI(item.name)}` },
  sprouts: { id: 'sprouts', name: 'Sprouts', url: (item) => `https://shop.sprouts.com/search?search_term=${encodeURI(item.name)}` },
  stopshop: { id: 'stopshop', name: 'Stop & Shop', url: (item) => `https://stopandshop.com/product-search/${encodeURI(item.name)}` },
  target: { id: 'target', name: 'Target', url: (item) => `https://www.target.com/s?searchTerm=${encodeURI(item.name)}` },
  thefreshmarket: { id: 'thefreshmarket', name: 'The Fresh Market', url: (item) => `https://shop.thefreshmarket.com/search?search_term=${encodeURI(item.name)}` },
  unitedsupermarkets: { id: 'unitedsupermarkets', name: 'United Supermarkets', url: (item) => `https://www.unitedsupermarkets.com/rs/SearchProduct?searchkey=${encodeURI(item.name)}` },
  voila: { id: 'voila', name: 'Voila', url: (item) => `https://voila.ca/products/search?q=${encodeURI(item.name)}` },
  walmart: { id: 'walmart', name: 'Walmart', url: (item) => `https://www.walmart.com/search?q=${encodeURI(item.name)}` },
  wegmans: { id: 'wegmans', name: 'Wegmans', url: (item) => `https://shop.wegmans.com/search?search_term=${encodeURI(item.name)}` },
  wholefoods: { id: 'wholefoods', name: 'Whole Foods', url: (item) => `https://www.wholefoodsmarket.com/search?text=${encodeURI(item.name)}` },
  winndixie: { id: 'winndixie', name: 'Winn Dixie', url: (item) => `https://shop.winndixie.com/shop/?query=${encodeURI(item.name)}` },
};

export default grocers;
