import React, { useEffect, useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import RecipeCard from './RecipeCard';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
  },
  scrollParent: {
    overflowX: 'hidden',
    paddingRight: 10,
  },
  scroller: {
    overflowX: 'auto',
    paddingRight: 8,
    paddingTop: 2, /* otherwise top border is hidden */
  },
  gridList: {
    flexWrap: 'nowrap',
    overflow: 'auto',
  },
  tileRoot: {
    marginRight: 10,
  },
  tile: {
    overflow: 'visible',
  },
  cardItem: {
    marginBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
  },
  title: {
    fontSize: '1.1rem',
    // fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10,
    paddingLeft: 10,
  },
  notFound: {
    marginBottom: 10,
  },
}));

const startWidth = document.documentElement.clientWidth;

const calcScroll = (el) => {
  if (el) {
    return {
      left: el.scrollLeft,
      width: el.scrollWidth - el.offsetWidth
    };
  }
  return { left: 0, width: 0 };
};

let ticking = false;

const reverseRecipes = (array) => {
  const output = [];
  for (let i = array.length - 1; i > -1; i--) {
    output.push(array[i]);
  }

  return output;
};

const RecipeGridList = ({ actionProps, autoHide, fullPage, loading, notFoundMessage, recipes, title, uid, preview, ...props }) => {
  const classes = useStyles();
  const [/* width */, setWidth] = useState(startWidth);
  const [scroll, setScroll] = useState({ left: 0, width: null });

  if (recipes) {
    recipes.map((recipe) => {
      if (recipe.ingredients && typeof (recipe.ingredients) === 'number') delete recipe.ingredients;
      return true;
    });
  }

  const recipesRev = recipes ? reverseRecipes(recipes) : [];

  useEffect(() => {
    setScroll(calcScroll(ref.current));
    const resize = () => {
      const width = document.documentElement.clientWidth;
      setWidth(width);
      setScroll(calcScroll(ref.current));
    };
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [setWidth]);

  const ref = React.useRef(null);
  const itemRef = React.useRef(null);

  useEffect(() => {
    if (ref.current) {
      const onScroll = () => {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            setScroll(calcScroll(ref.current));
            ticking = false;
          });
          ticking = true;
        }
      };
      ref.current.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, []);

  if (loading) {
    if (autoHide) {
      return null;
    }
    return <LinearProgress />;
  }

  if (autoHide && !(recipesRev && recipesRev.length)) {
    return null;
  }

  const scrollBy = (by) => {
    ref.current.scrollBy({ left: 2 * by * itemRef.current.clientWidth, behavior: 'smooth' });
  };

  const bySlug = {};
  (recipesRev || []).forEach((r) => {
    if (bySlug[r.slug]) {
    }
    bySlug[r.slug] = true;
  });

  return (
    <Grid container item direction="column" className={classes.container}>
      <Grid container item direction="row" className={classes.container} justifyContent="space-between">
        <Grid item className={classes.title}>
          {title}
        </Grid>
        {!!(recipesRev && recipesRev.length) && (
        <Grid item style={{ marginTop: 0 }}>

            {scroll.left > 0 && (
              <IconButton onClick={() => scrollBy(-1)}><ChevronLeft /></IconButton>
            )}

            {scroll.left < scroll.width && (
              <IconButton onClick={() => scrollBy(1)}><ChevronRight /></IconButton>
            )}

        </Grid>
        )}
      </Grid>
      {!(recipesRev && recipesRev.length) && (
        <Grid item className={classes.notFound}>
          {notFoundMessage || 'No recipes found'}
        </Grid>
      )}
      <Grid item container className={classes.scrollParent}>
        <Grid ref={ref} item container wrap="nowrap" className={classes.scroller} spacing={0}>
          {(recipesRev || []).map((recipeRev, idx) => (
            <Grid item key={recipeRev.slug} ref={idx === 0 ? itemRef : undefined} className={classes.cardItem}>
              <RecipeCard recipe={recipeRev} actionProps={actionProps} uid={uid} preview={preview} />
            </Grid>
          ))}
        </Grid>
      </Grid>

      {props.children}
      <Divider component="div" xs={12} style={{ marginTop: 2, marginBottom: 0 }} />
    </Grid>
  );
};

export default RecipeGridList;
