import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import MailStatusIcon from './MailStatusIcon';
import MemberModal from './MemberModal';

import useAuth from 'hooks/useAuth';
import useSnack from 'hooks/useSnack';
import dbLoad from 'hooks/utils/dbLoader';
import { dbSet } from 'hooks/utils/dbUpdater';

const MemberTable = ({ household, setHousehold, currentMember, setCurrentMember }) => {
  const { ruid, uid, email } = useAuth();
  const { setSnack } = useSnack();

  const removeMember = async (accessKey) => {
    if (uid !== ruid) {
      return;
    }
    const invitation = await dbLoad({ path: `/invitations/${accessKey}`, source: 'MemberTable' });
    if (invitation) {
      await dbSet({ path: `/invitations/${accessKey}`, value: null, source: 'MemberTable' });
    }
    await dbSet({ uid, path: `household/members/${accessKey}`, value: null, source: 'MemberTable' });
    setHousehold((priorHousehold) => {
      delete priorHousehold.members[accessKey];
      return { ...priorHousehold };
    });
    setSnack({ message: 'Member has been removed from your household' });
  };

  const sortMembers = (a, b) => {
    const emailA = a[1].email || 'Ω';
    const emailB = b[1].email || 'Ω';
    if (emailA === email) {
      return -1;
    }
    if (emailB === email) {
      return 1;
    }
    return emailA.localeCompare(emailB);
  };

  return [
    <MemberModal key="member-modal" member={currentMember} handleClose={() => setCurrentMember(null)} household={household} setHousehold={setHousehold} />,
    <List component="nav" key="member-list">
      <>
        {Object.entries(household.members || {}).sort(sortMembers).map(([accessKey, member], idx) => (
          <ListItem key={`member-${accessKey}`} button onClick={() => (ruid === uid ? setCurrentMember(member) : null)} dense disableGutters divider>
            {ruid === uid && (
            <ListItemIcon>
              <EditIcon onClick={() => setCurrentMember(member)} size="small" />
            </ListItemIcon>
            )}
            <ListItemText>{member.email || member.name}</ListItemText>
            {ruid === uid && (
            <ListItemSecondaryAction id="switch-list-label-wifi" primary={member.name || 'Member'}>
              <MailStatusIcon member={member} household={household} setHousehold={setHousehold} />
              {member.email !== email && (
                <IconButton elevation={1} variant="contained" color="primary" size="small" onClick={() => removeMember(accessKey)}>
                  <RemoveIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </>
    </List>
  ];
};

export default MemberTable;
