import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { RestaurantMenu } from '@material-ui/icons';
import People from '@material-ui/icons/People';
import Counter from 'mdi-material-ui/Counter';
import ShopOnline from 'mdi-material-ui/Web';

import Household from './household';
import OnlinePreferences from './online';
import QuantityPreferences from './quantity';
import RecipePreferences from './recipe';
import Badge from '@material-ui/core/Badge';
import ChefHat from 'mdi-material-ui/Crown';
import usePremium from 'components/usePremium';

import useNav from 'hooks/useNav';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  tabs: {
    height: '100%',
    width: 300,
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      marginLeft: '20%',
      marginTop: 20,
    },
  },
  tabIndicator: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tab: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    minHeight: 50,
  },
  tabWrapperFirst: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tabWrapper: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tabIcon: {
    marginRight: 10,
  },
  premiumBadge: {
    //backgroundColor: 'maroon',
    color: '#8476D1',
    borderRadius: '50%',
    fontSize: '0.6em',
    right: '-10px',
    fontWeight: 'bold',
  },
}));

const Settings = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { setNav } = useNav();
  const [tab, setTab] = useState(4);
  const { handlePremiumAccess, PremiumPromptComponent } = usePremium();

  useEffect(() => {
    if (window.location.hash && window.location.hash.slice(1) === 'online') {
      setTab(3);
    } else if (mobile) {
      setTab(4);
    } else {
      setTab(0);
    }
  }, [mobile]);

  useEffect(() => {
    setNav((nav) => ({ pageName: 'Settings' }));
  }, [setNav]);

  const handleChange = (event, newValue) => {
    if (newValue === 2) {
      handlePremiumAccess();
    } 
    setTab(newValue);
  };


  return (
    <Grid container style={{ minHeight: '80vh', width: '100%' }}>
      <Grid container justifyContent="flex-start" item xs={12} md={4} lg={3} style={{ display: mobile && tab !== 4 ? 'none' : '' }}>
        <Tabs
          orientation="vertical"
          variant="fullWidth"
          value={tab}
          onChange={handleChange}
          aria-label="Settings navigation sidebar"
          classes={{ root: classes.tabs, indicator: classes.tabIndicator }}
        >
          <Tab classes={{ root: classes.tab, wrapper: classes.tabWrapperFirst }} label="Recipe Preferences" icon={<RestaurantMenu className={classes.tabIcon} />} />
          <Tab classes={{ root: classes.tab, wrapper: classes.tabWrapper }} label="Quantity Preferences" icon={<Counter className={classes.tabIcon} />} />
          <Tab classes={{ root: classes.tab, wrapper: classes.tabWrapper }} label={
            <Badge badgeContent={<ChefHat style={{ fontSize: '2em' }} />} classes={{ badge: classes.premiumBadge }}>
              <span>Household</span>
            </Badge>
          } icon={
              <People className={classes.tabIcon} />
          } />
          <Tab classes={{ root: classes.tab, wrapper: classes.tabWrapper }} label="Online Stores" icon={<ShopOnline className={classes.tabIcon} />} />
          <Tab style={{ visibility: 'hidden' }} />
        </Tabs>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <div hidden={tab !== 0}>
          <RecipePreferences close={mobile ? () => setTab(4) : undefined} />
        </div>
        <div hidden={tab !== 1}>
          <QuantityPreferences close={mobile ? () => setTab(4) : undefined} />
        </div>
        <div hidden={tab !== 2}>
          <Household close={mobile ? () => setTab(4) : undefined} />
        </div>
        <div hidden={tab !== 3}>
          <OnlinePreferences close={mobile ? () => setTab(4) : undefined} />
        </div>
      </Grid>
      <PremiumPromptComponent featureText='The ability to share Guustav with other household members'/>
    </Grid>
  );
};

export default Settings;
