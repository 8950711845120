import firebase from 'firebase/app';
import 'firebase/database';
import dayjs from 'dayjs';
import { keywords, populateOneProfileRecipeCache, utils } from 'guustav-shared';
import sample from 'lodash/sample';
import shuffle from 'lodash/shuffle';

import dbLoader from './dbLoader';

// import { GET_GUUSTAV_RECIPE_BY_URI } from 'hooks/queries';
import { dbSet } from 'hooks/utils/dbUpdater';
import { addRecipeEvent } from 'hooks/utils/events';
import { timeStamp, trace } from 'utils';

// const checkRecipes = process.env.REACT_APP_ENV === 'production';

const m = (meal) => (meal.recipe ? meal.recipe.slug : 'no recipe');
const ms = (meals) => meals.map((meal) => ({ ...meal, recipe: m(meal) }));
const j = (item) => (typeof item === 'object' ? JSON.parse(JSON.stringify(item)) : item);

// cacheOnly means don't use it as a product for a meal slot
const keywordsByProduct = keywords.filter((k) => !k.cacheOnly).reduce((h, k) => Object.assign(h, { [k.product]: k }), {});
const k = (product) => {
  if (product && keywordsByProduct[product]) {
    return keywordsByProduct[product].keyword;
  }
};

const weightedProducts = [];
keywords.filter((k) => !k.cacheOnly).forEach((k) => {
  const { limit } = k;
  for (let i = 0; i < Math.max(1, Math.floor(limit / 10)); ++i) {
    weightedProducts.push(k.product);
  }
});
const randomizedProducts = shuffle(weightedProducts);

// This is to keep meat eaters from getting vegetarian meals.
// Vegetarians should already not get meat meals due to
// health filtering.
const veggieOnly = ({ recipePreferences, product, localTrace }) => {
  localTrace('Veggie only rp: %o, product: %o', recipePreferences, product);
  const health = (recipePreferences || {}).health || [];
  if (health.includes('vegetarian') || health.includes('vegan')) {
    localTrace('Veggie only: health includes vegetarian so returning false');
    return false;
  }
  const keyword = keywordsByProduct[product];
  localTrace('Veggie only: keyword: %o', keyword);
  return keyword && keyword.vegetarian;
};

// function to test if the recipe name refers to any type of pasta we know
const isPasta = (recipe) => {
  const pastaKeywords = ['PASTA', 'NOODLES'];
  Object(keywords).forEach((e) => {
    if (e.keyword.toUpperCase() && e.keyword.toUpperCase() === 'PASTA') { pastaKeywords.push(e.product.toUpperCase()); }
  });
  let test = false;
  pastaKeywords.forEach((e) => {
    if ((test === false) && (recipe.label.toUpperCase().includes(e))) { test = true; }
  });
  return test;
};

const maxPasta = (menu, localKeywords) => {
  if (localKeywords.Pasta) { return true; } return false;
};

// See if recipe is still in Edamam, wasting our precious api credits
/* const checkRecipe = async ({ client, recipe, localTrace, force = false }) => {
  if (!force && !checkRecipes) {
    return true;
  }
  if (/\/users/.test(recipe.uri)) {
    return true;
  }
  if (typeof recipe.ingredients === 'object' && /amazon/.test(recipe.image)) {
    localTrace('Recipe %s already has ingredients and new image url so assuming valid', recipe.slug);
    return true;
  }
  const { data } = await client.query({ query: GET_GUUSTAV_RECIPE_BY_URI, variables: { uri: recipe.uri } });
  if (data && data.getGuustavRecipeByURI) {
    Object.assign(recipe, data.getGuustavRecipeByURI);
    localTrace('Recipe %s still in edamam', recipe.slug);
    return true;
  }
  localTrace('Recipe %s not in edamam', recipe.slug);
  return false;
};
 */
// See if recipe matches preferences
const recipeConflicts = ({ recipePreferences, recipe, localTrace }) => {
  const healths = ((recipePreferences || {}).health || []);
  const skipVeggieCheck = healths.includes('vegetarian') || healths.includes('vegan');
  if (!skipVeggieCheck && recipe.product && veggieOnly({ recipePreferences, product: recipe.product, localTrace })) {
    localTrace('recipeConflicts: skipping because veggie meal and user not veggie: %o, %o', recipePreferences, recipe);
    return 'no-veggie';
  }
  const mismatches = utils.recipeConflictsWithPreferences({ recipePreferences, recipe });
  if (mismatches) {
    localTrace('recipeConflicts: %s conflicts %o, rp: %o, recipe: %o', recipe.label, mismatches, recipePreferences, recipe);
    return mismatches;
  }
  localTrace('recipeConflicts: %s no conflicts, rp: %o, recipe: %o', recipe.label, recipePreferences, recipe);
  return null;
};

/*
 * Fill in any repeat spots with randomized repeated recipes that aren't excluded due to recency.
 */
const populateRepeatsFromRepeats = async ({ courseId, menu, excluded, repeated, used, localTrace, client }) => {
  const needed = menu.meals.filter((m) => m.status.action === 'pick' && m.status.type === 'repeat').length;
  if (needed === 0) {
    localTrace('No repeat replacements needed: %o', j(menu));
    return;
  }
  const defaultTime = dayjs().subtract(30, 'days');
  const repeats = Object.values(repeated || {}).sort((a, b) => (b.time || defaultTime).localeCompare(a.time || defaultTime));
  const older = {}; const
    newer = {};
  const cutoff = dayjs().subtract(14, 'day');
  Object.entries(repeats).forEach(([slug, recipe]) => {
    if (recipe.time && dayjs(recipe.time).isBefore(cutoff)) {
      older[slug] = recipe;
    } else {
      newer[slug] = recipe;
    }
  });
  // Per Francois on 5/16/2022, don't use newer repeats, just switch to new recipes
  const randomized = shuffle(older); // .concat(shuffle(newer));
  localTrace('Repeats: needed %d, available: %d', needed, repeats.length);
  localTrace('Repeats: %o', j(repeats));
  // Should we go in strict order or randomize?
  const repeatMeals = [...randomized];
  const replacements = [];
  while (repeatMeals.length && replacements.length < needed) {
    let i = 3;
    while (repeatMeals.length && replacements.length < needed && i > 0) {
      i--;
      const recipe = repeatMeals.shift();
      const { slug } = recipe;
      if (excluded[slug]) {
        localTrace('Skipping repeat: %s due to excluded: %s', slug, excluded[slug]);
        continue;
      }
      if (used.recipes[slug]) {
        localTrace('Skipping repeat: %s due to already used: %s', slug, used.recipes[slug]);
        continue;
      }
      // First pass: look for new product/keyword
      // Second pass: look for new product
      // Third pass: anything goes
      if (i < 1 && recipe.product && used.products[recipe.product]) {
        localTrace('Skipping repeat %o due to product: %o in %o', recipe, recipe.product, used.products[recipe.product]);
        continue;
      }
      const keyword = k(recipe.product);
      if (i < 2 && keyword && used.keywords[keyword]) {
        localTrace('Skipping repeat %o due to keyword: %o in %o', recipe, recipe.keyword, used.keywords[keyword]);
        continue;
      }
      const stillValid = true;
      // await checkRecipe({ client, recipe, localTrace });
      if (!stillValid) {
        localTrace('Skipping repeat %o due to uri not in Edamam anymore', recipe);
        continue;
      }
      localTrace('Adding new repeat: %o', j(recipe));
      const meal = { courseId, recipe, status: { type: 'repeat', action: 'none' } };
      replacements.push(meal);
      if (recipe.product) {
        used.products[recipe.product] = 'repeat';
      }
      if (keyword) {
        used.keywords[keyword] = 'repeat';
      }
      used.recipes[slug] = 'replacement';
      excluded[slug] = 'used';
    }
  }
  const newMeals = menu.meals.map((meal) => {
    if (meal.status.action !== 'pick' || meal.status.type === 'new') {
      return meal;
    }
    const replacement = replacements.shift();
    if (!replacement) {
      // switch to new meal if we run out of repeats
      meal.status.type = 'new';
      return meal;
    }
    return replacement;
  });
  localTrace('New menu after using repeats: %o', ms(j(newMeals)));
  menu.meals = newMeals;
};

const url_string = window.location;
const url = new URL(url_string);
const mealPlanId = url.searchParams.get('mealplan');

const findPrePlan = async ({ uid, email, courseId, recipePreferences, initial, localTrace }) => {
  if (!initial) {
    localTrace('findPrePlan: they must have asked for a new recipe so ditch the plan');
    dbSet({ uid, path: 'plan/next', value: null, origin: 'fillMeals' });
    return null;
  }

  if (mealPlanId === null) {
    // what happens if the user has not passed a premade meal plan
    const planName = await dbLoader({ uid, path: 'plan/next', origin: 'fillMeals' });
    if (planName) {
      localTrace('findPrePlan: next plan for user: %s', planName);
      const plan = await dbLoader({ path: `/cache/plans/${planName}`, origin: 'fillMeals' });
      if (plan) {
        localTrace('findPrePlan: using plan: %o', plan);
        return plan;
      }
    }

    if (email === 'retaildemo@guustav.com') {
      const snapshot = await firebase.database().ref('/cache/plans').orderByChild('defaultForNewUsers').startAt(true)
        .endAt(true)
        .once('value');
      if (!snapshot || !snapshot.val()) {
        localTrace('findPrePlan: no initial user plans');
        return null;
      }

      const plans = Object.entries(snapshot.val());
      const matchingPlans = plans.filter(([name, plan]) => {
        localTrace('findPrePlan: checking plan: %s', name);
        if (name === 'retaildemo') { return true; } return false;
      }).map(([name, plan]) => plan);
      return matchingPlans[0];
    }

    // Now look for a generic new user plan
    const newUser = initial && !(await dbLoader({ uid, path: 'plan', origin: 'fillMeals' }));
    if (!newUser) {
      localTrace('findPrePlan: not a new user so not using initial user plans');
      return null;
    }
    const snapshot = await firebase.database().ref('/cache/plans').orderByChild('defaultForNewUsers').startAt(true)
      .endAt(true)
      .once('value');
    if (!snapshot || !snapshot.val()) {
      localTrace('findPrePlan: no initial user plans');
      return null;
    }

    const plans = Object.entries(snapshot.val());
    const matchingPlans = plans.filter(([name, plan]) => {
      localTrace('findPrePlan: checking plan: %s', name);
      if (name === 'retaildemo') return false;
      const matchingRecipes = Object.values(plan.recipes || {}).filter((recipe) => !recipeConflicts({ recipePreferences, recipe, localTrace }));
      plan.matches = matchingRecipes.length;
      localTrace('findPrePlan: plan %o matches: %d', plan, plan.matches);
      return plan.matches > 0;
    }).map(([name, plan]) => plan);
    const sortFn = (a, b) => {
      // The plan with the most matches wins (higher = first).
      const m = Math.sign(b.matches - a.matches);
      if (m !== 0) {
        return m;
      }
      // If same number of recipe matches, use the plan with the shortest name
      // since that's likely to be more generic (lower = first).
      return Math.sign(a.name.length - b.name.length);
    };

    const bestMatch = matchingPlans.sort(sortFn)[0];
    localTrace('findPrePlan: best match: %o', bestMatch);
    return bestMatch;
  }

  // load the requested plan
  const plans = await dbLoader({ path: '/cache/plans/', origin: 'fillMeals' });
  if (plans) {
    let plan;
    Object.values(plans).forEach((element) => {
      if (element.externalId === Number(mealPlanId)) plan = element;
    });
    return plan;
  }
};

const populateNewFromPrePlan = async ({ uid, plan, courseId, menu, recipePreferences, excluded, used, localTrace, initial, client }) => {
  const needed = menu.meals.filter((m) => m.status.action === 'pick' && m.status.type === 'new').length;
  if (needed === 0) {
    localTrace('PrePlan: no more new meals needed. Menu: %o', j(menu));
    return;
  }
  localTrace('Profile cache: needed: %d', needed);
  const recipes = Object.values(plan.recipes);
  localTrace('PrePlan: trying pre-plan: %s, recipes: %o', plan.name, j(recipes));

  const replacements = [];
  while (replacements.length < needed && recipes.length) {
    const recipe = recipes.shift();
    const { slug } = recipe;
    if (excluded[slug]) {
      localTrace('PrePlan: skipping %s due to excluded %s', slug, excluded[slug]);
      continue;
    }
    if (used.recipes[slug]) {
      localTrace('PrePlan: skipping %s due to used %s', slug, used.recipes[slug]);
      continue;
    }
    const reason = recipeConflicts({ recipePreferences, recipe, localTrace });
    if (reason) {
      localTrace('PrePlan: skipping %s because %o does not match preferences %o (%s)', recipe.label, recipe, recipePreferences, reason);
      continue;
    }
    const stillValid = true;

    // await checkRecipe({ client, recipe, localTrace });
    if (!stillValid) {
      localTrace('PrePlan: skipping %s due to uri not in Edamam anymore', slug);
      continue;
    }
    const meal = { courseId, recipe, status: { action: 'none', type: 'new' } };
    localTrace('PrePlan: adding new from %s: %o', plan.name, j(meal));
    replacements.push(meal);
    if (recipe.product) {
      used.products[recipe.product] = 'used';
      const newKeyword = k(recipe.product);
      if (newKeyword) {
        used.keywords[newKeyword] = 'used';
      }
    }
  }
  const newMeals = menu.meals.map((meal) => {
    if (meal.status.action !== 'pick') {
      return meal;
    }
    const replacement = replacements.shift();
    if (replacement) {
      return replacement;
    }
    return meal;
  });
  menu.meals = newMeals;
  localTrace('PrePlan: new menu after using pre-planned: %o', ms(j(newMeals)));
};

const populateNewFromProfileCache = async ({ courseId, menu, recipePreferences, excluded, used, localTrace, client }) => {
  const needed = menu.meals.filter((m) => m.status.action === 'pick' && m.status.type === 'new').length;
  if (needed === 0) {
    localTrace('Profile cache: no more new meals needed. Menu: %o', j(menu));
    return;
  }
  localTrace('Profile cache: needed: %d', needed);
  const diets = [...(recipePreferences.diet || [])];
  const healths = [...(recipePreferences.health || [])];
  localTrace('Profile cache: diets: %o, healths: %o, rp: %o', j(diets), j(healths), recipePreferences);
  const { gourmet, simple } = recipePreferences;
  const replacements = [];
  const permutations = [];
  permutations.push({ diet: diets, health: healths, gourmet, simple });
  localTrace('Profile cache: first perm: %o', j(permutations));
  const usedHealths = [...healths];
  while (usedHealths.length) {
    usedHealths.shift();
    permutations.push({ diet: diets, health: [...usedHealths], gourmet, simple });
  }
  localTrace('Profile cache: permutations after healths: %o', j(permutations));
  const usedDiets = [...diets];
  while (usedDiets.length) {
    usedDiets.shift();
    permutations.push({ diet: [...usedDiets], health: healths, gourmet, simple });
    const usedHealths = [...healths];
    while (usedHealths.length) {
      usedHealths.shift();
      permutations.push({ diet: [...usedDiets], health: [...usedHealths], gourmet, simple });
    }
  }
  localTrace('Profile cache: permutations after diets: %o', j(permutations));
  permutations.push({ gourmet, simple });
  permutations.push({ gourmet, simple: !simple });
  // If they don't want gourmet, never show it to them
  if (gourmet) {
    permutations.push({ gourmet: !gourmet, simple });
    permutations.push({ gourmet: !gourmet, simple: !simple });
  }

  localTrace('Profile cache: permutations: %o', j(permutations));
  const byKey = {};
  while (replacements.length < needed && permutations.length) {
    const permutation = permutations.shift();
    const key = utils.recipePreferencesToKey(permutation);
    localTrace('Profile cache: trying %o, key: %s', j(permutation), key);
    let profileRecipes = await dbLoader({ path: `/profiles/${key}/recipes`, origin: 'fillMeals' });
    if (!byKey[key] && (!profileRecipes || Object.keys(profileRecipes).length === 0)) {
      byKey[key] = true;
      localTrace('Profile cache: main profile is empty so populating due to timing issue.');
      await populateOneProfileRecipeCache({ db: firebase.database(), recipePreferences });
      profileRecipes = await dbLoader({ path: `/profiles/${key}/recipes`, origin: 'fillMeals' });
    }
    if (!profileRecipes || Object.keys(profileRecipes).length === 0) {
      localTrace('Profile cache: no recipes for permutation key: %s', key);
      continue;
    }
    localTrace('Profile cache: got %d keywords for permutation key: %s', Object.keys(profileRecipes).length, key);
    const localProducts = { ...used.products };
    const localKeywords = { ...used.keywords };
    for (let pass = 0; pass < 4; ++pass) {
      localTrace('Profile cache: pass: %d', pass);
      const availableProducts = shuffle(randomizedProducts);
      while (availableProducts.length && replacements.length < needed) {
        const currentProduct = availableProducts.shift();
        if (pass < 3 && veggieOnly({ recipePreferences, product: currentProduct, localTrace })) {
          // If we're desperate on pass 3, we'll show it to them
          // Note: using original recipe preferences, not current permutation
          localTrace('Skipping product %s because it is vegetarian and we eat meat: %o', currentProduct, recipePreferences);
          continue;
        }
        if (pass < 2 && localProducts[currentProduct]) {
          localTrace('Skipping product %s on pass %d due to used', currentProduct, pass);
          continue;
        }
        const keyword = k(currentProduct);
        if (keyword && pass < 1 && localKeywords[keyword]) {
          localTrace('Skipping keyword %s on pass %d due to used', keyword, pass);
          continue;
        }
        const recipes = { ...profileRecipes[currentProduct] };
        localTrace('Profile cache: recipes for %s: %d', currentProduct, Object.keys(recipes).length);
        while (Object.keys(recipes).length) {
          const slug = sample(Object.keys(recipes));
          const recipe = { ...recipes[slug], slug };
          delete recipes[slug];
          if (excluded[slug]) {
            localTrace('Skipping %s due to exclued %s', slug, excluded[slug]);
            continue;
          }
          if (used.recipes[slug]) {
            localTrace('Skipping %s due to used %s', slug, used.recipes[slug]);
            continue;
          }

          // checking if we already have pasta recipes in the menu
          if (isPasta(recipe) && maxPasta(menu, localKeywords)) {
            localTrace('Skipping %s due to too many pasta recipes in menu', slug);
            continue;
          }
          const stillValid = true;
          // await checkRecipe({ client, recipe, localTrace });
          if (!stillValid) {
            localTrace('Skipping %s due to uri not in Edamam anymore', slug);
            continue;
          }
          const meal = { courseId, recipe, status: { action: 'none', type: 'new' } };
          localTrace('Adding new from %s: %o', currentProduct, j(meal));
          replacements.push(meal);
          if (recipe.product) {
            localProducts[recipe.product] = 'local';
            used.products[recipe.product] = 'used';

            const newKeyword = k(recipe.product);
            if (newKeyword) {
              localKeywords[newKeyword] = 'local';
              used.keywords[newKeyword] = 'used';
            }
            // burning pasta keyword if we already have pasta dish in the menu
            if ((newKeyword !== 'Pasta') && isPasta(recipe)) {
              localKeywords.Pasta = 'local';
              used.keywords.Pasta = 'used';
            }
          }
          break;
        }
      }
    }
  }
  const newMeals = menu.meals.map((meal) => {
    if (meal.status.action !== 'pick') {
      return meal;
    }
    const replacement = replacements.shift();
    if (replacement) {
      return replacement;
    }
    return meal;
  });
  localTrace('New menu after using profile cache: %o', ms(j(newMeals)));
  menu.meals = newMeals;
};

// retrieves as many recipes as needed to get to the right number of meals
const populateNewFromGlobalCache = async ({ courseId, menu, recipePreferences, excluded, used, localTrace, client }) => {
  const needed = menu.meals.filter((m) => m.status.action === 'pick' && m.status.type === 'new').length;
  if (needed === 0) {
    localTrace('Global cache: no more new meals needed. Menu: %o', j(menu));
    return;
  }
  localTrace('Global cache: needed: %d', needed);
  const replacements = [];
  const localProducts = { ...used.products };
  const localKeywords = { ...used.keywords };
  for (let pass = 0; pass < 4; ++pass) {
    localTrace('Global cache: pass: %d', pass);
    const availableProducts = shuffle(randomizedProducts);
    while (availableProducts.length && replacements.length < needed) {
      const currentProduct = availableProducts.shift();
      if (pass < 3 && veggieOnly({ recipePreferences, product: currentProduct, localTrace })) {
        // If we're desperate on pass 3, we'll show it to them
        localTrace('Skipping product %s because it is vegetarian and we eat meat: %o', currentProduct, recipePreferences);
        continue;
      }
      if (pass < 2 && localProducts[currentProduct]) {
        localTrace('Skipping product %s on pass %d due to used', currentProduct, pass);
        continue;
      }
      localTrace('Global cache: using product %s', currentProduct);
      const keyword = k(currentProduct);
      // On first pass, try to use a different keyword each time
      if (keyword && pass < 1 && localKeywords[keyword]) {
        localTrace('Skipping keyword %s on pass %d due to used and first pass', keyword, pass);
        continue;
      }
      const globalRecipes = await dbLoader({ path: `/cache/recipes/${currentProduct}`, origin: 'fillMeals' });
      if (!globalRecipes) {
        localTrace('No global recipes for %s', currentProduct);
        continue;
      }
      localTrace('Global cache: global recipes for %s: %d', currentProduct, Object.keys(globalRecipes).length);
      const recipes = { ...globalRecipes };
      while (Object.keys(recipes).length) {
        const slug = sample(Object.keys(recipes));
        const recipe = { ...recipes[slug], slug };
        delete recipes[slug];
        if (recipe.rejected) {
          localTrace('Skipping %s due to rejected %o', slug, recipe.rejected);
          continue;
        }
        if (excluded[slug]) {
          localTrace('Skipping %s due to exclued %s', slug, excluded[slug]);
          continue;
        }
        if (used.recipes[slug]) {
          localTrace('Skipping %s due to used %s', slug, used.recipes[slug]);
          continue;
        }
        const stillValid = true;
        // await checkRecipe({ client, recipe, localTrace });
        if (!stillValid) {
          localTrace('Skipping %s due to uri not in Edamam anymore', slug);
          continue;
        }
        const meal = { courseId, recipe, status: { action: 'none', type: 'new' } };
        localTrace('Adding new from %s: %o', currentProduct, j(meal));
        replacements.push(meal);
        if (recipe.product) {
          localProducts[recipe.product] = 'local';
          used.products[recipe.product] = 'used';
          const newKeyword = k(recipe.product);
          if (newKeyword) {
            localKeywords[newKeyword] = 'local';
            used.keywords[newKeyword] = 'used';
          }
        }
        break;
      }
    }
  }
  const newMeals = menu.meals.map((meal) => {
    if (meal.status.action !== 'pick') {
      return meal;
    }
    const replacement = replacements.shift();
    if (replacement) {
      return replacement;
    }
    return meal;
  });
  localTrace('New menu after using global cache: %o', ms(j(newMeals)));
  menu.meals = newMeals;
};

/*
 * Fill the menu. There can be repeat spots and new spots. Change as few meals as possible, i.e.
 * if the recipe preferences haven't changed and we already have a full menu, just stop.
 *
  meals: [
    { recipe, type: new|repeat, replace: true|false, keyword (if not in recipe), product (if not in recipe) }
  ]

need to:
 check for meals that have replace set
 handle swaps -> recipe = pick
 handle removes -> recipe = none
 handle adds -> recipe = pick or recipe
 set events based on what's happening
 keep track of used by storing it
 */
const fillMeals = async ({ uid, email, courseId, recipePreferences, menu, client, initial = false }) => {
  const messages = [];
  const localTrace = (...args) => {
    // const [message, ...rest] = args;
    // trace(`realtime: fillMeals[${courseId}]: ${message}`, ...rest);
    const d = new Date();
    const ts = `${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}`;
    args.push(ts);
    messages.push(args);
  };
  localTrace('In FillMeals: uid: %o, rp: %o, menu: %o, initial: %o', uid, recipePreferences, j(menu.meals), initial);

  if (!menu.meals.some((m) => m.status.action === 'pick')) {
    localTrace('Returning with no meals because no meals to replace');
    return [];
  }

  const used = (await dbLoader({ uid, path: 'plan/used', origin: 'fillMeals' })) || {};
  used.keywords = used.keywords || {};
  used.products = used.products || {};
  used.recipes = used.recipes || {};

  const before = {};
  menu.meals.forEach((m) => {
    before[m.recipe.slug] = m.recipe;
    if (m.recipe.product) {
      const keyword = k(m.recipe.product);
      if (m.status.action === 'pick') {
        // We're replacing this one so allow the same one to come up randomly
        delete used.products[m.recipe.product];
        delete used.keywords[keyword];
      } else {
        used.products[m.recipe.product] = 'current';
        const keyword = k(m.recipe.product);
        used.keywords[keyword] = 'current';
      }
      used.recipes[m.recipe.slug] = 'current';
    }
  });
  localTrace('Used: %o', j(used));

  const userCache = await dbLoader({ uid, path: 'recipes', origin: 'fillMeals' });
  const { excluded = {}, repeated = {} /* liked = {}, disliked = {} */ } = (userCache || {});
  localTrace('Excluded: %o', j(excluded));
  // adds any repeats
  await populateRepeatsFromRepeats({ courseId, menu, excluded, repeated, used, localTrace, client });
  // adds from premade menus if appropriate
  const plan = await findPrePlan({ uid, email, recipePreferences, courseId, initial, localTrace });
  if (plan) {
    await populateNewFromPrePlan({ plan, courseId, uid, recipePreferences, menu, excluded, used, localTrace, initial, client });
  }
  // re-ads to menu any recipes that were on the menu incase filling just one meal
  await populateNewFromProfileCache({ courseId, recipePreferences, menu, excluded, used, localTrace, client });
  // if anything still needed grabs additional recipes
  await populateNewFromGlobalCache({ courseId, menu, excluded, used, localTrace, client });

  menu.meals.forEach((meal, idx) => {
    if (meal.status.action === 'pick') {
      meal.recipe = { slug: `position-${idx}`, label: 'No recipe found', uri: 'none' };
      meal.status.action = 'not found';
    }
  });

  await dbSet({ uid, path: 'plan/used', value: used });

  const recipeEvents = [];
  menu.meals.forEach((meal) => {
    if (!before[meal.recipe.slug]) {
      recipeEvents.push(addRecipeEvent({ action: 'viewed', recipe: meal.recipe, uid, source: 'pick' }));
    }
  });
  localTrace('events: %o', recipeEvents);
  await Promise.all(recipeEvents);
  localTrace('Final result: %o', j(menu));
  dumpTrace({ courseId, messages, uid });
  return menu;
};

const dumpTrace = ({ courseId, messages, uid }) => {
  console.group('------Fill Meals------');
  const logs = [];
  messages.forEach((args) => {
    const [message, ...rest] = args;
    trace(`fillMeals[${courseId}]: ${message} [%s]`, ...rest);
    logs.push({ message: `fillMeals[${courseId}]: ${message} [%s]`, args: rest });
  });
  const ts = timeStamp();
  // TODO: may not want to do this all the time
  firebase.database().ref(`/users/${uid}/events/fillMeals/${ts}`).set(JSON.stringify(logs));
  console.groupEnd();
};

export default fillMeals;
// export { checkRecipe };
