import React, { useEffect, useState } from 'react';

import Badge from '@material-ui/core/Badge';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { /* Home, */ MenuBook, Restaurant, Settings, Shop, /* HomeOutlined, */ MenuBookOutlined, RestaurantOutlined, SettingsOutlined, ShopOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import useNav from 'hooks/useNav';
import useAuth from 'hooks/useAuth';
import useShoppingList from 'hooks/useShoppingList';
import { trace } from 'utils';

const locations = ['/menu', '/shopping', '/recipes', '/preferences'];

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    background: theme.palette.background.default,
  },
  nav: {
    height: 'inherit',
    background: theme.palette.background.default,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  action: {
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'inherit',
    },
    [theme.breakpoints.up('sm')]: {
      '& svg': {
        width: '2.5rem',
        height: '2.5rem',
      },
    },
  },
  selected: {
    '& svg': { fill: 'orange' }
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
  selectedLabel: {
    fontWeight: 'bold',
    '& svg': { fill: 'orange' },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
}));

const currentPage = (path) => {
  if (path === '/') {
    return 0;
  }
  const p = path.split('/')[1];
  if (p === 'menu') {
    return 0;
  }
  if (p === 'shopping') {
    return 1;
  }

  /*
   * This was preventing /shop/amazon from working because it kept going back to /shopping
  if (p === 'shop') {
    return 1;
  }
  */
  if (p === 'recipes') {
    return 2;
  }
  if (p === 'preferences') {
    return 3;
  }
  return null;
};

const icons = [
  // [<Home/>, <HomeOutlined/>],
  [<Restaurant />, <RestaurantOutlined />],
  [<Shop />, <ShopOutlined />],
  [<MenuBook />, <MenuBookOutlined />],
  [<Settings />, <SettingsOutlined />],
];

const Nav = ({ bottom }) => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const history = useHistory();
  const [current, setCurrent] = useState();
  const [next, setNext] = useState(null);
  const { nav } = useNav();
  const { shoppingList } = useShoppingList();
  const { anonymous, uid } = useAuth();

  let prefs= !(anonymous||(uid==='visitor'));

  const count = Object.keys(shoppingList || {}).filter((slug) => shoppingList[slug].needed).length;
  const hide = (bottom && md) || (!bottom && !md) || current === null;

  useEffect(() => {
    const idxFromPath = currentPage(history.location.pathname);
    setCurrent(idxFromPath);
    setNext(idxFromPath);
  }, [setCurrent, history.location.pathname]);

  useEffect(() => {
    trace('Nav: next: %s, path: %s', next, locations[next], history.location.pathname);
    if (next !== null && locations[next] !== history.location.pathname) {
      history.push(locations[next]);
    }
  }, [next, history]);

  if (hide || nav.print) {
    return null;
  }

  const getIcon = (item) => (
    icons[item][current === item ? 0 : 1]
  );

  const getClasses = (item) => {
    if (bottom) {
      return { root: classes.action, selected: classes.selected, label: current === item ? classes.selectedLabel : classes.label };
    }
    if (item === current) {
      return { label: classes.selectedLabel };
    }
  };

  const handleChange = (event, newNext) => {
    setNext(newNext);
  };

  const onClick = (newNext) => () => {
    setNext(newNext);
  };

  if (bottom) {
    return (
      <BottomNavigation value={current} onChange={handleChange} showLabels className={classes.nav}>
        {/* <BottomNavigationAction label="Home" icon={getIcon(0)} classes={getClasses(0)} /> */}
        <BottomNavigationAction label="Menu" icon={getIcon(0)} classes={getClasses(0)} />
        <BottomNavigationAction
          label="ShoppingList" classes={getClasses(1)}
          icon={<Badge overlap="rectangular" badgeContent={count} color="secondary">{getIcon(1)}</Badge>}
        />
        <BottomNavigationAction label="Recipes" icon={getIcon(2)} classes={getClasses(2)} />
        {prefs&&(<BottomNavigationAction label="Preferences" icon={getIcon(3)} classes={getClasses(3)} />)}
      </BottomNavigation>
    );
  }

  return (
    <Grid container justifyContent="flex-start" spacing={4} style={{ paddingLeft: 20 }}>
      {/* <Grid item>
        <Button variant="text" startIcon={getIcon(0)} classes={getClasses(0)} onClick={onClick(0)}>Home</Button>
      </Grid> */}
      <Grid item>
        <Button variant="text" startIcon={getIcon(0)} classes={getClasses(0)} onClick={onClick(0)}>Menu</Button>
      </Grid>
      <Grid item>
        <Button
          variant="text" onClick={onClick(1)} classes={getClasses(1)}
          startIcon={<Badge overlap="rectangular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={count}>{getIcon(1)}</Badge>}
        >
          Shopping List
        </Button>
      </Grid>
      <Grid item>
        <Button variant="text" startIcon={getIcon(2)} classes={getClasses(2)} onClick={onClick(2)}>Recipes</Button>
      </Grid>
      {prefs&&(
      <Grid item>
        <Button variant="text" startIcon={getIcon(3)} classes={getClasses(3)} onClick={onClick(3)}>Preferences</Button>
      </Grid>)}
    </Grid>
  );
};

export default Nav;
