import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
import omit from 'lodash/omit';

import EditProduct from './EditProduct';

import { DELETE_GENERIC_PRODUCT, UPDATE_GENERIC_PRODUCT } from 'hooks/queries';
import { trace, traceError } from 'utils';

const AdminList = ({ dispatch, state }) => {
  const [current, setCurrent] = useState();
  const [mutation] = useMutation(UPDATE_GENERIC_PRODUCT);
  const [deleteMutation] = useMutation(DELETE_GENERIC_PRODUCT);
  const [error, setError] = useState();

  const onSave = (product) => {
    setError(null);
    mutation({
      variables: {
        id: product.id,
        product: omit(product, ['id', '__typename']),
      }
    }).then((result) => {
      dispatch({ action: 'replace', item: product });
      setCurrent(null);
    }).catch((error) => {
      setCurrent(null);
      traceError(error);
      setError('Error saving product');
    });
  };

  const onDelete = (id) => {
    setError(null);
    deleteMutation({
      variables: {
        id
      }
    }).then((result) => {
      dispatch({ action: 'remove', id });
      setCurrent(null);
    }).catch((error) => {
      traceError(error);
      setError('Error deleting product');
    });
  };

  if (current) {
    trace('current: %o', current);
  }

  const products = state.results.filter((p) => !p.newItem);

  return (
    <Grid container item direction="column" xs={11} alignItems="center" style={{ marginTop: 10, marginBottom: 50 }}>
      {error && (
        <Grid container item justifyContent="center" xs={6}>
          <Alert style={{ width: '100%', marginBottom: 10 }} severity="error">{error}</Alert>
        </Grid>
      )}
      {current && (
        <EditProduct product={current} onSave={onSave} onClose={() => setCurrent(null)} onDelete={onDelete} />
      )}
      {!products.length && (
        <Alert style={{ width: '100%', marginBottom: 10 }} severity="info">No matching products found.</Alert>
      )}
      {!!products.length && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell />
                <TableCell>Slug</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Aisle</TableCell>
                <TableCell>Default Measure</TableCell>
                <TableCell>Default Unit</TableCell>
                <TableCell align="right">Default Quantity</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row) => (
                <TableRow key={row.id} hover style={{ cursor: 'pointer' }}>
                  <TableCell>
                    <IconButton size="small" onClick={() => setCurrent(row)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.slug}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.department}</TableCell>
                  <TableCell>{row.aisle}</TableCell>
                  <TableCell>{row.defaultMeasureName}</TableCell>
                  <TableCell>{row.defaultMeasureUnit}</TableCell>
                  <TableCell align="right">{row.defaultQuantity}</TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => onDelete(row.id)}>
                      <Cancel />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

export default AdminList;
