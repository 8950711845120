import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';

import NewProduct from './NewProduct';

import useAppStyles from 'hooks/useAppStyles';
import useProductSearch from 'hooks/useProductSearch';
import useSnack from 'hooks/useSnack';

const ProductSearchForm = ({ productList, selectProduct }) => {
  const classes = useAppStyles();
  const { setSnack } = useSnack();
  const { state, dispatch, error, loading } = useProductSearch({});
  const [adding, setAdding] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (searchTerm === '') {
      setOptions([]);
      return undefined;
    }
    dispatch({ action: 'search', searchTerm });
  }, [dispatch, searchTerm]);

  useEffect(() => {
    if (state.results && state.results.length) {
      setOptions(state.results);
    } else {
      setOptions([]);
    }
  }, [state.results]);

  useEffect(() => {
    if (error) {
      setSnack({ message: 'An error occured while searching. Please try again later.', severity: 'error' });
    }
  }, [error, setSnack]);

  const addToList = (selected) => {
    if (!selected) {
      return;
    }
    const product = {
      slug: selected.slug,
      name: selected.name,
      department: selected.department || 'other',
      onMenu: false,
      fromSearch: true,
      actual: selected.defaultQuantity || 1,
      quantity: {
        measure: selected.defaultMeasureUnit || 'each',
        quantity: selected.defaultQuantity || 1,
      },
      updatedAt: new Date()
    };
    const existing = Object.values(productList).find((p) => p.name.toLowerCase() === selected.name.toLowerCase());
    if (existing) {
      setSnack({ message: `${selected.name} is already in your list` });
      const id = `product-${product.slug}`;
      // const yOffset = 0;
      const element = document.getElementById(id);
      if (element) {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        element.classList.remove(classes.pulseRow);
        void element.offsetWidth; // https://css-tricks.com/restart-css-animation/
        element.classList.add(classes.pulseRow);
      }
    } else {
      selectProduct(product);
      setSearchTerm('');
      setSnack({ message: `${selected.name} has been added to your list` });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const option = options.find((o) => o.name.toLowerCase() === searchTerm.toLowerCase());
    if (option) {
      addToList(option);
    } else {
      setAdding(true);
    }
  };

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ borderTop: '0px solid #ccc' }}>
      {adding && (
        <NewProduct name={searchTerm} addToList={addToList} handleClose={() => setAdding(false)} />
      )}
      <Grid item className={classes.grow} elevation={3}>
        <form onSubmit={submit}>
          <Autocomplete
            id="product-quick-search"
            style={{ width: '100%' }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            freeSolo
            value={searchTerm}
            onChange={(event, option) => {
              if (option && !option.newItem) {
                addToList(option);
              }
            }}
            onInputChange={(event, newInputValue) => {
              if (newInputValue !== 'Add New Item') {
                setSearchTerm(newInputValue);
              }
            }}
            classes={{ paper: classes.blend }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.stickySearch}
                variant="outlined"
                placeholder="Add items to my shopping list by name, department, or keyword..."
                fullWidth
                size="small"
              />
            )}
            renderOption={(product) => {
              if (product.newItem) {
                return (
                  <Button
                    size="small" variant="contained" endIcon={<AddIcon />} color="primary"
                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); setAdding(true); }}
                  >
                    Add New Item
                  </Button>
                );
              }
              return product.name;
            }}
          />
        </form>
        {loading && (
          <LinearProgress />
        )}
      </Grid>
    </Grid>
  );
};

export default ProductSearchForm;
