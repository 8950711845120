import firebase from 'firebase/app';

import 'firebase/database';
import { trace, traceError } from 'utils';

const dbSet = async ({ uid, path, value }) => new Promise((resolve, reject) => {
  try {
    trace('set: %o %o', path, value);
    const ref = path[0] === '/' ? path : `/users/${uid}/${path}`;
    firebase.database().ref(ref).set(value, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve(value);
      }
    });
  } catch (err) {
    traceError(err);
    reject(err);
  }
});

const dbUpdate = async ({ uid, path, updates }) => new Promise((resolve, reject) => {
  try {
    const ref = path[0] === '/' ? path : `/users/${uid}/${path}`;
    firebase.database().ref(ref).update(updates, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve(updates);
      }
    });
  } catch (err) {
    traceError(err);
    reject(err);
  }
});

export { dbSet, dbUpdate };
