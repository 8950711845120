import React from 'react';

import Grid from '@material-ui/core/Grid';

const IngredientTextPrint = ({ classes, product }) => {
  const text = [];
  Object.values(product.recipes || []).forEach((r, idx) => {
    !!r.quantity.notes && text.push(<div key={`rn-${r.slug}-${idx}`}>{r.quantity.notes}</div>);
    !!r.quantity.text && text.push(r.quantity.text);
    text.push(
      <div key={`ingr-${r.slug}-${idx}`} style={{ marginBottom: 4 }}>&nbsp;from&nbsp;
        <span>{r.label}</span>
      </div>
    );
  });

  return (
    <Grid item container justifyContent="space-between" alignItems="flex-start" wrap="nowrap">
      <Grid item style={{ fontSize: 7 }}>
        {text.map((t, idx) => (
          <div key={`i-${idx}`}>{t}</div>
        ))}
      </Grid>
    </Grid>
  );
};

export default IngredientTextPrint;
