import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Alert from '@material-ui/lab/Alert';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import Add from './Add';
import tourSteps from './tourSteps';
import useSnack from 'hooks/useSnack';

import EdamamIcon from 'assets/Edamam_Badge_Transparent.svg';
import MealCard from 'components/meal/MealCard';
import MealSkeleton from 'components/meal/MealSkeleton';
import PreferencesSummary from 'components/preferences/PreferencesSummary';
import RecipeDialog from 'components/recipes/RecipeDialog';
import RecipeDrawer from 'components/recipes/RecipeDrawer';
import RecipeModal from 'components/recipes/RecipeModal';
import { quantityPreferences, recipePreferences } from 'data/defaultPreferences';
import useTour from 'hooks/useTour';
import fillMeals from 'hooks/utils/fillMeals';
// , { checkRecipe } from 'hooks/utils/fillMeals';
import { populateMealRecipes } from 'hooks/utils/recipeUtils';
import { j, trace } from 'utils';

const STATUS = { pick: 'pick', search: 'search', enter: 'enter' };

const TabPanel = ({ courseId, menu, currentTab, index, count, change, preview, updatePreferences, startSearch, working, email, recipPreferences, qtyPreferences, uid,updatedEaters }) => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (courseId === 'dinner') {
    trace('tab panel %s menu: %o, count: %o', courseId, j(menu), count);
  }

  if (menu.error) {
    return (
      <Grid
        container role="tabpanel" justifyContent={mobile ? 'center' : 'flex-start'}
        style={{ display: currentTab === index ? '' : 'none', paddingTop: 20, paddingLeft: 20, paddingRight: 20, position: 'relative' }}
      >
        <Grid item>
          <LinearProgress style={{ visiblity: menu.loading ? 'visible' : 'hidden' }} />
        </Grid>
        <Alert severity="error">{menu.error}</Alert>
      </Grid>
    );
  }

  return (
    <Grid
      container role="tabpanel"
      style={{ display: currentTab === index ? '' : 'none', paddingTop: 10, paddingLeft: 20, paddingRight: 20, position: 'relative' }}
    >
      <Grid item>
        <LinearProgress style={{ visiblity: menu.loading ? 'visible' : 'hidden' }} />
      </Grid>
      <Grid item xs={12} style={{ marginLeft: 10, marginBottom: 10 }}>
        {/* <Eaters courseId={courseId} eaters={menu.eaters} setEaters={setEaters} /> */}
        <PreferencesSummary currentEaters={menu.eaters} pushEaters={updatedEaters} recipePreferences={recipPreferences} quantityPreferences={qtyPreferences} courseId={courseId} updatePrefs={updatePreferences} uid={uid} />
      </Grid>
      {/* What is being displayed when menu is being generated */}
      {menu.loading && menu.meals.map((meal) => (
        <MealSkeleton key={`meal-${meal.recipe.slug}`} lg={lg} count={count} change={change} action />
      ))}
      {/* What is being displayed when adding one meal */}
      {menu.loading && menu.meals.length < count && new Array(count - menu.meals.length).fill().map((_, idx) => (
        <MealSkeleton key={`temp-${idx}`} lg={lg} count={count} change={change} action />
      ))}
      {/* Menu Display */}
      {!menu.loading && menu.meals.map((meal, idx) => (
        <MealCard
          key={`meal-${meal.recipe.slug}`} meal={meal} count={count}
          lg={lg} change={change} preview={preview} startSearch={startSearch}
          readonly={working} email={email}
        />
        
      ))}
      {/* not sure this can happen with the new way */}
      {!menu.loading && count > menu.meals.length && new Array(count - menu.meals.length).fill().map((_, idx) => (
        <MealSkeleton key={`meal-${idx}`} lg={lg} count={count} change={change} action />
      ))}
    </Grid>
  );
};

/*
 * This fills up a menu based on the quantity preferences.
 * client: the apollo client for graphql queries
 * course: the current course (dinner, lunch, breakfast)
 * setter: the state setter for the current course
 * currentRef: the current meals for the course - this has to use a ref to avoid infinite looping as we change the value
 * previousRef: ref to all meals we've suggested so we don't repeat within a session
 * uid: user id
 * recipePreferences: current recipe prefs
 * quantityPreferences: current quantity prefs
 * step: current wizard step
 */
const getEaters = (state, courseId) => (
  get(state, `quantityPreferences.${courseId}Eaters`) || get(state, 'quantityPreferences.eaters')
);

const buildMealTemplate = ({ course, quantityPreferences }) => {
  let count = 0; let
    newCount = 0;
  if (course === 'dinner') {
    count = quantityPreferences.dinnerCount;
    newCount = quantityPreferences.newDinnerCount;
  } else if (course === 'lunch') {
    count = quantityPreferences.lunchCount;
    newCount = quantityPreferences.newLunchCount;
  } else if (course === 'breakfast') {
    count = quantityPreferences.breakfastCount;
    newCount = quantityPreferences.newBreakfastCount;
  }
  const meals = [];
  for (let i = 0; i < newCount; ++i) {
    meals.push({ status: { action: 'pick', type: 'new' }, recipe: { slug: `position-${i}`, label: 'Searching...', uri: 'none' } });
  }
  for (let i = 0; i < count - newCount; ++i) {
    meals.push({ status: { action: 'pick', type: 'repeat' }, recipe: { slug: `position-${newCount + i}`, label: 'Searching...', uri: 'none' } });
  }
  return meals;
};

const Meals = ({ step, state, setState, uid, email, finishStep, enableNext }) => {
  const client = useApolloClient();
  const { setSnack } = useSnack();
  useTour({ id: 'wizard-menu', steps: tourSteps, active: step.id === 'menu', skipReference: 'plan' });
  const [dinners, setDinners] = useState({
    loading: true,
    eaters: getEaters(state, 'dinner'),
    meals: [],
  });
  const [lunches, setLunches] = useState({
    loading: true,
    eaters: getEaters(state, 'lunch'),
    meals: [],
  });
  const [breakfasts, setBreakfasts] = useState({
    loading: true,
    eaters: getEaters(state, 'breakfast'),
    meals: [],
  });
  const [currentTab, setCurrentTab] = useState(0);
  const [editing, setEditing] = useState(false);
  const [preview, setPreview] = useState();
  const [origin, setOrigin] = useState(null);
  const [working, setWorking] = useState(true);
  const [counts, setCounts] = useState({});

  const loading = dinners.loading || lunches.loading || breakfasts.loading;

  const course = ['Dinner', 'Lunch', 'Breakfast'][currentTab];
  const courseId = ['dinner', 'lunch', 'breakfast'][currentTab];

  const resetMeals = () => {
    setDinners({
      loading: true,
      eaters: getEaters(state, 'dinner'),
      meals: [],
    });
    setLunches({
      loading: true,
      eaters: getEaters(state, 'lunch'),
      meals: [],
    });
    /* breakfasts are only hand picked so no need to reset
    setBreakfasts({
      loading: true,
      eaters: getEaters(state, 'breakfast'),
      meals: [],
    }); */
  };

  useEffect(() => {
    if (dinners.loading || lunches.loading || breakfasts.loading) {
      enableNext(false);
      setWorking(true);
    } else {
      enableNext(true);
      setWorking(false);
    }
  }, [dinners.loading, lunches.loading, breakfasts.loading, enableNext, setWorking]);

  trace('in menu state.plan: %o', state.plan ? j(state.plan) : 'nope');

  useEffect(() => {
    const prefsChanged = step.lastId === 'recipePreferences' && !isEqual(state.planRecipePreferences, state.recipePreferences);
    if (step.id === 'menu' && step.entering && prefsChanged) {
      setDinners((oldDinners) => ({ ...oldDinners, loading: true }));
    }
  }, [step, state.planRecipePreferences, state.recipePreferences]);

  useEffect(() => {
    const prefsChanged = !state.needMenuRefresh || (state.needMenuRefresh !== [] && state.needMenuRefresh[0] === 'letsRefresh'); // step.lastId === 'recipePreferences' && !isEqual(state.planRecipePreferences, state.recipePreferences);
    if (step.id === 'menu' && step.entering && !state.plan && prefsChanged) {
      resetMeals();
      setCounts({
        dinner: state.quantityPreferences.dinnerCount,
        lunch: state.quantityPreferences.lunchCount,
        breakfast: state.quantityPreferences.breakfastCount,
      });
      const fnDinner = async () => {
        const { eaters } = state.quantityPreferences;
        const menu = { eaters, meals: buildMealTemplate({ course: 'dinner', quantityPreferences: state.quantityPreferences }) };
        trace('before fillmeals: %o', j(menu));
        const newMenu = await fillMeals({ client, uid, email, courseId: 'dinner', menu, recipePreferences: state.recipePreferences, initial: true });
        trace('after fillmeals: %o', j(newMenu));
        setDinners(menu);
      };
      fnDinner();
      const fnLunch = async () => {
        const { eaters } = state.quantityPreferences;
        const menu = { eaters, meals: buildMealTemplate({ course: 'lunch', quantityPreferences: state.quantityPreferences }) };
        trace('before fillmeals: %o (%s)', j(menu), 'lunch');
        const newMenu = await fillMeals({ client, uid, email, courseId: 'lunch', menu, recipePreferences: state.recipePreferences, initial: true });
        trace('after fillmeals: %o (%s)', j(newMenu), 'lunch');
        setLunches(menu);
      };
      fnLunch();
      const fnBreakfast = async () => {
        const { eaters } = state.quantityPreferences;
        const menu = { eaters, meals: buildMealTemplate({ course: 'breakfast', quantityPreferences: state.quantityPreferences }) };
        trace('before fillmeals: %o (%s)', j(menu), 'breakfast');
        const newMenu = await fillMeals({ client, uid, email, courseId: 'breakfast', menu, recipePreferences: state.recipePreferences, initial: true });
        trace('after fillmeals: %o (%s)', j(newMenu), 'breakfast');
        setBreakfasts(menu);
      };
      fnBreakfast();
    }
  }, [step, setDinners, setLunches, setBreakfasts, uid, state.recipePreferences, state.quantityPreferences,
    state.plan, state.planRecipePreferences, client, setCounts, email]);

  // Update wizard state on tab change
  // I guess we are assuming this component never unmounts until they quit the wizard
  // and if they come back in, we start over.
  useEffect(() => {
    if (step.id === 'menu' && step.exiting) {
      trace('Wizard.Menu: updating state: step: %o dinners: %o', step, dinners);
      populateMealRecipes({ client, breakfasts, lunches, dinners }).then(({ filledBreakfasts, filledLunches, filledDinners }) => {
        setState((state) => {
          const plan = {
            breakfasts: filledBreakfasts.meals,
            lunches: filledLunches.meals,
            dinners: filledDinners.meals,
          };
          const qp = { ...state.quantityPreferences };
          const rp = { ...state.recipePreferences };
          qp.breakfastEaters = breakfasts.eaters;
          qp.lunchEaters = lunches.eaters;
          qp.dinnerEaters = dinners.eaters;
          if (!isEqual(state.plan, plan) || !isEqual(state.quantityPreferences, qp)) {
            state.plan = plan;
            state.quantityPreferences = qp;
            state.planRecipePreferences = rp;
            trace('Wizard.Menu: updating state to %o', state);
            return { ...state };
          }
          return state;
        });
        finishStep();
      });
    }
  }, [step, setState, breakfasts, lunches, dinners, finishStep, client]);

  const tabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  const recipPreferences = state.recipePreferences;
  const qtyPreferences = state.quantityPreferences;

  const setEaters = (eaters) => {
    // TODO: not actually doing anything with eaters as far as meals right now; only using it for quantities on ingredients
    // but maybe some day we will choose different meals based on it.
    if (courseId === 'dinner') {
      setDinners((previous) => {
        const newMenu = { ...previous, eaters };
        fillMeals({ client, uid, email, courseId: 'dinner', recipePreferences: state.recipePreferences, menu: newMenu });
        return newMenu;
      });
    } else if (courseId === 'lunch') {
      setLunches((previous) => {
        const newMenu = { ...previous, eaters };
        fillMeals({ client, uid, email, courseId: 'lunch', recipePreferences: state.recipePreferences, menu: newMenu });
        return newMenu;
      });
    } else if (courseId === 'breakfast') {
      setBreakfasts((previous) => {
        const newMenu = { ...previous, eaters };
        fillMeals({ client, uid, email, courseId: 'breakfast', recipePreferences: state.recipePreferences, menu: newMenu });
        return newMenu;
      });
    }
  }; 

  const updatePreferences = (recipPreferences, qtyPreferences, changeImpact) => {
    // updating preferences when coming back from PreferenceSummary
    if (changeImpact !== null) {
      setState({ ...state, quantityPreferences: qtyPreferences, recipePreferences: recipPreferences, needMenuRefresh: changeImpact });
    } else {
      setState({ ...state, quantityPreferences: qtyPreferences, recipePreferences: recipPreferences, needMenuRefresh: false });
    }
  };

  // slug could be the one to replace or null, meaning to add one
  // recipe could be a recipe, or 'pick', or 'none'
  /*
   * add meal (slug: null, recipe: null) -> { action: 'replace' }  # add new
   * add from search -> { slug: null, recipe } -> { action: 'replace', recipe } # add new
   * add custom-> { slug: null, recipe } -> { action: 'replace', recipe }  # add new
   * update custom -> { slug, recipe } -> { action: 'replace', recipe }
   * swap -> { slug, recipe: null } ->  { action: 'replace' }
   * delete -> { slug, recipe: 'delete' } -> just delete it
   */
  const change = async ({ slug, recipe }) => {
    trace('Menu: change %s -> %o, keys: %o', slug, recipe ? j(recipe) : null, recipe ? Object.keys(recipe) : null);
    setWorking(true);
    enableNext(false);

    let menu = courseId === 'dinner' ? dinners : (courseId === 'lunch' ? lunches : breakfasts);

    let meal;
    if (slug) {
      // replacing
      meal = menu.meals.find((m) => m.recipe.slug === slug);
    } else if (origin && origin.recipe) {
      // replacing via search
      meal = menu.meals.find((m) => m.recipe.slug === origin.recipe.slug);
    } else {
      // adding
      meal = { courseId, status: { action: 'pick', type: 'new' }, recipe: { slug: `position-${menu.meals.length}`, label: 'Searching...', uri: 'none' } };
      menu.meals.push(meal);
      setCounts((oldCounts) => ({
        ...oldCounts, [courseId]: oldCounts[courseId] + 1
      }));
      console.log('finder');
      console.log(meal);
    }

    if (recipe === 'delete') {
      // deleting
      menu.meals = menu.meals.filter((m) => m.recipe.slug !== meal.recipe.slug);
      setCounts((oldCounts) => ({
        ...oldCounts, [courseId]: oldCounts[courseId] - 1
      }));
    } else if (recipe) {
      // replacing with user choice either from search or entry
      trace('setting meal.recipe %o to recipe %o, keys: %o', j(meal), j(recipe), Object.keys(recipe));
      if (typeof recipe.ingredients !== 'object') {
        trace('Menu: checking recipe: %o', j(recipe));
        // await checkRecipe({ client, recipe, localTrace: console.trace, force: true });
      }
      meal.recipe = recipe;
      meal.status = { ...meal.status, action: 'none' };
      setSnack({ message: meal.recipe.label+' added to menu' });
    } else {
      // replacing with pick
      meal.status = { action: 'pick', type: meal.status.type || 'new' };
      menu = await fillMeals({ client, uid, email, courseId, recipePreferences: state.recipePreferences, menu });
    }

    if (courseId === 'dinner') {
      setDinners({ ...menu });
    } else if (courseId === 'lunch') {
      setLunches({ ...menu });
    } else {
      setBreakfasts({ ...menu });
    }

    setWorking(false);
    enableNext(true);
    console.log(slug);
    // if browsing keep drawer open
    if (slug !== null) setOrigin(null);
  };

  // Opens the recipe drawer
  const startSearch = (courseId) => ({ recipe }) => {
    setOrigin({ courseId, recipe, title: `Replacing: ${recipe.label}` });
  };

  // Add menu
  // pick: add new meal chosen by wizard
  // search: open the recipe drawer so they can search, then add if they pick one
  // enter: open the recipe dialog so they can enter a new recipe
  const addMeal = (action) => {
    if (action === STATUS.pick) {
      change({ slug: null });
    } else if (action === STATUS.search) {
      setOrigin({
        courseId,
        title: 'Adding Meal',
        action: ({ recipe }) => {
          trace('addMeal, setOrigin.action: %o, keys: %o', recipe, Object.keys(recipe));
          change({ slug: null, recipe });
          // setOrigin(null);
        }
      });
    } else if (action === STATUS.enter) {
      // TODO: show recipe enter modal and add recipe when done unless cancel
      setEditing(true);
    }
  };

  // Callback from add custom dialog
  const addCustomRecipe = ({ recipe }) => {
    change({ slug: null, recipe });
    setOrigin(null);
  };

  // Callback from update custom recipe dialog
  const updateCustomRecipe = ({ recipe, remove = false }) => {
    change({ slug: recipe.slug, recipe: remove ? 'delete' : { ...recipe, source: 'Guustav Custom' } });
    setPreview(null);
  };

  // Passed to recipe drawer so it knows what action to perform
  const actionProps = {
    title: undefined,
    course,
    actions: [
      { action: change, text: 'Add To Menu', course, icon: <AddCircleOutlineIcon />, ...origin }
    ],
  };
  const dinnerCount = dinners && dinners.meals && dinners.meals.length ? dinners.meals.length : counts.dinner; // state.quantityPreferences.dinnerCount;
  const lunchCount = lunches && lunches.meals && lunches.meals.length ? lunches.meals.length : counts.lunch; // state.quantityPreferences.lunchCount;
  const breakfastCount = breakfasts && breakfasts.meals && breakfasts.meals.length ? breakfasts.meals.length : counts.breakfast; // state.quantityPreferences.breakfastCount;

  trace('counts: %o', counts);

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Grid container justifyContent="space-between">
          <Grid item style={{ root: { flexGrow: 1 } }}>
            <Tabs value={currentTab} onChange={tabChange} aria-label="menu course tabs">
              <Tab label={`Dinners (${dinnerCount})`} />
              <Tab label={`Lunches (${lunchCount})`} />
              <Tab label={`Breakfasts (${breakfastCount})`} />
            </Tabs>
          </Grid>
          <Grid><img alt="Edamam attribution" style={{ height: 20 }} src={EdamamIcon} /></Grid>
        </Grid>
      </AppBar>

      {/* Fab for add */}
      {!loading && !working && (
        <Add course={course} add={addMeal} />
      )}
      <TabPanel
        currentTab={currentTab} index={0} courseId="dinner" menu={dinners} count={dinnerCount}
        change={change}
        updatePreferences={updatePreferences} preview={setPreview} startSearch={startSearch('dinner')} working={working} email={email}
        recipPreferences={recipPreferences} qtyPreferences={qtyPreferences} uid={uid} updatedEaters={setEaters}
      />

      <TabPanel
        currentTab={currentTab} index={1} courseId="lunch" menu={lunches} count={lunchCount}
        change={change}
        updatePreferences={updatePreferences} preview={setPreview} startSearch={startSearch('lunch')} working={working} email={email}
        recipPreferences={recipPreferences} qtyPreferences={qtyPreferences} uid={uid} updatedEaters={setEaters}
      />

      <TabPanel
        currentTab={currentTab} index={2} courseId="breakfast" menu={breakfasts} count={breakfastCount}
        change={change}
        updatePreferences={updatePreferences} preview={setPreview} startSearch={startSearch('breakfast')} working={working} email={email}
        recipPreferences={recipePreferences} qtyPreferences={qtyPreferences} uid={uid} updatedEaters={setEaters}
      />

      {/* previews the recipe */}
      <RecipeModal key="recipe-modal" readonly={false} recipe={preview} close={() => setPreview(null)} onUpdateRecipe={updateCustomRecipe} />

      {/* add or update a custom recipe */}
      <RecipeDialog key="recipe-dialog" course={course} open={editing} setOpen={setEditing} use={addCustomRecipe} />

      {/* side drawer that to search or pick from suggestions, etc */}
      <RecipeDrawer
        key="recipe-drawer" isOpen={!!origin} close={() => setOrigin(null)}
        actionProps={actionProps}
        recipePreferences={state.recipePreferences}
        dinners={dinners}
        lunches={lunches}
        breakfasts={breakfasts}
      />
    </>
  );
};

export default Meals;
