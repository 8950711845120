import React, { useEffect, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Navigation from '@material-ui/icons/Navigation';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import ProductList from 'components/productList';
import SortMenu from 'components/productList/SortMenu';
import ProductSearchForm from 'components/productSearch/ProductSearchForm';
import inventory from 'hooks/utils/inventoryUtils';
import { trace } from 'utils';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: 4,
    paddingRight: 4,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
}));

const displayProps = {
  type: 'switch',
  recipeIcon: true,
  quantityText: true,
  notes: 'editable',
  lastPurchased: true,
  frequency: true,
  quantity: 'readonly', // readonly, none, or see if setQuantity exists
  source: 'wizard-ingredients',
};

const Pantry = ({ step, state, setState, uid }) => {
  const pageClasses = useStyles();
  const [pageState, setPageState] = useState('loading');
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [sort, setSort] = useState('By Department');
  const [due, setDue] = useState('all');

  const topListener = () => {
    setShowBackToTop(document.getElementById('plan-wizard-content').scrollTop > 200);
  };

  useEffect(() => {
    const el = document.getElementById('plan-wizard-content');
    if (el) {
      el.addEventListener('scroll', topListener);
      return () => {
        el.removeEventListener('scroll', topListener);
      };
    }
  }, []);

  useEffect(() => {
    if (uid) {
      const messages = [];
      inventory({ uid, source: 'PantryChecklist', messages }).then((inventoryChecker) => {
        setPageState((pageState) => {
          pageState = state.shoppingList || {};
          Object.values(inventoryChecker.purchases).forEach((item) => {
            messages.push(['PantryChecklist: item: %s, due: %s, frequency: %o', item.slug, item.due, item.frequency]);
            pageState[item.slug] = { ...item, ...pageState[item.slug], due: item.due, frequency: item.frequency };
          });
          return pageState;
        });
        console.group();
        messages.forEach((args) => {
          const [message, ...rest] = args;
          trace(message, ...rest);
        });
        console.groupEnd();
      });
    }
  }, [uid, state.shoppingList, setPageState]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      items: Object.values(pageState || {})
        .filter((item) => !!item.needed)
        .reduce((map, item) => Object.assign(map, { [item.slug]: { ...item, needed: true } }), {})
    }));
  }, [pageState, setState]);

  const toggle = ({ products, on }) => {
    // TODO: if this is too slow, we'll use a toggle map
    setPageState((ps) => {
      products.forEach((product) => {
        ps[product.slug].needed = on;
      });
      return { ...ps };
    });
  };

  const saveNote = ({ slug, note }) => {
    const product = pageState[slug];
    setPageState((ps) => ({
      ...ps,
      [slug]: { ...product, notes: note }
    }));
  };

  const selectProduct = (product) => {
    setPageState((ps) => ({
      ...ps,
      [product.slug]: { ...product, needed: true, added: true },
    }));
  };

  const loading = pageState === 'loading';

  const dueItems = Object.values(pageState).filter((i) => i.due);
  const notDueItems = Object.values(pageState).filter((i) => (!i.due && !i.onMenu));

  const items = due === 'all' ? pageState : (due === 'due' ? dueItems : notDueItems);

  return (
    <>
      <LinearProgress style={{ display: loading ? '' : 'none' }} />
      <AppBar position="sticky" color="inherit" elevation={0} style={{ marginBottom:20}}>
        <ProductSearchForm selectProduct={selectProduct} productList={pageState} />
        <Grid container item justifyContent="space-around" alignItems="center">
          <Grid item style={{ marginTop: 10, marginBottom: 10 }}> 
            <ToggleButtonGroup size="small" value={due} exclusive onChange={(event, value) => setDue(value)} aria-label="toggle all items, due items">
              <ToggleButton value="all" aria-label="all items">
                All Items: {Object.keys(pageState).length}
              </ToggleButton>
              <ToggleButton value="due" aria-label="due items">
                Due for Purchase: {Object.keys(dueItems).length}
              </ToggleButton>
              <ToggleButton value="notDue" aria-label="due items">
                Not Due Items: {Object.keys(notDueItems).length}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        <Grid item xs={9}>
          <Typography>Select needed and non-needed items. Customize buying frequencies to suit your needs.</Typography>
        </Grid>
        <Grid item xs={1}>
          <SortMenu style={{ padding: 6 }} onSort={(sort) => setSort(sort)} sort={sort} pantryType="due" />
        </Grid>
        </Grid>
        </AppBar>
      <Grid container item direction="column" justifyContent="flex-start" alignItems="center" className={pageClasses.container}>        
        <ProductList displayProps={displayProps} products={items} sort={sort} source="pantry" toggle={toggle} uid={uid} saveNote={saveNote} />
        <Fab
          size="small" aria-label="back to top" variant="extended"
          style={{ display: showBackToTop ? '' : 'none', position: 'absolute', right: 10, bottom: 100 }}
          onClick={() => document.getElementById('plan-wizard-content').scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <Navigation />
          Back to Top
        </Fab>
      </Grid>
    </>
  );
};

export default Pantry;
