const quantityPreferences = {
  eaters: 4, /* this will get set to household.memberCount the first time */
  dinnerEaters: 4,
  lunchEaters: 4,
  breakfastEaters: 4,
  dinnerCount: 4,
  newDinnerCount: 1,
  packagedDinnerCount: 0,
  lunchCount: 0,
  newLunchCount: null, // why is this null?
  packagedLunchCount: 0,
  breakfastCount: 0,
  newBreakfastCount: 0,
  packagedBreakfastCount: 0,
};

const recipePreferences = {
  simple: true,
  gourmet: false,
};

export { quantityPreferences, recipePreferences };
