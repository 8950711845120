import { unstable_createMuiStrictModeTheme, responsiveFontSizes } from '@material-ui/core/styles';

// unstable is a new feature that avoids some warnings in
// strict mode; will chagne to non-unstable when they release it.
// see https://github.com/mui-org/material-ui/issues/13394
const theme = responsiveFontSizes(unstable_createMuiStrictModeTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      'Arial',
      'Sans',
    ],
  },
  palette: {
    primary: {
      main: 'rgb(55, 41, 51)',
      dark: 'rgb(55, 41, 51)',
    },
    secondary: {
      /* main: 'rgb(122, 206, 213)', */
      main: '#483D8B',
      dark: '#1b6c6e',
    },
    background: {
      paper: 'white',
      /* default: 'rgb(235, 231, 227)', */
      default: '#f8f8f8'
    },
    action: {
    },
    error: {
      main: '#c80000',
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#F8F8F8'
      }
    }
  }
}), { breakpoints: ['xs', 'xm', 'md', 'lg'], factor: 3 });

export default theme;
