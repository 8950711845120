import React, { useContext, useState } from 'react';

import { trace } from 'utils';

const PausedContext = React.createContext(false);
const PausedContextProvider = ({ children }) => {
  const [paused, setPaused] = useState(false);
  const ref = React.useRef(paused);
  ref.current = paused;
  const set = (p) => {
    trace('usePause: setting paused to: %o', p);
    ref.current = p;
    setPaused(p);
  };
  trace('usePause, pause: %d, ref: %o', paused, ref.current);
  return <PausedContext.Provider value={[ref, set]}>{children}</PausedContext.Provider>;
};

const usePause = () => useContext(PausedContext);

export default usePause;
export { PausedContextProvider };
