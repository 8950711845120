import React, { useContext, useCallback, useMemo, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';

import useAuth from './useAuth';
import useSnack from './useSnack';

import dbWatcher from 'hooks/utils/dbWatcher';
import { addRecipeEvent } from 'hooks/utils/events';
import { timeStamp, traceError } from 'utils';

const RepeatsContext = React.createContext({});

const initialState = { loading: true };

const RepeatsContextProvider = ({ children }) => {
  const { uid } = useAuth();
  const { setSnack } = useSnack();
  const [repeats, setRepeats] = useState(initialState);
  const [saved, setSaved] = useState(initialState);

  dbWatcher({ uid, path: 'recipes/repeated', set: setRepeats, source: 'hooks/useRepeats' });
  dbWatcher({ uid, path: 'recipes/saved', set: setSaved, source: 'hooks/useRepeats' });

  const loading = repeats.loading || saved.loading;

  const repeat = useCallback(({ course, recipe }) => {
    if (repeats.loading || !uid) {
      return;
    }
    addRecipeEvent({ action: 'repeated', recipe, uid, source: 'repeats' });
    const updates = {
      [`recipes/repeated/${recipe.slug}`]: { ...recipe, course: course || recipe.course || '', time: timeStamp() },
    };
    const ref = firebase.database().ref(`/users/${uid}`);
    ref.update(updates, (error) => {
      if (error) {
        traceError(error);
        setSnack({ message: 'There was a problem adding that recipe to your repeats', severity: 'error' });
      } else {
        setSnack({ message: `${recipe.label} was added to your rotation` });
      }
    });
  }, [repeats.loading, uid, setSnack]);

  const unrepeat = useCallback((recipe) => {
    if (repeats.loading || !uid) {
      return;
    }
    addRecipeEvent({ action: 'unrepeated', recipe, uid, source: 'repeats' });
    const updates = {
      [`recipes/repeated/${recipe.slug}`]: null,
    };
    const ref = firebase.database().ref(`/users/${uid}`);
    ref.update(updates, (error) => {
      if (error) {
        traceError(error);
      }
    });
  }, [repeats.loading, uid]);

  const save = useCallback(({ course, recipe }) => {
    if (saved.loading || !uid) {
      return;
    }
    addRecipeEvent({ action: 'saved', recipe, uid, source: 'repeats' });
    const updates = {
      [`recipes/saved/${recipe.slug}`]: { ...recipe, course: course || recipe.course || '', time: timeStamp() },
    };
    const ref = firebase.database().ref(`/users/${uid}`);
    ref.update(updates, (error) => {
      if (error) {
        traceError(error);
        setSnack({ message: 'There was a problem saving that recipe', severity: 'error' });
      } else {
        setSnack({ message: `${recipe.label} was saved for later` });
      }
    });
  }, [saved.loading, uid, setSnack]);

  const unsave = useCallback((recipe) => {
    if (saved.loading || !uid) {
      return;
    }
    addRecipeEvent({ action: 'unsaved', recipe, uid, source: 'repeats' });
    const updates = {
      [`recipes/saved/${recipe.slug}`]: null,
    };
    const ref = firebase.database().ref(`/users/${uid}`);
    ref.update(updates, (error) => {
      if (error) {
        traceError(error);
      }
    });
  }, [saved.loading, uid]);

  const value = useMemo(
    () => ({ loading, repeats: repeats.value || {}, saved: saved.value, repeat, unrepeat, save, unsave }),
    [loading, repeats.value, saved.value, repeat, unrepeat, save, unsave]
  );
  return <RepeatsContext.Provider value={value}>{children}</RepeatsContext.Provider>;
};

const useRepeats = () => useContext(RepeatsContext);

export default useRepeats;
export { RepeatsContextProvider };
