import React from 'react';

import Grid from '@material-ui/core/Grid';

import ProductCard from './ProductCard';

const SearchResults = ({ ...props }) => {
  const { state } = props;
  const searchResults = state.results;

  return (
    <Grid key="search-results" container item direction="column" alignItems="center" style={{ padding: '0 2% 0 2%', border: '0px solid red' }}>
      <Grid container item justifyContent="space-between">
        <Grid>
          <h2>{state.searchCount} Product{state.searchCount === 1 ? '' : 's'} found for <em>&quot;{state.searchTerm}&quot;</em></h2>
        </Grid>
      </Grid>
      <Grid container item spacing={3} justifyContent="flex-start" alignItems="stretch" style={{ border: '0px solid purple' }}>
        {searchResults.map((product) => (
          <Grid id={product.slug} key={`sr-${product.slug}`} container alignItems="stretch" item xs={2} style={{ border: '0px solid blue' }}>
            <ProductCard
              product={product}
              {...props}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default SearchResults;
