import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import padStart from 'lodash/padStart';
import uniq from 'lodash/uniq';

import dbLoader from 'hooks/utils/dbLoader';

const nameSort = async ({ products }) => {
  const sorted = Object.values(products).sort((a, b) => a.name.localeCompare(b.name));
  return sorted;
};

const dueSort = async ({ products }) => {
  const sorted = Object.values(products).sort((a, b) => {
    if (a.added && !b.added) {
      return -1;
    } if (b.added && !a.added) {
      return 1;
    }
    const dueSort = Math.sign(b.due - a.due);
    if (dueSort === 0) {
      const dateSort = Math.sign(b.lastPurchased, a.lastPurchased);
      if (dateSort === 0) {
        return a.name.localeCompare(b.name);
      }
      return dateSort;
    }
    return dueSort;
  });
  return sorted;
};

const likelySort = async ({ products, source }) => {
  const likely = await nameSort({ products: Object.values(products).filter((p) => !!p.likelyHave), source }) || [];
  const unlikely = await departmentSort({ products: Object.values(products).filter((p) => !p.likelyHave), source }) || {};
  return {
    likely, unlikely
  };
};

/*
 * Given a list of items, sort them into a map of { department: [items] }
 */

const departmentKey = (item, departments) => {
  const dept = (item.department && departments[item.department]) || { name: 'Other', order: 999999 };
  return `${padStart(dept.order, 10, '0')}:${dept.name}`;
};

const departmentSort = async ({ products, source }) => {
  const departments = await dbLoader({ path: '/global/departments', source: `${source} -> productList/utils/sortIntoDepartments` });
  const uniqueDepartments = uniq(Object.values(products || {})
    .map((item) => departmentKey(item, departments)))
    .sort().reduce((map, department) => Object.assign(map, { [department.split(':')[1]]: [] }), {});

  const map = Object.values(products)
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce((map, item) => {
      const key = departmentKey(item, departments).split(':')[1];
      map[key].push(item);
      return map;
    }, uniqueDepartments);

  return map;
};

const recipeKey = (item) => (
  item.recipes && Object.keys(item.recipes).length ? Object.values(item.recipes)[0].label : '~'
);

const recipeSort = async ({ products }) => {
  const uniqueRecipes = uniq(Object.values(products || {})
    .map((item) => recipeKey(item)))
    .sort().reduce((map, recipe) => Object.assign(map, { [recipe.replace('~', 'None')]: [] }), {});

  const map = Object.values(products)
    .sort((a, b) => a.name.localeCompare(b.name))
    .reduce((map, item) => {
      const key = recipeKey(item).replace('~', 'None');
      map[key].push(item);
      return map;
    }, uniqueRecipes);

  return map;
};

const calculateIncrement = (product) => {
  let i = 1;
  const split = String(product.quantity.quantity).split('.')[1];
  if (!split) {
    i = 1;
  } else if (split.length === 1 && split[0] === '5') {
    i = 0.5;
  } else if (split.length === 1) {
    i = 0.1;
  } else {
    i = 0.25;
  }
  return i;
};

const isInventory = (product) => {
  if (!product.recipes) {
    return true;
  }
  return product.quantity.quantity < product.actual;
};

const recipeList = (product) => {
  const recipes = Object.values(product.recipes || {});
  if (recipes.length === 1) {
    return <p>On recipe:<br />&nbsp;&nbsp;{recipes[0].label}</p>;
  }
  return (
    <>
      <h2>On Recipes:</h2>
      {recipes.map((recipe, idx) => (
        <p key={`recipe-${idx}`} style={{ marginRight: 8 }}>{recipe.label}</p>
      ))}
    </>
  );
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export { calculateIncrement, isInventory, recipeList, HtmlTooltip, dueSort, nameSort, departmentSort, recipeSort, likelySort };
