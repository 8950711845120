import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SearchIcon from '@material-ui/icons/Search';
import dayjs from 'dayjs';
import { useHistory, Link } from 'react-router-dom';

import useAppStyles from 'assets/useStyles';
import RecipeGridList from 'components/recipes/RecipeGridList';
import useAuth from 'hooks/useAuth';
import { dbUpdate } from 'hooks/utils/dbUpdater';
import { searchRecipes } from 'hooks/utils/recipeUtils';

const Results = ({ results }) => (
  <div>
    {Object.entries(results).map(([keyword, recipes]) => (
      <div key={keyword}>
        <h3>{keyword}</h3>
        <RecipeGridList recipes={recipes} />
      </div>
    ))}
  </div>
);

const Suggested = () => {
  const history = useHistory();
  const classes = useAppStyles();
  const theme = useTheme();
  const [input, setInput] = useState('Chicken,Beef,Stir Fry,Pasta,Vegetarian,Keto,Sheet Pan/One Pan,Mediterranean');
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const { admin } = useAuth();

  useEffect(() => {
    if (!admin) {
      alert('You are not allowed to do this.');
      history.push('/');
    }
  }, [admin, history]);

  const onSubmit = async () => {
    setLoading(true);
    const map = {};
    const updates = {};
    const ts = dayjs().format('YYYY-MM-DD');
    const fn = async (term) => {
      const result = await searchRecipes({ term, curated: true, page: 0, limit: 10, fullMeals: true });
      if (result.recipes.length) {
        map[term] = result.recipes;
        updates[`${ts} ${term}`] = {
          defaultForNewUsers: false,
          name: `${ts} ${term}`,
          suggestionPlan: false,
          suggestionText: term,
          uses: 0,
          recipes: result.recipes.reduce((h, r) => Object.assign(h, { [r.slug]: r }), {})
        };
      }
    };
    const promises = input.split(',').map((keyword) => fn(keyword));
    await Promise.all(promises);
    setResults(map);
    await dbUpdate({ path: '/cache/plans/', updates, source: 'admin/Suggestions' });
    setLoading(false);
  };

  return (
    <Grid container direction="column" xs={11} alignItems="center" style={{ marginTop: 10 }}>
      <Grid container direction="row" justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Link to="/admin/review">Recipe Management</Link>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs="11" style={{ marginTop: 20 }}>
          <form onSubmit={onSubmit}>
            <TextField
              fullWidth
              value={input || ''}
              onChange={(ev) => setInput(ev.target.value)}
              className={classes.input}
              placeholder="Search for recipes ..."
              inputProps={{ 'aria-label': 'search recipes' }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {mobile && (
                      <IconButton edge={false} onClick={onSubmit}><SearchIcon /></IconButton>
                    )}
                    {!mobile && (
                      <Button variant="contained" onClick={onSubmit} color="primary" startIcon={<SearchIcon />}>
                        Generate
                      </Button>
                    )}
                  </InputAdornment>
                )
              }}
            />
            {loading && <Grid item style={{ marginTop: 10 }}><LinearProgress /></Grid>}
            {Object.keys(results).length > 0 && (
              <Results results={results} />
            )}
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Suggested;
