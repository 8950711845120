import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import callChatGPTFunction from 'utils/chatgptUtils';
import { CircularProgress, Typography, Grid } from '@material-ui/core';

const RecipeChatGPTDialog = ({ recipe, open, setOpen }) => {
  const [gptInstructions, setGPTInstructions] = useState([]);
  const [searching,setSearching]=useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      let prompt = '';
      // adding question for chatGPT
      prompt = 'Provide cooking instructions without additional text for ' + recipe.label;
      //creating a string with all the ingredients
      let ingrString = '';
      if (recipe.ingredients) recipe.ingredients.forEach((ingr) => { ingrString = ingrString + ingr.text + ',' });
      if (ingrString !== '') prompt = prompt + ' using only the following ingredients ' + ingrString;
      setSearching(true);
      callChatGPTFunction(prompt).then((value) => {
        setSearching(false);
        let ingrArray = value.split('\n');
        setGPTInstructions(ingrArray);
      });
    }
  }, [open, recipe]);

  console.log(gptInstructions)
  return (
    <>
      {(gptInstructions !== []) && (<Dialog open={open} onClose={handleClose}>
        <DialogTitle>ChatGPT's take on {recipe.label}:</DialogTitle>
        {searching&&(<Grid container justifyContent='center' alignItems='center' direction='column' style={{ marginTop: 15 }}>
          <Typography color="textSecondary">
            ChatGTP is thinking
          </Typography>
          <CircularProgress sx={{ color: 'blue.500' }} />
        </Grid>)}
        <DialogContent>
          {[...gptInstructions || []].map((item, idx) => (
            <div key={idx}>{item}</div>))}
        </DialogContent>
      </Dialog>)}
    </>
  );
};

export default RecipeChatGPTDialog;
