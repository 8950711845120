import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

const Breakfasts = ({ classes, state, setState }) => {
  const [text, setText] = useState(state.quantityPreferences.breakfastCount || 0);

  const update = (breakfastCount) => {
    const q = state.quantityPreferences;
    let { newBreakfastCount } = q;
    const diff = breakfastCount - q.breakfastCount;
    if (diff > 0) {
      newBreakfastCount += diff;
    }
    if (newBreakfastCount > breakfastCount) {
      newBreakfastCount = breakfastCount;
    }
    setText(breakfastCount);
    setState({
      ...state,
      quantityPreferences: {
        ...q,
        breakfastCount,
        newBreakfastCount,
      }
    });
  };

  const handleSliderChange = (event, breakfasts) => {
    update(breakfasts);
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const value = state.quantityPreferences.breakfastCount || 0;

  const handleBlur = () => {
    let breakfasts;
    if (!text || isNaN(Number(text))) {
      breakfasts = 0;
    } else {
      breakfasts = Number(text);
    }
    if (breakfasts < 0) {
      breakfasts = 0;
    } else if (breakfasts > 7) {
      breakfasts = 7;
    }
    update(breakfasts);
  };

  return (
    <Grid container item>
      <Grid item xs={1} />
      <Grid container direction="column" item xs={11}>
        <Grid item>
          <Typography id="breakfast-count" gutterBottom className={classes.label}>
            How many breakfasts do you need each week?
          </Typography>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Slider
              valueLabelDisplay="auto"
              value={typeof value === 'number' ? value : 0}
              onChange={handleSliderChange}
              aria-labelledby="breakfast-count"
              step={1}
              min={0}
              max={7}
            />
          </Grid>
          <Grid item xs>
            <Input
              className={classes.input}
              value={text}
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: 7,
                type: 'number',
                'aria-labelledby': 'breakfast-count',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Breakfasts;
