import React from 'react';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import { balances, onRecipePreferences, toggleRecipePreferences } from 'guustav-shared/dietHealth';

const DietBalancing = ({ classes, state, setState }) => {
  const toggle = (slug) => {
    const newPrefs = toggleRecipePreferences({ recipePreferences: state.recipePreferences, slug });
    setState({ ...state, recipePreferences: newPrefs });
  };

  const on = (slug) => onRecipePreferences({ recipePreferences: state.recipePreferences, slug });

  return (
    <Grid item>
      {balances.map((diet) => (
        <span key={`diet-${diet.slug}`}>
          <Tooltip placement="top" arrow title={diet.tooltip || ''}>
            <Chip
              label={diet.name}
              classes={{ root: classes.chip, deletable: classes.activeChip }}
              clickable
              size="small"
              color="secondary"
              variant={on(diet.slug) ? 'default' : 'outlined'}
              onClick={() => toggle(diet.slug)}
              onDelete={on(diet.slug) ? () => toggle(diet.slug) : undefined}
              deleteIcon={<DoneIcon />}
            />
          </Tooltip>
        </span>
      ))}
    </Grid>
  );
};

export default DietBalancing;
