import React, { useEffect, useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

import IngredientTextPrint from 'components/productList/IngredientTextPrint';
import useStyles from 'components/productList/styles';
import { volumes, weights } from 'data/measurements';
import useNav from 'hooks/useNav';
import dbLoader from 'hooks/utils/dbLoader';
import dbWatcher from 'hooks/utils/dbWatcher';

const ShoppingPrintView = ({ history }) => {
  const classes = useStyles();
  const [depts, setDepts] = useState({ loading: true });
  const [shop, setShop] = useState({ loading: true });
  const { setNav } = useNav();

  useEffect(() => {
    dbLoader({ path: '/global/departments' }).then((result) => {
      setDepts(result);
    });
  }, []);
  // dbWatcher({ path: '/global/departments', set: setDepts, source: 'pages/shopping/PurchaseHistory' });
  dbWatcher({ path: 'shoppingList', set: setShop, source: 'pages/shopping/PurchaseHistory' });

  console.log(depts);
  const departments = depts || {};
  const shoppingList = shop.value || {};

  useEffect(() => {
    setNav({ print: true, pageName: 'Shopping List' });
  }, [setNav]);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, [2000]);
  }, []);

  if (shoppingList.loading || departments.loading) {
    return null;
  }

  const byDepartment = {};
  Object.entries(shoppingList).forEach(([slug, product]) => {
    const { department } = product;
    if (byDepartment[department]) {
      if (!byDepartment[department].some((p) => p.slug === slug)) {
        byDepartment[department].push(product);
      }
    } else {
      byDepartment[department] = [product];
    }
  });

  const sortedDepartments = Object.keys(byDepartment).sort((a, b) => (
    Math.sign((departments[a] || { order: 99999 }).order - (departments[b] || { order: 99999 }).order)
  ));

  return (
    <Grid container item xs={12} sm={10} md={8} direction="column" justifyContent="flex-start" alignContent="stretch" style={{ marginTop: 20, border: '0px solid red', width: '8.5in' }}>
      {sortedDepartments.map((department, idx) => (
        <Grid item container key={`department-${department}`}>
          <Grid item style={{ marginTop: idx === 0 ? 0 : 6 }}>
            <h4 style={{ marginTop: 0, marginBottom: 2, fontSize: '8px' }}>
              {departments[department].name}
            </h4>
          </Grid>
          {Object.values(byDepartment[department])
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((product, idx) => (
              <Grid id={`product-${product.slug}`} key={`product-row-${idx}`} container item direction="column" style={{ breakInside: 'avoid' }}>
                <Grid container item direction="row" alignItems="center" xs={12} style={{ border: '1px solid #ccc' }}>
                  <Grid container item direction="column" xs={1} justifyContent="flex-start" alignContent="center" style={{ border: '0px solid red' }}>
                    <Checkbox
                      size="small"
                      checked={false}
                      color="primary"
                      name={product.name}
                    />
                  </Grid>

                  <Grid
                    container direction="column" alignItems="flex-start" justifyContent="flex-start"
                    style={{ borderRight: '1px solid #aaa', borderLeft: '1px solid #aaa', paddingLeft: 10 }} item xs={10}
                  >
                    <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <h4 style={{ margin: '0 0 0 0', marginTop: 3, marginBottom: 2, fontSize: '8px' }}>{product.name}</h4>
                        <IngredientTextPrint product={product} classes={classes} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={1} alignContent="center" style={{ borderLeft: '0px solid #ccc' }}>
                    <Grid container direction="column" item xs={12} justifyContent="center" alignContent="center" style={{ border: '0px solid red' }}>
                      <Grid item style={{ fontSize: '7px' }}>
                        {product.actual}&nbsp;
                        {(volumes[product.quantity.measure] || weights[product.quantity.measure] || { name: product.quantity.measure }).name}
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default ShoppingPrintView;
