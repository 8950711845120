import padStart from 'lodash/padStart';

import format from './format';

export { default as errorInfo } from './errorInfo';

const zeroPad = (str, length = 2) => padStart(str, length, '0');

const dateStamp = (date) => {
  const today = date || new Date();
  return `${today.getFullYear()}-${zeroPad(today.getMonth() + 1)}-${zeroPad(today.getDate())}`;
};

const timeStamp = (date) => {
  const t = date || new Date();
  return `${t.getFullYear()}-${zeroPad(t.getMonth() + 1)}-${zeroPad(t.getDate())} ${zeroPad(t.getHours())}:${zeroPad(t.getMinutes())}`;
};

const filterObject = (object, fn) => (
  Object.entries(object)
    .filter(([key, value]) => fn([key, value]))
    .reduce((map, [key, value]) => (
      Object.assign(map, { [key]: value })
    ), {})
);

const firstNum = (nums) => {
  const first = nums.find((n) => n === 0 || n === '0' || (n && !isNaN(n)));
  if (typeof first !== 'undefined') {
    return Number(first);
  }
  return undefined;
};

const isQA = () => process.env.REACT_APP_ENV === 'qa';
const isNative = () => (window.GuustavNative ? 'flutter' : window.ReactNativeWebView && window.ReactNativeWebView.postMessage ? 'react' : false);
const isNativeModal = () => !!window.GuustavModal;

const nativeLog = (message) => {
  console.debug(message);
  postNativeEvent({ action: 'log', message });
};
window.nativeLog = nativeLog;

const postNativeEvent = ({ action, ...rest }) => {
  if (window.GuustavNative && window.GuustavNative.postMessage) {
    window.GuustavNative.postMessage(JSON.stringify({ action, ...rest }));
  } else if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action, ...rest }));
  }
};

const generateAccessKey = (uid) => {
  const uPart = uid.slice(0, 6);
  const randPart = [...Array(6)].map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 65)).join('');
  const datePart = Math.floor(new Date().getTime() / 60 * 1000);
  return `${uPart}-${randPart}-${datePart}`;
};

const j = (object) => JSON.parse(JSON.stringify(object));

const trace = (...args) => {
  const [message, ...rest] = args;
  if (isNative()) {
    const msg = format(message, ...rest);
    postNativeEvent({ action: 'log', message: msg });
  }
  console.debug(message, ...rest);
};

const traceError = (...args) => {
  const [message, ...rest] = args;
  if (isNative()) {
    const msg = format(message, ...rest);
    if (msg.length < 200) {
      postNativeEvent({ action: 'log', message: msg });
    }
  }
  console.error(message, ...rest);
};

export { dateStamp, filterObject, firstNum, generateAccessKey, isNative, isNativeModal, isQA, j, nativeLog, postNativeEvent, timeStamp, trace, traceError };
