import React from 'react';

import Link from '@material-ui/core/Link';

const ToggleAll = ({ displayProps, products, toggle, toggled, title }) => {
  const needed = (product) => (toggled ? toggled[product.slug] : product.needed);
  const allText = displayProps.toggleAllText === '-' ? null : displayProps.toggleAllText || 'All Needed';
  const noneText = displayProps.toggleNoneText === '-' ? null : displayProps.toggleNoneText || 'None Needed';
  const components = [];
  if (allText) {
    components.push(
      <Link
        href="#"
        key="toggle-on"
        disabled={products.every((p) => needed(p))}
        style={{ verticalAlign: 'middle' }}
        color="primary"
        onClick={(ev) => { ev.preventDefault(); toggle({ products, on: true }); }}
        aria-label={`${title} to needed`}
      >
        {allText}
      </Link>
    );
  }
  if (allText && noneText) {
    components.push(<span key="toggle-divider">&nbsp;|&nbsp;</span>);
  }
  if (noneText) {
    components.push(
      <Link
        href="#"
        key="toggle-off"
        disabled={products.every((p) => !needed(p))}
        style={{ verticalAlign: 'middle' }}
        color="primary"
        onClick={(ev) => { ev.preventDefault(); toggle({ products, on: false }); }}
        aria-label={`${title} to not needed`}
      >
        {noneText}
      </Link>
    );
  }
  return components;
};

export default ToggleAll;
