import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

import { firstNum } from 'utils';

const NewDinners = ({ classes, state, setState }) => {
  const [text, setText] = useState(firstNum([state.quantityPreferences.newDinnerCount, state.quantityPreferences.dinnerCount, 4]));
  const dinnerCount = firstNum([state.quantityPreferences.dinnerCount, 4]);

  useEffect(() => {
    const newDinnerCount = firstNum([state.quantityPreferences.newDinnerCount, state.quantityPreferences.dinnerCount, 4]);
    setText(newDinnerCount);
  }, [state.quantityPreferences.newDinnerCount, state.quantityPreferences.dinnerCount]);

  const update = (newDinnerCount) => {
    setState((state) => ({
      ...state, quantityPreferences: { ...state.quantityPreferences, newDinnerCount }
    }));
    setText(newDinnerCount);
  };

  const handleSliderChange = (event, newDinners) => {
    update(newDinners);
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const value = Number(text);

  const handleBlur = () => {
    let newDinnerCount;
    if (text === '' || isNaN(text)) {
      newDinnerCount = 0;
    } else {
      newDinnerCount = Number(text);
    }
    if (newDinnerCount > dinnerCount) {
      newDinnerCount = dinnerCount;
    }
    update(newDinnerCount);
  };

  return (
    <Grid container item>
      <Grid item xs={1} />
      <Grid container direction="column" item xs={11}>
        <Grid item>
          <Typography id="new-dinner-count" gutterBottom className={classes.label}>
            How many <em>new</em> dinner recipes do you like to try each week?
          </Typography>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Slider
              valueLabelDisplay="auto"
              value={value}
              onChange={handleSliderChange}
              aria-labelledby="new-dinner-count"
              step={1}
              min={0}
              max={dinnerCount}
            />
          </Grid>
          <Grid item xs>
            <Input
              className={classes.input}
              value={text}
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: dinnerCount,
                type: 'number',
                'aria-labelledby': 'new-dinner-count',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewDinners;
