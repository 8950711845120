import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import MealRating from './MealRating';

import { dbUpdate } from 'hooks/utils/dbUpdater';
import { timeStamp, trace } from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 8,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
}));

const Feedback = ({ step, state, setState, uid, setRecipeEvents, enableNext, finishStep }) => {
  const pageClasses = useStyles();
  const [pageState, setPageState] = useState('loading');
  const [meals, setMeals] = useState([]);

  useEffect(() => {
    const plan = state.lastPlan;
    if (step.id === 'feedback' && plan && step.entering) {
      trace('Loading previous feedback: %o', plan.feedback);
      const meals = [].concat(plan.dinners, plan.lunches, plan.breakfasts).filter((m) => !!m);
      if (plan.feedback) {
        setPageState(plan.feedback);
      } else if (meals.length) {
        const feedback = meals.reduce((map, meal) => {
          if (state.repeats[meal.recipe.slug]) {
            Object.assign(map, { [meal.recipe.slug]: 'repeated' });
          } else if (state.likes[meal.recipe.slug]) {
            Object.assign(map, { [meal.recipe.slug]: 'liked' });
          } else if (state.dislikes[meal.recipe.slug]) {
            Object.assign(map, { [meal.recipe.slug]: 'disliked' });
          }
          return map;
        }, {});
        setPageState(feedback);
      } else {
        setPageState({});
      }
      setMeals(meals);
      enableNext(true);
    }
  }, [enableNext, step, state.lastPlan, setPageState, setMeals, state.repeats, state.likes, state.dislikes]);

  useEffect(() => {
    if (step.id === 'feedback' && step.exiting) {
      finishStep();
    }
  }, [step, finishStep]);

  const setValue = ({ slug, value }) => {
    setPageState({ ...pageState, [slug]: value });
  };

  useEffect(() => {
    if (step.id === 'feedback' && step.exiting) {
      trace('Updating feedback: %o', pageState);
      const updates = {};
      const recipeEvents = {};
      Object.entries(pageState).forEach(([slug, value]) => {
        updates[`plan/feedback/${slug}`] = value;
        const meal = meals.find((m) => m.recipe.slug === slug);
        if (value) {
          recipeEvents[meal.recipe.slug] = { action: value, recipe: meal.recipe, uid, source: 'feedback' };
        } else if (recipeEvents[meal.recipe.slug]) {
          delete recipeEvents[meal.recipe.slug];
        }
        if (value === 'repeated') {
          updates[`recipes/repeated/${slug}`] = { ...meal.recipe, time: timeStamp() };
          updates[`recipes/disliked/${slug}`] = null;
        } else {
          updates[`recipes/repeated/${slug}`] = null;
        }
        if (value === 'liked') {
          updates[`recipes/liked/${slug}`] = { ...meal.recipe, time: timeStamp() };
          updates[`recipes/disliked/${slug}`] = null;
        } else {
          updates[`recipes/liked/${slug}`] = null;
        }
        if (value === 'disliked') {
          updates[`recipes/disliked/${slug}`] = { ...meal.recipe, time: timeStamp() };
          updates[`recipes/liked/${slug}`] = null;
          updates[`recipes/repeated/${slug}`] = null;
        } else {
          updates[`recipes/disliked/${slug}`] = null;
        }
      });
      // Wait to save feedback recipe events until they finish,
      // since they may come back in and change the answers.
      setRecipeEvents((r) => ({ ...r, ...recipeEvents }));
      updates['plan/used'] = null;
      dbUpdate({ uid, path: '', updates });
      setState((state) => ({
        ...state,
        lastPlan: { ...state.lastPlan, feedback: pageState }
      }));
    }
  }, [step, pageState, uid, setState, meals, setRecipeEvents]);

  if (step.id !== 'feedback') {
    return null;
  }

  if (pageState === 'loading') {
    return null;
  }

  return (
    <Grid container item direction="column" justifyContent="flex-start" alignItems="center" className={pageClasses.container}>

      <Grid container item sm={12} md={11} lg={10} justifyContent="flex-start" style={{ marginTop: 10 }}>
        <h2>Tell us what you thought about your previous plan.</h2>

        <Grid container item direction="column" spacing={2}>
          {Object.values(meals).map((meal) => (
            <MealRating
              key={meal.recipe.slug} meal={meal}
              value={pageState[meal.recipe.slug]}
              setValue={(value) => setValue({ slug: meal.recipe.slug, value })}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Feedback;
