import React, { useEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import firebase from 'firebase/app';
import get from 'lodash/get';
import 'firebase/database';

import Link from '@material-ui/core/Link';

import useAuth from 'hooks/useAuth';
import useNav from 'hooks/useNav';
import usePause from 'hooks/usePause';
import dbWatcher from 'hooks/utils/dbWatcher';
import { trace, traceError } from 'utils';

const Debug = ({ history, source }) => {
  const { uid, email, anonymous, loading } = useAuth();
  const [reset, setReset] = useState(false);
  const [paused, setPaused] = usePause();
  const { nav } = useNav();
  const [data, setData] = useState({ loading: true });
  const theme = useTheme();

  dbWatcher({ path: '', set: setData, source: `${source}:components/Debug` });
  const user = data.value || {};

  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const xl = useMediaQuery(theme.breakpoints.only('xl'));
  const ls = useMediaQuery('(orientation: landscape)');
  const po = useMediaQuery('(orientation: portrait)');

  useEffect(() => {
    if (reset) {
      setPaused(true);
    }
  }, [setPaused, reset]);

  useEffect(() => {
    if (paused && paused.current && reset) {
      firebase.database().ref(`/users/${uid}`).transaction((current) => {
        if (current === null) {
          return null;
        }
        const newData = user.account ? { account: user.account } : { anonymous: true };
        return newData;
      }, (error, committed, snapshot) => {
        if (error) {
          traceError('Error starting over: %o', error);
        } else {
          trace('After reset: %o', snapshot.val());
          setReset(null);
          setPaused(false);
          window.location = '/';
        }
      });
    }
  }, [uid, paused, setPaused, reset, user.account]);

  if (loading) {
    return null;
  }

  const debug = () => {
    trace('account: %o', user.account);
    trace('dinners: %o', get(user, 'plan.dinners', []).map((d) => d.recipe.slug));
    trace('ingredients: %o', Object.keys(user.ingredients || {}).join('|'));
    trace('recipes: %o', Object.keys(user.recipes || {}).join('|'));
    trace('shoppingList: %o', Object.keys(user.shoppingList || {}).join('|'));
  };
  return (
    <div style={{ color: 'black', width: '100%', textAlign: 'center', borderTop: '1px solid black' }}>
      {anonymous ? 'guest' : (email || 'Logged Out')}
      &nbsp;&nbsp;
      <Link href="#!" underline="always" onClick={debug}>Debug</Link>
      &nbsp;&nbsp;
      <Link href="#!" underline="always" onClick={() => setReset(uid)}>Restart</Link>
      &nbsp;&nbsp;
      <span>
        {xs && ' xs'}
        {sm && ' sm'}
        {md && ' md'}
        {lg && ' lg'}
        {xl && ' xl'}
        &nbsp;&nbsp;
        {ls && ' landscape'}
        {po && ' portrait'}
        &nbsp;{nav.mode}
      </span>
    </div>
  );
};

export default Debug;
