/*
 * cache: by product, includes quarantined, approved, not seen
 * review: quarantined only, includes person and reason
 * approved: approved only, but includes all of cache not quarantined
 *
 *
 * in cache - shows up under pick for me
 *  in review/rejected - nope
 *  onQuarantine -> set reasons in review, set rejected in cache
 *  onApprove -> add to /approved
 * not in cache - shows up under let me pick
 *  in review - nope
 *  onQuarantine -> add to /review, add to cache as rejected
 *  onApprove -> add to cache, approved
 */
import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Disliked from '@material-ui/icons/ThumbDown';
import Dislike from '@material-ui/icons/ThumbDownOutlined';
import Liked from '@material-ui/icons/ThumbUp';
import Like from '@material-ui/icons/ThumbUpOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import { keywords } from 'guustav-shared';
import { useHistory } from 'react-router-dom';

import Help from './Help';
import PlanDrawer from './PlanDrawer';
import PlanRecipeFooter from './PlanRecipeFooter';
import ReviewSearch from './ReviewSearch';
import ReviewSearchBox from './ReviewSearchBox';

import useStyles from 'assets/useStyles';
import ImageDialog from 'components/ImageDialog';
import ApproveRecipeDialog from 'components/recipes/ApproveRecipeDialog';
import QuarantineRecipeDialog from 'components/recipes/QuarantineRecipeDialog';
import RecipeCard from 'components/recipes/RecipeCard';
import RecipeModal from 'components/recipes/RecipeModal';
import getApprovedRecipes from 'data/approvedRecipes';
import getRejectedRecipes from 'data/rejectedRecipes';
import useAuth from 'hooks/useAuth';
import useNav from 'hooks/useNav';
import dbLoader from 'hooks/utils/dbLoader';
import { dbSet } from 'hooks/utils/dbUpdater';
import { timeStamp, trace } from 'utils';

const products = keywords.map((k) => k.product).sort();
const PAGE_SIZE = 50;

const Review = ({ ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const { setNav } = useNav();
  const { admin, email } = useAuth();
  const [pageState, setPageState] = useState({ loading: false });
  const [preview, setPreview] = useState();
  const [image, setImage] = useState();
  const [recipeToQuarantine, setRecipeToQuarantine] = useState(null);
  const [recipeToApprove, setRecipeToApprove] = useState(null);
  const [sources, setSources] = useState(null);
  const [term, setTerm] = useState('');
  const [includeOthers, setIncludeOthers] = useState(false);
  // Every recipe in the cache
  const [cache, setCache] = useState();
  // These are quarantined but not in cache; we probably don't have a product for them
  const [rejected, setRejected] = useState(null);
  // These should be in the cache but easier to deal with this array
  const [approved, setApproved] = useState(null);
  const [approvedForProduct, setApprovedForProduct] = useState(null);
  const [keptCount, setKeptCount] = useState(null);
  const [keptForProduct, setKeptForProduct] = useState(null);
  const [plansOpen, setPlansOpen] = useState(false);
  const [plan, setPlan] = useState();
  const [profiles, setProfiles] = useState();

  const person = email ? email.replace(/[.#$[\]]/g, '_') : 'admin';

  useEffect(() => {
    if (!admin) {
      alert('You are not allowed to do this.');
      history.push('/');
    }
  }, [admin, history]);

  useEffect(() => {
    if (!admin) {
      return;
    }
    const fn = async () => {
      const data = {};
      setRejected(await getRejectedRecipes());
      setApproved(await getApprovedRecipes());
      const cachedRecipes = await dbLoader({ path: '/cache/recipes' });
      let kept = 0;
      Object.entries(cachedRecipes || {}).forEach(([product, recipes]) => {
        Object.entries(recipes || {}).forEach(([slug, recipe]) => {
          if (!recipe.rejected) {
            kept += 1;
          }
          if (data[slug]) {
            data[slug].products.push(product);
          } else {
            data[slug] = { ...recipe, slug, products: [product] };
          }
        });
      });
      setCache(data);
      setKeptCount(kept);
    };
    fn();
  }, [admin, setCache, setRejected, setApproved, setKeptCount]);

  useEffect(() => {
    setNav((nav) => ({
      stickyChildren: [
        <ReviewSearchBox key="searchBox" term={term} setTerm={setTerm} />,
        <Help
          key="help" keptCount={keptCount} keptForProduct={keptForProduct} plan={plan}
          approved={approved} approvedForProduct={approvedForProduct} showPlans={() => setPlansOpen(true)}
        />
      ],
      hideHeaderBottom: true,
      pageName: 'Admin: Review'
    }));
  }, [setNav, term, setTerm, approved, plan, approvedForProduct, keptCount, keptForProduct]);

  useEffect(() => {
    const fn = async () => {
      const rp = (await dbLoader({ path: '/profiles' })) || {};
      const shallow = Object.keys(rp).reduce((h, key) => Object.assign(h, { [key]: { user: rp[key].users } }), {});
      setProfiles(shallow);
    };
    fn();
  }, []);

  const isQuarantined = (recipe) => {
    const item = cache[recipe.slug];
    if (!item) {
      return false;
    }
    return /* includeOthers ? item.rejected : */ !!(item.rejected || {})[person];
  };

  const isApproved = (recipe) => {
    const item = cache[recipe.slug];
    if (!item) {
      return false;
    }
    return /* includeOthers ? item.approved : */!!(item.approved || {})[person];
  };

  useEffect(() => {
    if (!cache || !pageState.product) {
      return;
    }
    setPageState((currentPageState) => ({ ...currentPageState, loading: true }));
    if (pageState.product === 'all') {
      setPageState((currentPageState) => ({ ...currentPageState, cache }));
      setKeptForProduct(null);
      setApprovedForProduct(null);
    } else {
      const data = Object.entries(cache)
        .filter(([slug, recipe]) => recipe.products.includes(pageState.product))
        .reduce((h, [slug, recipe]) => Object.assign(h, { [slug]: recipe }), {});
      setPageState((currentPageState) => ({ ...currentPageState, cache: data }));
      setKeptForProduct(Object.keys(data).length);
      setApprovedForProduct(Object.values(data).filter((r) => !r.rejected && r.approved).length);
    }
  }, [pageState.product, setPageState, cache]);

  // Cache changes
  useEffect(() => {
    setPageState((currentPageState) => {
      trace('cache or filters changed: %o', currentPageState);
      if (!pageState.cache || !currentPageState.product) {
        return currentPageState;
      }
      let recipes = Object.values(pageState.cache);
      const bySource = {};
      recipes.forEach((r) => {
        if (!bySource.All) {
          bySource.All = { label: 'All', count: 0 };
        }
        bySource.All.count++;
        if (!bySource[r.source]) {
          bySource[r.source] = { label: r.source, count: 0 };
        }
        bySource[r.source].count++;
      });
      setSources(bySource);

      let newSource = pageState.source;
      if (pageState.source && pageState.source.label !== 'All') {
        recipes = recipes.filter((r) => r.source === pageState.source.label);
        if (!recipes.length) {
          newSource = bySource.All;
        }
      }
      const total = recipes.length;
      const quarantined = recipes.filter((r) => (includeOthers ? Object.keys(r.rejected || {}).length : (r.rejected || {})[person])).length;
      const approved = recipes.filter((r) => (includeOthers ? Object.keys(r.approved || {}).length : (r.approved || {})[person])).length;

      if (pageState.hideQuarantined) {
        recipes = recipes.filter((r) => (includeOthers ? !Object.keys(r.rejected || {}).length : !(r.rejected || {})[person]));
      }
      if (pageState.hideApproved) {
        recipes = recipes.filter((r) => (includeOthers ? !Object.keys(r.approved || {}).length : !(r.approved || {})[person]));
      }
      recipes = recipes.sort((a, b) => {
        if (!a || !a.label) {
          console.log('bad recipe: %o', a);
          return 1;
        }
        if (!b || !b.label) {
          console.log('bad recipe: %o', b);
          return -1;
        }
        if (currentPageState.product === 'all') {
          const p = (a.products[0] || 'Ω').localeCompare(b.products[0] || 'Ω');
          if (p !== 0) {
            return p;
          }
        }
        const l = a.label.localeCompare(b.label);
        return l;
      });
      const len = recipes.length;
      const pages = Math.floor(len / PAGE_SIZE) + 1;
      let { page } = currentPageState;
      if (page >= pages) {
        page -= 1;
        if (pages <= 1) {
          page = 1;
        }
      }
      const start = (page - 1) * PAGE_SIZE;
      const currentPage = recipes.slice(start, start + PAGE_SIZE);
      const newState = { ...currentPageState, page, pages, recipes, currentPage, loading: false, source: newSource, total, approved, quarantined };
      trace('new state: %o', newState);
      return newState;
    });
  }, [cache, setSources, setPageState, person, pageState.cache, pageState.source,
    pageState.page, pageState.hideQuarantined, pageState.hideApproved, includeOthers]);

  const updatePagination = (event, page) => {
    setPageState((currentPageState) => ({ ...currentPageState, page }));
  };

  const quarantine = async (recipe) => {
    // The popup dialog handles updating the caches, we just need to update our local
    const ts = timeStamp();
    recipe.rejected = { ...(recipe.rejected || {}), [person]: ts };
    if (typeof recipe.approved === 'object' && recipe.approved[person]) {
      delete recipe.approved[person];
    } else if (recipe.approved) {
      recipe.approved = false;
    }
    if (!recipe.product) {
      recipe.product = 'tbd';
    }
    setCache({ ...cache, [recipe.slug]: recipe });
    setKeptCount((oldCount) => oldCount - 1);
    /*
    setPageState((currentPageState) => ({
      ...currentPageState,
      cache: {
        ...currentPageState.cache,
        [recipe.slug]: recipe,
      }
    }));
    */
    setRejected((currentRejected) => ({ ...currentRejected, [recipe.slug]: recipe }));
    setApproved((currentApproved) => {
      const newApproved = { ...currentApproved };
      delete newApproved[recipe.slug];
      return newApproved;
    });
  };

  const approve = async (recipe) => {
    if (!cache[recipe.slug]) {
      setKeptCount((oldCount) => oldCount + 1);
    }
    const existing = cache[recipe.slug] || { ...recipe };
    if (!existing.approved) {
      existing.approved = {};
    }
    existing.approved[person] = timeStamp();

    setApproved((currentApproved) => ({ ...currentApproved, [recipe.slug]: existing }));
    setRejected((currentRejected) => {
      const newRejected = { ...currentRejected };
      delete newRejected[recipe.slug];
      return newRejected;
    });
    setCache({ ...cache, [recipe.slug]: existing });
    setRecipeToApprove(recipe);
  };

  const saveQuarantine = (recipe) => {
    quarantine(recipe);
    setRecipeToQuarantine(null);
  };

  const changeProduct = (ev) => {
    if (ev.target.value === pageState.product) {
      return;
    }
    setPageState({ ...pageState, product: ev.target.value || 'All', loading: true, page: 1 });
  };

  const changeSource = (ev, source) => {
    const label = source ? source.label : 'All';
    const currentLabel = pageState.source ? pageState.source.label : 'All';
    if (label === currentLabel) {
      return;
    }
    setPageState({ ...pageState, source, loading: true });
  };

  const onPlan = (recipe) => recipe && plan && plan.recipes && plan.recipes[recipe.slug];

  const toggleRecipeOnPlan = (recipe) => {
    if (onPlan(recipe)) {
      dbSet({ path: `/cache/plans/${plan.name}/recipes/${recipe.slug}`, value: null });
    } else {
      dbSet({ path: `/cache/plans/${plan.name}/recipes/${recipe.slug}`, value: recipe });
    }
  };

  const actionProps = plan
    ? {
      toggles: [
        {
          offText: 'Add to Menu',
          onText: 'Remove from Menu',
          offIcon: <RemoveCircleOutlineIcon />,
          onIcon: <AddCircleOutlineIcon />,
          on: onPlan,
          toggle: toggleRecipeOnPlan
        },
      ]
    }
    : {
      toggles: [
        {
          offText: 'Quarantine',
          onText: 'Quarantined',
          offIcon: <Dislike />,
          onIcon: <Disliked className={classes.negative} />,
          on: isQuarantined,
          toggle: (recipe) => setRecipeToQuarantine(recipe),
        },
        {
          offText: 'Keep',
          onText: 'Kept',
          offIcon: <Like />,
          onIcon: <Liked className={classes.positive} />,
          on: isApproved,
          toggle: (recipe) => setRecipeToApprove(recipe),
        },
      ]
    };

  if (pageState.loading) {
    return <LinearProgress style={{ width: '100%', marginTop: -1 }} />;
  }

  const sourceOptions = sources ? Object.values(sources).sort((a, b) => (a.label === 'All' ? -1 : b.label === 'All' ? 1 : a.label.localeCompare(b.label))) : null;

  const components = [
    <ImageDialog key="image" open={!!image} title={image ? image.label : ''} onClose={() => setImage(null)} image={image ? image.image : null} />,
    <RecipeModal key="modal" close={() => setPreview(null)} readonly recipe={preview} noToggle />,
    <ApproveRecipeDialog key="approve" recipe={recipeToApprove} open={!!recipeToApprove} onClose={() => setRecipeToApprove(null)} onSave={approve} person={person} />,
    <QuarantineRecipeDialog
      key="review" recipe={recipeToQuarantine} open={!!recipeToQuarantine} onClose={() => setRecipeToQuarantine(null)}
      onSave={saveQuarantine} person={person}
    />,
    <PlanDrawer
      key="plans" isOpen={plansOpen} close={() => setPlansOpen(false)} plan={plan} setPlan={setPlan}
      profiles={profiles} setProfiles={setProfiles} setPreview={setPreview}
    />
  ];

  if (term) {
    components.push(
      <ReviewSearch
        key="search" rejected={rejected} cache={cache}
        keptCount={keptCount} keptForProduct={keptForProduct} approved={approved} approvedForProduct={approvedForProduct}
        setRecipeToApprove={setRecipeToApprove} setRecipeToQuarantine={setRecipeToQuarantine}
        term={term} setTerm={setTerm} onPlan={onPlan}
        setPreview={setPreview} actionProps={actionProps}
        plan={plan} profiles={profiles}
      />
    );
  } else {
    components.push(
      <Grid key="container" direction="column" container style={{ paddingTop: 10, paddingBottom: 20, border: '0px solid red', width: '100%' }} alignItems="center">
        <Grid container item style={{ border: '0px solid blue' }} xs={12} spacing={1} justifyContent="center">
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id="product-label">Choose Main Ingredient</InputLabel>
              <Select
                required labelId="product-label"
                value={pageState.product || ''}
                onChange={changeProduct}
              >
                <MenuItem key="all" value="all">All Recipes</MenuItem>
                {products.map((product) => (
                  <MenuItem key={product} value={product}>{product}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5} style={{ border: '0px solid blue' }}>
            {!!Object.keys(sourceOptions || []).length && (
              <Autocomplete
                options={sourceOptions}
                value={sourceOptions.find((s) => pageState.source && s.label === pageState.source.label) || sourceOptions[0]}
                renderInput={(params) => <TextField {...params} label="Sources" />}
                getOptionSelected={(option, value) => (value ? option.label === value.label : option.label === 'All')}
                getOptionLabel={(s) => `${s.label} (${s.count})`}
                onChange={changeSource}
              />
            )}
          </Grid>
          <Grid item style={{ alignSelf: 'end' }} />
        </Grid>
        {pageState.pages === 0 && (
          <Grid item container alignItems="center">
            <Grid item container justifyContent="center" style={{ paddingTop: 20 }}>
              <h2>No recipes for {pageState.product}{pageState.source ? `/${pageState.source.label}` : ''}</h2>
            </Grid>
          </Grid>
        )}

        {pageState.product && pageState.pages > 0 && (
          <Grid item container alignItems="center">
            <Grid key={pageState.product} container item direction="column" alignItems="center" style={{ padding: '0 2% 0 2%', border: '0px solid red' }}>
              <Grid container direction="column" item justifyContent="flex-start" style={{ border: '0px solid green' }}>
                <h3>
                  Total: {pageState.total}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  Quarantined: {pageState.quarantined}
                  <Button
                    className={classes.negative} size="small"
                    onClick={() => setPageState({ ...pageState, hideQuarantined: !pageState.hideQuarantined })}
                  >
                    {pageState.hideQuarantined ? 'show' : 'hide'}
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  Approved: {pageState.approved}
                  <Button
                    className={classes.positive} size="small"
                    onClick={() => setPageState({ ...pageState, hideApproved: !pageState.hideApproved })}
                  >
                    {pageState.hideApproved ? 'show' : 'hide'}
                  </Button>
                  <FormControlLabel
                    value="top" label="Other Reviewers?" labelPlacement="start" style={{ marginLeft: 20 }}
                    control={(
                      <Checkbox
                        color="primary" name="includeOthers"
                        checked={includeOthers} onChange={(ev) => setIncludeOthers(ev.target.checked)}
                      />
                    )}
                  />
                </h3>
                <Pagination page={pageState.page} count={pageState.pages} onChange={updatePagination} />
              </Grid>
              <Grid container item spacing={3} justifyContent="flex-start" alignItems="center" className={classes.centerOnXs} style={{ paddingTop: 10, border: '0px solid orange' }}>
                {pageState.currentPage.map((recipe) => (
                  <Grid id={`sr-${recipe.slug}`} key={`sr-${recipe.slug}`} container justifyContent="center" item xs={11} sm={4} md={3} lg={2} style={{ border: '0px solid blue' }}>
                    <RecipeCard
                      recipe={recipe} actionProps={actionProps} preview={setPreview} reviewing person={includeOthers ? null : person}
                      footer={plan ? <PlanRecipeFooter recipe={recipe} profiles={profiles} />
                        : <div style={{ marginTop: -10, textAlign: 'center' }}><small>{recipe.source}</small></div>}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
  return components;
};

export default Review;
