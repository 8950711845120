import React, { useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

import dbLoad from 'hooks/utils/dbLoader';
import { dbSet, dbUpdate } from 'hooks/utils/dbUpdater';
import mergeUsers from 'hooks/utils/mergeUtils';
import { trace, traceError } from 'utils';

const Merge = ({ ...props }) => {
  const history = useHistory();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const { redirectUrl } = history.location.state;

  useEffect(() => {
    const fn = async () => {
      try {
        // Still logged in as the old one
        const anonymousUser = firebase.auth().currentUser;
        if (!anonymousUser.isAnonymous) {
          setTimeout(() => setSuccess('Your account has already been updated.'), 1500);
          return;
        }
        const oldUid = anonymousUser.uid;
        const { credential } = history.location.state;
        const fromData = await dbLoad({ uid: oldUid, path: '', source: 'account/Merge' });
        await dbSet({ uid: oldUid, path: 'merged', value: 'true', source: 'account/Merge' });
        trace('account/Merge: signing in new user');
        const authInfo = await firebase.auth().signInWithCredential(credential);
        trace('account/Merge: new user: %o', authInfo);
        const newUid = authInfo.user.uid;
        trace('account/Merge: anonymous user (old): %o, new: %s', anonymousUser, newUid);
        const toData = await dbLoad({ uid: newUid, path: '', source: 'account/Merge' });
        const updates = mergeUsers({ fromData, fromUid: oldUid, toData, toUid: newUid });
        await dbUpdate({ uid: newUid, path: '', updates });
        // Delete the auth user here, but delete the data in a firebase function.
        // We can't delete before we sign in as the other user and afterwards, we
        // don't have access to delete it. Not really sure why we have access to
        // delete the old one.
        trace('account/Merge: deleting old user');
        try {
          await anonymousUser.delete();
        } catch (ex) {
          // just in case firebase doesn't let us delete after we logged in
          // background function will delete it then
          console.error(ex);
        }
        setTimeout(() => setSuccess('Your account was successfully updated'), 1500);
      } catch (ex) {
        traceError('account/Merge: error merging: %o', ex);
        setError('There was a problem merging your accounts. Please contact support@guustav.com');
      }
    };
    fn();
  }, [history.location.state, setError, setSuccess]);

  const onContinue = () => {
    history.push(decodeURI(redirectUrl || '/'));
  };

  return (
    <Grid container item direction="column" justifyContent="center" alignItems="center" xs={8} spacing={2} style={{ marginTop: 10 }}>
      {!!error && (
        <Alert severity="error">{error}</Alert>
      )}
      {!!success && (
        <Alert severity="success">{success}</Alert>
      )}
      {!success && !error && (
        <Grid item>
          <div style={{ marginTop: 20, textAlign: 'center' }}>
            You already had a Guustav account. We are updating it with your new plan.
          </div>
          <LinearProgress />
        </Grid>
      )}
      {!!success && (
        <Grid item>
          <Button onClick={onContinue} color="primary" variant="contained" fullWidth={false}>
            Go to menu
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Merge;

/*
 *     const anonymousUser = firebase.auth().currentUser;
    return firebase.database().ref(`/users/${anonymousUser.uid}`).once('value', (snapshot) => {
      const anonymousData = snapshot.val();
      trace('Login: data for anonymous %o: %o', anonymousUser.uid, anonymousData);
      // Always assume we need a merge since they just finished the wizard and we need to copy in the result.
      setMergeData({ uid: anonymousUser.uid, data: anonymousData });
      // This is the old one. Delete the auth user here, but delete the data in a firebase function.
      // because this user no longer has permission to delete the database data.
      return firebase.database().ref(`/users/${anonymousUser.uid}/merged`).set(true, (error) => {
        if (error) {
          traceError('Login: Error removing anonymous data: %o', error);
        } else {
          trace('Login: Anonymous data set to merged. Signing in with: %o', credential);
          return firebase.auth().signInWithCredential(credential).then((authResult) => {
            trace('signed in with: %o', authResult);
            setNewUid(authResult.user.uid);
            return anonymousUser.delete().then((err) => {
              if (err) {
                traceError('Error guest user: %o', err);
              } else {
                trace('Guest user deleted');
              }
              return null;
            });
          }).catch((error) => {
            traceError('Login: Failed to sign in after merge: %o', error);
            return null;
          });
        }
*/
