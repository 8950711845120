import React from 'react';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DoneIcon from '@material-ui/icons/Done';
import { filters, onRecipePreferences, toggleRecipePreferences } from 'guustav-shared/dietHealth';

const DietaryFilters = ({ classes, state, setState }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const toggle = (slug) => {
    const newPrefs = toggleRecipePreferences({ recipePreferences: state.recipePreferences, slug });
    setState({ ...state, recipePreferences: newPrefs });
  };

  const on = (slug) => onRecipePreferences({ recipePreferences: state.recipePreferences, slug });

  const groupCount = sm ? 3 : 4;
  const arr = [];
  for (let i = 0; i < groupCount; ++i) {
    arr.push([]);
  }
  const groups = filters.reduce((arr, item, idx) => { arr[idx % groupCount].push(item); return arr; }, arr);

  return (
    <Grid container justifyContent="space-between">
      <>
        {groups.map((group, idx) => (
          <Grid key={`group-${idx}`} container direction="column" item xs={sm ? 4 : 3}>
            {group.map((diet) => (
              <span key={`diet-${diet.slug}`}>
                <Tooltip key="tooltip" arrow title={diet.tooltip || ''}>
                  <Chip
                    classes={{ root: classes.chip, label: classes.chipLabel, deletable: classes.activeChip }}
                    label={diet.name}
                    clickable
                    color="secondary"
                    size="small"
                    variant={on(diet.slug) ? 'default' : 'outlined'}
                    onClick={() => toggle(diet.slug)}
                    onDelete={on(diet.slug) ? () => toggle(diet.slug) : null}
                    deleteIcon={<DoneIcon />}
                  />
                </Tooltip>
              </span>
            ))}
          </Grid>
        ))}
      </>
    </Grid>
  );
};

export default DietaryFilters;
