import React, { useEffect } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import { useHistory, useLocation } from 'react-router-dom';

import useAccount from 'hooks/useAccount';
import useAuth from 'hooks/useAuth';
import dbLoad from 'hooks/utils/dbLoader';
import { dbUpdate } from 'hooks/utils/dbUpdater';
import { generateAccessKey, trace } from 'utils';

const Initialize = ({ ...props }) => {
  const auth = useAuth();
  const { account } = useAccount();
  const history = useHistory();
  const redirectUrl = new URLSearchParams(useLocation().search).get('r');

  useEffect(() => {
    if (auth.loading || account.loading) {
      return;
    }
    const fn = async () => {
      trace('Initialize: account: %o, auth: %o', account, auth);
      if (account.email) {
        history.push(decodeURI(redirectUrl || '/'));
      } else if (auth.ruid && auth.ruid !== auth.uid) {
        // I guess if they leave a household, we will set up their own
        // account the first time they get here after leaving.
        history.push(decodeURI(redirectUrl || '/'));
      } else if (auth.uid) {
        // Only want to do this the very first time
        let name = auth.displayName;
        const household = await dbLoad({ uid: auth.uid, path: 'household', source: 'account/Initialize' });
        let householdName = household ? household.name : null;
        if (!name) {
          // This can happen with apple if we delete the firebase account; they don't resend the name
          name = await dbLoad({ uid: auth.uid, path: 'account/name', source: 'account/Initialize' });
          if (!name) {
            // Fall back to e-mail
            name = auth.email.split('@')[0];
          }
          if (!name) {
            name = 'Member';
          }
        }
        if (!householdName) {
          const split = name.split(' ');
          if (split.length === 1) {
            householdName = `${split[0] || name} Household`;
          } else {
            householdName = `${split[1] || name} Household`;
          }
        }
        const updates = {
          anonymous: false,
          'account/email': auth.email,
          'account/name': name,
          'household/name': householdName,
        };
        const data = {};
        let match = null;
        if (household) {
          Object.entries(household.members || {}).forEach(([key, member]) => {
            if (member.uid === auth.uid || member.email === auth.email) {
              if (!match || key.length > match.length) {
                // Get the longest one since we switched to generating long ones later
                // and those are most likely to be accurate I guess.
                match = key;
              }
              Object.assign(data, { ...member });
              updates[`household/members/${key}`] = null;
            }
          });
        }
        const accessKey = match || generateAccessKey(auth.uid);
        updates[`household/members/${accessKey}`] = { ...data, uid: auth.uid, email: auth.email, name: auth.displayName, allowed: 'on' };
        trace('Initialize: updates: %o', updates);
        dbUpdate({ uid: auth.uid, path: '', updates, source: 'account/Initialize' });
      }
    };
    fn();
  }, [account, auth, history, redirectUrl]);

  return <LinearProgress />;
};

export default Initialize;
