/* This isn't currently part of the wizard. */
import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Brightness5 from '@material-ui/icons/Brightness5';
import Brightness6 from '@material-ui/icons/Brightness6';
import Brightness7 from '@material-ui/icons/Brightness7';
import isEqual from 'lodash/isEqual';

import Breakfasts from './Breakfasts';
import Dinners from './Dinners';
// import Eaters from './Eaters';
import Lunches from './Lunches';
import NewDinners from './NewDinners';
import NewLunches from './NewLunches';

import dbWatcher from 'hooks/utils/dbWatcher';
import { trace } from 'utils';

const useStyles = makeStyles((theme) => ({
  input: {
    width: 42,
  },
  label: {
    fontSize: '1.2em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1em',
    },
  },
  icon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  chip: {
    minWidth: 100,
    marginBottom: 8,
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      minWidth: 70,
      fontSize: '.7em',
      marginRight: 12,
    },
  },
  activeChip: {
    marginRight: 8,
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 30,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
}));

const Preferences = ({ state, setState, step, finishStep }) => {
  const classes = useStyles();
  /* const [permanent, setPermanent] = useState(false); */
  const [pageState, setPageState] = useState('loading');

  const { value: repeats } = dbWatcher({ path: 'recipes/repeats', source: 'wizards/plan/quantityPreferences/index' });

  useEffect(() => {
    if (step.id === 'quantityPreferences' && step.entering) {
      trace('Loading quantity preferences: %o', state.quantityPreferences);
      setPageState((pageState) => ({
        ...pageState,
        quantityPreferences: { ...state.quantityPreferences },
      }));
    }
  }, [step, setPageState, state.quantityPreferences]);

  useEffect(() => {
    if (step.id === 'quantityPreferences' && step.exiting) {
      trace('Updating quantity preferences: %o', pageState.quantityPreferences);
      setState((state) => {
        if (pageState && !isEqual(pageState.quantityPreferences, state.quantityPreferences)) {
          return { ...state, quantityPreferences: pageState.quantityPreferences };
        }
        return state;
      });
      finishStep();
    }
  }, [step, pageState, setState, finishStep]);

  /*
  const reset = () => {
    setPageState((state) => ({
      ...state,
      quantityPreferences: {...quantityPreferences },
    }));
  };
  */

  if (step.id !== 'quantityPreferences') {
    return null;
  }

  const showNew = false; //! !(Object.keys(recipes||{}).length || Object.keys(repeats||{}).length || lastPlan);

  return (
    <Grid container item direction="column" justifyContent="flex-start" className={classes.container}>
      {/*
        <Grid item container>
          <Eaters classes={classes} state={pageState} setState={setPageState} uid={uid} />
        </Grid>
      */}

      <Grid item container alignItems="flex-start">
        <Grid item xs={1}>
          <Brightness7 className={classes.icon} />
        </Grid>
        <Grid item>
          <h3 style={{ margin: 0, padding: 0 }}>Dinner</h3>
        </Grid>
      </Grid>
      <Dinners classes={classes} state={pageState} setState={setPageState} />
      {showNew && <NewDinners classes={classes} state={pageState} setState={setPageState} />}

      <Grid item container alignItems="flex-start">
        <Grid item xs={1}>
          <Brightness6 className={classes.icon} />
        </Grid>
        <Grid item>
          <h3 style={{ margin: 0, padding: 0 }}>Lunch</h3>
        </Grid>
      </Grid>
      <Lunches classes={classes} state={pageState} setState={setPageState} />
      {showNew && <NewLunches classes={classes} state={pageState} setState={setPageState} />}

      {repeats && Object.values(repeats).some((f) => f.course === 'breakfast') && (
        <>
          <Grid item container alignItems="flex-start">
            <Grid item xs={1}>
              <Brightness5 className={classes.icon} />
            </Grid>
            <Grid item>
              <h3 style={{ margin: 0, padding: 0 }}>Breakfast</h3>
            </Grid>
          </Grid>

          <Breakfasts classes={classes} state={pageState} setState={setPageState} />
        </>
      )}

    </Grid>
  );
};

export default Preferences;
