import React, { useCallback, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AddShoppingCart, List, RestaurantMenu, AttachMoney, Cancel } from '@material-ui/icons';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import Calendar from 'mdi-material-ui/Calendar';
import Lightbulb from 'mdi-material-ui/LightbulbOnOutline';
import Shepherd from 'shepherd.js';

import ConfirmCloseDialog from './ConfirmCloseDialog';
import Feedback from './feedback';
import Ingredients from './ingredients';
import Menu from './menu';
import Pantry from './pantry';
import PromoCoupons from './promoCoupons';
import QuantityPreferences from './quantityPreferences';
import QuickPicks from './quickPicks';
import RecipePreferences from './recipePreferences';
import OnlinePreferences from './online';
import Summary from './summary';
import WizardProgressBottom from './WizardProgressBottom';
import WizardProgressTop from './WizardProgressTop';

import Debug from 'components/nav/Debug';
import { quantityPreferences, recipePreferences } from 'data/defaultPreferences';
import useAuth from 'hooks/useAuth';
import { refreshUserRecipeExcluded } from 'hooks/utils/cacheUtils';
import { load } from 'hooks/utils/dbLoaderMulti';
import { dbUpdate } from 'hooks/utils/dbUpdater';
import { addEvent } from 'hooks/utils/events';
import { trace } from 'utils';
import FullWeekPlanningIntro from './FullWeekIntro';
import InventoryManagementIntro from './InventoryIntro';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';

const dummy = () => null;

const useStyles = makeStyles((theme) => ({
  inactiveStep: {
    display: 'none',
  },
  dialogPaper: {
    // height: '90vh',
    [theme.breakpoints.down('sm')]: {
      height: 'inherit',
    },
  },
  dialogHeader: {
    padding: 8,
    borderBottom: '1px solid #ccc',
    marginBottom: 0,
  },
  headerCaption: {
    fontSize: '.8rem',
  },
  dialogProgress: {
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: 0,
  },
  dialogContent: {
    /* minHeight: '50vh', */
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    /*
    [theme.breakpoints.up('md')]: {
      paddingLeft: '20vw',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10vw',
    },
    */
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8em',
    },
  },
  dialogTitle: {
    padding: 0,
  },
  dialogStepName: {
    background: 'linear-gradient(45deg, rgb(72, 61, 139)  30%, rgb(147, 112, 219) 90%)',
    color: 'white',
    fontSize: '1.0em',
    justifyContent: 'flex-start',
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  input: {
    width: 42,
  },
}));

const getSteps = (currentPlan, mode, email) => {
  const steps = [];
  // pantry mode called from the shopping list for existing user
  if (mode === 'pantry') {
    steps.push({ id: 'ingredients', label: 'Pantry Checklist', icon: <Lightbulb /> });
    return steps;
  }
  // pantry mode called for new user
  if (mode==='manageInventory'){
    steps.push({ id: 'inventoryManagementIntro', label: 'Simplified List Creation', icon: <CastForEducationIcon /> });
    steps.push({ id: 'ingredients', label: 'Shopping Checklist', icon: <List /> });  
    steps.push({ id: 'summary', label: 'Summary', icon: <AddShoppingCart /> });
    return steps;
  }
  if (mode==='fullWeekPlanning'){
    steps.push({ id: 'fullWeekPlanningIntro', label: 'Life Simplified', icon: <CastForEducationIcon /> });
    console.log('added full week planning intro');
  }
  if (currentPlan.dinners && currentPlan.dinners.length) {
    steps.push({ id: 'feedback', label: 'Feedback', icon: <ThumbsUpDownIcon /> });
  } else if (!currentPlan.recipePreferences) {
    steps.push({ id: 'recipePreferences', label: 'Food Preferences', icon: <RestaurantMenu /> });
    steps.push({ id: 'onlinePreferences', label: 'Online Stores', icon: <RestaurantMenu /> });
  }
  
  steps.push({ id: 'menu', label: 'Choose Menu', icon: <Calendar /> });
  steps.push({ id: 'ingredients', label: 'Shopping Checklist', icon: <List /> });
/*   if (!currentPlan.recipePreferences) {
    steps.push({ id: 'quickPicks', label: 'Grocery Favorites', icon: <Lightbulb /> });
  } */
  steps.push({ id: 'summary', label: 'Summary', icon: <AddShoppingCart /> });
  if (email === 'retaildemo@guustav.com') {
    const firstElement = steps.shift();
    steps.unshift(firstElement, { id: 'recipePreferences', label: 'Food Preferences', icon: <RestaurantMenu /> });
    const start = steps.length - 1;
    const deleteCount = 0;
    steps.splice(start, deleteCount, { id: 'promocoupons', label: 'Coupons and Promos', icon: <AttachMoney /> });
  }
  console.log(steps);
  console.log(mode);
  return steps;
};

const Wizard = ({ isOpen, close, mode, source }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tall = useMediaQuery('@media (min-height: 600px)');
  const { email, uid } = useAuth();
  const [steps, setSteps] = useState();
  const [step, setStep] = useState({});
  const [state, setState] = useState({});
  const [currentPlan, setCurrentPlan] = useState('loading');
  const [recipeEvents, setRecipeEvents] = useState({});
  const [closing, setClosing] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);

  const showTop = mode !== 'pantry' && !mobile && tall;
  console.log(source);
  useEffect(() => {
    if (isOpen && uid) {
      load({ uid, paths: ['household', 'plan', 'shoppingList', 'recipes/repeated', 'recipes/liked', 'recipes/disliked'], source: 'wizards/plan/index' })
        .then((result) => {
          trace('Wizard: result: %o', result);
          const steps = getSteps(result.plan || {}, mode, email);
          setSteps(steps);
          const state = {
            recipePreferences: result['household/recipePreferences'] || recipePreferences,
            quantityPreferences,
            ...(result.household || {}),
            lastPlan: result.plan,
            shoppingList: result.shoppingList,
            inventory: result.inventory,
            repeats: result['recipes/repeated'] || {},
            likes: result['recipes/liked'] || {},
            dislikes: result['recipes/disliked'] || {},
          };
          setState(state);
          setStep({ current: 0, last: null, direction: null, ...steps[0], entering: true });
          setCurrentPlan(result.plan || {});
          refreshUserRecipeExcluded({ uid });
        });
      addEvent({ action: 'startPlan', category: 'activity', uid });
    } else {
      setStep({});
      setState({ quantityPreferences, recipePreferences });
    }
  }, [mode, uid, setState, setCurrentPlan, setStep, isOpen, email]);

  useEffect(() => {
    // Every time the step changes, cancel the active tour
    if (Shepherd.activeTour) {
      Shepherd.activeTour.cancel();
    }
  }, [step.id]);

  const onClickBack = () => {
    setStep((step) => ({
      ...step,
      id: step.id,
      action: 'back',
      entering: false,
      exiting: true,
    }));
  };

  const onClickNext = () => {
    setNextEnabled(false);
    setStep((step) => ({
      ...step,
      id: step.id,
      action: 'next',
      entering: false,
      exiting: true,
    }));
  };

  const onClickGoto = (gotoId) => {
    // TODO: is this used?
    setStep((step) => ({
      ...step,
      id: step.id,
      action: 'goto',
      entering: false,
      exiting: true,
      gotoId,
    }));
  };

  const handleBack = useCallback(() => {
    setStep((oldStep) => {
      if (oldStep.current <= 0) {
        return oldStep;
      }
      trace('back, changing to %o', oldStep - 1);
      return {
        current: oldStep.current - 1,
        last: oldStep.current,
        direction: -1,
        ...steps[oldStep.current - 1],
        lastId: oldStep.id,
        entering: true,
        exiting: false,
        gotoId: null,
        action: null,
      };
    });
  }, [setStep, steps]);

  const handleNext = useCallback(() => {
    setStep((oldStep) => {
      if (oldStep.current >= steps.length - 1) {
        return oldStep;
      }
      trace('next, changing to %o', oldStep.current + 1);
      const data = steps[oldStep.current + 1];
      addEvent({ action: 'wizardNext', category: 'activity', uid, data: { step: data.label } });
      setNextEnabled(false);
      return {
        maxCompleted: Math.max(oldStep.maxCompleted || 0, oldStep.current),
        current: oldStep.current + 1,
        last: oldStep.current,
        direction: 1,
        ...steps[oldStep.current + 1],
        lastId: oldStep.id,
        entering: true,
        exiting: false,
        gotoId: null,
        action: null,
      };
    });
  }, [setStep, setNextEnabled, steps, uid]);

  const handleGoto = useCallback((gotoId) => {
    const current = steps.findIndex((s) => s.id === gotoId);
    const last = current > 0 ? steps[current - 1] : null;
    setStep((oldStep) => {
      if (current > (oldStep.maxCompleted || 0)) {
        return oldStep;
      }
      return {
        ...oldStep,
        current,
        direction: 1,
        ...steps[current],
        last,
        lastId: last ? last.id : null,
        entering: true,
        exiting: false,
        gotoId: null,
        action: null,
      };
    });
  }, [setStep, steps]);

  const finishStep = useCallback(() => {
    console.log('in finishStep: %o', step);
    if (step.action === 'next') {
      handleNext();
    } else if (step.action === 'back') {
      handleBack();
    } else if (step.action === 'goto') {
      handleGoto(step.gotoId);
    }
  }, [step, handleBack, handleGoto, handleNext]);

  const closeWizard = (path) => {
    if (Shepherd.activeTour) {
      Shepherd.activeTour.cancel();
    }
    close(path);
  };

  const startClose = () => {
    if (step.current > 0) {
      setClosing(true);
    } else {
      closeWizard();
    }
  };

  const finishClose = () => {
    setClosing(false);
    closeWizard();
  };

  const save = async () => {
    const updates = {
      shoppingList: state.items
    };
    trace('updates: %o', updates);
    await dbUpdate({ uid, path: '', updates });
    closeWizard('/shopping');
  };

  if (!isOpen) {
    return null;
  }

  if (currentPlan === 'loading') {
    return null;
  }

  trace('step: %o, currentPlan: %o', step, currentPlan);
  return [
    <ConfirmCloseDialog key="close-dialog" isOpen={closing} handleReject={() => setClosing(false)} handleConfirm={finishClose} />,
    <Dialog
      key="main-dialog" fullScreen classes={{ paper: classes.dialogPaper }}
      disableBackdropClick disableEscapeKeyDown
      open={isOpen} onClose={close} aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" onClose={close} className={classes.dialogTitle}>
        <Grid container alignItems="flex-start" justifyContent="space-between" className={classes.dialogHeader}>
          <Grid item>
            Your Planning Assistant
          </Grid>
          <Grid item container style={{ width: 'auto' }} spacing={2}>
            <Grid item>
              {mobile && (
                <IconButton onClick={startClose} style={{ padding: 0, margin: 0 }}><Cancel /></IconButton>
              )}
              {!mobile && (
                <Grid item container direction="column" alignItems="center">
                  <Button fullWidth onClick={startClose}>
                    <Grid item container direction="row" justifyContent="center" alignItems="center">
                      <Cancel />
                      <Grid item className={classes.headerCaption}>Cancel</Grid>
                    </Grid>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {showTop && (
          <Grid container item justifyContent="center" xs={12} className={classes.dialogProgress}>
            <WizardProgressTop step={step} steps={steps} mobile={mobile} onClickBack={onClickBack} onClickNext={nextEnabled ? onClickNext : null} onClickGoto={onClickGoto} />
          </Grid>
        )}
        {mobile && (
        <Grid item className={classes.dialogStepName}>
          {step.label}
        </Grid>
        )}
      </DialogTitle>
      <DialogContent id="plan-wizard-content" dividers className={classes.dialogContent}>
        <Grid container alignItems="flex-start">
          {currentPlan.dinners && currentPlan.dinners.length && (
            <Grid item container className={step.id === 'feedback' ? classes.activeStep : classes.inactiveStep}>
              <Feedback
                state={state} setState={setState} step={step} uid={uid} setRecipeEvents={setRecipeEvents}
                finishStep={finishStep} enableNext={setNextEnabled}
              />
            </Grid>
          )}
          {/* {!currentPlan.recipePreferences && ( */}
          <Grid item container className={step.id === 'recipePreferences' ? classes.activeStep : classes.inactiveStep}>
            <RecipePreferences
              state={state} setState={setState} step={step} finishStep={finishStep}
              enableNext={step.id === 'recipePreferences' ? setNextEnabled : dummy}
            />
          </Grid>
          {/* )} */}
          <Grid item container className={step.id === 'onlinePreferences' ? classes.activeStep : classes.inactiveStep}>
            <OnlinePreferences
              state={state} setState={setState} step={step} finishStep={finishStep}
              enableNext={step.id === 'onlinePreferences' ? setNextEnabled : dummy}
            />
          </Grid>

          {/* Intro for full week planning */}
          <Grid item container className={step.id === 'fullWeekPlanningIntro' ? classes.activeStep : classes.inactiveStep}>
            <FullWeekPlanningIntro
              state={state} setState={setState} step={step} finishStep={finishStep}
              enableNext={step.id === 'fullWeekPlanningIntro' ? setNextEnabled : dummy}
            />
          </Grid>
          {/* Intro for just inventory management */}
          <Grid item container className={step.id === 'inventoryManagementIntro' ? classes.activeStep : classes.inactiveStep}>
            <InventoryManagementIntro
              state={state} setState={setState} step={step} finishStep={finishStep}
              enableNext={step.id === 'inventoryManagementIntro' ? setNextEnabled : dummy}
            />
          </Grid>
          {/* Not used currently */}
          {!currentPlan.quantityPreferences && (
            <Grid item container className={step.id === 'quantityPreferences' ? classes.activeStep : classes.inactiveStep}>
              <QuantityPreferences
                state={state} setState={setState} step={step} finishStep={finishStep}
                enableNext={step.id === 'quantityPreferences' ? setNextEnabled : dummy}
              />
            </Grid>
          )}
          <Grid item container className={step.id === 'menu' ? '' : classes.inactiveStep}>
            <Menu
              state={state} setState={setState} step={step} uid={uid} email={email}
              finishStep={finishStep}
              enableNext={step.id === 'menu' ? setNextEnabled : dummy}
            />
          </Grid>
          <Grid item container className={step.id === 'ingredients' ? '' : classes.inactiveStep}>
            {mode === 'pantry' && (
              <Pantry state={state} setState={setState} uid={uid} email={email} finishStep={finishStep} enableNext={dummy} />
            )}
            {mode !== 'pantry' && (
              <Ingredients
                mode={mode} state={state} setState={setState} step={step} uid={uid}
                email={email} finishStep={finishStep}
                enableNext={step.id === 'ingredients' ? setNextEnabled : dummy}
              />
            )}
          </Grid>
          {!currentPlan.recipePreferences && (
            <Grid item container className={step.id === 'quickPicks' ? '' : classes.inactiveStep}>
              <QuickPicks
                state={state} setState={setState} step={step} uid={uid} finishStep={finishStep}
                enableNext={step.id === 'quickPicks' ? setNextEnabled : dummy}
              />
            </Grid>
          )}
          <Grid item container className={step.id === 'promocoupons' ? '' : classes.inactiveStep}>
            <PromoCoupons
              state={state} setState={setState} step={step} uid={uid} setRecipeEvents={setRecipeEvents}
              finishStep={finishStep} enableNext={setNextEnabled}
            />
          </Grid>

          <Grid item container className={step.id === 'summary' ? '' : classes.inactiveStep}>
            <Summary close={close} step={step} state={state} setState={setState} uid={uid} recipeEvents={recipeEvents} finishStep={finishStep} email={email} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="column">
          <WizardProgressBottom
            save={mode === 'pantry' ? save : undefined} step={step} steps={steps} mobile={mobile}
            onClickNext={onClickNext} onClickBack={onClickBack} nextEnabled={nextEnabled}
          />
          {window.location.port === '3000' && (
            <Debug source="wizard" />
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  ];
};

export default Wizard;
