import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import camelCase from 'lodash/camelCase';

import frequencies from 'data/frequencies';
import useAppStyles from 'hooks/useAppStyles';
import useAuth from 'hooks/useAuth';
import { dbSet } from 'hooks/utils/dbUpdater';
import dbWatcher from 'hooks/utils/dbWatcher';

const formErrors = (data) => {
  const errors = {};
  if (!data.name) {
    errors.email = 'Product name is required';
  }
  return errors;
};

const NewProduct = ({ name, addToList, handleClose, ingredient = false }) => {
  const classes = useAppStyles();
  const { uid } = useAuth();
  const [data, setData] = useState({ name, department: 'other' });
  const [frequencyChanged, setFrequencyChanged] = useState(false);
  const [depts, setDepts] = useState({ loading: true });

  dbWatcher({ path: '/global/departments', set: setDepts, source: 'components/NewProduct' });
  const departments = depts.value || {};

  const onBlur = () => {
    setData({ ...data });
  };

  const handleSave = async () => {
    data.slug = camelCase(`custom-${data.name}`);
    if (data.frequency) {
      await dbSet({ uid, path: `inventory/${data.slug}/frequency`, value: data.frequency });
    }
    addToList(data);
    handleClose();
  };

  const change = (field, value) => {
    if (field === 'department' && departments[value] && (typeof data.frequency === 'undefined' || !frequencyChanged)) {
      data.frequency = departments[value].frequency;
    }
    if (field === 'frequency') {
      setFrequencyChanged(true);
    }
    setData({ ...data, [field]: value });
  };

  const errors = formErrors(data, false);

  return (
    <Dialog open onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm" scroll="body" disableBackdropClick>
      <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
        <input type="submit" style={{ display: 'none' }} />
        <DialogTitle id="form-dialog-title">Add New Product</DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" spacing={2} style={{ padding: 8 }}>
            <Grid item>
              <TextField
                id="product-name" autoFocus fullWidth variant="outlined" label="Product"
                value={data.name} onChange={(ev) => change('name', ev.target.value)} onBlur={onBlur}
                required error={!!errors.email} helperText={errors.email || ' '} size="small"
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ minWidth: 120 }}>
                <InputLabel
                  shrink htmlFor="department-label"
                  style={{ backgroundColor: '#ffffff'/* this is to fix a bug in material where the line goes through until you click */ }}
                >
                  Department
                </InputLabel>
                <Select
                  native
                  inputProps={{
                    name: 'department',
                    id: 'department-label'
                  }}
                  value={data.department || ''}
                  onChange={(ev) => change('department', ev.target.value)}
                >
                  {Object.keys(departments).map((key) => (
                    <option key={key} value={key}>{departments[key].name}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!ingredient && (
              <Grid item>
                <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ minWidth: 120 }}>
                  <InputLabel
                    shrink htmlFor="frequency-label"
                    style={{ backgroundColor: '#ffffff' }}
                  >
                    Purchase Frequency
                  </InputLabel>
                  <Select
                    native
                    inputProps={{
                      name: 'frequency',
                      id: 'frequency-label'
                    }}
                    value={data.frequency || ''}
                    onChange={(ev) => change('frequency', ev.target.value)}
                  >
                    {Object.keys(frequencies).map((key) => (
                      <option key={key} value={key}>{frequencies[key]}</option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!ingredient && (
              <Grid item>
                <TextField
                  fullWidth variant="outlined" label="Notes" placeholder="Notes" multiline
                  rows={3}
                  value={data.notes} onChange={(ev) => change('notes', ev.target.value)}
                  error={!!errors.notes} helperText={errors.notes || ' '} size="small"
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={!!Object.keys(formErrors(data, true)).length}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewProduct;
