import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Add from '@material-ui/icons/Add';
import Cancel from '@material-ui/icons/Cancel';

import MemberTable from 'components/members/MemberTable';
import useAuth from 'hooks/useAuth';
import dbLoad from 'hooks/utils/dbLoader';
import { dbSet } from 'hooks/utils/dbUpdater';
import { generateAccessKey } from 'utils';

const defaultValue = [];

const Household = ({ close }) => {
  const { ruid, uid } = useAuth();
  const [household, setHousehold] = useState();
  const [currentMember, setCurrentMember] = useState();
  const [name, setName] = useState('');

  // dbWatcher({ uid, path: 'household/members', set: setMembers, source: 'pages/preferences/Household', defaultValue });

  useEffect(() => {
    dbLoad({ path: 'household', uid, defaultValue }).then((household) => {
      setHousehold(household);
      setName(household.name);
    });
  }, [uid, setHousehold]);

  if (!household) {
    return null;
  }

  const add = () => {
    setCurrentMember({ accessKey: generateAccessKey(uid) });
  };

  const updateName = (ev) => {
    setName(ev.target.value);
  };

  const save = () => {
    dbSet({ uid, path: 'household/name', value: name, source: 'preferences/Household' });
  };

  return (
    <Grid container direction="column" style={{ width: '100%', padding: 10 }}>
      {close && (
        <Grid item container justifyContent="flex-end">
          <IconButton onClick={close}><Cancel /></IconButton>
        </Grid>
      )}
      <Hidden mdUp>
        <Divider />
      </Hidden>
      <Grid item xs={10} sm={8} md={6} style={{ paddingTop: 10 }}>
        {ruid === uid && (
          <TextField autoFocus fullWidth variant="outlined" label="Household" value={name} onChange={updateName} onBlur={save} size="small" />
        )}
        {ruid !== uid && (
          <div>{name}</div>
        )}
      </Grid>
      <MemberTable household={household} setHousehold={setHousehold} currentMember={currentMember} setCurrentMember={setCurrentMember} />
      {ruid === uid && (
        <Button onClick={add} startIcon={<Add />}>Add</Button>
      )}
    </Grid>
  );
};

export default Household;
