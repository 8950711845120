import React from 'react';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Forward30 from '@material-ui/icons/Forward30';

const Simple = ({ classes, state, setState }) => (
  <Grid container item alignItems="center">
    <Grid item xs={1}>
      <Forward30 className={classes.icon} />
    </Grid>
    <Grid container item justifyContent="space-between" alignItems="center" xs={11} sm={8} md={6}>
      <Grid item>
        <Typography className={classes.label}>
          Display only simple, quick meals?
        </Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={state.recipePreferences.simple || false}
          onChange={(ev) => setState({ ...state, recipePreferences: { ...state.recipePreferences, simple: ev.target.checked } })}
          inputProps={{ 'aria-label': 'toggle simple recipes' }}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default Simple;
