const frequencies = {
  0: 'Don\'t track',
  1: 'Weekly',
  2: 'Every 2 Weeks',
  3: 'Every 3 Weeks',
  4: 'Monthly',
  5: 'Every 5 Weeks',
  6: 'Every 6 Weeks',
  7: 'Every 7 Weeks',
  8: 'Every 8 Weeks',
  9: 'Every 9 Weeks',
  10: 'Every 10 Weeks',
  11: 'Every 11 Weeks',
  12: 'Every 3 Months',
  24: 'Every 6 Months',
  36: 'Every 9 Months',
  52: 'Yearly',
};

export default frequencies;
