import React, { useEffect, useState } from 'react';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grow from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import useShoppingList from 'hooks/useShoppingList';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    /* paddingTop: '56.25%', // 16:9 */
    paddingTop: '85%',
    borderRadius: '50%',
    background: theme.palette.background.default,
  },
  cardHeader: {
    padding: 0,
    textAlign: 'center',
    alignItems: 'center',
    background: theme.palette.background.default,
  },
  action: {
    position: 'relative',
    top: 0,
    left: 0,
  },
  deleteIcon: {
    /* background: theme.palette.background.default, */
    color: theme.palette.error.main,
  },
  headerContent: {
    '& span': {
      display: '-webkit-box',
      '-webkitLineClamp': 2,
      '-webkitBoxOrient': 'vertical',
      lineHeight: '1.6em',
      fontSize: '1.0em',
      height: '3.0em',
      overflow: 'hidden',
      fontWeight: 'bold',
      [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        fontSize: '0.9em',
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        fontSize: '0.8em',
      },
    },
  },
  card: {
    overflow: 'visible',
    background: theme.palette.background.default,
    /* background: theme.palette.background.default, */
    border: '1px solid red',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  cardActions: {
    background: theme.palette.background.default,
  },
  bottomNav: {
    background: theme.palette.background.default,
    flexGrow: 1,
  },
  progress: {
    width: '80%!important',
    height: '80%!important',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

const ProductCard = ({ product, history }) => {
  const classes = useStyles();
  const { shoppingList, toggle } = useShoppingList();
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    setFadeIn(true);
  }, [product]);

  const addToShoppingList = () => {
    toggle({ products: [product], needed: true });
  };

  const removeFromShoppingList = () => {
    toggle({ products: [product], needed: false, remove: true });
  };

  if (!product) {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress className={classes.progress} fontSize="inherit" />
      </div>
    );
  }

  // TODO: use real matching, i.e. slug
  const onList = Object.values(shoppingList).some((p) => p.name.toLowerCase() === product.name.toLowerCase());

  return [
    <Card
      id={`product-${product.slug}`} className={classes.card} key={`product-${product.slug}-card`}
      raised={false} elevation={0} style={{ border: '0px solid red' }}
    >

      <CardActionArea style={{ border: '0px solid red' }}>
        <Grow in={fadeIn}>
          <CardMedia
            className={classes.media}
            title={product.name}
          />
        </Grow>
      </CardActionArea>

      <CardHeader
        classes={
          {
            root: classes.cardHeader,
            content: classes.headerContent,
            action: classes.action,
            avatar: classes.avatar,
          }
        }
        title={product.name}
      />

      <CardActions disableSpacing className={classes.cardActions}>
        <BottomNavigation showLabels className={classes.bottomNav}>
          {onList && (
            <BottomNavigationAction
              label="Remove from Shopping List" onClick={addToShoppingList}
              icon={<RemoveCircleOutlineIcon />}
            />
          )}
          {!onList && (
            <BottomNavigationAction
              label="Add to Shopping List" onClick={removeFromShoppingList}
              icon={<AddCircleOutlineIcon />}
            />
          )}
        </BottomNavigation>
      </CardActions>
    </Card>
  ];
};

export default ProductCard;
