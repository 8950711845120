import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import pick from 'lodash/pick';

import ReviewSearchResults from './ReviewSearchResults';

import { SEARCH_RECIPES } from 'hooks/queries';

const PAGE_SIZE = 50;

const ReviewSearch = ({ cache, approved, approvedForProduct, keptCount, keptForProduct, rejected, term,
  setTerm, setPreview, setRecipeToQuarantine, setRecipeToApprove, actionProps, onPlan, plan, profiles }) => {
  const [search, setSearch] = useState({ hits: 0, page: 0, more: false, query: '', recipes: [], searching: false });

  const [doSearch, { error, data }] = useLazyQuery(SEARCH_RECIPES);

  useEffect(() => {
    setSearch({ hits: 0, page: 1, more: true, query: term, recipes: [], searching: !!term });
  }, [term, setSearch]);

  useEffect(() => {
    if (search.query && search.page > 0) {
      doSearch({ variables: { query: search.query, page: search.page, pageSize: PAGE_SIZE } });
    }
  }, [doSearch, search.query, search.page]);

  useEffect(() => {
    const filterSearch = (recipes) => recipes.map((r) => mapSearch(r));

    const mapSearch = (recipe) => {
      const isRejected = !!rejected[recipe.slug];
      const isApproved = !isRejected && !!approved[recipe.slug];
      return { ...pick(recipe, ['label', 'source', 'uri', 'url', 'slug', 'image', 'dietLabels', 'healthLabels', 'time', 'ingredients']), approved: isApproved, rejected: isRejected };
    };

    if (data && data.searchRecipes) {
      const { count, more, recipes } = data.searchRecipes;
      const pages = Math.floor(count / PAGE_SIZE) + 1;
      setSearch((oldSearch) => ({
        ...oldSearch,
        hits: count,
        more,
        pages,
        recipes: filterSearch(recipes),
        searching: false,
      }));
    }
  }, [data, setSearch, rejected, approved]);

  const updatePagination = (event, page) => {
    setSearch((currentSearch) => ({ ...currentSearch, page, searching: true }));
  };

  const resetSearch = () => {
    setSearch({ hits: 0, more: false, page: 0, query: '', searching: false });
    setTerm('');
  };

  return (
    <>
      {search.searching && (
        <LinearProgress style={{ width: '100%', marginTop: -1 }} />
      )}
      <Grid container direction="column" key="search-results">
        {error && (
          <Alert severity="error" style={{ marginTop: 8 }}>
            An error occurred while searching. Please try again later.
          </Alert>
        )}
        {!error && (
          <ReviewSearchResults
            cache={cache} reset={resetSearch} search={search} actionProps={actionProps} setPreview={setPreview}
            updatePagination={updatePagination} onPlan={onPlan} plan={plan} profiles={profiles}
          />
        )}
      </Grid>
    </>
  );
};

export default ReviewSearch;
