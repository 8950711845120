import dbLoader from 'hooks/utils/dbLoader';

let notRejectedRecipes;

const getNotRejectedRecipes = async () => {
  if (notRejectedRecipes) {
    return notRejectedRecipes;
  }
  notRejectedRecipes = await dbLoader({ path: '/approved' });
  return notRejectedRecipes;
};

export default getNotRejectedRecipes;
