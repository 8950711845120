import React from 'react';

import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import { createRoot } from 'react-dom/client';
import 'assets/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'shepherd.js/dist/css/shepherd.css';
import 'firebase/auth';
import 'firebase/database';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import theme from './assets/theme';
import firebaseConfig from './data/firebaseConfig';
import ErrorBoundary from './ErrorBoundary';
import { AccountContextProvider } from './hooks/useAccount';
import useAuth, { AuthContextProvider } from './hooks/useAuth';
import { ErrorContextProvider } from './hooks/useError';
import { NavContextProvider } from './hooks/useNav';
import { PausedContextProvider } from './hooks/usePause';
import { RepeatsContextProvider } from './hooks/useRepeats';
import { ShoppingListContextProvider } from './hooks/useShoppingList';
import { SnackContextProvider } from './hooks/useSnack';
import * as serviceWorker from './serviceWorker';

import { trace } from 'utils';

trace('ENV: %o', process.env);
const prefix = process.env.REACT_APP_ENV === 'production' ? '' : 'qa-';
const graphqlURI = process.env.NODE_ENV === 'development' ? `${window.location.origin}/graphql` : `https://${prefix}api.guustav.com/graphql`;
trace('uri: %o', graphqlURI);

window.onerror = (message, file, line, column, error) => {
  trace(`Error (window.onError): ${message} ${error} ${file} ${line} ${column}`);
};

firebase.initializeApp(firebaseConfig);

const httpLink = createHttpLink({
  uri: graphqlURI,
});

const AppWrapper = () => {
  const { uid } = useAuth();
  document.body.style.backgroundColor = theme.palette.background.default;

  const authLink = setContext(async (_, { headers }) => {
    const user = firebase.auth().currentUser;
    const token = user ? await user.getIdToken() : null;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      }
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  trace('client: %o, uid: %s', client, uid);

  // This seems kind of ridiculous, but it's recommended to use fine-grained
  // firebase listeners so there's one handler for each thing that we listen
  // for and one context per handler. But, we should go back and convert some
  // of these to simple firebase listeners because we probably don't need all
  // of them everywhere.
  return (
    <ApolloProvider client={client}>
      <ErrorBoundary client={client} uid={uid}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <StylesProvider>
            <PausedContextProvider>
              <ErrorContextProvider>
                <NavContextProvider client={client}>
                  <SnackContextProvider>
                    <AccountContextProvider>
                      <RepeatsContextProvider>
                        <ShoppingListContextProvider>
                          <BrowserRouter>
                            <App />
                          </BrowserRouter>
                        </ShoppingListContextProvider>
                      </RepeatsContextProvider>
                    </AccountContextProvider>
                  </SnackContextProvider>
                </NavContextProvider>
              </ErrorContextProvider>
            </PausedContextProvider>
          </StylesProvider>
        </MuiThemeProvider>
      </ErrorBoundary>
    </ApolloProvider>
  );
};

/*
  <React.StrictMode>
  </React.StrictMode>,
  */
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <AuthContextProvider>
    <AppWrapper />
  </AuthContextProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
