import React from 'react';

import ProductSearchForm from './ProductSearchForm';

import useShoppingList from 'hooks/useShoppingList';

const ProductSearch = ({ history }) => {
  const { shoppingList, update } = useShoppingList();

  const selectProduct = (product) => {
    update({ products: [product], remove: false });
  };

  return (
    <ProductSearchForm selectProduct={selectProduct} productList={shoppingList} />
  );
};

export default ProductSearch;
