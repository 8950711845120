// stand alone component that will rretunr an array of recipes or[] in it's setResults calling property. 
// User can enter a number of ingredients and request a number of meals from those ingredients. will be able to select which meals 
// it wants to return as well as see the details of the recipe

import React, { useState, useAuth, useEffect } from 'react';
import callChatGPTFunction from 'utils/chatgptUtils';
import { Card, CardContent, Typography, Button, Collapse, List, ListItem, ListItemText, ListItemIcon, IconButton, TextField, DialogContentText } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Grid } from '@material-ui/core';
import { CardActions, CircularProgress, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';





const RecipesFromChatGPT = ({ setResults, actionName, open, setOpen, searchTerms, quantityPreferences, recipePreferences }) => {
  const [recipes, setRecipes] = useState([]);
  const [expandedRecipeId, setExpandedRecipeId] = useState(null);
  const [input, setInput] = useState(searchTerms || '');
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [searching, setSearching] = useState(false);


  const converIngredientstoArray = (ingredientsString) => {
    let result = [];
    console.log(ingredientsString);
    let tempResult = ingredientsString.split('\n');
    // getting rid of ingredients that are empty or decorative text
    tempResult.forEach((el) => {
      if (el.toUpperCase() !== el.toLowerCase())
      { result.push({food:el});}
    });
    return result;
  }

  const logControlCharacters = (str) => {
    const controlCharPositions = [];
  
    for (let i = 0; i < str.length; i++) {
      if (/[\x00-\x1F\x7F]/.test(str[i])) {
        controlCharPositions.push({
          character: str[i],
          position: i
        });
      }
    }
  
    if (controlCharPositions.length > 0) {
      console.log("Control characters found:");
      for (const { character, position } of controlCharPositions) {
        console.log(`Character: ${JSON.stringify(character)}, Position: ${position}`);
      }
    } else {
      console.log("No control characters found.");
    }
  };

  function sanitizeJSON(input) {
    let result = '';

    for (let i = 0; i < input.length; i++) {
        const char = input[i];
        const codePoint = char.codePointAt(0);
        
        // Check if the character is a control character excluding '\n'
        if ((codePoint >= 0x00 && codePoint <= 0x1F) || (codePoint >= 0x7F && codePoint <= 0x9F)) {
            // If the character is '\n', add it to the result, otherwise skip
            if (char === '\n') {
                result += char;
            } else {
                console.log(`Replaced control character: '${char}' (code point: ${codePoint}) at position ${i}`);
            }
        } else {
            // If not a control character, add it to the result
            result += char;
        }
    }

    return result;
}

  
  const parseRecipesFromChatGPTResponseJSON = (response) => {
    let results = [];
    console.log(response);
    logControlCharacters(response);
    let jsonObj = JSON.parse(sanitizeJSON(response));
    results = Object.values(jsonObj);
    console.log(results);
    // Check if the results have only one element and that element is an array which would be an incorrect formatting out of chatgpt
    if (results.length === 1 && Array.isArray(results[0])) {
      results = results[0];
    }
    results.forEach((el) => {
      console.log(el);
      el.ingredients = converIngredientstoArray(el.ingredientsDirect);
    })
    return results;
  };


  const getRecipes = async () => {
    setSearching(true);
    let calories = 500;
    let eaters = 4;
    if (quantityPreferences && quantityPreferences.dinnerEaters) eaters = quantityPreferences.dinnerEaters;
    let recipePref = '';
    if (recipePreferences && recipePreferences.health) recipePreferences.health.forEach(health => {
      let newHealth = health.replaceAll('_', ' ');
      recipePref = recipePref + newHealth;
    })
    if (recipePreferences && recipePreferences.diet) recipePreferences.diet.forEach(health => {
      let newHealth = health.replaceAll('_', ' ');
      recipePref = recipePref + newHealth;
    })

    let healthString = '';
    if (recipePref !== '') healthString = '.Also make sure the recipes are ' + recipePref;

    let startString = 'without extra comments, suggest dinner recipes for ' + eaters + ' people with as many as possible of these ingredients: ';
    //let resultFormatting = '. Recipes need to take less than 45 minutes to make. separate each recipe with --- and format each recipe in the following order RecipeName and the recipe name, RecipeTime with the total preparation time, RecipeYield with the number of servings,RecipeCalories with the total calories per serving and add the term RecipeInstructions before the instructions, RecipeIngredients before the ingredients. The number of calories per serving needs to be at least ' + calories + ' for each recipe. Do not deviate from the format or add comments outside of what is needed in the recipe instructions.';
    let resultFormatting = '. Recipes need to take less than 45 minutes to make. Format results as JSON object that can be parsed with JSON.parse. Each recipe should be structured as {name:the recipe name, time:the total preparation time, yield:the number of servings,calories:total calories per serving,  instructionsStr: the instructions, ingredientsDirect: the ingredients each separated by \n}. The number of calories per serving needs to be at least ' + calories + ' for each recipe. Do not deviate from the format or add comments outside of what is needed in the recipe instructions.Do not include control characters in the response';

    let requestString = startString + input + resultFormatting + healthString;
    try {
      let response = await callChatGPTFunction(requestString);
      let results = parseRecipesFromChatGPTResponseJSON(response);
      // doing 3 tries total in case we are dealing with some malformatted responses from ChatGPT
      if (results === []) {
        response = await callChatGPTFunction(requestString);
        results = parseRecipesFromChatGPTResponseJSON(response);
      };
      if (results === []) {
        response = await callChatGPTFunction(requestString);
        results = parseRecipesFromChatGPTResponseJSON(response);
      };
      setSearching(false);
      let recipesDisplay = recipes.concat(results);
      console.log(recipesDisplay);
      setRecipes(recipesDisplay); // Assuming the response contains an array of recipe objects
    } catch (error) {
      setSearching(false);
      console.error('Error fetching recipes:', error);
    }
  };

  const firstGetRecipes = () => {
    setRecipes([]);
    getRecipes();
  }


  const getMoreRecipes = () => {
    getRecipes();
  }

  const toggleRecipe = (recipeId) => {
    if (expandedRecipeId === recipeId) {
      setExpandedRecipeId(null);
    } else {
      setExpandedRecipeId(recipeId);
    }
  };

  const handleInputChange = (event) => {

    setInput(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      firstGetRecipes();
    }
  };

  const toggleSelectRecipe = (recipe) => {
    let workingArray;
    if (!isSelected(recipe)) {
      workingArray = [...selectedRecipes, recipe];
    }
    else {
      workingArray = selectedRecipes.filter(obj => obj.name !== recipe.name)
    }
    setSelectedRecipes(workingArray);
  }

  const isSelected = (recipe) => {
    let result = false;
    selectedRecipes.forEach((element) => {
      if (element.name === recipe.name) result = true;
    })
    return result;
  }

  const getButtonText = (recipe) => {
    let result = 'Select';
    if (isSelected(recipe)) result = <><CheckCircleIcon /> Selected</>;
    return result;
  }

  const getButtonVariant = (recipe) => {
    let result = 'text';
    if (isSelected(recipe)) result = 'contained';
    return result;
  }


  const handleClose = () => {
    setOpen(false);
  }

  const handleAction = () => {
    setOpen(false);
    setResults(selectedRecipes);
  }

  useEffect(() => {
    if (searchTerms && searchTerms !== '') {
      setInput(searchTerms);
      firstGetRecipes();
    }
  }, [searchTerms])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Recipes by ChatGPT</DialogTitle>
      <DialogContent>
        <DialogContentText><small>We will use ChatGPT to suggest recipes based on the ingredients you want to use and your preferences. To Start, simply enter the ingredients you want to use.</small><br /><br /></DialogContentText>
        <Grid container direction='row' justifyContent='space-between' >
          <TextField label='Ingredients' type="text" value={input} onChange={handleInputChange} placeholder="Enter ingredients" style={{ minWidth: 400 }} onKeyDown={handleKeyPress} />
          <Button color='secondary' variant='outlined' onClick={firstGetRecipes} size='small' disabled={searching}>Show Recipes</Button>
        </Grid>
        {recipes.length > 0 && (
          <Grid container direction='column' spacing={2} style={{ marginTop: 20 }}>
            <Typography>Disclaimer: Sometimes chatGPT provides recipes without the requested ingredients, that's normal</Typography>
            {recipes.map((recipe, idx) => (
              <>
                {(recipe && recipe.name) && (
                  <Card key={'gptRecipe' + idx} raised elevation={2} style={{ maxWidth: 600, marginTop: 10 }} >
                    <CardContent>
                      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant="h6" component="div">
                          {recipe.name}
                        </Typography>
                        <Button color='secondary' variant={getButtonVariant(recipe)} onClick={() => toggleSelectRecipe(recipe)}>{getButtonText(recipe)}</Button>
                      </Grid>
                      <Typography color="textSecondary">
                        Cooking Time: {recipe.time}
                      </Typography>
                      <Typography color="textSecondary">
                        Number of Ingredients: {recipe.ingredientsDirect.length}
                      </Typography>
                      <Typography color="textSecondary">
                        Number of servings: {recipe.yield}
                      </Typography>
                      <Typography color="textSecondary">
                        Calories: {recipe.calories}
                      </Typography>
                      <CardActions>
                        <Grid container justifyContent='flex-end' alignItems='center' alignContent='center'>
                          <Grid>
                            <IconButton
                              onClick={() => toggleRecipe(idx)}
                              style={{ marginTop: "1rem" }}
                              color='secondary'
                              size='medium'
                            >
                              {(expandedRecipeId !== idx) && (<><small>More</small><ExpandMoreIcon /></>)}
                              {(expandedRecipeId === idx) && (<><small>Less</small><ExpandLessIcon /></>)}
                            </IconButton></Grid>
                        </Grid>
                      </CardActions>
                      <Collapse in={expandedRecipeId === idx}>
                        <div style={{ marginTop: "1rem" }}>
                          <Typography variant="h6" component="h4">
                            Instructions:
                          </Typography>
                          <List>
                            {recipe.instructionsStr.split('\n').map((instruction, index) => (
                              <ListItem key={'instructions'+recipe.name + index}>
                                <ListItemText key={'instructionsT'+recipe.name + index} primary={instruction} />
                              </ListItem>
                            ))}
                          </List>
                          <Typography variant="h6" component="h4">
                            Ingredients:
                          </Typography>
                          <List>
                            {recipe.ingredients.map((ingredient, index) => (
                              <ListItem key={'ingredients'+recipe.name + index}>
                                <ListItemText key={'ingredientsT'+recipe.name + index} primary={ingredient.food} />
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      </Collapse>
                    </CardContent>
                  </Card>)}
              </>
            ))}
          </Grid>
        )}

        {(!searching && !(recipes.length > 0)) && (<div>No recipes found. Hit Show Recipes</div>)}
        {searching && (<Grid container justifyContent='center' alignItems='center' direction='column' style={{ marginTop: 15, marginBottom: 40 }}>
          <Typography color="textSecondary">
            ChatGPT is thinking and because we are waiting for the full response before showing the results it can take up to 1 minute
          </Typography>
          <CircularProgress sx={{ color: 'blue.500' }} />
        </Grid>)}
        {((recipes.length > 0) && !searching) && (<Grid container justifyContent='center' alignItems='center' direction='column' style={{ marginTop: 15, marginBottom: 40 }}>
          <Button color='secondary' variant='outlined' onClick={getMoreRecipes}>{'<<<'} Show more Recipes {'>>>'}</Button></Grid>)}
      </DialogContent>
      <Grid container style={{ marginTop: 40 }} justifyContent='flex-end'>
        <Button color="primary" variant="text" onClick={handleClose} style={{ marginRight: 10 }}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={handleAction} style={{ minWidth: 200 }}>
          {actionName}
        </Button></Grid>
    </Dialog>

  );
};

export default RecipesFromChatGPT;



