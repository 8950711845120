import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Cancel';

import RecipeGridList from './RecipeGridList';
import RecipeSearch from './RecipeSearch';

import MealList from 'components/meal/MealList';
import RecipeDialog from 'components/recipes/RecipeDialog';
import RecipeModal from 'components/recipes/RecipeModal';
import useAuth from 'hooks/useAuth';
import dbLoader from 'hooks/utils/dbLoader';
import { groupedRecipes } from 'hooks/utils/recipeUtils';
import { trace } from 'utils';
import { Typography } from '@material-ui/core';

const defaultActionProps = [{ course: 'dinner' }];

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    overflowX: 'hidden',
    maxWidth: '100vw',
    [theme.breakpoints.up('md')]: {
      width: '100%', // Adjust the width for larger screens
      maxWidth: '60vw', // Set the maximum width for larger screens
    },
  },
  drawerTitle: {
    position: 'sticky',
    background: 'white',
    top:0,
    zIndex: 1,
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft:3,
      width: '100%', // Adjust the width for larger screens
      maxWidth: '60vw', // Set the maximum width for larger screens
    },
    /*    [theme.breakpoints.up('sm')]: {
      maxWidth: '90vw',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '60vw',
    }, */
  },
  drawerBody: {
    //marginTop: 60,
    minWidth: '60vw',
    overflowY: 'auto', // add vertical scrolling
    flexGrow: 1,
    
  },
  scrollParent: {
    overflowX: 'hidden',
    paddingRight: 10,
  },
  scroller: {
    overflowX: 'auto',
    paddingRight: 8,
    paddingTop: 2, /* otherwise top border is hidden */
  },
}));

const RecipeDrawer = ({ actionProps = defaultActionProps, close, isOpen, recipePreferences, exclude, dinners, lunches, breakfasts, ...props }) => {
  const classes = useStyles();
  const hash = decodeURIComponent((window.location.hash || '#').slice(1));
  const { uid } = useAuth();
  const [, setSearching] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [adding, setAdding] = useState(false);
  const [recipes, setRecipes] = useState('loading');
  const [changed, setChanged] = useState(true);
  const [plans, setPlans] = useState([]);
  const { course, slug } = (actionProps || defaultActionProps);
  const loading = recipes === 'loading';
  // const {menuImages,setMenuImages} =useState(null);
  trace(recipes);

  const [preview, setPreview] = useState();

  // const ref = React.useRef(null);
  // const itemRef = React.useRef(null);

  /* useEffect(() => {

  }, [mealAdded, setMenuImages]); */

  useEffect(() => {
    dbLoader({ path: '/cache/plans' }).then((value) => {
      const suggestPlans = [];
      Object.values(value).forEach((element, index) => {
        if (element.suggestionPlan) {
          element.recipesArray = [];
          Object.values(element.recipes).forEach((recipe, i) => {
            element.recipesArray.push(recipe);
          });
          suggestPlans.push(element);
        }
      });
      suggestPlans.sort((a, b) => {
        let aOrder = 0;
        if (a.suggestionOrder) aOrder = parseInt(a.suggestionOrder);
        let bOrder = 0;
        if (b.suggestionOrder) bOrder = parseInt(b.suggestionOrder);
        return (bOrder - aOrder);
      });

      setPlans(suggestPlans || []);
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      if (setSearchActive) {
        setSearchActive(false);
      }
    }
  }, [isOpen, setSearchActive]);

  useEffect(() => {
    if (changed && uid && isOpen) {
      if (recipePreferences) {
        groupedRecipes({ uid, recipePreferences, course }).then((recipes) => {
          setRecipes(recipes);
          setChanged(false);
        });
      }
    }
  }, [isOpen, uid, recipePreferences, setRecipes, course, changed]);

  const use = () => {
    setChanged(true);
  };

  trace(recipes);

  /*  const scrollBy = (by) => {
    ref.current.scrollBy({ left: 2 * by * itemRef.current.clientWidth, behavior: 'smooth' });
  }; */

  const meals = dinners.meals.concat(lunches.meals).concat(breakfasts.meals);

  let recipessaved = false;
  if (recipes.saved) { recipessaved = recipes.saved.length > 0; }

  let recipesrepeat = false;
  if (recipes.repeated) { recipesrepeat = recipes.repeated.length > 0; }

  let recipescustom = false;
  if (recipes.custom) { recipescustom = recipes.custom.length > 0; }
  let buttonMarginTop = 20;
  if (recipescustom) buttonMarginTop = 0;

  return (
    <>
      <RecipeModal key="recipe-modal" readonly recipe={preview} close={() => setPreview(null)} />
      <RecipeDialog key="recipe-dialog" open={adding} setOpen={setAdding} course={course} slug={slug} history={props.history} use={use} />
      <Drawer anchor="right" open={isOpen} onClose={close} classes={{ paper: classes.drawerPaper }}>
        <Grid container direction="column">
          <Grid container item direction="column" className={classes.drawerTitle} alignItems='center' spacing={3}>
            {/* <MealList meals={meals} title="Current Menu:" preview={setPreview} /> */}
            <Grid container item justifyContent="space-between" alignItems='center'>
              <Typography style={{ paddingLeft: 20, fontSize:'1.4rem', fontWeight:480 }}>
                {actionProps.title || 'Recipe Browsing'}
              </Typography>
              <Button startIcon={<CloseIcon />} onClick={close}>Close</Button>
              </Grid>
              <RecipeSearch
              setSearching={setSearching} setSearchActive={setSearchActive} setPreview={setPreview}
              recipePreferences={recipePreferences} actionProps={actionProps || defaultActionProps} defaulTerm={hash}
            />

            </Grid>


          <Grid item container direction="column" className={classes.drawerBody}>
            <Grid container item direction="column" style={{ paddingLeft: 20, display: loading || searchActive ? 'none' : 'block' }}>
              {!(exclude && exclude.includes('trending')) && (
                <RecipeGridList loading={loading} recipes={recipes.trending} title="Trending Recipes" autoHide actionProps={actionProps} uid={uid} preview={setPreview} />
              )}

              {recipessaved && (
              <RecipeGridList
                loading={loading} recipes={recipes.saved} title="Your Saved Recipes" uid={uid}
                notFoundMessage="You have not saved any recipes" preview={setPreview} actionProps={actionProps}
              />
              )}

              {recipesrepeat && (
              <RecipeGridList
                loading={loading} recipes={recipes.repeated} title="Your Repeat Recipes" actionProps={actionProps} uid={uid}
                notFoundMessage="You have not marked any recipes for repeat" preview={setPreview}
              />
              )}

              {recipescustom && (
                <RecipeGridList
                  loading={loading} recipes={recipes.custom} title="Your Custom Recipes" actionProps={actionProps} uid={uid}
                  notFoundMessage="You have not created any custom recipes yet" preview={setPreview}
                />
              )}
              <Button
                color="secondary" size="medium" variant="outlined"
                onClick={() => setAdding(true)}
                style={{ marginTop: buttonMarginTop }}
              >
                <AddIcon />&nbsp;Add Custom Recipe
              </Button>
              <Typography style={{ paddingLeft: 20,marginTop:20, fontSize:'1.2rem', fontWeight:480, textDecoration:'underline' }}>Recipes trending this week:</Typography>
              {plans.map((plan, idx) => <RecipeGridList fullPage key={idx} loading={loading} recipes={plan.recipesArray} title={`${plan.suggestionText} Recipes`} autoHide actionProps={actionProps} uid={uid} preview={setPreview} />)}

              {/*               <RecipeGridList loading={loading} recipes={recipes.suggestions} title="Suggestions for You" autoHide actionProps={actionProps} uid={uid} preview={setPreview}/> */}
              <RecipeGridList loading={loading} recipes={recipes.planHistory} title="Previously Planned Recipes" autoHide actionProps={actionProps} uid={uid} preview={setPreview} />
              <RecipeGridList loading={loading} recipes={recipes.recent} title="Recently Seen Recipes" autoHide actionProps={actionProps} uid={uid} preview={setPreview} />
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default RecipeDrawer;
