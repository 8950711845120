import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import reviewReasons from 'guustav-shared/reviewReasons';

import dbLoader from 'hooks/utils/dbLoader';
import { dbUpdate } from 'hooks/utils/dbUpdater';
import { timeStamp } from 'utils';

const QuarantineRecipeDialog = ({ recipe, open, onSave, onClose, person }) => {
  const [reason, setReason] = useState({});
  const [otherReasons, setOtherReasons] = useState(null);

  useEffect(() => {
    if (recipe) {
      dbLoader({ path: `/review/${recipe.slug}/reasons` }).then((r) => {
        if (r) {
          const others = [];
          Object.entries(r).forEach(([email, list]) => {
            if (email === person) {
              setReason(list || {});
            } else {
              others.push({ email, reasons: list });
            }
          });
          setOtherReasons(others);
        } else {
          setReason({});
          setOtherReasons(null);
        }
      });
    }
  }, [setReason, setOtherReasons, recipe, person]);

  const save = async () => {
    const updates = {};
    const product = recipe.product || 'tbd';
    const cachePath = `/cache/recipes/${product}/${recipe.slug}`;
    const cacheItem = (await dbLoader({ path: cachePath })) || { ...recipe };
    if (cacheItem.approved) {
      cacheItem.approved[person] = null;
      if (Object.keys(cacheItem.approved).length === 0) {
        cacheItem.approved = null;
      }
    } else {
      cacheItem.approved = null;
    }
    if (!cacheItem.rejected) {
      cacheItem.rejected = {};
    }
    cacheItem.rejected[person] = timeStamp();
    const review = (await dbLoader({ path: `/review/${recipe.slug}` })) || { ...recipe, approved: null, rejected: null };
    if (!review.reasons) {
      review.reasons = {};
    }
    review.reasons[person] = reason;
    updates[`/review/${recipe.slug}`] = review;
    updates[`/approved/${recipe.slug}`] = null;
    updates[cachePath] = cacheItem;
    await dbUpdate({ path: '/', updates });
    if (onSave) {
      onSave(recipe, reason);
    }
    if (onClose) {
      onClose();
    }
  };

  const OtherSummary = () => (otherReasons || []).map((o) => {
    const { email, reasons } = o;
    const list = Object.keys(reasons).filter((r) => reasons[r] && r !== 'note').map((r) => reviewReasons[r]);
    if (reasons.note) {
      list.push(reasons.notes);
    }
    return <div key={email}>{email}: {list.join('; ')}</div>;
  });

  const toggle = (field) => () => setReason({ ...reason, [field]: !reason[field] });

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
        <div>Add Recipe to Quarantine</div>
        <DialogContentText>{(recipe || {}).label}</DialogContentText>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={2}>
          <Grid container item justifyContent="center">
            <Grid item container spacing={1} justifyContent="flex-start">
              {Object.entries(reviewReasons).map(([field, label]) => (
                <Grid item key={field} xs={3}>
                  <Chip
                    style={{ width: 130 }}
                    label={label}
                    size="small"
                    color="primary"
                    variant={reason[field] ? 'default' : 'outlined'}
                    onClick={toggle(field)}
                    onDelete={reason[field] ? toggle(field) : undefined}
                    clickable
                    deleteIcon={<DoneIcon />}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              autoFocus fullWidth variant="outlined" label="Notes"
              value={reason.notes} onChange={(ev) => setReason({ ...reason, notes: ev.target.value })} multiline rows={3} columns={60}
            />
          </Grid>
          {otherReasons && !!otherReasons.length && (
            <div>
              <b>Other Reviewers</b>
              <OtherSummary />
            </div>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={save} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuarantineRecipeDialog;
