import React, { useMemo, useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Delete, Edit, NoteAdd } from '@material-ui/icons';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import Frequency from './Frequency';
import IngredientText from './IngredientText';
import { calculateIncrement } from './utils';
import MeasureInput from './MeasureInput';

import { volumes, weights } from 'data/measurements';
import useAuth from 'hooks/useAuth';
import { Typography } from '@material-ui/core';


// import { trace } from 'utils';

/* const measureC = ({ product, full }) => {
  if (full) {
    return (volumes[product.quantity.measure] || weights[product.quantity.measure] || { name: product.quantity.measure }).name;
  }
  return (volumes[product.quantity.measure] || weights[product.quantity.measure] || { symbols: product.quantity.measure }).symbols;
}; */

const ProductRow = ({ classes, displayProps, product, remove, setQuantity, toggle, toggled, uid }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const { admin } = useAuth();
  const [checked, setChecked] = useState(displayProps.type === 'checkboxInvert');
  const [checking, setChecking] = useState(false);

  const { setNoteProduct, setPreviewRecipe } = displayProps;
  const increment = useMemo(() => {
    if (!product) {
      return 1;
    }
    return calculateIncrement(product);
  }, [product]);

  const needed = !!(toggled ? toggled[product.slug] : product.needed);
  const faded = displayProps.type === 'switch' && !needed && !(displayProps.source === 'wizard-quickpicks');
  const opacity = faded || displayProps.faded ? 0.5 : 1.0;
  const rowClass = (checked && displayProps.type === 'checkbox') || (!checked && displayProps.type === 'checkboxInvert') ? classes.fadeRow : classes.row;
  const check = () => {
    setChecked(!displayProps.type === 'checkboxInvert');
    setChecking(true);
    setTimeout(() => {
      toggle({ products: [product], on: displayProps.type === 'checkbox', cb: () => setChecking(false) });
    }, 1000);
  };
  const strike= (displayProps.type!=='switch')&&faded;

  const { measure } = product.quantity;
  const showFrequency = displayProps.frequency && !!((needed && !product.onMenu) || product.frequency || product.frequency === 0);
  return (
    <Grid container item direction="row" className={rowClass} wrap="nowrap">
      {displayProps.type.match(/checkbox/) && (
        <Grid container item justifyContent="center" alignItems="center" className={classes.checkboxContainer}  style={{maxWidth:40}}>
          <IconButton color="secondary" size="medium" onClick={check} className={classes.checkboxIcon}>
            <>
              {checking && <CircularProgress size={14} />}
              {!checking && !checked && <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />}
              {!checking && checked && <CheckBoxOutlinedIcon fontSize="small" />}
            </>
          </IconButton>
        </Grid>
      )}
      {displayProps.type === 'switch' && (
        <Grid container item direction="column" justifyContent="center" alignItems="center" className={classes.switchContainer} style={{maxWidth:50}}>
          <Grid item>
            <Switch
              checked={needed}
              color={needed ? 'secondary' : 'primary'}
              onChange={(ev) => {
                toggle({ products: [product], on: ev.target.checked });
              }}
              name={product.name}
              size='small'
            />
          </Grid>
          <Grid item className={classes.switchText}>
            <small>
              {needed ? <span>Needed <span style={{ display: sm ? 'none' : 'none', visibility: 'hidden' }}>Not</span></span> : 'Not Needed'}
            </small>
          </Grid>
        </Grid>
      )}
      <Grid container item direction="column" justifyContent="space-between" alignItems="flex-start" className={classes.nameCell}>
        <Grid container item direction="row" justifyContent="space-between" alignItems='center'>
          <Grid
            item title={admin ? `${product.department}/${product.slug}` : undefined}
            style={{ opacity, textDecoration: strike ? 'line-through' : 'none' }}
            container direction='row' alignItems='center'
          >
            <Typography className={classes.name}>{product.name}&nbsp;</Typography>
             <Typography style={{fontSize:'0.7rem'}}>({product.actual}{(volumes[measure] || weights[measure] || { name: measure }).symbols})</Typography>
          </Grid>
          {displayProps.lastPurchased && product.lastPurchased && (
            <Grid item>
              <small>Last Purchased:&nbsp;{product.lastPurchased}</small>
            </Grid>
          )}
        </Grid>
        {product.onMenu && (
          <Grid item>
            <IngredientText classes={classes} mobile={sm} product={product} setPreviewRecipe={setPreviewRecipe} />
          </Grid>
        )}
       
        <Grid id="notes-edit" container item justifyContent="space-between" alignItems="center" wrap="nowrap">
          {!product.onMenu && (
          <>
            {displayProps.notes === 'editable' && (
            <Grid item style={{ visibility: needed ? '' : 'hidden' }}>
              <Button
                onClick={() => setNoteProduct(product)} size="small" disableElevation variant="text"
                style={{ textTransform: 'none' }}
                startIcon={product.notes && !!product.notes.length ? <Edit /> : <NoteAdd />}
              >
                <div style={{fontSize:'0.7rem',fontWeight:350}}>{product.notes || 'Add Notes'}</div>
              </Button>
            </Grid>
            )}
            {needed && displayProps.notes === 'readonly' && (
            <Grid item id="notes-text">{product.notes}</Grid>
            )}
          </>
          )}

          </Grid>
      </Grid>
      <Grid id="edititem" container item justifyContent="center" alignItems="center" style={{width:80}} direction='column'>
          {displayProps.quantity === 'editable' && (
              <Grid item style={{ marginLeft: 10, visibility: needed ? '' : 'hidden',paddingTop:3 }}>
                <MeasureInput product={product} mobile={sm} setQuantity={setQuantity} increment={increment} /> 

              </Grid>)}
          {displayProps.remove && (
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton aria-label={`remove ${product.name}`} style={{color:'#8B0000', marginRight:50}} onClick={() => remove({ products: [product] })}>
              <Delete />
            </IconButton>
          </Grid>)}
          {showFrequency && (
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Frequency product={product} uid={uid} toggle={needed} toggled={toggled} mobile={sm} />
          </Grid>)}
        </Grid>
    </Grid>
  );
};

export default ProductRow;

