import React, { useState } from 'react';
import axios from 'axios';
import cheerio from 'cheerio';

const MicroDataRecipeImporter = () => {
  const [recipe, setRecipe] = useState(null);
  const [recipeUrl, setRecipeUrl] = useState('');

  const fetchRecipeFromUrl = async () => {
    try {
      const response = await axios.get(recipeUrl);
      const html = response.data;

      // Use Cheerio to parse the HTML
      const $ = cheerio.load(html);

      // Extract recipe details using microdata selectors
      const importedRecipe = {};

      // Extract recipe name
      const recipeName = $('[itemtype="http://schema.org/Recipe"] [itemprop="name"]').text();
      importedRecipe.name = recipeName;

      // Extract recipe ingredients
      const ingredients = $('[itemtype="http://schema.org/Recipe"] [itemprop="recipeIngredient"]').map((_, el) =>
        $(el).text()
      ).get();
      importedRecipe.ingredients = ingredients;

      // Extract recipe instructions
      const instructions = $('[itemtype="http://schema.org/Recipe"] [itemprop="recipeInstructions"]').map((_, el) =>
        $(el).text()
      ).get();
      importedRecipe.instructions = instructions;

      // Extract other relevant details as needed

      setRecipe(importedRecipe);
    } catch (error) {
      console.error('Error fetching recipe:', error);
    }
  };

  const handleInputChange = (event) => {
    setRecipeUrl(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    fetchRecipeFromUrl();
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Enter recipe URL"
          value={recipeUrl}
          onChange={handleInputChange}
        />
        <button type="submit">Import Recipe</button>
      </form>
      {recipe && (
        <div>
          <h2>{recipe.name}</h2>
          <h3>Ingredients:</h3>
          <ul>
            {recipe.ingredients.map((ingredient, index) => (
              <li key={index}>{ingredient}</li>
            ))}
          </ul>
          <h3>Instructions:</h3>
          <ol>
            {recipe.instructions.map((instruction, index) => (
              <li key={index}>{instruction}</li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};

export default MicroDataRecipeImporter;
