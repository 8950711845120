import React, { useEffect } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';
import { useHistory, useLocation } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

import useAuth from 'hooks/useAuth';
import dbLoad from 'hooks/utils/dbLoader';
import { dbSet, dbUpdate } from 'hooks/utils/dbUpdater';
import { timeStamp, trace } from 'utils';

const AccountAccept = ({ ...props }) => {
  const auth = useAuth();
  const history = useHistory();

  const accessKey = new URLSearchParams(useLocation().search).get('a');

  useEffect(() => {
    trace('account/Join: in effect with accessKey: %s, auth: %o', accessKey, auth);
    if (auth.uid && auth.ruid && auth.uid !== auth.ruid) {
      trace('account/Join: primary is set');
      // this happens after we setup their account access
      history.push('/');
      return;
    }
    trace('account/Join: no primary yet');
    // They have not been set up yet
    const fn = async () => {
      trace('account/Join: in fn');
      const invitation = await dbLoad({ path: `/invitations/${accessKey}`, source: 'account/Member' });
      if (!invitation) {
        trace('account/Join: could not find invitation for %s', accessKey);
        // I guess it was a bogus accessKey
        history.push('/account/initialize');
        return;
      }
      if (invitation.ruid && invitation.ruid !== auth.ruid) {
        // Someone else already used this one
        history.push('/account/initialize');
        return;
      }
      trace('account/Join: found invitation for %s: %o', accessKey, invitation);

      const primary = { accessKey, household: invitation.household, uid: invitation.uid };
      trace('account/Join: setting primary account for %s: %o', auth.uid, primary);
      // Have to set this before we check the other account because the security rule
      // uses it to give them access. If they don't have access, it will throw an exception;
      // they may also have access but no longer be allowed so we'll handle that too.
      await dbSet({ uid: auth.uid, path: 'account/primary', value: primary, source: 'account/Member' });
      let allowed = 'off';
      try {
        // Probably this will error out if they aren't allowed but just in case
        trace('account/Join: checking allowed in %s', invitation.uid);
        allowed = await dbLoad({ uid: invitation.uid, path: `household/members/${accessKey}/allowed`, source: 'account/Member' });
        trace('account/Join: allowed: %o', allowed);
      } catch (ex) {
        // If they get a read error because of a rule, then they aren't allowed
        trace('account/Join: got error checking membership status: %o', ex);
        return;
      }
      if (allowed !== 'on') {
        trace('account/Join: not allowed so treating as normal user');
        history.push('/account/initialize');
        return;
      }
      const updates = {};
      invitation.acceptedAt = timeStamp();
      invitation.ruid = auth.uid;
      updates[`/invitations/${accessKey}`] = invitation;
      trace('account/Join: invitation is allowed, reading members');
      const members = await dbLoad({ uid: invitation.uid, path: 'household/members', source: 'account/Member' });
      trace('account/Join: members: %o', members);
      const member = members[accessKey];
      updates[`/users/${invitation.uid}/household/members/${accessKey}`] = {
        ...member, email: auth.email, name: auth.displayName, uid: auth.uid, acceptedAt: timeStamp()
      };
      trace('account/Join: updates: %o', updates);
      await dbUpdate({ path: '/', updates, source: 'account/Member' });
      trace('account/Join: reloading user');
      // This forces an auth state change since we are using onIdTokenChanged in useAuth
      firebase.auth().currentUser.getIdTokenResult(true);
    };
    fn();
  }, [auth, accessKey, history]);

  return <LinearProgress />;
};

export default AccountAccept;
