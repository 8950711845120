import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import RecipeImage from 'components/recipes/RecipeImage';

const useStyles = makeStyles((theme) => ({
  container: {
    // marginTop: 5,
  },
  scrollParent: {
    overflowX: 'hidden',
    paddingRight: 10,

  },
  scroller: {
    overflowX: 'auto',
    paddingRight: 8,
    paddingTop: 2, /* otherwise top border is hidden */
  },
  tileRoot: {
    marginRight: 10,
  },
  tile: {
    overflow: 'visible',
  },
  cardItem: {
    marginBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
  },
  title: {
    fontSize: '0,.8rem',
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10,
    paddingLeft: 10,
  },
  notFound: {
    marginBottom: 10,
  },
}));

const startWidth = document.documentElement.clientWidth;

const calcScroll = (el) => {
  if (el) {
    return {
      left: el.scrollLeft,
      width: el.scrollWidth - el.offsetWidth
    };
  }
  return { left: 0, width: 0 };
};

let ticking = false;

const MealList = ({ actionProps, autoHide, fullPage, loading, notFoundMessage, meals, title, uid, preview, ...props }) => {
  const classes = useStyles();
  const [/* width */, setWidth] = useState(startWidth);
  const [scroll, setScroll] = useState({ left: 0, width: null });

  if (meals) {
    meals.map((meal) => {
      if (meal.recipe.ingredients && typeof (meal.recipe.ingredients) === 'number') delete meal.recipe.ingredients;
      return true;
    });
  }

  useEffect(() => {
    setScroll(calcScroll(ref.current));
    const resize = () => {
      const width = document.documentElement.clientWidth;
      setWidth(width);
      setScroll(calcScroll(ref.current));
    };
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, [setWidth]);

  const ref = React.useRef(null);
  const itemRef = React.useRef(null);

  useEffect(() => {
    if (ref.current) {
      const onScroll = () => {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            setScroll(calcScroll(ref.current));
            ticking = false;
          });
          ticking = true;
        }
      };
      ref.current.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, []);

  if (loading) {
    if (autoHide) {
      return null;
    }
    return <LinearProgress />;
  }

  const scrollBy = (by) => {
    ref.current.scrollBy({ left: 2 * by * itemRef.current.clientWidth, behavior: 'smooth' });
  };

  const style = { position: 'relative', overflow: 'visible' };

  return (
    <Grid container item direction="column" className={classes.container}>
      <Grid container item direction="row" className={classes.container} justifyContent="space-between">
        <Grid item className={classes.title}>
          {title}
        </Grid>
        {!!(meals && meals.length) && (
        <Grid item justifyContent="space-between" spacing={0} style={{ marginTop: 0 }}>

            {scroll.left > 0 && (
              <IconButton onClick={() => scrollBy(-1)}><ChevronLeft /></IconButton>
            )}

            {scroll.left < scroll.width && (
              <IconButton onClick={() => scrollBy(1)}><ChevronRight /></IconButton>
            )}

        </Grid>
        )}
      </Grid>

      <Grid item container className={classes.scrollParent}>
        <Grid ref={ref} item container wrap="nowrap" className={classes.scroller} spacing={0}>
          {meals && (meals.map((meal, idx) => (
            <Grid item key={meal.recipe.slug} ref={idx === 0 ? itemRef : undefined} className={classes.cardItem}>
              <RecipeImage recipe={meal.recipe} preview={preview} style={style} sizeControl />
              {/* <RecipeCard recipe={meal.recipe} actionProps={actionProps} uid={uid} preview={preview} /> */}
            </Grid>
          )))}
        </Grid>
      </Grid>

      {props.children}
    </Grid>
  );
};

export default MealList;
