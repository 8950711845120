import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';

import PlanRecipeFooter from './PlanRecipeFooter';

import RecipeCard from 'components/recipes/RecipeCard';
import { dbSet } from 'hooks/utils/dbUpdater';
import dbWatcher from 'hooks/utils/dbWatcher';
import { timeStamp } from 'utils';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: '40vw',
    [theme.breakpoints.down('md')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
    paddingLeft: 20,
    paddingRight: 20,
  },
  drawerTitle: {
  },
}));

const defaultPlans = {};

const PlanDrawer = ({ isOpen, close, plan, setPlan, profiles, setProfiles, setPreview }) => {
  const classes = useStyles();
  const [plans, setPlans] = useState('loading');
  const [adding, setAdding] = useState(false);
  const [newName, setNewName] = useState();

  dbWatcher({ path: '/cache/plans', setDirect: setPlans, source: 'hooks/useRepeats', defaultValue: defaultPlans });

  useEffect(() => {
    if (plans && plans !== 'loading') {
      setPlan((oldPlan) => {
        if (plans && oldPlan) {
          return plans[oldPlan.name];
        }
        return null;
      });
    }
  }, [plans, setPlan]);

  const removeFromPlan = (recipe) => {
    setPlans((oldPlans) => {
      delete oldPlans[plan.name].recipes[recipe.slug];
      return { ...oldPlans };
    });
    dbSet({ path: `/cache/plans/${plan.name}/recipes/${recipe.slug}`, value: null });
  };

  const setPlanDefault = (value) => {
    dbSet({ path: `/cache/plans/${plan.name}/defaultForNewUsers`, value });
  };

  const setPlanSuggest = (value) => {
    dbSet({ path: `/cache/plans/${plan.name}/suggestionPlan`, value });
  };

  const setPlanSuggestOrder = (value) => {
    dbSet({ path: `/cache/plans/${plan.name}/suggestionOrder`, value });
  };

  const setPlanSuggestText = (value) => {
    dbSet({ path: `/cache/plans/${plan.name}/suggestionText`, value });
  };

  const createId = () => {
    // simple unique id using timestamp for meal plan unique reference
    const id = new Date();
    return id.getTime();
  };

  const addPlan = () => {
    const planId = createId();
    const newPlan = { name: newName, defaultForNewUsers: false, uses: 0, recipes: {}, externalId: planId };
    dbSet({ path: `/cache/plans/${newName}`, value: newPlan });
    setPlan(newPlan);
    setAdding(false);
  };

  const removePlan = () => {
    dbSet({ path: `/cache/plans/${plan.name}`, value: null });
    setPlan(null);
  };

  const actionProps = {};

  return (
    <Drawer anchor="right" open={isOpen} onClose={close} classes={{ paper: classes.drawerPaper }}>
      <Grid container direction="column">
        <Grid container item direction="column" className={classes.drawerTitle}>
          <Grid container item justifyContent="space-between" alignItems="center">
            <Grid item>
              <h2>Pre-defined Menus</h2>
            </Grid>
            <Grid item container direction="column" style={{ width: 'auto' }}>
              <Grid item>
                <Button startIcon={<CloseIcon />} onClick={close}>Close</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {profiles && (
          <Grid item>
            Recipe Preference Profiles: {Object.keys(profiles).length}
          </Grid>
        )}
        <Grid item><hr /></Grid>
        {plans === 'loading' && (
          <Grid item><LinearProgress /></Grid>
        )}
        {plans !== 'loading' && (
          <Grid container item spacing={2} alignItems="flex-end">
            {plan && !adding && (
              <Grid item>
                <IconButton size="small" style={{ padding: 0 }} edge={false} onClick={() => setPlan(null)}><ClearIcon /></IconButton>
              </Grid>
            )}
            <Grid item>
              <FormControl style={{ minWidth: 200 }}>
                <InputLabel id="plan-label">Pre-defined Menus</InputLabel>
                <Select
                  required labelId="plan-label"
                  value={plan || ''}
                  renderValue={(value) => value.name || ''}
                  onChange={(ev) => { setPlan(ev.target.value); setAdding(false); setNewName(''); }}
                >
                  {(Object.values(plans || [])).sort().map((plan) => (
                    <MenuItem key={plan.name} value={plan}>{plan.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              {plan && (
                <Button size="small" variant="contained" onClick={removePlan} color="primary" startIcon={<CloseIcon />}>
                  Delete Menu
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button size="small" variant="contained" onClick={() => { setPlan(null); setNewName(''); setAdding(true); }} color="primary" startIcon={<AddIcon />}>
                Add Menu
              </Button>
            </Grid>
            {adding && (
              <Grid item>
                <TextField size="small" variant="outlined" label="Plan Name" value={newName} onChange={(ev) => setNewName(ev.target.value)} />
                <IconButton style={{ marginBottom: -8 }} onClick={addPlan}><AddIcon /></IconButton>
              </Grid>
            )}
          </Grid>
        )}
        {plan && plan.recipes && (
          <Grid item container direction="column">
            <Grid item container justifyContent="flex-start" alignItems="center">
              <Grid item>
                <FormControlLabel
                  value="top" label="Default Plan for New Users?" labelPlacement="start" style={{ marginRight: 20 }}
                  control={(
                    <Checkbox
                      color="primary" name="defaultPlan"
                      checked={plan.defaultForNewUsers} onChange={(ev) => setPlanDefault(ev.target.checked)}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value="top" label="Suggestion Plan?" labelPlacement="start" style={{ marginRight: 20 }}
                  control={(
                    <Checkbox
                      color="primary" name="suggestionPlan"
                      checked={plan.suggestionPlan} onChange={(ev) => setPlanSuggest(ev.target.checked)}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  label="Suggestion Text, will be followed by Recipes" labelPlacement="start" value="top" style={{ marginRight: 20 }}
                  control={
                    <TextField size="small" variant="outlined" label="Suggestion Text" value={plan.suggestionText} onChange={(ev) => setPlanSuggestText(ev.target.value)} />
                  }
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  label="Priority of the plan in the display of suggestions" labelPlacement="start" value="top" style={{ marginRight: 20 }}
                  control={
                    <TextField size="small" variant="outlined" label="Suggestion Order" value={plan.suggestionOrder} onChange={(ev) => setPlanSuggestOrder(ev.target.value)} />
                  }
                />
              </Grid>

              <Grid item>
                <Typography variant="body1">Plan used {plan.uses} time{plan.uses === 1 ? '' : 's'}</Typography>
                <Typography variant="body1">Plan external id: {plan.externalId}</Typography>
                <IconButton onClick={() => { window.open(`/printplan?mealplan=${plan.externalId}`, '_blank'); }}><PrintOutlinedIcon /></IconButton>
              </Grid>
            </Grid>
            <Grid item container>
              {Object.entries((plan.recipes || {})).map(([slug, recipe]) => (
                <Grid key={recipe.slug} xs={12} sm={6} item style={{ marginTop: 20, position: 'relative' }}>
                  <RecipeCard
                    recipe={recipe} footer={<PlanRecipeFooter recipe={recipe} profiles={profiles} />}
                    actionProps={actionProps} preview={setPreview}
                    header={(
                      <IconButton
                        onClick={() => removeFromPlan(recipe)}
                        style={{ padding: 0, position: 'absolute', top: 0, right: 0, marginTop: -10, marginRight: -10 }}
                      >
                        <CloseIcon color="error" />
                      </IconButton>
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Drawer>
  );
};

export default PlanDrawer;
