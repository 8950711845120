import React, { useState, useEffect } from 'react';
import MicroDataRecipeImporter from 'components/recipes/MicroDataRecipeImporter';
import RecipesFromChatGPT from 'components/recipes/RecipesFromChatGPT';
import { Button } from '@material-ui/core';
import GPTShoppingListDialog from 'components/recipes/ImportChatGPTRecipeDialog';
import callChatGPTFunction from 'utils/chatgptUtils';

const ChatGPT = () => {
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const [open,setOpen] =useState(false);
  const [openlist,setOpenlist] =useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');

  useEffect(()=>{
    if (input&&(input!=='')) console.log(response);
  },[input,response]);
  

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleInputMessageChange = (event) => {
    setInputMessage(event.target.value);
  };

  const handleMessageSend = async () => {

    try {
      const generatedResponse = await callChatGPTFunction(inputMessage);
      setResponse(generatedResponse);
    } catch (error) {
      console.error('Error calling Firebase function:', error);
    }
  };


const showRecipes=(recipes)=>
{
  setOpenlist(recipes);
  console.log(recipes)};
  

return (
    <div>

    <div>
      <h3> ChatGPT via firebase</h3>
      <div>
          <div>
            <span>{inputMessage} </span>
          </div>
      </div>
      <div>
        <input type="text" value={inputMessage} onChange={handleInputMessageChange} />
        <button onClick={handleMessageSend}>Send Message</button>
      </div>
      {response && (
        <div>
          <span>Response: </span>
          <span>{JSON.stringify(response)}</span>
        </div>
      )}
    </div>

    <div>
      <h1>MicroData Recipe Importer</h1>
      <MicroDataRecipeImporter />
    </div>
    <div>
      <h1>ChatGPT Recipes</h1>
      <RecipesFromChatGPT actionName='Add Selected to Menu' open={open} setOpen={setOpen} setResults={showRecipes}/>
      {!open&&(<Button color="primary" variant="contained" onClick={() => setOpen(true)}>
        Open Dialog
      </Button>)}
    </div>

    <div>
      <h1>ChatGPT import</h1>
      {openlist&&(<GPTShoppingListDialog actionName='Add Selected to Menu' open={openlist} setOpen={setOpenlist} setResults={showRecipes}/>)}
      {!openlist&&(<Button color="primary" variant="contained" onClick={() => setOpenlist(true)}>
        Open Dialog
      </Button>)}
    </div>

  </div>

  );
};

export default ChatGPT;
