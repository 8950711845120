import React, { useState } from 'react';

// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Slider from '@material-ui/core/Slider';
import { makeStyles /* useTheme */, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import People from '@material-ui/icons/People';
import Table from 'mdi-material-ui/TableFurniture';

// import Tip from 'components/Tip';
import { trace } from 'utils';

// Not allowing household editing on wizard for now
// import Household from './Household';

const useStyles = makeStyles((theme) => ({
  input: {
    width: 42,
  },
  label: {
    fontSize: '1.2em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1em',
    },
  },
  icon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

const Eaters = ({ courseId, eaters, setEaters }) => {
  const classes = useStyles();
  const [text, setText] = useState(eaters || 4);
  const handleSliderChange = (event, eaters) => {
    trace('Wizard.Eaters: handleSliderChange: %o', eaters);
    setText(eaters);
    setEaters(eaters);
  };

  const handleInputChange = (event) => {
    trace('Wizard.Eaters: handleInputChange: %o', event.target.value);
    setText(event.target.value);
    if (!isNaN(Number(event.target.value))) {
      setEaters(Number(event.target.value));
    }
  };

  const value = eaters || 4;

  const handleBlur = () => {
    trace('Wizard.Eaters: handleBlur: %o', text);
    let eaters;
    if (!text || isNaN(Number(text))) {
      eaters = 1;
    } else {
      eaters = Number(text);
    }
    if (eaters < 1) {
      eaters = 1;
    } else if (eaters > 10) {
      eaters = 10;
    }
    setText(eaters);
    // setState((state) => ({ ...state, quantityPreferences: { ...state.quantityPreferences, eaters } }));
    trace('Wizard.Eaters: handleBlur: %o, %o', text, eaters);
    setEaters(eaters);
  };
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid container direction="row" item xs={2} alignItems="center">
        {!mobile && (
        <Grid>
          <Table className={classes.icon} />
        </Grid>
        )}
        <Grid>
          <Typography id="eaters" gutterBottom className={classes.label}>
            People eating?
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Slider
          valueLabelDisplay="auto"
          value={typeof value === 'number' ? value : 0}
          onChange={handleSliderChange}
          aria-labelledby="eaters"
          step={1}
          min={1}
          max={10}
        />
      </Grid>
      <Grid item xs="auto" style={{ paddingLeft: 20 }}>
        <Input
          className={classes.input}
          value={text}
          margin="dense"
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 1,
            max: 10,
            type: 'number',
            'aria-labelledby': 'eaters',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Eaters;
