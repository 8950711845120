import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Slider from '@material-ui/core/Slider';
// import Typography from '@material-ui/core/Typography';

const Dinners = ({ classes, state, setState }) => {
  const [text, setText] = useState(state.quantityPreferences.dinnerCount || 4);

  const update = (dinnerCount) => {
    const q = state.quantityPreferences;
    let { newDinnerCount } = q;
    if (dinnerCount === 0) newDinnerCount = 0;
    const diff = dinnerCount - q.dinnerCount;
    if (diff > 0) {
      newDinnerCount += diff;
    }
    if (newDinnerCount > dinnerCount) {
      newDinnerCount = dinnerCount;
    }
    setText(dinnerCount);
    setState({
      ...state,
      quantityPreferences: {
        ...q,
        dinnerCount,
        newDinnerCount,
      }
    });
  };

  const handleSliderChange = (event, dinners) => {
    update(dinners);
  };

  const handleInputChange = (event) => {
    setText(event.target.value);
  };

  const value = state.quantityPreferences.dinnerCount || 4;

  const handleBlur = () => {
    let dinners;
    if (!text || isNaN(Number(text))) {
      dinners = 0;
    } else {
      dinners = Number(text);
    }
    if (dinners < 0) {
      dinners = 0;
    } else if (dinners > 7) {
      dinners = 7;
    }
    update(dinners);
  };

  return (
    <Grid container item>
      <Grid item xs={1} />
      <Grid container direction="column" item xs={11}>
        <Grid container item spacing={2}>
          <Grid item xs>
            <Slider
              valueLabelDisplay="auto"
              value={typeof value === 'number' ? value : 0}
              onChange={handleSliderChange}
              aria-labelledby="dinner-count"
              step={1}
              min={0}
              max={7}
            />
          </Grid>
          <Grid item xs>
            <Input
              className={classes.input}
              value={text}
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: 7,
                type: 'number',
                'aria-labelledby': 'dinner-count',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dinners;
