/* eslint no-unused-vars : "off" */
import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import CheckIcon from '@material-ui/icons/Check';
import SendIcon from '@material-ui/icons/Send';
import WarningIcon from '@material-ui/icons/Warning';

import useAuth from 'hooks/useAuth';
import useSnack from 'hooks/useSnack';
import { dbUpdate } from 'hooks/utils/dbUpdater';
import { timeStamp } from 'utils';

const validEmail = (email) => email && email.indexOf('@');

const useStyles = makeStyles((theme) => ({
  iconButton: {
    paddingTop: 0,
    paddingBottom: 0
  },
}));

const MailStatusIcon = ({ household, member, setHousehold }) => {
  const classes = useStyles();
  const { uid, ruid, displayName, email, loading } = useAuth();
  const { setSnack } = useSnack();

  if (loading) {
    return null;
  }

  if (member.uid === ruid) {
    return (
      <Tooltip key="tooltip" arrow title="This is you!">
        <IconButton size="small" className={classes.iconButton}>
          <AccountCircleIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  }

  if (uid === ruid && !!member.uid) {
    return (
      <Tooltip key="tooltip" arrow title={`${member.name || 'User'} has joined Guustav and is a member of your household.`}>
        <IconButton size="small" className={classes.iconButton}>
          <CheckIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  }

  return null;
};

export default MailStatusIcon;
