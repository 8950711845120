function format(fmt) {
  const re = /(%?)(%([ojds]))/g;
  let args = Array.prototype.slice.call(arguments, 1);
  fmt = fmt || '';
  if (typeof fmt !== 'string') {
    args = [fmt];
    fmt = '%o';
  }
  if (args.length) {
    fmt = fmt.replace(re, (match, escaped, ptn, flag) => {
      let arg = args.shift();
      switch (flag) {
        case 'o':
          try {
            if (Array.isArray(arg)) {
              arg = JSON.stringify(arg);
            } else if (typeof arg === 'object') {
              arg = JSON.stringify(arg);
            }
          } catch (ex) {
            try {
              arg = JSON.stringify(Object.keys(arg).reduce((h, key) => Object.assign(h, { [key]: String(arg[key]) }), {}));
            } catch (ex2) {
              arg = `Could not handle ${arg}`;
            }
          }
          break;
        case 's':
          arg = `${arg}`;
          break;
        case 'd':
          arg = Number(arg);
          break;
        case 'j':
          arg = JSON.stringify(arg);
          break;
        default:
          break;
      }
      if (!escaped) {
        return arg;
      }
      args.unshift(arg);
      return match;
    });
  }

  // arguments remain after formatting
  if (args.length) {
    fmt += ` ${args.join(' ')}`;
  }

  // update escaped %% values
  fmt = fmt.replace(/%{2,2}/g, '%');

  return `${fmt}`;
}

export default format;
