import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

import fillImage from 'assets/fork_and_knife_icon.svg';
import customRecipeImage from 'assets/my_recipe_icon.svg';
import aiRecipeImage from 'assets/ai_recipe_icon.png';
import proxyImage from 'hooks/utils/imageUtils';

const noImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

const useStyles = makeStyles((theme) => ({
  mediaLazyImage: {
    backgroundImage: `url(${fillImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    width: '100%',
    aspectRatio: 16 / 9,
    objectFit: 'cover',
  },
  mediaLazyNoImage: {
    backgroundImage: `url(${fillImage})`,
    backgroundSize: 'contain',
    objectFit: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    width: '100%',
    aspectRatio: 16 / 9,
  },
  /* need this one only because our custom image is transparent */
  mediaCustomImage: {
    objectFit: 'contain',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    width: '100%',
    aspectRatio: 16 / 9,
  },
}));

/*
  noImage is just a 1x1 pixel so the background shows through
  loading = before we figure out if we have an image or not
    - we want to show the no image background with object-fit: contain
  custom
    - just show the image with no background since it should load fast
      and it's transparent
  imageUrl is null = we don't have an image
    - now use a 1 pixel image and continue to show the background no image as contain
  imageUrl is not null = we do have a real image
    - show the real image using object-fit: cover so it fills the space
*/

/*
const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
*/

const ref = React.createRef();

const RecipeImage = ({ recipe, preview, sizeControl, desiredWidth, cooked }) => {
  const classes = useStyles();
  const client = useApolloClient();
  let custom = recipe ? /\/users/.test(recipe.uri) : false;
  if (recipe.uri==='made-by-ai') custom=true;
  const [imageUrl, setImageUrl] = useState(custom ? customRecipeImage : 'loading');
  const [loaded, setLoaded] = useState(false);
  let imgOpacity = 1;
  if (cooked) imgOpacity = 0.2;
  

  useEffect(() => {
    if (!recipe || custom || !client || loaded) {
      return;
    }
    // TODO: instead of lazy, may want to roll our own isVisible observer thing,
    // because then we can wait to load the proxy image until we know we need it.
    // Right now, the proxy image loads even if the recipe is not in the viewport.
    //
    // Other option would be to do this on the server so the image url is only
    // referenced when the image is ready to be loaded. That's probably the best
    // way but, the firebase storage stuff is much nicer on the client.
    const fn = async () => {
      setLoaded(true);
      console.debug('loading proxy for %o', recipe);
      const image = await proxyImage({ client, recipe, slug: recipe.slug });
      setImageUrl(image);
    };
    fn();
  }, [client, custom, recipe, loaded]);

  let widthControl = '100%';
  if (sizeControl) { if (desiredWidth) widthControl = desiredWidth; else widthControl = '80px'; }

  if (recipe.uri==='made-by-ai') 
    {
      return (
        <img className={classes.mediaCustomImage} src={aiRecipeImage} alt={recipe.label} style={{ width: widthControl, opacity: imgOpacity }} />
      );
    }

  if (custom) {
    return (
      <img className={classes.mediaCustomImage} src={customRecipeImage} alt={recipe.label} style={{ width: widthControl, opacity: imgOpacity , borderRadius:15 }} />
    );
  }

  if (imageUrl === 'loading') {
    return (
      <img ref={ref} loading="lazy" className={classes.mediaLazyImage} src={noImage} alt={recipe.label} style={{ width: widthControl, opacity: imgOpacity, borderRadius:15  }} />
    );
  }

  if (imageUrl === null) {
    return (
      <img className={classes.mediaLazyNoImage} src={noImage} alt={recipe.label} style={{ width: widthControl, opacity: imgOpacity, borderRadius:15  }} />
    );
  }

  const handleClick = () => {
    if (preview) preview(recipe);
  };

  return (
    <img loading="lazy" className={classes.mediaLazyImage} src={imageUrl} alt={recipe.label} onClick={handleClick} style={{ width: widthControl, opacity: imgOpacity, borderRadius:15  }} />
  );
};

export default RecipeImage;
