import React, { useCallback, useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BackIcon from '@material-ui/icons/ChevronLeft';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

import RecipeCard from './RecipeCard';

import useAppStyles from 'assets/useStyles';
import useAuth from 'hooks/useAuth';
import { searchRecipes } from 'hooks/utils/recipeUtils';
import { trace } from 'utils';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Badge from '@material-ui/core/Badge';
import ChefHat from 'mdi-material-ui/Crown';
import usePremium from 'components/usePremium';

import RecipesFromChatGPT from 'components/recipes/RecipesFromChatGPT';
//import GPTMenuSearchBar from 'components/chatGPT/gptMenuSearchBar';
//import GPTShoppingListDialog from 'components/recipes/ImportChatGPTRecipeDialog';




const useStyles = makeStyles((theme) => ({

  premiumBadge: {
    //backgroundColor: 'maroon',
    color: '#8476D1',
    borderRadius: '50%',
    fontSize: '0.6em',
    top:'-20%',
    left:'-105%',
    transform:'none',
    //right: '-20px',
    //fontWeight: 'bold',
  },
}));

const RecipeSearch = ({ recipePreferences, defaultTerm, setSearching, setSearchActive, setPreview, actionProps, chatGPTEnabled, plan, executeClose, searchLabel }) => {
  const { uid } = useAuth();
  const client = useApolloClient();
  const classes = useAppStyles();
  const [search, setSearch] = useState({});
  const [term, setTerm] = useState('');
  const [curated, setCurated] = useState(true);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [, setObserver] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const classesBadge = useStyles();
  const { handlePremiumAccess, PremiumPromptComponent } = usePremium();
  const [featureText,setFeatureText]=useState('');
  const [searchPlacehoder, setsearchPlacehoder]=useState("Search for recipes by ingredients, name, keywords...");


  const [openGPT,setOpenGPT]=useState(false);
  //const [openGPTGroceries,setOpenGPTGroceries]=useState(false);
  const [gptSearchTerms, setGetSearchTerms]=useState('');
  //const [gptRecipes,setGPTRecipes]= useState([]);
  console.log(executeClose);

  const doSearch = useCallback(async ({ term, page, curated }) => {
    trace('doSearch: term: %s, page: %o, curated: %o', term, page, curated);
    if (term) {
      setSearching(true);
      setSearch((currentSearch) => {
        const loading = page === 0 ? 'top' : 'bottom';
        if (term !== currentSearch.term || curated !== currentSearch.curated) {
          return { ...currentSearch, curated, term, page: 0, loading, recipes: [], hits: null };
        }
        return { ...currentSearch, curated, term, page, loading };
      });
      const { recipes, lastPage, hits, more } = await searchRecipes({ client, uid, course: actionProps.course, recipePreferences, term, page, curated });
      setSearch((currentSearch) => {
        // Check if currentSearch and currentSearch.recipes exist and are in the expected format
        const currentRecipes = Array.isArray(currentSearch?.recipes) ? currentSearch.recipes : [];
        
        return {
          ...currentSearch,
          loading: false,
          active: true,
          page: lastPage,
          more,
          hits,
          recipes: [...currentRecipes, ...recipes],
        };
      });
      if (setSearchActive) {
        setSearchActive(true);
      }
      setSearching(false);
    } else {
      setSearch({});
      if (setSearchActive) {
        setSearchActive(false);
      }
      setSearching(false);
    }
  }, [uid, client, recipePreferences, actionProps, setSearch, setSearching, setSearchActive]);


  useEffect(() => {
    if (searchLabel) {
      setsearchPlacehoder(searchLabel);
    }
  }, [searchLabel]);

  useEffect(() => {
    if (defaultTerm) {
      doSearch({ term: defaultTerm, curated: false, page: 0 });
    }
  }, [doSearch, defaultTerm]);

  useEffect(() => {
    if (executeClose) {
      doSearch({ term: null });
    }
  }, [doSearch, executeClose]);

  useEffect(() => {
    setObserver((current) => {
      if (search.curated || !search.more || !search.term || !search.recipes) {
        if (current && current.observer) {
          current.observer.disconnect();
        }
        return {};
      }
      const lastPage = search.recipes[search.recipes.length - 1].page;
      const firstOnLastPage = search.recipes.find((r) => r.page === lastPage);
      if (!firstOnLastPage) {
        return {};
      }
      trace('current observer: %o, item: %o', current, firstOnLastPage.slug);
      if (current && current.slug === firstOnLastPage.slug) {
        return current;
      }
      if (current.observer) {
        current.observer.disconnect();
      }
      const el = document.querySelector(`#sr-${firstOnLastPage.slug}`);
      if (el) {
        const newObserver = {
          slug: firstOnLastPage.slug,
          page: lastPage,
          observer: new IntersectionObserver((entries) => {
            const item = entries[0];
            const slug = item.target.id.replace(/^sr-/, '');
            const { page } = search.recipes.find((r) => r.slug === slug) || {};
            if (page && item && item.isIntersecting) {
              doSearch({ term: search.term, curated: false, page: page + 1 });
            }
          })
        };
        newObserver.observer.observe(el);
        return newObserver;
      }
    });
  }, [doSearch, search, setObserver]);


  useEffect(() => {
    doSearch({ term, curated, page: 0 });
 }, [curated]);

  trace('search: %o', search);

  if (search && search.recipes) {
    search.recipes.map((recipe) => {
      delete recipe.ingredients;
      return true;
    });
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleGPTSearch=(searchText)=>{
    setGetSearchTerms(searchText);
    setOpenGPT(true)
  };

  const handleSearchOptionSelect = (mode) => {
    handleMenuClose();
    switch (mode) {
      case 'default':
        setCurated(true);
        break;
      case 'chatgpt':
        doSearch({term:null}); //close previous search if open
        setFeatureText('ChatGPT Recipe Access');
        handlePremiumAccess();
        handleGPTSearch(term);
        break;
      case 'extended':
        setFeatureText(' Elite Recipe Search');
        handlePremiumAccess();
        setCurated(false);
        break;
      default:
        break;
    }
  };

  const addGPTRecipes = (recipes) => {
    for (let actionProp of actionProps.actions) {
      if (actionProp.course === 'dinner') {
        actionProp.action({ recipes });
        break;
      }
    }
  }
  
  

  return (
    <Grid id="recipe-search" item container direction="column">
      <Grid item container>
        <Grid item xs={12}>
          <form onSubmit={(e) => { e.preventDefault(); doSearch({ term, curated, page: 0 }); }}>
            <TextField
              fullWidth
              value={term || ''}
              onChange={(ev) => setTerm(ev.target.value)}
              className={classes.input}
              placeholder={searchPlacehoder}
              inputProps={{ 'aria-label': 'search recipes' }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {mobile && (
                      <IconButton edge={false} onClick={handleMenuClick}><SearchIcon /><ArrowDropDownIcon /></IconButton>
                    )}
                    {!mobile && (
                      <div>
                        <Button variant="contained" onClick={handleMenuClick} color="primary" startIcon={<SearchIcon />}>
                          Search <ArrowDropDownIcon />
                        </Button>
                      </div>
                    )}
                  </InputAdornment>
                )
              }}
            />

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleSearchOptionSelect('default')}>
                Approved Recipes Search
              </MenuItem>
              {chatGPTEnabled&&(<MenuItem onClick={() => handleSearchOptionSelect('chatgpt')}>
                <Badge badgeContent={<ChefHat style={{ fontSize: '1.5em' }} />} classes={{ badge: classesBadge.premiumBadge }}>
                  <span>
                    ChatGPT Search
                  </span>
                </Badge>
              </MenuItem>)}
              <MenuItem onClick={() => handleSearchOptionSelect('extended')}>
                <Badge badgeContent={<ChefHat style={{ fontSize: '1.5em' }}/>} classes={{ badge: classesBadge.premiumBadge }}>
                  <span>
                    Elite Recipe Search
                  </span>
                </Badge>
              </MenuItem>
            </Menu>
          </form>

        </Grid>
        {search.loading === 'top' && (
          <Grid item xs={12}><LinearProgress style={{ width: '100%' }} /></Grid>
        )}
      </Grid>
      {plan&&(<RecipesFromChatGPT actionName='Add Selected to Menu' open={openGPT} setOpen={setOpenGPT} setResults={addGPTRecipes} searchTerms={gptSearchTerms} quantityPreferences={plan.value.quantityPreferences} recipePreferences={plan.value.recipePreferences} />)}
      {!plan&&(<RecipesFromChatGPT actionName='Add Selected to Menu' open={openGPT} setOpen={setOpenGPT} setResults={addGPTRecipes} searchTerms={gptSearchTerms} />)}
      {/* <GPTShoppingListDialog actionName='Add Selected to Menu' open={openGPTGroceries} setOpen={setOpenGPTGroceries} onClose={fnGPTRecipes ()=>{console.log('chatGPT completed')}} recipes={gptRecipes}/> */}
      <Grid key="search-results" container item direction="column" alignItems="center" style={{ padding: '0 2% 0 2%', border: '0px solid red' }}>
        {search.active && (
          <Grid container item justifyContent="space-between">
            <Grid item>
              {search.recipes.length > 0 && search.loading !== 'top' && (
                <h2>{search.curated ? 'Guustav Approved' : 'Guustav and Partners'} Recipes for <em>&quot;{search.term}&quot;</em></h2>
              )}
              {search.recipes.length === 0 && !search.loading && (
                <h2>No {search.curated ? 'Guustav Approved' : ''} Recipes found for <em>&quot;{search.term}&quot;</em></h2>
              )}
            </Grid>
            <Grid item>
              <Button onClick={() => doSearch({ term: null })} startIcon={<BackIcon />}>Back</Button>
            </Grid>
          </Grid>
        )}
        {search.recipes && (
          <Grid container item spacing={1} justifyContent="flex-start" alignItems="stretch" className={classes.centerOnXs}>
            {search.recipes.map((recipe,idx) => (
              <Grid id={`sr-${recipe.slug}`} key={`sr-${recipe.slug}`+idx} container alignItems="stretch" item xs={6} sm={3} md={2} style={{ border: '0px solid blue' }}>
                <RecipeCard recipe={recipe} actionProps={actionProps} uid={uid} preview={setPreview} />
              </Grid>
            ))}
            {search.more && (
              <Grid container alignItems="center" justifyContent="flex-start" item xs={6} sm={3} md={2} style={{ border: '0px solid blue' }}>
                <div style={{ width: 200, textAlign: 'center' }}>
                  <CircularProgress color="secondary" size="50%" />
                </div>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <PremiumPromptComponent featureText={featureText}/>
    </Grid>
  );
};

export default RecipeSearch;
