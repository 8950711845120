import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

const OnlineStoreConfirmModal = ({ purchases, toggle, onClose }) => {
  const [toggling, setToggling] = useState(false);

  const purchase = () => {
    setToggling(true);
    toggle({ products: purchases,
      on: true,
      cb: () => {
        setToggling(false);
        onClose();
      } });
  };

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>Mark Items as Purchased</DialogTitle>
      <DialogContent dividers>
        You added {purchases.length} item{purchase.length === 1 ? '' : 's'} to your online grocery cart.
        If you would like us to mark those items as purchased in your Guustav shopping list, tap Purchase below.
        Otherwise, you can manually mark them in Guustav now, or when you receive your online order.
      </DialogContent>
      <DialogActions>
        <Grid container direction="column" spacing={1}>
          {toggling && (
            <Grid item>
              <LinearProgress />
            </Grid>
          )}
          <Grid item container justifyContent="flex-end">
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={purchase} color="secondary">
              Purchase
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default OnlineStoreConfirmModal;
