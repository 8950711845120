import React, { useContext, useMemo, useState } from 'react';

const NavContext = React.createContext({});

const NavContextProvider = ({ children }) => {
  const [nav, setNav] = useState({});

  nav.isWizard = useMemo(() => (
    !!(nav.mode && nav.mode.match(/wizard|onboard/))
  ), [nav.mode]);

  const value = useMemo(() => ({ nav, setNav }), [nav, setNav]);
  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

const useNav = () => useContext(NavContext);

export default useNav;
export { NavContextProvider };
