import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';

import SearchResults from './SearchResults';

import useNav from 'hooks/useNav';
import useProductSearch from 'hooks/useProductSearch';

const useStyles = makeStyles((theme) => ({
}));

const Products = ({ Results, ...props }) => {
  const classes = useStyles();
  const { state, dispatch } = useProductSearch({ searchTerm: (window.location.hash || '#').slice(1) });
  const [searchTerm, setSearchTerm] = useState((window.location.hash || '#').slice(1));
  const { setNav } = useNav();

  useEffect(() => {
    setNav((nav) => ({ mode: nav.mode, back: props.back || '/groceries', next: null, pageName: 'Product Search' }));
  }, [setNav, props.back]);

  useEffect(() => {
    window.location.hash = state.searchTerm;
  }, [state.searchTerm]);

  const search = (ev) => {
    ev.preventDefault();
    dispatch({ action: 'search', searchTerm });
  };

  const searchResults = state.results;
  const showSearch = state.searchTerm && state.searchTerm.length >= 3;
  const showProgress = !state.error && (showSearch && (!searchResults || !searchResults.length));

  return (
    <>
      <Grid key="search-input" direction="column" container item>
        <form onSubmit={search} style={{ width: '100%' }}>
          <Grid key="search-input" direction="column" container item>
            <TextField
              value={searchTerm}
              onChange={(ev) => setSearchTerm(ev.target.value)}
              className={classes.input}
              placeholder="Search for products by name or description"
              inputProps={{ 'aria-label': 'search products' }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </form>
      </Grid>

      {showProgress && (
        <Grid key="search-progress" container item justifyContent="center" direction="column"><LinearProgress style={{ width: '100%' }} /></Grid>
      )}

      {state.error && (
        <Grid container item justifyContent="center" style={{ marginTop: 10 }}>
          <Alert severity="error">An error occurred while searching. Please try again later.</Alert>
        </Grid>
      )}

      {showSearch && !showProgress && !state.error && (
        <>
          {Results && (
            <Results dispatch={dispatch} state={state} />
          )}
          {!Results && (
            <SearchResults key="product-search-results" state={state} {...props} />
          )}
        </>
      )}

    </>
  );
};

export default Products;
