import { useEffect, useMemo, useState } from 'react';

import firebase from 'firebase/app';

import 'firebase/database';
import { trace } from 'utils';

const load = async ({ uid, paths, source }) => {
  trace('multiLoad[%s]: %o, %o', source, uid, paths);
  const promises = paths.map((p) => (
    new Promise((resolve, reject) => {
      const path = p[0] === '/' ? p : `/users/${uid}/${p}`;
      const ref = firebase.database().ref(path);
      const listener = (snapshot) => {
        trace('multiLoad[%s]: snapshot for %s: %o', source, p, snapshot.val());
        resolve([p, snapshot.val()]);
      };
      ref.once('value', listener);
    })
  ));
  const results = await Promise.all(promises);
  trace('multiLoad[%s]: promise results: %o', source, results);
  return results.reduce((map, value) => (
    Object.assign(map, { [value[0]]: value[1] || undefined }) // send back undefined instead of null so we can use defaults
  ), {});
};

const useDb = ({ uid, paths, source }) => {
  const [value, setValue] = useState({ value: {}, loading: true });
  useEffect(() => {
    load({ uid, paths, source }).then((value) => {
      setValue({ value, loading: false });
    });
  }, [uid, paths, source]);

  return useMemo(() => value, [value]);
};

export default useDb;
export { load };
