import dayjs from 'dayjs';
import { cacheKey, fullMeal, keywords } from 'guustav-shared';
import { recipeConflictsWithPreferences } from 'guustav-shared/utils';
import get from 'lodash/get';
import stem from 'stem-porter';

import getApprovedRecipes from 'data/approvedRecipes';
import getNotRejectedRecipes from 'data/notRejectedRecipes';
import getRejectedRecipes from 'data/rejectedRecipes';
import stopWords from 'data/stopWords';
import { GET_GUUSTAV_RECIPE_BY_URI, SEARCH_RECIPES } from 'hooks/queries';
import dbLoader from 'hooks/utils/dbLoader';
import { trace } from 'utils';

const keywordsByProduct = keywords.filter((k) => !k.cacheOnly).reduce((h, k) => Object.assign(h, { [k.product]: k }), {});

const recipePreferenceVariables = ({ recipePreferences }) => {
  const params = {};
  params.simple = recipePreferences.simple;
  params.gourmet = recipePreferences.gourmet;
  if (recipePreferences.health && recipePreferences.health.length) {
    params.health = recipePreferences.health;
  }
  if (recipePreferences.diet && recipePreferences.diet.length) {
    params.diet = recipePreferences.diet;
  }
  if (recipePreferences.excluded && recipePreferences.excluded.length) {
    params.excluded = recipePreferences.excluded;
  }
  return params;
};

const groupedRecipes = async ({ uid, course, recipePreferences }) => {
  const promises = [];
  const prefsKey1 = `${cacheKey({ recipePreferences })}/${course.toLowerCase()}`;
  const prefsKey2 = `time=30/${course.toLowerCase()}`;
  promises.push(dbLoader({ uid, path: 'events/recipe/actions/viewed', key: 'recent' }));
  promises.push(dbLoader({ uid, path: 'planHistory', key: 'planHistory' }));
  promises.push(dbLoader({ uid, path: 'recipes/repeated', key: 'repeated' }));
  promises.push(dbLoader({ uid, path: 'recipes/custom', key: 'custom' }));
  promises.push(dbLoader({ uid, path: 'recipes/saved', key: 'saved' }));

  promises.push(dbLoader({ uid, path: `recipes/suggestions/${prefsKey1}`, key: 'suggestions1' }));
  promises.push(dbLoader({ path: `/suggestions/${prefsKey1}`, key: 'suggestions2' }));
  if (prefsKey1 !== prefsKey2) {
    promises.push(dbLoader({ uid, path: `recipes/suggestions/${prefsKey2}`, key: 'suggestions3' }));
    promises.push(dbLoader({ path: `/suggestions/${prefsKey2}`, key: 'suggestions4' }));
  }

  promises.push(dbLoader({ uid, path: 'recipes/trending', key: 'trending' }));
  /* promises.push(dbLoader({ path: `/trending/${prefsKey1}`, key: 'trending2' })); */
  /*
  if (prefsKey1 !== prefsKey2) {
    promises.push(dbLoader({ uid, path: `recipes/trending/${prefsKey2}`, key: 'trending3' }));
    promises.push(dbLoader({ path: `/trending/${prefsKey2}`, key: 'trending4' }));
  }
  */
  promises.push(dbLoader({ uid, path: 'plan', key: 'plan' }));
  return Promise.all(promises).then((results) => {
    trace('results from promises: %o', results);
    const recipesByType = results.reduce((map, result) => {
      let { key } = result;
      if (key.match(/.*[0-9]$/)) {
        key = key.slice(0, -1);
      }
      let { data } = result;
      if (key === 'planHistory') {
        const dates = Object.values(data || {});
        data = {};
        dates.forEach((date) => {
          ['dinners', 'lunches', 'breakfasts'].forEach((course) => {
            (date[course] || []).forEach((meal) => {
              if (date.feedback && date.feedback[course]) {
                meal.recipe.feedback = date.feedback[course][meal.recipe.slug];
              }
              data[meal.recipe.slug] = meal.recipe;
            });
          });
        });
      } else if (key === 'recent') {
        const today = dayjs();
        // { date: { ev1: event, ev2: event, ... }, date: ... }
        data = Object.entries(data || {})
          .filter(([date, events]) => today.diff(dayjs(date), 'd') < 30)
          .sort(([adate, _], [bdate, __]) => bdate.localeCompare(adate))
          .slice(0, 20)
          .reduce((h, [date, events]) => {
            Object.values(events).forEach((ev) => {
              Object.assign(h, { [ev.key]: { ...ev.data } });
            });
            return h;
          }, {});

        /*
        if (!data) data = {};
        let data1 = {};
        let today = new Date();
        let thirtydays = 1000*60*60*24*30
        let historyDate = Date.parse(today)-thirtydays;
        Object.entries(data||{}).forEach(([date, event]) => {
          let milliDate = Date.parse(date);
          if (milliDate > historyDate)
          Object.entries(event).forEach((subEvent) => {
            let recentMeal = subEvent[1].data;
            data1[recentMeal.slug] = recentMeal;
          });
        });
        data=data1;
         */
      }
      return Object.assign(map, { [key]: { ...(map[key] || {}), ...data } });
    }, {});
    trace(recipesByType);
    const plan = recipesByType.plan || {};
    const current = (plan.dinner || []).concat(plan.lunch || []).concat(plan.breakfast || []).reduce((h, m) => Object.assign(h, { [m.recipe.slug]: 'plan' }), {});
    const newRecipes = {
      custom: [],
      repeated: [],
      saved: [],
      planHistory: [],
      recent: [],
      trending: [],
      suggestions: [],
    };
    Object.keys(newRecipes).forEach((key) => {
      const recipes = recipesByType[key];
      trace('recipes for %s: %o', key, recipes);
      Object.entries(recipes || {}).forEach(([slug, data]) => {
        let recipe = data;
        if (data.recipe) {
          recipe = data.recipe;
        }
        if (!current[slug]) {
          newRecipes[key].push(recipe);
        } else if (key === 'saved' && current[slug] === 'repeats') {
          newRecipes[key].push(recipe);
        }
        current[recipe.slug] = key;
      });
    });
    trace('groupedRecipes: %o', newRecipes);
    return newRecipes;
  });
};

const estimateRecipeCount = async ({ recipePreferences }) => {
  const cache = (await dbLoader({ path: '/cache/recipes' })) || {};
  const cacheSize = Object.entries(cache)
    .filter(([product, recipes]) => !!keywordsByProduct[product])
    .reduce((sum, [product, recipes]) => sum += Object.values(recipes).filter((r) => !r.rejected).length, 0);
  let count = 0;
  Object.entries(cache).forEach(([product, recipes]) => {
    Object.values(recipes).forEach((recipe) => {
      if (!recipe.rejected) {
        const mismatches = recipeConflictsWithPreferences({ recipePreferences, recipe });
        if (!mismatches) {
          count++;
        }
      }
    });
  });
  trace('rp: %o, %d, %d', recipePreferences, count, cacheSize);
  return [count, cacheSize];
};

const populateRecipe = async ({ client, meal }) => {
  trace('Populate recipe: %o', meal);
  if (meal.recipe.uri.match(/edamam/i) && typeof meal.recipe.ingredients !== 'object') {
    const result = await client.query({ query: GET_GUUSTAV_RECIPE_BY_URI, variables: { uri: meal.recipe.uri } });
    trace('populate result: %o', result);
    Object.assign(meal.recipe, { ...get(result, 'data.getGuustavRecipeByURI'), ...meal.recipe });
  }
  return meal;
};

const populateMealRecipes = async ({ client, breakfasts, lunches, dinners }) => {
  trace('Filling in recipes: %o', dinners);
  const promises = [];
  (breakfasts.meals || []).forEach((meal) => {
    promises.push(populateRecipe({ client, meal }));
  });
  (lunches.meals || []).forEach((meal) => {
    promises.push(populateRecipe({ client, meal }));
  });
  (dinners.meals || []).forEach((meal) => {
    promises.push(populateRecipe({ client, meal }));
  });

  await Promise.all(promises);
  return { filledBreakfasts: breakfasts, filledLunches: lunches, filledDinners: dinners };
};

const searchRecipes = async ({ client, uid, recipePreferences, term, curated, page = 0, limit = 50, fullMeals = true }) => {
  if (curated) {
    const notRejected = await getNotRejectedRecipes(); // these are in the cache and not explicitly rejected
    trace('searchRecipes: curated, not rejected: %d, term: %o', Object.keys(notRejected).length, term);
    trace('searchRecipes: curated, not rejected: %o', notRejected);
    // TODO: maybe due this in a server function instead
    const lTerm = term.toLowerCase();
    const lSplit = lTerm.split(/[^a-z0-9]/);
    const sTerm = stem(term.toLowerCase());
    const sSplit = lTerm.split(/[^a-z0-9]/).map((w) => stem(w));
    const recipes = Object.values(notRejected).map((recipe) => {
      let score = 0;
      if (!recipe.label) {
        return 0;
      }
      const label = recipe.label.toLowerCase();
      const labelSplit = label.split(/[^a-z0-9]/);
      if (label.indexOf(lTerm) >= 0) {
        score += 100;
      } else {
        const labelWords = labelSplit.filter((w) => !stopWords.has(w));
        if (lSplit.length > 1) {
          const count = lSplit.filter((w) => labelWords.includes(w)).length;
          if (count === lSplit.length) {
            score += 80;
          } else if (count > 0) {
            score += 15 * count;
          }
        } else if (labelWords.includes(lTerm)) {
          score += 100;
        }
        const labelStems = labelSplit.filter((w) => !stopWords.has(w)).map((w) => stem(w));
        if (sSplit.length > 1) {
          const count = sSplit.filter((w) => labelStems.includes(w)).length;
          if (count === sSplit.length) {
            score += 80;
          } else if (count > 0) {
            score += 15 * count;
          }
        } else if (labelStems.includes(sTerm)) {
          score += 50;
        }
      }
      if (recipe.product && recipe.product.toLowerCase() === lTerm) {
        score += 75;
      }
      if (recipe.product && stem(recipe.product.toLowerCase()) === sTerm) {
        score += 50;
      }
      const keyword = recipe.keyword ? (typeof recipe.keyword === 'string' ? recipe.keyword : recipe.keyword.keyword) : null;
      if (keyword) {
        if (keyword.toLowerCase() === lTerm) {
          score += 65;
        }
        if (stem(keyword.toLowerCase()) === sTerm) {
          score += 40;
        }
      }
      if (recipe.source && recipe.source.toLowerCase() === lTerm) {
        score += 30;
      }
      if (recipe.source && stem(recipe.source.toLowerCase()) === sTerm) {
        score += 20;
      }
      if (score >= 50 && recipe.approved) {
        score += 101;
      }
      return { ...recipe, score };
    }).filter((r) => r.score > 0)
      .sort((a, b) => {
        if (a.score > b.score) {
          return -1;
        }
        if (b.score > a.score) {
          return 1;
        }
        return a.label.localeCompare(b.label);
      })
      .slice(0, limit);
    trace('searchRecipes: search results: %o', recipes);
    return { recipes, hits: recipes.length, more: false, page: 1 };
  }
  const approved = await getApprovedRecipes(); // these are in the cache and explicitly approved
  const rejected = await getRejectedRecipes(); // these are in the cache and explicitly rejected
  trace(
    'searchRecipes: not curated, approved %d, rejected: %d, term: %o, page: %o',
    Object.keys(approved).length,
    Object.keys(rejected).length,
    term,
    page
  );
  const recipes = [];
  let queryPage = page;
  let more = true;
  let hits = null;
  const rpv = recipePreferenceVariables({ recipePreferences });
  while (recipes.length < 24 && more) {
    queryPage += 1;
    const { data } = await client.query({ query: SEARCH_RECIPES,
      variables: { query: term, uid, recipePreferences: rpv, page: queryPage } });
    trace('searchRecipe: page: %o, edamam results: %o', queryPage, data);
    if (hits === null) {
      hits = data.searchRecipes.count || 0;
    }
    if (data.searchRecipes.recipes) {
      const currentPage = queryPage;
      recipes.push(...data.searchRecipes.recipes
        .filter((recipe) => !rejected[recipe.slug] && (!fullMeals || fullMeal(recipe).fullMeal))
        .map((recipe) => ({ ...recipe, page: currentPage, approved: !!(approved[recipe.slug] && approved[recipe.slug].approved) })));
      more = data.searchRecipes.more;
    } else {
      more = false;
    }
  }
  const response = { recipes, hits, lastPage: queryPage, more };
  trace('Returning from search: %o', response);
  return response;
};

export { estimateRecipeCount, groupedRecipes, recipePreferenceVariables, populateMealRecipes, searchRecipes };
