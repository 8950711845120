import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import firebase from 'firebase/app';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import { useHistory } from 'react-router-dom';

import { addEvent, addGlobalEvent } from 'hooks/utils/events';
import { onLogin } from 'hooks/utils/loginUtils';
import { signInWithMerge } from 'hooks/utils/mergeUtils';
import { isNative, postNativeEvent, trace } from 'utils';

const AuthContext = React.createContext();

const AuthContextProvider = ({ children }) => {
  const history = useHistory();
  // When the user first arrives, we don't know if they are a visitor or not which can
  // cause a loading glitch as it loads the defaults, then refreshes once the user is
  // fully loaded. This localStorage item tells us they are probably not anonymous so
  // assume we need to wait to load them.
  // However, if this item gets stuck but they are somehow logged out, we need a way to
  // treat them as anonymous eventually so set a timer and clear it if they haven't
  // logged in after a certain interval.
  const [state, setState] = useState({ loading: true, uid: null, detach: null, anonymous: undefined });
  trace('useAuth: state: %o current user: %o', state, firebase.auth().currentUser);

  // Have to do the ref thing or setTimeout and ref.on will have the original value due to stale closure:
  // https://github.com/facebook/react/issues/14010
  const nonClosedRef = React.useRef(state);
  nonClosedRef.current = state;

  const logout = useCallback(() => {
    addEvent({ action: 'logout', category: 'activity', uid: state.uid });
    // why remove this - it's just the date when they logged in last
    // localStorage.removeItem('guustav.lastLogin');
    firebase.auth().signOut().then(() => {
      // This causes an extra refresh
      // window.location = '/';
    });
  }, [state.uid]);

  useEffect(() => {
    if (state.uid) {
      const nativeEnv = isNative();
      if (nativeEnv === 'flutter') {
        postNativeEvent({ action: 'uid', uid: state.uid, email: state.email });
        const flutterLog = (e) => {
          trace('Got flutterLog: %o', e);
          const { detail } = e;
          const { action, category, data } = detail;
          if (state.uid) {
            addEvent({ action, category, data, uid: state.uid });
          }
          addGlobalEvent({ action, category, data, uid: state.uid, email: state.email });
        };
        window.addEventListener('flutter-log', flutterLog);
        return () => {
          window.removeEventListener('flutter-log', flutterLog);
        };
      }
    }
  }, [state.uid, state.email]);

  useEffect(() => {
    const nativeEnv = isNative();
    if (!nativeEnv) {
      return;
    }
    const handler = async (credential, invitation, path) => {
      try {
        if (invitation) {
          path = `/account/join/?a=${invitation.accessKey}`;
        }
        trace('Flutter sign in credential: %o', credential);
        trace('Flutter invitation: %o', invitation);
        const authInfo = await signInWithMerge(credential);
        if (!authInfo) {
          trace('Flutter sign in failure');
          postNativeEvent({ action: 'login', message: 'There was a problem logging in. Please contact support@guustav.com' });
        } else {
          trace('Flutter: sign in sucess, redirect path: %s', path);
          window.location = path;
        }
      } catch (error) {
        trace('Flutter sign in error: %s, %s', error.message, error.code);
      }
    };
    if (nativeEnv === 'flutter') {
      trace('adding flutter listener');
      const flutterLogin = (e) => {
        const data = e.detail.credential;
        trace('Flutter event: %o', data);
        let cred = firebase.auth.AuthCredential.fromJSON(data);
        if (data.providerId === 'facebook.com') {
          const { accessToken } = e.detail.credential;
          trace('Flutter: facebook login');
          cred = firebase.auth.FacebookAuthProvider.credential(accessToken);
        } else if (data.providerId === 'google.com') {
          trace('Flutter: google login');
          cred = firebase.auth.GoogleAuthProvider.credential(data.idToken);
        } else if (data.providerId === 'apple.com') {
          trace('Flutter: apple login');
          const provider = new firebase.auth.OAuthProvider('apple.com');
          cred = provider.credential({
            idToken: data.idToken,
            rawNonce: data.nonce,
          });
        } else if (data.providerId === 'email') {
          trace('Flutter: email login');
          cred = firebase.auth.EmailAuthProvider.credential(data.idToken, data.accessToken);
        }
        handler(cred, null, '/account/initialize');
      };
      const reload = (e) => {
        if (e?.detail?.path) {
          trace('Got native reload request to: %s', e.detail.path);
          history.push(e.detail.path);
        } else {
          trace('Got native reload request at: %s', window.location.href);
          window.location.reload();
        }
      };
      window.addEventListener('flutter-login', flutterLogin);
      window.addEventListener('flutter-logout', logout);
      window.addEventListener('flutter-reload', reload);
      return () => {
        window.removeEventListener('flutter-login', flutterLogin);
        window.removeEventListener('flutter-logout', logout);
        window.removeEventListener('flutter-reload', reload);
      };
    }
  }, [logout, history]);

  useEffect(() => {
    const login = async (authInfo) => {
      trace(`web: useAuth firebase state changed: ${JSON.stringify(authInfo)}`);
      if (authInfo && authInfo.uid) {
        firebase.analytics().setUserId(authInfo.uid);
      }
      if (authInfo && authInfo.uid) {
        if (authInfo.isAnonymous) {
          setState({ uid: authInfo.uid, anonymous: authInfo.isAnonymous });
        } else {
          const priorUid = nonClosedRef.current.uid;
          const result = await onLogin({ authInfo, priorUid, action: 'login' });
          trace(`web: useAuth onLogin result: ${JSON.stringify(result)}`);
          setState((priorState) => {
            const newState = { loading: false, ...result };
            trace('useAuth: setState on anon change. before: %o, after: %o', priorState, newState);
            return newState;
          });
          if (result.error) {
            alert('There was a problem logging you in. Please contact support@guustav.com');
          }
        }
      } else {
        setState({ loading: false, uid: 'visitor', detach: null, displayName: 'Guest', primary: null, email: null });
      }
    };
    // onIdTokenChange fires on both login and getIdTokenResult(true) which we use for invitation acceptance
    // firebase.auth().onAuthStateChanged(login);
    trace('adding firebase onIdTokenChanged listener');
    firebase.auth().onIdTokenChanged(login);
  }, []);

  const value = useMemo(
    () => ({ logout,
      loading: state.loading,
      uid: state.uid,
      anonymous: state.anonymous,
      admin: state.admin,
      email: state.email,
      displayName: state.displayName,
      ruid: state.ruid,
      rname: state.rname,
      primary: state.primary }),
    [logout, state.loading, state.uid, state.ruid, state.anonymous, state.email, state.displayName, state.rname, state.primary, state.admin]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
// Note: We don't want to use state here because then we would have to pass
// dispatch/state down to every component that uses this. This way, we can
// just call useAuth whereever we want and state is maintained in the context.
const useAuth = () => useContext(AuthContext);

export default useAuth;
export { AuthContextProvider };
