// Configure Firebase.
// It's ok for this information to be public, the client doesn't have the keys
// to do anything. When users log in, they are able to do what they need to
// based on the database rules.
let config;
if (process.env.REACT_APP_ENV === 'production') {
  config = {
    apiKey: 'AIzaSyD0FqoJTyvdtWjoEjkJWQHUxsipqsilUeQ',
    authDomain: 'app.guustav.com',
    databaseURL: 'https://guustav-prod-app-default-rtdb.firebaseio.com',
    projectId: 'guustav-prod-app',
    storageBucket: 'guustav-prod-app.appspot.com',
    messagingSenderId: '1023724066788',
    appId: '1:1023724066788:web:19dc255cc6463eb45019f1',
    measurementId: 'G-QZB51DHL66'
  };
} else {
  config = {
    apiKey: 'AIzaSyC0PSONGwfhz13I4igYA7PWrJRWo15Nhh0',
    authDomain: 'qa-app.guustav.com',
    databaseURL: 'https://guustav-qa-app-default-rtdb.firebaseio.com',
    projectId: 'guustav-qa-app',
    storageBucket: 'guustav-qa-app.appspot.com',
    messagingSenderId: '887063912575',
    appId: '1:887063912575:web:cfee7c99b635418df89f4a',
    measurementId: 'G-ECSZVQ7012'
  };
}

export default config;
