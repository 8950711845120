// FullWeekPlanningIntro.js
import React, { useEffect } from 'react';
import { Typography, Grid, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import Image from 'assets/MezplandShoppingDrawing.png';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  img: {
    height: '40vh',
    [theme.breakpoints.down('xs')]: {
      height: '20vh',
    },
  },
}));


const FullWeekPlanningIntro = ({ step, finishStep, enableNext }) => {
  const classes = useStyles();

  useEffect(() => {
    enableNext(true);
  }, [enableNext]);

  useEffect(() => {
    if (step.id === 'fullWeekPlanningIntro' && step.exiting) {
      finishStep();
    }
  }, [step, finishStep]);

  return (
    <Grid container direction="column" justifyContent='center' alignItems='center' style={{ width: '90%', marginLeft: 20 }}>
      <div className={classes.imgContainer}>
        <img src={Image} alt="Inventory Management" className={classes.img} />
      </div>
      <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', color: '#483D8B' }}>Effortless Shopping with Automatic Grocery Lists</Typography>
      <Typography variant="body1" style={{ marginBottom: '16px' }}>
        Discover how Guustav can streamline your grocery shopping experience by predicting what you need based on your previous purchases.
      </Typography>
      <List>
        {[
          'Tell us about your unique culinary preferences, dietary requirements, and any food allergies. We\'ll ensure your meals are always to your liking',
          'Design a diverse, easy-to-cook menu with our extensive collection of mouthwatering recipes',
          'Take control of your grocery list effortlessly. Add items beyond your meal ingredients, and we\'ll remember your shopping frequency, reminding you when it\'s time for a refill',
          'With Guustav, your optimized shopping list is always ready, whether you shop in-store or prefer online grocery services.'].map((text, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: '#4CAF50', color: '#fff' }}>{index + 1}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="body1">{text}</Typography>}
              />
            </ListItem>
          ))}
      </List>
    </Grid>
  );
};

export default FullWeekPlanningIntro;
