import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SearchIcon from '@material-ui/icons/Search';

import useAppStyles from 'assets/useStyles';

const ReviewSearchBox = ({ term, setTerm }) => {
  const classes = useAppStyles();
  const theme = useTheme();
  const [input, setInput] = useState('');
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <form onSubmit={(e) => { e.preventDefault(); setTerm(input); }}>
      <TextField
        fullWidth
        value={input || ''}
        onChange={(ev) => setInput(ev.target.value)}
        className={classes.input}
        placeholder="Search for recipes ..."
        inputProps={{ 'aria-label': 'search recipes' }}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {mobile && (
                <IconButton edge={false} onClick={() => setTerm(input)}><SearchIcon /></IconButton>
              )}
              {!mobile && (
                <Button variant="contained" onClick={() => setTerm(input)} color="primary" startIcon={<SearchIcon />}>
                  Search
                </Button>
              )}
            </InputAdornment>
          )
        }}
      />
    </form>
  );
};

export default ReviewSearchBox;
