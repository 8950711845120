import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import BackIcon from '@material-ui/icons/ChevronLeft';
import Pagination from '@material-ui/lab/Pagination';
import { fullMeal } from 'guustav-shared';

import PlanRecipeFooter from './PlanRecipeFooter';

import RecipeCard from 'components/recipes/RecipeCard';
import { GET_EDAMAM_RECIPE_BY_URI } from 'hooks/queries';
import useAppStyles from 'hooks/useAppStyles';

const ReviewSearchResults = ({ reset, search, setPreview, actionProps, updatePagination, cache, onPlan, profiles, plan }) => {
  const classes = useAppStyles();
  const [fullMealsOnly, setFullMealsOnly] = useState(false);
  const [fullMeals, setFullMeals] = useState({});
  const [unreviewedOnly, setUnreviewedOnly] = useState(false);
  const client = useApolloClient();
  const [filtering, setFiltering] = useState(false);

  const [unreviewed, setUnreviewed] = useState({});

  useEffect(() => {
    setFiltering(true);
    const fn = async () => {
      const full = {};
      const un = {};
      const unknown = [];
      search.recipes.forEach((recipe) => {
        const cachedItem = cache[recipe.slug];
        if (unreviewedOnly) {
          if (!cachedItem || !(cachedItem.approved || cachedItem.rejected)) {
            un[recipe.slug] = recipe;
          }
        }
        if (fullMealsOnly) {
          if (cachedItem) {
            if (cachedItem.fullMeal) {
              if (cachedItem.fullMeal.fullMeal) {
                full[recipe.slug] = true;
              }
            } else {
              full[recipe.slug] = true;
              if (!unreviewedOnly || un[recipe.slug]) {
                unknown[recipe.uri] = recipe.slug;
              }
            }
          } else {
            full[recipe.slug] = true;
            if (!unreviewedOnly || un[recipe.slug]) {
              unknown[recipe.uri] = recipe.slug;
            }
          }
        }
      });
      const promises = Object.entries(unknown).map(([uri, slug]) => {
        const q = async () => {
          const { data } = await client.query({ query: GET_EDAMAM_RECIPE_BY_URI, variables: { uri } });
          if (data && data.getEdamamRecipeByURI) {
            const r = JSON.parse(data.getEdamamRecipeByURI);
            const fm = fullMeal(r);
            if (fm && fm.fullMeal) {
              full[slug] = true;
            } else {
              full[slug] = false;
            }
          } else {
            full[slug] = false;
          }
        };
        return q();
      });
      await Promise.all(promises);
      setFullMeals(full);
      setUnreviewed(un);
      setFiltering(false);
    };
    fn();
  }, [cache, client, search.recipes, fullMealsOnly, unreviewedOnly]);

  if (search.error) {
    return <div>{search.error}</div>;
  }
  return (
    <Grid key="search-results" container item direction="column" alignItems="center" style={{ padding: '0 2% 0 2%', opacity: search.searching ? 0.5 : 1.0 }}>
      <Grid container item justifyContent="space-between">
        {!search.searching && !!search.query && search.hits === 0 && (
          <h2>No results found for <em>&quot;{search.query}&quot;</em></h2>
        )}
        {search.hits > 0 && [
          <Grid item key="hits">
            <h2>{search.hits} {search.curated ? 'Guustav Approved' : ''} Recipe{search.hits === 1 ? '' : 's'} found for <em>&quot;{search.query}&quot;</em></h2>
          </Grid>,
          <Grid item key="back">
            <Button onClick={reset} startIcon={<BackIcon />}>Back</Button>
          </Grid>,
          <Grid key="filter" item xs={12}>
            <FormControlLabel
              value="top" label="Full Meals Only" labelPlacement="start" style={{ marginRight: 20 }}
              control={(
                <Checkbox
                  color="primary" name="fullMeals"
                  checked={fullMealsOnly} onChange={(ev) => setFullMealsOnly(ev.target.checked)}
                />
              )}
            />
            <FormControlLabel
              value="top" label="Un-Reviewed Only" labelPlacement="start"
              control={(
                <Checkbox
                  color="primary" name="unreviewed"
                  checked={unreviewedOnly} onChange={(ev) => setUnreviewedOnly(ev.target.checked)}
                />
              )}
            />
          </Grid>,
          <Grid item xs={12} key="pagination">
            <Pagination page={search.page} count={search.pages} onChange={updatePagination} />
          </Grid>
        ]}
      </Grid>
      <Grid container item spacing={3} justifyContent="flex-start" alignItems="stretch" className={classes.centerOnXs} style={{ marginTop: 10 }}>
        {!!search.recipes && search.recipes.map((recipe) => (
          <Grid
            id={`sr-${recipe.slug}`} key={`sr-${recipe.slug}`} container alignItems="stretch" item
            xs={11} sm={4} md={3}
            style={{
              display: !filtering && ((unreviewedOnly && !unreviewed[recipe.slug]) || (fullMealsOnly && !fullMeals[recipe.slug])) ? 'none' : 'block',
              opacity: filtering ? 0.5 : 1.0,
            }}
          >
            <RecipeCard
              recipe={recipe} actionProps={actionProps} preview={setPreview} reviewing
              highlight={onPlan(recipe) ? '#0000ff' : undefined}
              footer={plan ? <PlanRecipeFooter recipe={recipe} profiles={profiles} />
                : <div style={{ marginTop: -10, textAlign: 'center' }}><small>{recipe.source}</small></div>}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ReviewSearchResults;
