import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import RepeatIcon from '@material-ui/icons/Repeat';

import EdamamIcon from 'assets/Edamam_Badge_Transparent.svg';
import useStyles from 'assets/useStyles';
import { ReactComponent as GandalfTheWhite } from 'assets/wizard_icon_white.svg';
import RecipeDialog from 'components/recipes/RecipeDialog';
import RecipeGridList from 'components/recipes/RecipeGridList';
import RecipeModal from 'components/recipes/RecipeModal';
import RecipeSearch from 'components/recipes/RecipeSearch';
import useAuth from 'hooks/useAuth';
import useNav from 'hooks/useNav';
import useRepeats from 'hooks/useRepeats';
import dbLoader from 'hooks/utils/dbLoader';
import { groupedRecipes } from 'hooks/utils/recipeUtils';
import { trace } from 'utils';
import Wizard from 'wizards/plan';
import { Typography } from '@material-ui/core';

const course = 'dinner';

const Recipes = ({ ...props }) => {
  const classes = useStyles();
  const hash = decodeURIComponent((window.location.hash || '#').slice(1));
  const [adding, setAdding] = useState(false);
  const [recipes, setRecipes] = useState('loading');
  const [recipePreferences, setRecipePreferences] = useState('loading');
  const [showWizard, setShowWizard] = useState(false);
  const [preview, setPreview] = useState();
  const { loading: authLoading, uid, anonymous } = useAuth();
  const { setNav } = useNav();
  const { loading: repeatsLoading, repeats, repeat, unrepeat, saved, save, unsave } = useRepeats();
  const [changed, setChanged] = useState(true);
  const [, setSearching] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  const [plans, setPlans] = useState([]);
  let menuMode='';
  if (anonymous) menuMode='fullWeekPlanning';

  const loading = authLoading || recipes === 'loading' || repeatsLoading;
  trace(recipes);

  const repeatOn = (recipe) => !!(repeats && repeats[recipe.slug]);
  const toggleRepeat = (recipe) => {
    if (repeatOn(recipe)) {
      unrepeat(recipe);
    } else {
      repeat({ course: 'dinner', recipe });
    }
  };

  const saveOn = (recipe) => !!(saved && saved[recipe.slug]);
  const toggleSave = (recipe) => {
    if (saveOn(recipe)) {
      unsave(recipe);
      unrepeat(recipe);
    } else {
      save({ course: 'dinner', recipe });
    }
  };

  const actionProps = {
    toggles: [
      {
        offText: 'Set Repeat',
        onText: 'Stop Repeat',
        offIcon: <RepeatIcon className={classes.unrepeated} />,
        onIcon: <RepeatIcon className={classes.repeated} />,
        on: repeatOn,
        toggle: toggleRepeat
      },
      {
        offText: 'Save',
        onText: ' Unsave',
        offIcon: <BookmarkBorderOutlinedIcon className={classes.unrepeated} />,
        onIcon: <BookmarkIcon className={classes.repeated} />,
        on: saveOn,
        toggle: toggleSave
      },
    ]
  };

  useEffect(() => {
    setNav((nav) => ({ pageName: 'My Recipes' }));
  }, [setNav]);

  useEffect(() => {
    dbLoader({ uid, path: 'household/recipePreferences' }).then((value) => {
      setRecipePreferences(value || {});
    });
  }, [uid, setRecipePreferences]);

  useEffect(() => {
    dbLoader({ path: '/cache/plans' }).then((value) => {
      const suggestPlans = [];
      Object.values(value).forEach((element, index) => {
        if (element.suggestionPlan) {
          element.recipesArray = [];
          Object.values(element.recipes).forEach((recipe, i) => {
            element.recipesArray.push(recipe);
          });
          suggestPlans.push(element);
        }
      });
      suggestPlans.sort((a, b) => {
        let aOrder = 0;
        if (a.suggestionOrder) aOrder = parseInt(a.suggestionOrder);
        let bOrder = 0;
        if (b.suggestionOrder) bOrder = parseInt(b.suggestionOrder);
        return (bOrder - aOrder);
      });
      setPlans(suggestPlans || []);
    });
  }, []);

  useEffect(() => {
    if (uid && changed && recipePreferences !== 'loading') {
      groupedRecipes({ uid, recipePreferences, course: 'dinner' }).then((recipes) => {
        trace(recipes);
        setRecipes(recipes);
        console.log(recipes);
        setChanged(false);
      });
    }
  }, [uid, recipePreferences, setRecipes, repeats, saved, changed]);

  const use = ({ recipe }) => {
    setChanged(true);
  };
  let recipessaved = false;
  if (recipes.saved) { recipessaved = recipes.saved.length > 0; }

  let recipesrepeat = false;
  if (recipes.repeated) { recipesrepeat = recipes.repeated.length > 0; }

  let recipescustom = false;
  if (recipes.custom) { recipescustom = recipes.custom.length > 0; }

  console.log(actionProps);
  return (
    <>
      <RecipeDialog key="recipe-dialog" open={adding} setOpen={setAdding} course={course} use={use} />
      <RecipeModal key="recipe-modal" readonly={!preview || !preview.uri.includes(uid)} recipe={preview} close={() => setPreview(null)} use={use} />
      <Wizard isOpen={showWizard} close={(path) => { setShowWizard(false); if (path) { props.history.push(path); } }} source="recipes" mode={menuMode} />
      <Grid container direction="column" style={{ marginBottom: 100 }}>
        <RecipeSearch
          setSearching={setSearching} setSearchActive={setSearchActive} setPreview={setPreview}
          recipePreferences={recipePreferences} actionProps={actionProps} defaulTerm={hash}
          chatGPTEnabled={false}
        />
        <Grid container item direction="column" style={{ paddingTop: 20, paddingLeft: 20, display: loading || searchActive ? 'none' : 'block' }}>
        <Grid container item direction="column">
            <Grid>
              <Button
                color="secondary" size="large" variant="contained"
                onClick={() => setShowWizard(true)}
                startIcon={<GandalfTheWhite />}
                style={{borderRadius:15}}
              >
                &nbsp;Click here to create a menu
              </Button>,
            </Grid>
          </Grid>
          <Divider component="div" xs={12} style={{ marginTop: 20, marginBottom: 20 }} />

          {recipessaved && (
          <RecipeGridList
            fullPage loading={loading} recipes={recipes.saved} title="Your Saved Recipes" uid={uid}
            notFoundMessage="You have not saved any recipes" preview={setPreview} actionProps={actionProps}
          />
          )}

          {recipesrepeat && (
          <RecipeGridList
            fullPage loading={loading} recipes={recipes.repeated} title="Your Repeat Recipes" uid={uid}
            notFoundMessage="You have not marked any recipes for repeat" preview={setPreview} actionProps={actionProps}
          />
          )}

          {recipescustom && (
          <RecipeGridList
            fullPage loading={loading} recipes={recipes.custom} title="Your Custom Recipes" actionProps={actionProps}
            uid={uid}
            notFoundMessage="You have not created any custom recipes yet" preview={setPreview}
          />
          )}
          {!anonymous&&(
          <Button
            color="secondary" size="medium" variant="outlined"
            onClick={() => setAdding(true)}
          >
            <AddIcon />&nbsp;Add custom recipe
          </Button>)}
          {plans.map((plan, idx) => <RecipeGridList key={idx} fullPage loading={loading} recipes={plan.recipesArray} title={`${plan.suggestionText} Recipes`} autoHide actionProps={actionProps} uid={uid} preview={setPreview} />)}

          <RecipeGridList
            fullPage loading={loading} recipes={recipes.planHistory} title="Previously Planned Recipes" autoHide
            actionProps={actionProps} uid={uid} preview={setPreview}
          />
          <RecipeGridList fullPage loading={loading} recipes={recipes.recent} title="Recently Seen Recipes" autoHide actionProps={actionProps} uid={uid} preview={setPreview} />
        </Grid>

        <Grid container justifyContent="center"><img alt="Edamam attribution" style={{ marginTop: 20, height: 20 }} src={EdamamIcon} /></Grid>
      </Grid>
    </>
  );
};

export default Recipes;
