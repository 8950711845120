import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { useHistory } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

import useAuth from 'hooks/useAuth';
import useAccount from 'hooks/useAccount';
import dbLoad from 'hooks/utils/dbLoader';
import { dbSet, dbUpdate } from 'hooks/utils/dbUpdater';
import { timeStamp, trace } from 'utils';

const AccountJoin = ({...props}) => {
  const auth = useAuth();
  const account = useAccount();
  const history = useHistory();
  const [accessKey, setAccessKey] = useState('');
  const [error, setError] = useState('');
  const [joining, setJoining] = useState(false);

  useEffect(() => {
    if (auth.loading || account.loading || joining) {
      return;
    }
    if (auth.uid && auth.ruid && auth.uid !== auth.ruid) {
      trace('account/join: primary is set');
      setError(`You are already a member of the ${account.account.primary.household}. If you join another household you will be removed from your previous one.`);
    }
  }, [auth, account, joining]);

  const join = async () => {
    setJoining(true);
    const invitation = await dbLoad({ path: `/invitations/${accessKey}`, source: 'account/join' });
    if (!invitation) {
      setJoining(false);
      setError('The invitation for the given code could not be found.');
      return;
    }
    if (invitation.ruid && invitation.ruid !== auth.ruid) {
      setJoining(false);
      setError('The invitation code has already been claimed by someone else. Please ask the household owner to re-invite you.');
      return;
    }
    const primary = { accessKey, household: invitation.household, uid: invitation.uid };
    trace('account/join: setting primary account for %s: %o', auth.uid, primary);
    // Have to set this before we check the other account because the security rule
    // uses it to give them access. If they don't have access, it will throw an exception;
    // they may also have access but no longer be allowed so we'll handle that too.
    await dbSet({ uid: auth.uid, path: 'account/primary', value: primary, source: 'account/join' });
    let allowed = 'off';
    try {
      // Probably this will error out if they aren't allowed but just in case
      trace('account/join: checking allowed in %s', invitation.uid);
      allowed = await dbLoad({ uid: invitation.uid, path: `household/members/${accessKey}/allowed`, source: 'account/join' });
      trace('account/join: allowed: %o', allowed);
    } catch (ex) {
      // If they get a read error because of a rule, then they aren't allowed
      trace('account/join: got error checking membership status: %o', ex);
    }
    if (allowed !== 'on') {
      setError('The invitation has been rescinded. Please ask the household owner to re-invite you.');
      setJoining(false);
      return;
    }
    const updates = {};
    invitation.acceptedAt = timeStamp();
    invitation.ruid = auth.uid;
    updates[`/invitations/${accessKey}`] = invitation;
    trace('account/join: invitation is allowed, reading members');
    const members = await dbLoad({ uid: invitation.uid, path: 'household/members', source: 'account/join' });
    trace('account/join: members: %o', members);
    const member = members[accessKey];
    updates[`/users/${invitation.uid}/household/members/${accessKey}`] = {
      ...member, email: auth.email, name: auth.displayName, uid: auth.uid, acceptedAt: timeStamp()
    };
    trace('account/join: updates: %o', updates);
    await dbUpdate({ path: '/', updates, source: 'account/join' });
    trace('account/join: reloading user');
    // This forces an auth state change since we are using onIdTokenChanged in useAuth
    firebase.auth().currentUser.getIdTokenResult(true);
    setError(`You have successfully joined the ${invitation.household}. You will be redirected to the home page.`);
    setTimeout(() => {
      history.push('/');
    }, 3000);
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <h2>Join Household</h2>
      <Grid item xs={10} sm={8} md={6}>When you join another household, you will share menus, shopping lists, and recipes. You will lose access to any history you ahve created, until you leave the other household.</Grid>
      {error && !joining && (
        <Grid item xs={10} sm={8} md={6}>
          <Alert severity="warning" style={{ marginTop: 10 }}>{error}</Alert>
        </Grid>
      )}
      {joining && (
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <LinearProgress />
          </Grid>
        </Grid>
      )}
      <Grid item xs={8} sm={8} md={6} style={{ marginTop: 10 }}>
        <TextField
          size="small"
          label="Invitation Code"
          defaultValue={accessKey}
          helperText="Paste the code you received in the invitation from the owner of the household."
          variant="outlined"
          onChange={(ev) => setAccessKey(ev.target.value)}
        />
      </Grid>
      <Grid item xs={8} md={6} style={{ marginTop: 8 }}>
        {!joining && !error && (
          <Alert severity="info">Don't have a code? You can continue to use Guustav as the account owner. If you are sure you belong in another household, ask the owner to invite you by adding you as a household member on the Preferences -> Household page.</Alert>
        )}
      </Grid>
      <Grid item>
        <Button onClick={join} variant="contained" color="primary" disabled={joining}>Join</Button>
        <Link style={{ marginLeft: 10 }} onClick={() => history.push('/')}>Back</Link>
      </Grid>
    </Grid>
  );
};

export default AccountJoin;
