import React from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const Styles = ({ ...props }) => (
  <div>
    <h2>Header 2</h2>
    <Typography variant="h2">Header 2 - Typo</Typography>
    <h3>Header 3</h3>
    <Typography variant="h3">Header 3 - Typo</Typography>
    <h4>Header 4</h4>
    <Typography variant="h4">Header 4 - Typo</Typography>
    <h5>Header 5</h5>
    <Typography variant="h5">Header 5 - Typo</Typography>
    <Typography variant="body1">Body1 Typo</Typography>
    <Typography variant="body2">Body2 Typo</Typography>
    <Typography variant="caption">Caption Typo</Typography>
    <h6>Header 6</h6>
    <Typography variant="h6">Header 6 - Typo</Typography>
    <p>Paragraph</p>
    <Typography variant="p">Paragraph Typo</Typography>
    <TextField variant="outlined" placeholder="Textfield" fullWidth />
    <TextField variant="outlined" placeholder="Textfield - small" fullWidth size="small" />

  </div>
);

export default Styles;
