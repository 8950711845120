import dbLoader from 'hooks/utils/dbLoader';

let rejectedRecipes;

const getRejectedRecipes = async () => {
  if (rejectedRecipes) {
    return rejectedRecipes;
  }
  rejectedRecipes = await dbLoader({ path: '/review' });
  return rejectedRecipes;
};

export default getRejectedRecipes;
