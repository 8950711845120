import * as dayjs from 'dayjs';

import { load } from './dbLoaderMulti';

const minimumFrequencyDays = 3;

const paths = [
  'inventory',
  '/global/departments',
  '/global/supplies',
];

const inventory = async ({ uid, source, messages }) => {
  const trace = (...args) => {
    if (messages) {
      messages.push(args);
    }
  };
  trace('[%s] in inventory', source);
  const { inventory = {}, '/global/departments': departments, '/global/supplies': supplies } = await load({ uid, paths, source: 'inventoryUtils' });
  const due = (item) => {
    trace('[%s]: %s %s %s', source, '-'.repeat(40), item.slug, '-'.repeat(40));
    trace('[%s]: checking item due: %o', source, item);
    // This only applies to repeat purchases;
    if (!item.dates) {
      trace('[%s]: item: %s, no dates', source, item.slug);
      return false;
    }
    // repeat purchase
    if (typeof item.frequency === 'undefined') { // user hasn't specific a frequency yet
      trace('[%s]: item: %s, no frequency set', source, item.slug);
      if (supplies[item.slug] && supplies[item.slug].frequency) {
        item.frequency = supplies[item.slug].frequency;
        trace('[%s]: item: %s, frequency from supplies: %s', source, item.slug, item.frequency);
      } else if (departments[item.department] && departments[item.department].frequency) {
        item.frequency = departments[item.department].frequency;
        trace('[%s]: item: %s, frequency from department (%s): %s', source, item.slug, item.department, item.frequency);
      }
    }
    if (!item.frequency) {
      trace('[%s]: item: %s, one-time purchase, not due', source, item.slug);
      return false; // This means the user (or us) explicitly said it's a one-time purchase
    }
    const lastPurchased = Object.keys(item.dates).sort().reverse()[0];
    trace('[%s]: item: %s, lastPurchased: %o, frequency: %d', source, item.slug, lastPurchased, item.frequency);
    const daysAgo = dayjs().diff(dayjs(lastPurchased), 'd');
    if (daysAgo < minimumFrequencyDays) {
      trace('[%s]: item: %s, days since purchased (%o) < min (%o) so not due', source, item.slug, daysAgo, minimumFrequencyDays);
      // Never due when we purchased more recently than the min
      return false;
    }
    const today = dayjs();
    const realDueDate = dayjs(lastPurchased).add(item.frequency * 7, 'd');
    const closeEnoughDueDate = realDueDate.subtract(3, 'd');
    const due = today.isSame(closeEnoughDueDate) || today.isAfter(closeEnoughDueDate);
    trace(
      '[%s]: item: %s, due is %s because realDueDate is %s and closeEnoughDueDate is %s and today is %s',
      source,
      item.slug,
      due,
      realDueDate.format('YYYY-MM-DD'),
      closeEnoughDueDate.format('YYYY-MM-DD'),
      today.format('YYYY-MM-DD')
    );
    return due;
  };

  // For ingredients to control sorting
  const likelyHaveIngredient = (item) => {
    // Always needed means you get it every week and use it up so you you are not likely to have it.
    // Check the specific item defaults if we have them
    if (supplies && supplies[item.slug]) {
      const { alwaysNeeded } = supplies[item.slug];
      if (typeof alwaysNeeded !== 'undefined') {
        return !alwaysNeeded;
      }
    }
    // Check the department defaults
    if (departments && departments[item.department]) {
      const { alwaysNeeded } = departments[item.department];
      if (typeof alwaysNeeded !== 'undefined') {
        return !alwaysNeeded;
      }
    }
    return false;
  };

  const purchases = Object.entries(inventory || {})
    .filter(([slug, data]) => !!(data.dates && Object.keys(data.dates).length))
    .reduce((result, [slug, item]) => {
      if (typeof item.frequency === 'undefined') { // user hasn't specific a frequency yet
        if (supplies[item.slug] && supplies[item.slug].frequency) {
          item.frequency = supplies[item.slug].frequency;
        } else if (departments[item.department] && departments[item.department].frequency) {
          item.frequency = departments[item.department].frequency;
        }
      }
      return Object.assign(result, {
        [slug]: {
          ...item,
          lastPurchased: Object.keys(item.dates).sort().reverse()[0],
          needed: false,
          due: due(item),
        }
      });
    }, {});

  const repeats = Object.entries(inventory || {})
    .filter(([slug, data]) => data.frequency || data.frequency === 0)
    .reduce((result, [slug, data]) => Object.assign(result, {
      [slug]: {
        ...data,
        lastPurchased: Object.keys(data.dates || []).sort().reverse()[0],
        due: due(data),
      }
    }), {});

  return {
    departments,
    due,
    likelyHaveIngredient,
    purchases,
    repeats,
    supplies,
  };
};

export default inventory;
