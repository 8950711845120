import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Cancel from '@material-ui/icons/Cancel';
import reasons from 'guustav-shared/reviewReasons';
import { useHistory } from 'react-router-dom';

import ImageDialog from 'components/ImageDialog';
import RecipeDrawer from 'components/recipes/RecipeDrawer';
import RecipeModal from 'components/recipes/RecipeModal';
import { recipePreferences } from 'data/defaultPreferences';
import useAuth from 'hooks/useAuth';
import { dbSet } from 'hooks/utils/dbUpdater';
import dbWatcher from 'hooks/utils/dbWatcher';
import { trace } from 'utils';

const Filtered = ({ ...props }) => {
  const history = useHistory();
  const { admin } = useAuth();
  const [filtered, setFiltered] = useState('loading');
  const [search, setSearch] = useState(false);
  const [current, setCurrent] = useState();
  const [image, setImage] = useState();

  dbWatcher({ path: '/review', set: setFiltered, source: 'admin/filtered' });

  useEffect(() => {
    if (!admin) {
      alert('You are not allowed to do this.');
      history.push('/');
    }
  }, [admin, history]);

  const remove = (slug) => {
    dbSet({ path: `/review/${slug}`, value: null });
  };

  const add = (recipe) => {
    dbSet({ path: `/review/${recipe.slug}`, value: recipe });
  };

  const actionProps = {
    title: 'Recipes to Filter',
    course: 'dinner',
    actions: [
      { action: add, text: 'Add To Filtered', icon: <AddCircleOutlineIcon />, ...origin }
    ],
  };

  trace('filtered: %o', filtered);

  if (filtered === 'loading') {
    return <LinearProgress />;
  }

  const reason = (recipe) => {
    const list = [];
    Object.entries(reasons).forEach(([field, label]) => {
      if (recipe.reason && recipe.reason[field]) {
        list.push(label);
      }
    });
    if (recipe.reason && recipe.reason.notes) {
      list.push(recipe.reason.notes);
    }
    return list.join('; ');
  };

  const recipes = Object.values(filtered.value || {});

  return [
    <ImageDialog key="image" open={!!image} title={image ? image.label : ''} onClose={() => setImage(null)} image={image ? image.image : null} />,
    <RecipeModal key="modal" close={() => setCurrent(null)} readonly recipe={current} noToggle />,
    <Grid key="list" container direction="column" alignItems="center">
      <Button color="primary" variant="contained" onClick={() => setSearch(true)} style={{ position: 'absolute', right: 10, top: 10 }}>
        Add to Filtered
      </Button>
      <h3>Filtered Recipes</h3>
      {!recipes.length && (
        <div>No filtered recipes</div>
      )}

      {!!recipes.length && (
        <Grid item container xs={10}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell style={{ width: 300 }}>Reason</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recipes.sort((a, b) => a.label.localeCompare(b.label)).map((r) => (
                  <TableRow key={`${r.slug}`}>
                    <TableCell><Link style={{ color: 'blue' }} href="#" onClick={(e) => { e.preventDefault(); setCurrent(r); }}>{r.label}</Link></TableCell>
                    <TableCell>{reason(r)}</TableCell>
                    <TableCell>
                      <img style={{ cursor: 'pointer' }} onClick={() => setImage(r)} src={r.image} width="100" height="100" alt={r.label} />
                    </TableCell>
                    <TableCell>{r.source}</TableCell>
                    <TableCell>
                      <IconButton size="small" onClick={() => remove(r.slug)}>
                        <Cancel />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <RecipeDrawer key="recipe-drawer" isOpen={!!search} close={() => setSearch(null)} actionProps={actionProps} recipePreferences={recipePreferences} />
    </Grid>
  ];
};

export default Filtered;
