// usePremium.js
import React, { useState, useEffect,useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from '@material-ui/core';
import Graphics from 'assets/MezplandShoppingDrawing.png';
import { Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Box } from '@material-ui/core';


const PremiumPrompt = ({ open, onClose, onJoin, featureText }) => {
  const premiumFeatures = [
    {
      name: "Elite Recipe Search",
      description: "Unlock 2.5+ million delectable recipes. Find any dish you've set your heart on or discover new favorites!"
    },
    {
      name: "Family-Friendly Account Sharing",
      description: "Loop in your family with one Guustav account. Everyone can contribute to the grocery list or even shop!"
    },
    {
      name: "Pantry & Fridge Smart Recipes",
      description: "Turn lingering pantry and fridge items into delicious meals. Our AI crafts recipes using what you already have, cutting waste and upping your culinary game."
    },
    {
      name: "Recipe Remix by AI",
      description: "Change of heart on a planned recipe? Our AI tweaks your ingredient list into a whole new culinary adventure."
    }
  ];
  
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipRef]);


  return (
    <Dialog open={open} onClose={onClose}
    >

      <DialogTitle>
        <Typography color='secondary' style={{ fontWeight: 450, fontSize: '1.3em' }}>
          Premium features
        </Typography>
      </DialogTitle>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <img src={Graphics} alt='premium graphics' style={{ maxWidth: '80%', height: 'auto' }} />
      </Box>
      <DialogContent>
        
        <DialogContentText>
        Try {featureText} today — it's a premium feature that you can access for FREE, but only for a limited time! And there's more — take a look at our growing list of exciting premium features below.
        <ul style={{ paddingLeft: '10px' }}>
        {premiumFeatures.map((feature, index) => (
          <li key={index} ref={tooltipRef}>
          {feature.name}{' '}
          <Tooltip title={feature.description} arrow open={tooltipOpen === index}>
            <IconButton aria-label="info" onClick={() => setTooltipOpen(index)}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </li>
        ))}
      </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onJoin} color="secondary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const usePremium = () => {
  const [showPremiumPrompt, setShowPremiumPrompt] = useState(false);

  const handlePremiumAccess = () => {
    setShowPremiumPrompt(true);
  };

  const closePremiumPrompt = () => {
    setShowPremiumPrompt(false);
  };

  const handleJoinPremium = () => {
    // Logic to join premium, maybe redirect to a payment page or show a popup
    console.log('Joining premium...');
    // Update the premium status (you may need to use React context or a global state manager to share the status across components)
    closePremiumPrompt();
  };

  const PremiumPromptComponent = (props) => (
    <PremiumPrompt open={showPremiumPrompt} onClose={closePremiumPrompt} onJoin={handleJoinPremium} featureText={props.featureText || 'This feature '} />
  );

  return {
    handlePremiumAccess,
    PremiumPromptComponent
  };
};

export default usePremium;
