import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  smallBadge: {
    fontSize: "0.3rem", 
    backgroundColor:'green'
  },
  row: {
    border: '1px solid #ccc',
    paddingBottom: 0,
  },
  fadeRow: {
    border: '1px solid #ccc',
    opacity: 0,
    transition: 'opacity 2s 0s',
    paddingBottom: 0,
  },
  autoWidth: {
    width: 'auto',
  },
  switchContainer: {
    width: 100,
  },
  checkboxContainer: {
    width: 80,
  },
  checkboxIcon: {
    borderRadius: 4,
    // border: '1px solid #ccc',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
  },
  checkbox: {
    width: '90%',
    height: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      height: '40%',
    },
  },
  nameCell: {
    padding: 10,
    paddingTop: 4,
    paddingBottom: 12,
    [theme.breakpoints.down('sm')]: {
      padding: 4,
    },
    /* width: 'auto', */
    flexGrow: 1,
    // borderLeft: '1px solid #ccc',
    // borderRight: '1px solid #ccc',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    }
  },
  name: {
    fontWeight: 480,
    fontSize: '1.1rem',
  },
  quantityEdit: {
    width: 150,
  },
  quantityReadonly: {
    width: 110,
  },
  switchText: {
    paddingLeft: 4,
    paddingRight: 4,
    whiteSpace: 'nowrap',
  },
  ingredientCollapse: {
    fontSize: '.9rem',
    marginTop: 4,
    paddingLeft: 16,
    marginLeft: 0,
    lineHeight: '1.1rem',
    [theme.breakpoints.down('sm')]: {
      textIdent: -4,
      paddingLeft: 8,
    },
  },
  ingredientsOpen: {
  },
  ingredientsClosed: {
    marginBottom: 8,
  },
  remove: {
    width: 80,
    // borderLeft: '1px solid #ccc',
  },
}));

export default useStyles;
