import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    width: 200,
  },
  cardHeader: {
    padding: 14,
    paddingLeft: 8,
    height: '3.2rem',
  },
  headerContent: {
    '& span': {
      display: '-webkit-box',
      '-webkitLineClamp': 3,
      '-webkitBoxOrient': 'vertical',
      lineHeight: '1.2rem',
      minHeight: '1.6rem',
      height:'3.6rem',
      fontSize: '1rem',
      overflow: 'hidden',
      textAlign: 'center',
      fontWeight: 500,
      [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      },
      [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      },
      [theme.breakpoints.down('xs')]: {
      },
    },
  },
  headerAvatar: {
    marginRight: 0,
    marginTop: 0,
  },
  headerTitle: {
  },
  headerAction: {
  },
  cardActions: {
    justifyContent: 'center',
  },
  bottomNav: {
    lineHeight: '1rem',
    fontSize: '0.7rem',
    height: 40,
  },
  bottomNavAction: {
  },
  bottomNavActionLabel: {
    lineHeight: '1rem',
    fontSize: '0.7rem',
  },
  toggle: {
    padding: '0!important',
    marginRight: 8,
    border: 'none!important',
    backgroundColor: '#ffffff!important',
    /*
    borderRadius: '20%',
    border: '2px solid #bbb',
    */
  },
  toggleSelected: {
    border: 'none!important',
    color: `${theme.palette.success.main}!important`,
  },
  starred: {
    position: 'absolute',
    right: 0,
    top: -12,
    transform: 'rotate(15deg)',
    color: 'green',
  },
}));

export default useStyles;
