import React, { useEffect, useState, useRef } from 'react';

import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const IngredientText = ({ classes, mobile, product, printMode, setPreviewRecipe }) => {
  const [expanded, setExpanded] = useState(false);
  const collapseRef = useRef(null);

  useEffect(() => {
    if (mobile) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [mobile]);

  const text = [];
  Object.values(product.recipes || []).forEach((r, idx) => {
    !!r.quantity.notes && text.push(<div key={`rn-${r.slug}-${idx}`} style={{ marginBottom: 4 , fontWeight:'350', fontSize:'0.7rem'}}>{r.quantity.notes}</div>);
    let qtyText = '';
    if (r.quantity.text) qtyText = r.quantity.text;

    text.push(
      <div key={`ingr-${r.slug}-${idx}`} style={{ marginBottom: 4 , fontWeight:'350', fontSize:'0.7rem'}}>&nbsp;Recipe details: {qtyText}&nbsp;from&nbsp;
        <Link underline={printMode ? 'none' : 'always'} href="#" onClick={(e) => { e.stopPropagation(); e.preventDefault(); setPreviewRecipe(r); }}>
          {r.label}
        </Link>

      </div>
    );
  });

  const expand = () => setExpanded(!expanded);

  return (
    <Grid item container justifyContent="space-between" alignItems="flex-start" onClick={expand}>
      <Grid item className={classes.ingredientCollapse} ref={collapseRef}>
        {mobile && (
          <Collapse in={expanded} onClick={expand} collapsedSize="1.1rem" classes={{ entered: classes.ingredientsOpen }} style={{ height: expanded ? collapseRef.current?.scrollHeight : 0 }}>
            {text}
          </Collapse>
        )}
        {!mobile && <span>{text}</span>}
      </Grid>
      <Hidden mdUp>
        <Grid item onClick={() => setExpanded(!expanded)}>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Grid>
      </Hidden>
    </Grid>
  );
};

export default IngredientText;
