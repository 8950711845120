import React from 'react';

import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import { GET_VERSION } from 'hooks/queries';

const Admin = ({ ...props }) => {
  const { data, error, loading } = useQuery(GET_VERSION);

  if (loading) {
    return null;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="center">
      <Grid item container direction="column" justifyContent="flex-start" alignItems="center">
        <Grid item>
          <h4>Admin</h4>
        </Grid>
        <Grid item>
          <ul>
            <li><Link to="/admin/review">Recipe Management</Link></li>
            <li><Link to="/admin/suggested">Generate Suggested</Link></li>
            <li><Link to="/admin/trending">Trending Recipes</Link></li>
            <li><Link to="/admin/filtered">Filtered Recipes</Link></li>
            <li><Link to="/admin/products">Searchable Products</Link></li>
            <li><Link to="/admin/events">My Events</Link></li>
            <li><Link to="/admin/users">Users</Link></li>
            <li><Link to="/admin/history">My History</Link></li>
            <li><Link to="/admin/badImages">Bad Images</Link></li>
            <li><Link to="/admin/chatGPT">chatGPT</Link></li>
          </ul>
        </Grid>
      </Grid>
      <Grid item>
        <div style={{ marginTop: 50 }}>GIT Version: {data.gitVersion}</div>
      </Grid>
    </Grid>
  );
};

export default Admin;
