import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import CalendarToday from '@material-ui/icons/CalendarToday';
import CheckIcon from '@material-ui/icons/Check';
import KitchenIcon from '@material-ui/icons/Kitchen';
import SortIcon from '@material-ui/icons/Tune';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import StoreIcon from '@material-ui/icons/Store';
import RecipeIcon from 'mdi-material-ui/ChefHat';
import SelectedIcon from 'mdi-material-ui/CheckAll';

const useStyles = makeStyles((theme) => ({
  menuOn: {
    background: theme.palette.warning.light,
  }
}));

const SortMenu = ({ pantryType, sort, onSort, style }) => {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(null);

  const openMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };
  const sorts = {
    'By Department': StoreIcon,
    'By Recipe': RecipeIcon,
    Alphabetical: SortByAlphaIcon,
    
  };
  if (pantryType === 'due') {
    sorts['Due for Purchase'] = CalendarToday;
  } else {
    sorts.Pantry = KitchenIcon;
  }

  return (
    <>
      <IconButton
        style={style} aria-label="sort-menu" aria-controls="long-menu" aria-haspopup="true"
        onClick={openMenu}
      >
        <SortIcon />
      </IconButton>
      <Menu open={!!menuAnchor} onClose={closeMenu} anchorEl={menuAnchor} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} getContentAnchorEl={null}>
        {Object.entries(sorts).map(([text, Icon]) => (
          <MenuItem button key={text} onClick={() => { onSort(text); closeMenu(); }} className={sort === text ? classes.menuOn : undefined}>
            <ListItemIcon><Icon /></ListItemIcon>
            <ListItemText primary={sort === text ? <span>{text} <CheckIcon size="small" style={{ verticalAlign: 'middle' }} /></span> : text} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SortMenu;
