import dbLoader from 'hooks/utils/dbLoader';

let approvedRecipes;

const getApprovedRecipes = async () => {
  if (approvedRecipes) {
    return approvedRecipes;
  }
  const all = await dbLoader({ path: '/approved' });
  approvedRecipes = Object.entries(all)
    .filter(([slug, recipe]) => !!recipe.approved)
    .reduce((h, [slug, recipe]) => Object.assign(h, { [slug]: recipe }), {});
  return approvedRecipes;
};

export default getApprovedRecipes;
