import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { isNative, isQA } from 'utils';

const OnlineStoreHelpModal = ({ store, onOpen, onClose }) => {
  const iosBrowser = /ipad|iphone/i.test(navigator.userAgent) && !isNative();

  const openMobileApp = () => {
    if (isQA) {
      window.location.replace('https://apps.apple.com/us/app/guustav-qa/id1599423482');
    } else {
      window.location.replace('https://apps.apple.com/us/app/guustav-plan-shop-and-eat/id1573452801');
    }
  };

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>Online Shopping</DialogTitle>
      <DialogContent dividers>
        While we work to integrate more closely with stores, we offer the ability to guide you through online shopping.
        We will open a new tab or window to the store you chose, showing the store items matching the next item in your list.
        Once you have added the item to your cart in the store window, switch back to the Guustav window and mark the item as purchased.
        {iosBrowser && (
          <div style={{ marginTop: 8 }}>
            For a better online shopping experience, try our mobile app.
            <div style={{ marginTop: 8 }}>
              <Button variant="contained" color="secondary" onClick={openMobileApp}>Install App</Button>.
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onOpen}>
          Start Shopping
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnlineStoreHelpModal;
