import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
// import Tooltip from '@material-ui/core/Tooltip';
// import StarIcon from '@material-ui/icons/Star';
import startCase from 'lodash/startCase';
import { Grid, Button } from '@material-ui/core';

import useStyles from './recipeCardStyles';

import RecipeImage from 'components/recipes/RecipeImage';
import useAuth from 'hooks/useAuth';

const RecipeCard = ({ recipe, actionProps, preview, header, footer, ...props }) => {
  const classes = useStyles();
  const { admin } = useAuth();

  // added visible to show x on top right in review pages, fix that if this breaks something
  const style = { position: 'relative', overflow: 'visible', borderRadius: 15 };
  // let approved = admin && props.person ? false : !!recipe.approved;
  if (props.highlight) {
    style.border = `2px solid ${props.highlight}`;
  } else if (admin && props.reviewing) {
    if (recipe.approved) {
      if (!props.person || recipe.approved[props.person]) {
        // approved = true;
        style.border = '2px solid green';
        style.overflow = 'visible';
      }
    } else if (recipe.rejected) {
      if (!props.person || recipe.rejected[props.person]) {
        style.border = '2px solid red';
      }
    }
  }
  let displayBottom = true;
  if ((actionProps) && (actionProps.actions)) displayBottom = (actionProps.actions.length > 0);

  return (
    <Card raised elevation={2} className={classes.card} style={style}>
      <CardActionArea className={classes.cardActionArea} onClick={() => preview(recipe)}>
 
        <RecipeImage recipe={recipe} />
      {header}
      <CardHeader
        classes={
          {
            root: classes.cardHeader,
            title: classes.headerTitle,
            content: classes.headerContent,
            action: classes.headerAction,
            avatar: classes.headerAvatar,
          }
        }
        title={
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%' }}
          >
            {startCase(recipe.label)}
          </Grid>
        }
      />
      </CardActionArea>


      {!!(actionProps && actionProps.actions && displayBottom) && (
        <CardActions disableSpacing className={classes.cardActions}>

          <Grid container justifyContent={actionProps.actions.length === 1 ? "center" : "space-around"} alignItems='center' directon='row'>
            {actionProps.actions.map((actionProp, index) => (
              <Grid item xs={12 / actionProps.actions.length} key={`action-${actionProp.text}`} container justifyContent="center" alignItems="center">
                <Button
                  onClick={() => actionProp.action({ recipe })}
                  startIcon={typeof actionProp.icon === 'function' ? actionProp.icon(recipe) : actionProp.icon}
                  classes={{ root: classes.bottomNavAction, label: classes.bottomNavActionLabel }}
                  color='secondary'
                >
                  {actionProp.text}
                </Button>
              </Grid>
            ))}
          </Grid>
        </CardActions>
      )}
      {!!(actionProps && actionProps.toggles) && (
        <CardActions disableSpacing className={classes.cardActions}>
          <Grid container justifyContent={actionProps.toggles.length === 1 ? "center" : "space-around"} alignItems='center' direction='row'>
            {actionProps.toggles.map((toggle) => (
              <Grid item xs={12 / actionProps.toggles.length} key={`action-${toggle.onText}`} container justifyContent="center" alignItems="center">
                <Button
                  onClick={() => toggle.toggle(recipe)}
                  startIcon={toggle.on(recipe) ? toggle.onIcon : toggle.offIcon}
                  classes={{ root: classes.bottomNavAction, label: classes.bottomNavActionLabel }}
                  color='secondary'
                >
                  {toggle.on(recipe) ? toggle.onText : toggle.offText}
                </Button>
              </Grid>
            ))}
          </Grid>
        </CardActions>
      )}
      {footer}
    </Card>
  );
};

export default RecipeCard;
