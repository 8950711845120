import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const NotesDialog = ({ label, open, note, title, onClose, onSave, slug }) => {
  const [value, setValue] = useState(note);

  useEffect(() => {
    setValue(note || '');
  }, [note]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="xs">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <TextField autoFocus fullWidth variant="outlined" label={label} value={value} onChange={(ev) => setValue(ev.target.value)} multiline rows={3} columns={60} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { onSave({ slug, note: value }); onClose(); }} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotesDialog;
