import React, { useState, useEffect } from 'react';

import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, Grid, DialogActions, Divider } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AccessTime from '@material-ui/icons/AccessTime';
import ExpandMoreIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Brightness5 from '@material-ui/icons/Brightness5';
import Brightness6 from '@material-ui/icons/Brightness6';
import Brightness7 from '@material-ui/icons/Brightness7';
import LocalHospital from '@material-ui/icons/LocalHospital';
import PeopleIcon from '@material-ui/icons/People';
import Restaurant from '@material-ui/icons/Restaurant';
import clsx from 'clsx';
import _ from 'lodash';
import Poison from 'mdi-material-ui/BottleTonicSkull';
import Scale from 'mdi-material-ui/ScaleBalance';

import DietaryFilters from 'components/preferences/DietaryFilters';
import DietBalancing from 'components/preferences/DietBalancing';
import Gourmet from 'components/preferences/Gourmet';
import Simple from 'components/preferences/Simple';
import useRepeats from 'hooks/useRepeats';
import useSnack from 'hooks/useSnack';
import { dbSet } from 'hooks/utils/dbUpdater';
import { addEvent } from 'hooks/utils/events';
import Eaters from 'wizards/plan/eaters';
import Breakfasts from 'wizards/plan/quantityPreferences/Breakfasts';
import Dinners from 'wizards/plan/quantityPreferences/Dinners';
import Lunches from 'wizards/plan/quantityPreferences/Lunches';
import NewDinners from 'wizards/plan/quantityPreferences/NewDinners';
import NewLunches from 'wizards/plan/quantityPreferences/NewLunches';

const useStyles = makeStyles((theme) => ({
  input: {
    width: 42,
  },
  label: {
    fontSize: '1.2em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1em',
    },
  },
  icon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  chip: {
    minWidth: 100,
    marginBottom: 8,
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      minWidth: 70,
      fontSize: '.7em',
      marginRight: 12,
    },
  },
  activeChip: {
    marginRight: 8,
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
  sectionHeader: {
    marginTop: 30,
  },
  sectionBody: {
    marginTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
}));

// creates a summary bar with an edit icon that pulls up a preference edit dialog
const PreferencesSummary = ({ recipePreferences, quantityPreferences, courseId, updatePrefs, uid, pushEaters}) => {
  const [openPrefDialog, setOpenPrefDialog] = useState(false);
  const [scroll] = React.useState('paper');
  const [expandedHealth, setExpandedHealth] = React.useState(false);
  const [expandedDiet, setExpandedDiet] = React.useState(false);
  const [pageState, setPageState] = useState('loading');
  const classes = useStyles();
  const { repeats } = useRepeats();
  const { setSnack } = useSnack();
  const showNew = Object.keys(repeats || {}).length > 0;
  const [mealCount, setMealCount] = useState(0);
  const [eaters, setEaters] = useState(4);
  const [hasDiets, setHasDiets] = useState(false);
  const [dietNumber, setDietNumber] = useState(0);

  const changeImpact = [];
  const startingRecipePreferences = recipePreferences;
  const startingQuantityPreferences = quantityPreferences;

  const setChangeImpact = (state) => {
    // analyze changes made to preferences
    // change to number of eaters does not trigger menu refresh
    // changes to meal quantities triggers complete refresh
    // changes to recipe preferences triggers complete menu refresh
    if (!_.isEqual(pageState.recipePreferences, startingRecipePreferences)) changeImpact.push('letsRefresh');
    if (pageState.quantityPreferences.dinnerCount !== startingQuantityPreferences.dinnerCount) changeImpact.push('letsRefresh');
    if (pageState.quantityPreferences.lunchCount !== startingQuantityPreferences.lunchCount) changeImpact.push('letsRefresh');
  };

  const setWidget = (recipePreferences, quantityPreferences) => {
    /*     if (recipePreferences && recipePreferences.health) setExpandedDiet(recipePreferences.health.length > 0);
    if (recipePreferences && recipePreferences.diet) setExpandedHealth(recipePreferences.diet.length > 0);
 */
    if (courseId === 'dinner') {
      setMealCount(quantityPreferences.dinnerCount);
      setEaters(quantityPreferences.eaters);
    }
    if (courseId === 'lunch') {
      setMealCount(quantityPreferences.lunchCount);
      setEaters(quantityPreferences.eaters);
    }
    if (courseId === 'breakfast') {
      setMealCount(quantityPreferences.breakfastCount);
      setEaters(quantityPreferences.eaters);
    }
    let dietLength = 0;
    let healthLength = 0;
    if (recipePreferences.health) {
      dietLength = recipePreferences.health.length;
      console.log(recipePreferences);
      console.log(dietLength > 0);
      setExpandedHealth(dietLength > 0);
    }
    if (recipePreferences.diet) {
      healthLength = recipePreferences.diet.length;
      setExpandedDiet(healthLength > 0);
    }
    setHasDiets((healthLength + dietLength) > 0);
    setDietNumber(healthLength + dietLength);
  };

  useEffect(() => {
    setPageState((pageState) => ({
      ...pageState,
      recipePreferences: { ...recipePreferences },
      quantityPreferences: { ...quantityPreferences }
    }));
    setWidget(recipePreferences, quantityPreferences);
  }, [setPageState, recipePreferences, quantityPreferences, setMealCount, setDietNumber, setHasDiets]);

  const handleExpandHealthClick = () => {
    setExpandedHealth(!expandedHealth);
  };

  const handleExpandDietClick = () => {
    setExpandedDiet(!expandedDiet);
  };

  const save = () => {
    dbSet({ uid, path: 'household/quantityPreferences', value: pageState.quantityPreferences }).then(() => {
      addEvent({ action: 'saveQuantityPreferences', category: 'activity', uid, data: pageState.quantityPreferences });
    });
    dbSet({ uid, path: 'household/recipePreferences', value: pageState.recipePreferences }).then(() => {
      addEvent({ action: 'saveRecipePreferences', category: 'activity', uid, data: pageState.recipePreferences });
    });
    setSnack({ message: 'Updated preferences have been saved' });
  };

  const handleCancel = () => {
    setOpenPrefDialog(false);
    setWidget(pageState.recipePreferences, pageState.quantityPreferences);
  };

  const handleSave = () => {
    setOpenPrefDialog(false);

    setWidget(pageState.recipePreferences, pageState.quantityPreferences);
    setChangeImpact();
    save();
    updatePrefs(pageState.recipePreferences, pageState.quantityPreferences, changeImpact);
  };

  const handleApply = () => {
    setOpenPrefDialog(false);

    setWidget(pageState.recipePreferences, pageState.quantityPreferences);
    setChangeImpact();
    updatePrefs(pageState.recipePreferences, pageState.quantityPreferences, changeImpact);
  };

  const updateEaters = (eaters) => {
    if (courseId === 'dinner') {
      setPageState({ ...pageState, quantityPreferences: { ...pageState.quantityPreferences, dinnerEaters: eaters, eaters: eaters } });
    }
    if (courseId === 'lunch') {
      setPageState({ ...pageState, quantityPreferences: { ...pageState.quantityPreferences, lunchEaters: eaters, eaters: eaters } });
    }
    if (courseId === 'breakfast') {
      setPageState({ ...pageState, quantityPreferences: { ...pageState.quantityPreferences, breakfastEaters: eaters, eaters: eaters } });
    }
    setEaters(eaters);
    pushEaters(eaters);
  };

  return (
    <Grid item>
      <Dialog
        open={openPrefDialog}
        onClose={handleCancel}
        scroll={scroll}
      >
        <DialogTitle>Menu Settings</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Set your desired preferences for menu generation. those can be applied either only to the current menu or saved for all future menus.
          </DialogContentText>

          <Grid item xs={12}><Eaters courseId={courseId} eaters={eaters} setEaters={updateEaters} /></Grid>
          <Divider style={{ marginBottom: 10 }} />
          {/* Quantity preferences */}
          <Grid container item direction="column" justifyContent="flex-start">
            <div className={classes.label} style={{ textDecoration: 'underline' }}>How many meals would you like?</div><div><small>(changes here will discard existing menu)</small></div>
            <Grid item container alignItems="flex-start" style={{ marginTop: 10 }}>
              <Grid item xs={1}>
                <Brightness7 className={classes.icon} />
              </Grid>
              <Grid item>
                <div className={classes.label}>Dinners</div>
              </Grid>
            </Grid>
            <Dinners classes={classes} state={pageState} setState={setPageState} />
            {showNew && <NewDinners classes={classes} state={pageState} setState={setPageState} />}

            <Grid item container alignItems="flex-start">
              <Grid item xs={1}>
                <Brightness6 className={classes.icon} />
              </Grid>
              <Grid item>
                <div className={classes.label}>Lunches</div>
              </Grid>
            </Grid>
            <Lunches classes={classes} state={pageState} setState={setPageState} />
            {showNew && <NewLunches classes={classes} state={pageState} setState={setPageState} />}

            {repeats && Object.values(repeats).some((f) => f.course === 'breakfast') && (
              <>
                <Grid item container alignItems="flex-start">
                  <Grid item xs={1}>
                    <Brightness5 className={classes.icon} />
                  </Grid>
                  <Grid item>
                    <h3 style={{ margin: 0, padding: 0 }}>Breakfast</h3>
                  </Grid>
                </Grid>

                <Breakfasts classes={classes} state={pageState} setState={setPageState} />
              </>
            )}

          </Grid>
          <Divider style={{ marginBottom: 10 }} />
          {/* Recipe Preferences */}
          <div className={classes.label} style={{ textDecoration: 'underline' }}>Tell us about your meal preferences: </div>
          <Grid item container style={{ marginTop: 10 }}>
            <Simple classes={classes} state={pageState} setState={setPageState} />
            <Gourmet classes={classes} state={pageState} setState={setPageState} />
          </Grid>

          <Grid item container alignItems="flex-start" className={classes.sectionHeader}>
            <Grid item container alignItems="center" direction="row">
              <Grid item xs={1}>
                <Scale className={classes.icon} />
              </Grid>
              <Grid item>
                <h3 style={{ margin: 0, padding: 0 }}>Diet </h3>
              </Grid>
              <Grid>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedHealth,
                  })}
                  onClick={handleExpandHealthClick}
                  aria-expanded={expandedHealth}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item container className={classes.sectionBody}>
              <Collapse in={expandedHealth} timeout="auto" unmountOnExit>
                <DietBalancing classes={classes} state={pageState} setState={setPageState} />
              </Collapse>
            </Grid>
          </Grid>
          <Grid item container alignItems="flex-start" className={classes.sectionHeader}>
            <Grid item container alignItems="center" direction="row">
              <Grid item xs={1}>
                <Poison className={classes.icon} />
              </Grid>
              <Grid item>
                <h3 style={{ margin: 0, padding: 0 }}>Dietary Restrictions </h3>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedDiet,
                  })}
                  onClick={handleExpandDietClick}
                  aria-expanded={expandedDiet}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item container className={classes.sectionBody}>
              <Collapse in={expandedDiet} timeout="auto" unmountOnExit>
                <DietaryFilters classes={classes} state={pageState} setState={setPageState} />
              </Collapse>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave}>Save and Apply</Button>
          <Button onClick={handleApply}>Apply Only</Button>
        </DialogActions>
      </Dialog>
      <Grid container direction="row">
        <Grid item xs={8} sm={6} md={4} lg={3} xl={2}>
          <Button fullWidth size="small" variant="outlined" onClick={() => { setOpenPrefDialog(true); }}>
            <Grid item container direction="row" xs={12} alignItems="center" justifyContent="space-evenly">
              Settings:<PeopleIcon color="secondary" />{eaters}<Restaurant color="secondary" />{mealCount}
              {(recipePreferences && recipePreferences.gourmet) && (<AttachMoney color="secondary" />)}
              {(recipePreferences && (!recipePreferences.simple)) && <AccessTime color="secondary" />}
              {(hasDiets) && (<><LocalHospital color="secondary" />{dietNumber}</>)}
            </Grid>
          </Button>
          <small><small> (Tap to Edit)</small></small>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PreferencesSummary;
