import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';

// import Alert from '@material-ui/lab/Alert';

import useAuth from 'hooks/useAuth';
import { addGlobalEvent } from 'hooks/utils/events';
import { getSubscription } from 'hooks/utils/subscriptionUtils';
import { isNative, postNativeEvent, trace } from 'utils';

dayjs.extend(localizedFormat);

let timer = null;

const SubscribePage = ({ subscription, setSubscription, checkoutId }) => {
  const { uid, email } = useAuth();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  // const [error, setError] = useState();
  const [counter, setCounter] = useState(0);

  const referrer = get(history, 'location.state.referrer');
  trace('SubscribePage: referrer: %o, sub: %o, checkoutId: %o', referrer, subscription, checkoutId);

  useEffect(() => {
    if (checkoutId) {
      const fn = async () => {
        if (counter < 10) {
          const entitlement = await getSubscription({ uid });
          if (entitlement) {
            setSubscription({ ...entitlement, loading: false, active: true });
            setLoading(false);
          } else {
            timer = setTimeout(() => {
              setCounter((old) => old + 1);
            }, 2000);
          }
        }
      };
      fn();
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
    addGlobalEvent({ action: 'CheckSubscription', category: 'subscribe', data: { referrer, subscription }, uid, email });
    if (subscription && subscription.active) {
      trace('Subscription active');
      if (referrer === '/subscribe') {
        trace('Going back to menu');
        history.push('/menu');
      } else if (referrer) {
        trace('Going back to %s', referrer);
        history.push(referrer);
      } else {
        trace('Staying here');
        setLoading(false);
      }
      return;
    }
    trace('Subscription not active');
    if (isNative()) {
      addGlobalEvent({ action: 'ReloadPaywallTimer', category: 'subscribe', data: { subscription }, uid, email });
      // sometimes getting the sub conflicts without a small delay
      setTimeout(() => {
        trace('Go to native app subscribe');
        addGlobalEvent({ action: 'ReloadPaywall', category: 'subscribe', data: { subscription }, uid, email });
        postNativeEvent({ action: 'subscribe', path: referrer });
        setLoading(false);
      }, 1000);
    } else {
      addGlobalEvent({ action: 'LoadWebPaywall', category: 'subscribe', data: { subscription }, uid, email });
      trace('Go to web app subscribe');
      let buyLink;
      if (process.env.REACT_APP_ENV === 'production') {
        buyLink = `https://buy.stripe.com/aEU28E1yu1237u04gi?prefilled_email=${email}&client_reference_id=${uid}`;
      } else {
        buyLink = `https://buy.stripe.com/test_6oE15I1gc5Ye1iM4gg?prefilled_email=${email}&client_reference_id=${uid}`;
      }
      window.location = buyLink;
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [subscription, referrer, history, setLoading, checkoutId, counter, setCounter, email, setSubscription, uid]);

  if (isNative()) {
    return <LinearProgress />;
  }

  const active = subscription && subscription.active;
  const expires = subscription && subscription.expires_date ? dayjs(subscription.expires_date).format('L LL') : null;

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ border: '0px solid red' }}>
      {loading && (
        <Grid item container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item style={{ border: '0px solid blue', width: '100%' }}>
            <LinearProgress />
          </Grid>
          <Grid item>Checking subscription...</Grid>
        </Grid>
      )}
      {/*
      <Grid item>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
      </Grid>
      */}
      <Grid item style={{ marginTop: 20, fontSize: 10 }}>
        {!loading && active && (
          <div>
            <div>Subscription Active through {expires}</div>
            <div>
              <Button color="primary" onClick={() => history.push('/menu')}>Return to Menu</Button>
            </div>
          </div>
        )}
        {!loading && !active && expires && (
          <div>Subscription Expired on {expires}</div>
        )}
        {!loading && !active && !expires && (
          <div>No Subscription</div>
        )}
      </Grid>
    </Grid>
  );
};

export default SubscribePage;
