import dayjs from 'dayjs';

import { trace } from 'utils';

// const revenueCatApiKey = 'appl_bsafDyaQnPhlbtRBjZEMtvLgfvb';
const revenueCatApiKey = process.env.REACT_APP_ENV === 'production' ? 'appl_CGXKNJAycNDPFVLBAurwoQFiiYR' : 'appl_bsafDyaQnPhlbtRBjZEMtvLgfvb';

const getSubscription = async ({ uid }) => {
  if (uid === 'LJ9RTnKoU0con62S0T1Np60Fifp1' || uid === 'NIuBTTpZyxaqCbbqzVL8iXg7vfR2') {
    // brian
    return { special: true };
  }
  const url = `https://api.revenuecat.com/v1/subscribers/${uid}`;
  const auth = `Bearer: ${revenueCatApiKey}`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    }
  });
  const sub = await response.json();
  if (sub.code && sub.code === 7638) {
    trace('Got code 7638');
    return null;
  }
  trace('Subscription info: %o', sub);
  const entitlement = Object.values(sub?.subscriber?.entitlements || {}).find((e) => dayjs(e.expires_date).isAfter(dayjs()));
  return entitlement;
};

export { getSubscription };
