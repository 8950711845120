import firebase from 'firebase/app';
import 'firebase/storage';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

import { GET_EDAMAM_IMAGE_BY_URI } from 'hooks/queries';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const listBadImages = async () => {
  const storage = firebase.storage();
  const storageRef = storage.ref().child('badImages');
  const bad = [];
  let page = await storageRef.list({ maxResults: 100 });
  while (page) {
    page.items.forEach((item) => {
      bad.push({ slug: item.location.path.replace(/badImages\//, '') });
    });
    if (page.nextPageToken) {
      page = await storageRef.list({ maxResults: 100, pageToken: page.nextPageToken });
    } else {
      page = null;
    }
  }
  return bad;
};

const getImageFromStorage = async ({ storageRef, slug }) => {
  try {
    const badPath = `badImages/${slug}`;
    try {
      await storageRef.child(badPath).getDownloadURL();
      console.debug('%s: bad image', slug);
      return 'bad-image';
    } catch (ex) {
    }
    const path = `images/${slug}`;
    console.debug('%s: loading image from storage', slug);
    const downloadUrl = await storageRef.child(path).getDownloadURL();
    return downloadUrl;
  } catch (ex) {
    return null;
  }
};

const expired = ({ edamamUrl, slug }) => {
  if (/www.edamam.com\/web-img/.test(edamamUrl)) {
    console.debug('%s: old style Edamam url so marking expired.', slug);
    return true;
  }
  const url = new URL(edamamUrl);
  const date = url.searchParams.get('X-Amz-Date');
  const seconds = url.searchParams.get('X-Amz-Expires');
  if (date && seconds) {
    // subtract 60 to avoid timing issues
    // amz-date is in UTC so compare using that
    const expires = dayjs.utc(date, 'YYYYMMDDTHHmmss', false).add(parseFloat(seconds) - 60, 'seconds');
    const expired = expires.isBefore(dayjs());
    console.debug('%s: expiration date: %s, expired: %o', slug, expires.format('YYYY-MM-DD HH:mm:ss'), expired);
    return expired;
  }
  console.debug('%s: no expiration date so assuming expired', slug);
  return true;
};

const getImageFromEdamam = async ({ client, uri, slug }) => {
  try {
    const { data } = await client.query({ query: GET_EDAMAM_IMAGE_BY_URI, variables: { uri } });
    if (data && data.getEdamamImageByURI) {
      return data.getEdamamImageByURI;
    }
  } catch (ex) {
    console.error('%s: %o', slug, ex);
  }
  return null;
};

const b64toBlob = (b64Data, type = 'image/png') => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type });
  return blob;
};

const saveBadImageToStorage = async ({ storageRef, slug }) => {
  try {
    console.debug('%s: saving bad image to storage', slug);
    await storageRef.child(`badImages/${slug}`).putString('bad-image', 'raw');
  } catch (ex) {
    console.error('%s: %o', slug, ex);
  }
};

const saveImageToStorage = async ({ storageRef, image, slug }) => {
  try {
    console.debug('%s: saving good image to storage', slug);
    const blob = b64toBlob(image);
    const snapshot = await storageRef.child(`images/${slug}`).put(blob, {});
    console.debug('%s: uploaded %d bytes', slug, snapshot.totalBytes);
    const downloadUrl = await snapshot.ref.getDownloadURL();
    console.debug('%s: file available at %s', slug, downloadUrl);
    return downloadUrl;
  } catch (ex) {
    console.error('%s: %o', slug, ex);
  }
  return null;
};

const reloadImage = async ({ client, recipe }) => {
  const { image: url, slug, uri } = recipe;
  try {
    console.debug('%s: reloading image', slug);
    const storage = firebase.storage();
    const storageRef = storage.ref();

    const downloadUrl = await getImageFromStorage({ storageRef, slug });
    if (downloadUrl) {
      console.debug('%s: found cached image in storage: %s', slug, downloadUrl);
      if (downloadUrl !== 'bad-image') {
        return downloadUrl;
      }
      // Going to retry bad images every time because Edamam may have changed the URL.
      // We should reall cache by URL instead of recipe slug, but the URLs are all
      // crazy S3 things. But we could store the url with the image to detect if it
      // changed.
    }

    console.debug('%s: image not in storage', slug);
    let image;
    if (expired({ edamamUrl: url, slug })) {
      console.debug('%s: edamam url expired. Reloading recipe', slug);
      image = await getImageFromEdamam({ client, uri, slug });
    } else {
      console.debug('%s: edamam url not expired', slug);
    }
    if (!image) {
      console.debug('%s: could not find image for uri: %s', slug, uri);
      await saveBadImageToStorage({ storageRef, slug });
      return null;
    }

    console.debug('%s: got image from Edamam. Saving to storage.', slug);
    const newStorageUrl = await saveImageToStorage({ storageRef, image, slug });
    if (newStorageUrl) {
      console.debug('%s: saved image in storage for next time', slug);
    } else {
      console.error('%s: could not save image in storage. Returning it directly.', slug);
    }
    console.debug('%s: returning image', slug);
    return `data:image/png;base64,${image}`;
  } catch (ex) {
    console.error('%s: %o', slug, ex);
  }
  return null;
};

const getStorageDownloadUrl = async ({ slug, url }) => {
  try {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const path = `images/${slug}`;
    return await storageRef.child(path).getDownloadURL();
  } catch (ex) {
  }
  return null;
};

const image = async ({ client, recipe, slug }) => {
  try {
    const { image: url } = recipe;
    console.debug('proxying image url: %s for %s', url, slug);
    if (/googleapis.*alt=media/.test(url) || /googleapis.*token/.test(url)) {
      console.debug('returning already cached image');
      return url;
    }

    if (/googleapis/.test(url)) {
      // This is one I where I stored the wrong url
      const downloadUrl = await getStorageDownloadUrl({ url, slug });
      if (downloadUrl) {
        return downloadUrl;
      }
      console.debug('%s: could not find alledgedly cached image for %s', slug, url);
    } else if (!expired({ edamamUrl: url, slug })) {
      // If the image is good return it right away, but
      // kick off the process of caching it.
      reloadImage({ client, recipe });
      return url;
    }
    // If it's already expired, reload it
    return await reloadImage({ client, recipe });
  } catch (ex) {
    console.error('%s: %o', slug, ex);
  }
};

export default image;
export { listBadImages };
