import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Cancel from '@material-ui/icons/Cancel';
// import FoodOff from 'mdi-material-ui/FoodVariantOff';
import Poison from 'mdi-material-ui/BottleTonicSkull';
import Scale from 'mdi-material-ui/ScaleBalance';

// import DislikedIngredients from 'wizards/plan/recipePreferences/DislikedIngredients';
import DietaryFilters from 'components/preferences/DietaryFilters';
import DietBalancing from 'components/preferences/DietBalancing';
import Gourmet from 'components/preferences/Gourmet';
import Simple from 'components/preferences/Simple';
import useAuth from 'hooks/useAuth';
import useSnack from 'hooks/useSnack';
import load from 'hooks/utils/dbLoader';
import { dbSet } from 'hooks/utils/dbUpdater';
import { addEvent } from 'hooks/utils/events';
import { estimateRecipeCount } from 'hooks/utils/recipeUtils';

const useStyles = makeStyles((theme) => ({
  input: {
    width: 42,
  },
  label: {
    fontSize: '1.2em',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1em',
    },
  },
  icon: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  chip: {
    minWidth: 100,
    marginBottom: 8,
    marginRight: 16,
    [theme.breakpoints.down('xs')]: {
      minWidth: 70,
      fontSize: '.7em',
      marginRight: 12,
    },
  },
  activeChip: {
    marginRight: 8,
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.up('md')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
    marginBottom: 100,
  },
  sectionHeader: {
    marginTop: 30,
  },
  sectionBody: {
    marginTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  buttons: {
    marginTop: 5,
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: 10,
      top: 10,
      width: 'auto',
    },
  },
  saveButton: {
    marginRight: 10
  }
}));

const defaultValue = {};

let timer = null;

const RecipePreferences = ({ close }) => {
  const classes = useStyles();
  const { setSnack } = useSnack();
  const { uid, admin } = useAuth();
  const [pageState, setPageState] = useState('loading');
  const [originalState, setOriginalState] = useState();
  const [estimate, setEstimate] = useState(null);

  useEffect(() => {
    if (uid) {
      load({ path: 'household/recipePreferences', uid, defaultValue }).then((result) => {
        setPageState({ recipePreferences: result });
        setOriginalState({ recipePreferences: { ...result } });
      });
    }
  }, [uid, setPageState, setOriginalState]);

  useEffect(() => {
    if (admin && pageState !== 'loading') {
      setEstimate('processing');
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        estimateRecipeCount({ recipePreferences: pageState.recipePreferences }).then((result) => setEstimate(result));
      }, 1000);
    }
  }, [pageState, admin]);


   // Save changes on-the-fly
   useEffect(() => {
    if (pageState !== 'loading' && JSON.stringify(pageState) !== JSON.stringify(originalState)) {
      dbSet({ uid, path: 'household/recipePreferences', value: pageState.recipePreferences }).then(() => {
        setSnack({ message: 'Recipe preferences have been updated' });
      });
    }
  }, [pageState, uid, originalState, setSnack]);
  
  if (pageState === 'loading') {
    return <LinearProgress />;
  }

  return (
    <Grid container item direction="column" justifyContent="flex-start" className={classes.container}>
      <Grid container item justifyContent="space-between">
        {close && (
          <Grid item>
            <IconButton onClick={close}><Cancel /></IconButton>
          </Grid>
        )}
      </Grid>
      <Hidden mdUp>
        <Divider />
      </Hidden>
      <h3>Tell us about your food preferences so we can suggest the best recipes.</h3>
      <Grid item container>
        <Simple classes={classes} state={pageState} setState={setPageState} />
        <Gourmet classes={classes} state={pageState} setState={setPageState} />
      </Grid>

      <Grid item container alignItems="flex-start" className={classes.sectionHeader}>
        <Grid item xs={1}>
          <Scale className={classes.icon} />
        </Grid>
        <Grid item>
          <h3 style={{ margin: 0, padding: 0 }}>Diet</h3>
        </Grid>
        <Grid item container className={classes.sectionBody}>
          <DietBalancing classes={classes} state={pageState} setState={setPageState} />
        </Grid>
      </Grid>

      <Grid item container alignItems="flex-start" className={classes.sectionHeader}>
        <Grid item xs={1}>
          <Poison className={classes.icon} />
        </Grid>
        <Grid item>
          <h3 style={{ margin: 0, padding: 0 }}>Dietary Filters</h3>
        </Grid>
        <Grid item container className={classes.sectionBody}>
          <DietaryFilters classes={classes} state={pageState} setState={setPageState} />
        </Grid>
      </Grid>
      {admin && estimate && (
        <Grid container justifyContent="flex-start" spacing={1} style={{ fontWeight: 'bold', marginTop: 20, color: '#c00' }}>
          <Grid item>Estimated matching recipes (only admins see this):</Grid>
          {estimate === 'processing' && <Grid item><CircularProgress size="1em" style={{ marginLeft: 4, color: 'blue' }} /></Grid>}
          {estimate !== 'processing' && (
            <Grid item>
              {estimate[0]} of {estimate[1]}
            </Grid>
          )}
        </Grid>
      )}

      {/* Not doing disliked foods for now
        <Grid item container alignItems="flex-start" className={classes.sectionHeader}>
          <Grid item xs={1} >
            <FoodOff className={classes.icon}/>
          </Grid>
          <Grid item>
            <h3 style={{ margin: 0, padding: 0 }}>Disliked Foods</h3>
          </Grid>
          <Grid item container className={classes.sectionBody}>
            <DislikedIngredients classes={classes} state={pageState} setState={setPageState} />
          </Grid>
        </Grid>
      */}
    </Grid>
  );
};

export default RecipePreferences;
