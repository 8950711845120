import React, { useState } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import ProductList from 'components/productList';
import useAppStyles from 'hooks/useAppStyles';
import useSnack from 'hooks/useSnack';
import { dbSet, dbUpdate } from 'hooks/utils/dbUpdater';
import dbWatcher from 'hooks/utils/dbWatcher';
import { dateStamp, trace, traceError } from 'utils';

const displayProps = {
  type: 'checkboxInvert', // checkbox, none
  recipeIcon: false,
  quantityText: false,
  notes: 'readonly', // readonly, none
  lastPurchased: false,
  frequency: false,
  quantity: 'readonly', // readonly, none, or see if setQuantity exists
  remove: true,
  source: 'purchaseHistory',
  faded: true,
};

const isInventory = (product) => {
  if (!product.recipes) {
    return true;
  }
  return product.quantity.quantity < product.actual;
};

const PurchaseHistory = ({ uid }) => {
  const classes = useAppStyles();
  const { setSnack } = useSnack();
  const [purchaseHistory, setPurchaseHistory] = useState({ loading: true });

  const date = dateStamp();
  dbWatcher({ path: `purchaseHistory/date/${date}`, set: setPurchaseHistory, source: 'pages/shopping/PurchaseHistory' });

  const toggle = ({ products, on }) => {
    trace('products to toggle: %o', products);
    const date = dateStamp();
    const updates = {};
    products.forEach((product) => {
      const { slug } = product;
      updates[`purchaseHistory/date/${date}/${slug}`] = null;
      updates[`purchaseHistory/product/${product.slug}/${date}`] = null;
      updates[`shoppingList/${product.slug}`] = { ...product, needed: true };
      if (isInventory(product)) {
        // Doing this to avoid updating frequency
        updates[`inventory/${slug}/slug`] = slug;
        updates[`inventory/${slug}/name`] = product.name;
        updates[`inventory/${slug}/department`] = product.department;
        updates[`inventory/${slug}/actual`] = product.actual || 1;
        updates[`inventory/${slug}/quantity`] = product.quantity || null;
        updates[`inventory/${slug}/dates/${date}`] = null;
      }
    });
    trace('updates: %o', updates);
    dbUpdate({ uid, path: '', updates }).then(() => {
      setSnack({ message: 'Item moved back to your shopping list' });
    }).catch((err) => {
      traceError(err);
      setSnack({ message: 'An error occurred while moving the item back to your list' });
    });
  };

  const remove = ({ products }) => {
    // Note: this only works for today, which is all we show on the list
    const date = dateStamp();
    const updates = {};
    products.forEach((product) => {
      const { slug } = product;
      updates[`purchaseHistory/date/${date}/${slug}`] = null;
      updates[`purchaseHistory/product/${product.slug}/${date}`] = null;
      updates[`inventory/${slug}/dates/${date}`] = null;
    });
    trace('updates: %o', updates);
    dbUpdate({ uid, path: '', updates }).then(() => {
      setSnack({ message: 'Item has been removed from your puchase history' });
    }).catch((err) => {
      traceError(err);
      setSnack({ message: 'An error occurred while removing the item from your purchase history' });
    });
  };

  const saveNote = ({ slug, note }) => {
    dbSet({ uid, path: `shoppingList/${slug}/notes`, value: note });
  };

  if (purchaseHistory.loading) {
    return (
      <Grid container direction="column">
        <LinearProgress />
      </Grid>
    );
  }

  if (!purchaseHistory.value || Object.keys(purchaseHistory.value || {}).length === 0) {
    return null;
  }

  return (
    <Grid container item direction="column" justifyContent="flex-start" alignContent="stretch" className={classes.listPageContainer}>
      <Divider />
      <Grid item xs={12}>
        <h2 style={{ marginBottom: 10, marginLeft: 10 }}>Purchased Today</h2>
      </Grid>
      <ProductList
        displayProps={displayProps} products={purchaseHistory.value} sort="lastPurchased" saveNote={saveNote}
        source="purchaseHistory" remove={remove} toggle={toggle} uid={uid}
      />
    </Grid>
  );
};

export default PurchaseHistory;
