import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ShopIcon from '@material-ui/icons/Shop';

import useAuth from 'hooks/useAuth';
import useNav from 'hooks/useNav';
import dbLoad from 'hooks/utils/dbLoader';
import { dbSet } from 'hooks/utils/dbUpdater';
import { isNative, isNativeModal, postNativeEvent, trace } from 'utils';

const mapping = {
  teaspoon: 'tsp',
  tablespoon: 'tbsp',
  'fluid ounce': 'fl_oz',
  each: 'count',
  cup: 'cups',
  pint: 'pints',
  quart: 'quarts',
  gallon: 'gallons',
  milliliter: 'milliliters',
  liter: 'liters',
  ounce: 'ounces',
  ounces: 'ounces',
  pound: 'pounds',
  gram: 'grams',
  kilogram: 'kilograms',
  centiliter: ['milliliters', 10],
  deciliter: ['milliliters', 100],
  milligram: ['grams', 0.1],
};

const amazonUrl = 'https://www.amazon.com/afx/ingredients/landing?tag=guustav-20';
const sample = {
  ingredients: [
    {
      name: "green apples",
      componentIndex: 0,
      quantityList: [
        {
          unit: "COUNT",
          amount: 5
        },
        {
          unit: "KILOGRAMS",
          amount: 0.5
        }
      ],
      exclusiveOverride: false
    },
    {
      name: "strawberry non-fat greek yogurt",
      brand: "chobani",
      asinOverrides: [
        "B002GVJZI4"
      ],
      componentIndex: 0,
      quantityList: [
        {
          unit: "OUNCES",
          amount: 5
        }
      ],
      exclusiveOverride: false
    }
  ],
  saved: false,
  recipeComposition: {
    saved: false
  }
};
const convertToAmazonUnit = (measure, amount) => {
  const unit = mapping[measure.toLowerCase()];
  if (typeof unit === 'undefined') {
    console.log('measure: %o, mapping: %o', measure, mapping);
  }
  if (typeof unit === 'string') {
    return {
      unit,
      amount,
    };
  }

  return {
    unit: unit[0],
    amount: amount * unit[1],
  };
};

/* const Online = ({ history }) => {
  const { setNav } = useNav();
  const { uid } = useAuth();
  const [shop, setShop] = useState('loading');
  const [submitting, setSubmitting] = useState(false);

  trace('in amazon: %o', { uid, shop });

  useEffect(() => {
    if (!uid) {
      return;
    }
    const fn = async () => {
      const data = {
        ingredients: [],
        saved: false,
        recipeComposition: {
          saved: false
        }
      };
      const shoppingList = await dbLoad({ uid, path: 'shoppingList', source: 'pages/shopping/Online' });
      Object.entries(shoppingList).forEach(([slug, product]) => {
        const tempIngr = {
          name: product.name,
          quantityList: [convertToAmazonUnit(product.quantity.measure, product.quantity.quantity)]
        };
        data.ingredients.push(tempIngr);
      });
      if (false && isNative()) {
        // Not doing this, was going to send them to a server-side page, but seems to work without
        const rand = [...Array(6)].map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 65)).join('');
        const key = `${uid}-${rand}`;
        await dbSet({ path: `/scratch/shoppingAmazon/${uid}-${rand}`, value: JSON.stringify(data) });
        postNativeEvent({ action: 'open-url', url: `${window.location.origin}/shopping-amazon?key=${key}` });
      } else {
        setShop(JSON.stringify(data));
      }
    };
    fn();
  }, [uid]);

  useEffect(() => {
    setNav((nav) => ({
      up: true,
      pageName: 'Shopping - Online',
      actions:
  <>
    <Hidden smDown>
      <Button
        color="secondary" size="medium" variant="contained"
        onClick={() => history.push('/shopping')}
      >
        <ShopIcon />
                &nbsp;Shopping List
      </Button>
    </Hidden>
    <Hidden mdUp xsDown>
      <Button
        color="secondary" size="medium" variant="contained"
        onClick={() => history.push('/shopping')}
      >
        <ShopIcon />
                &nbsp;List
      </Button>
    </Hidden>
    <Hidden smUp>
      <IconButton color="secondary" size="medium" onClick={() => history.push('/shopping')}>
        <ShopIcon />
      </IconButton>
    </Hidden>
  </>
    }));
    window.scrollTo(0, 0);
  }, [history, setNav]);

  // Not doing this as long as it works in a flutter modal
  if (false && isNativeModal()) {
    return (
      <Grid container direction="column">
        <Grid item>
          You are being sent to Amazon Fresh in your browser to complete your shopping. Click the button below to return to your Guustav shopping list.
        </Grid>
        <Grid item>
          <Button onClick={() => history.push('/shopping')}>Return to List</Button>
        </Grid>
      </Grid>
    );
  }

  // trace(shop);
  if (shop === 'loading') {
    return <LinearProgress />;
  }

  trace('in online native: %o, native modal: %o, submitting: %o', isNative(), isNativeModal(), submitting);
  const Page = () => (
    <Grid container item direction="column" alignItems="center" xs={10}>
      <h3>Your Shopping list has been sent to Amazon Fresh</h3>
      <p>Click the button below to finish shopping</p>
      <form id="amazon-form" target={isNativeModal() ? undefined : '_blank'} method="post" action={amazonUrl}>
        <input
          type="textarea" hidden name="ingredients" value={shop} readOnly
        />
        <Button
          variant="contained" color="secondary" style={{ maxHeight: '10vh', maxWidth: '45vw' }} type="button" disabled={submitting}
          onClick={() => {
            if (isNativeModal()) {
              setSubmitting(true);
              setTimeout(() => setSubmitting(false), 3000);
            }
            document.getElementById('amazon-form').submit();
          }}
        >
          {submitting && isNativeModal() && <span>Loading Amazon...</span>}
          {!(submitting && isNativeModal()) && <span>Go to Amazon</span>}
        </Button>
      </form>

      <h4 style={{ marginBottom: 0 }}>Pro Tips:</h4>
      <ol>
        <li>Make sure that the quantities assigned to the items on Amazon match what is needed</li>
        <li>If some items are not available on Amazon fresh, you can also order them directly from Whole Foods section on Amazon</li>
        <li>Meats and seafood on Amazon Fresh are typically frozen or pre packaged, you might want to order these from Whole Foods</li>
      </ol>
    </Grid>
  );

  return <Page />;
};

export default Online; */


const Online = ({ history }) => {
  const { setNav } = useNav();
  const { uid } = useAuth();
  const [shop, setShop] = useState('loading');
  const [submitting, setSubmitting] = useState(false);

  trace('in amazon: %o', { uid, shop });
  trace('native: %o', { native: isNative(), nativeModal: isNativeModal() });

  useEffect(() => {
    if (!uid) {
      return;
    }
    const fn = async () => {
      const data = {
        ingredients: [],
        saved: false,
        recipeComposition: {
          saved: false
        }
      };
      const shoppingList = await dbLoad({ uid, path: 'shoppingList', source: 'pages/shopping/Online' });
      Object.entries(shoppingList).forEach(([slug, product]) => {
        const tempIngr = {
          name: product.name,
          quantityList: [convertToAmazonUnit(product.quantity.measure, product.quantity.quantity)]
        };
        data.ingredients.push(tempIngr);
      });

      setShop(JSON.stringify(data));
    };
    fn();
  }, [uid]);

  useEffect(() => {
    setNav((nav) => ({
      up: true,
      pageName: 'Shopping - Online',
      actions: (
        <>
          <Hidden smDown>
            <Button
              color="secondary" size="medium" variant="contained"
              onClick={() => history.push('/shopping')}
            >
              <ShopIcon />
              &nbsp;Shopping List
            </Button>
          </Hidden>
          <Hidden mdUp xsDown>
            <Button
              color="secondary" size="medium" variant="contained"
              onClick={() => history.push('/shopping')}
            >
              <ShopIcon />
              &nbsp;List
            </Button>
          </Hidden>
          <Hidden smUp>
            <IconButton color="secondary" size="medium" onClick={() => history.push('/shopping')}>
              <ShopIcon />
            </IconButton>
          </Hidden>
        </>
      )
    }));
    window.scrollTo(0, 0);
  }, [history, setNav]);

  const goToAmazon = async () => {
    const formData = new URLSearchParams();
    formData.append("ingredients", shop);
    const encodedFormData = encodeURIComponent(formData.toString());
    const amazonUrlWithParameters = `${amazonUrl}&${encodedFormData}`;
    if (false && isNative()) {
      /*
       * this was me trying fetch, but it redirects and you end up not where you need to be
      const ingredients = JSON.parse(shop);
      const options = {
        method: 'POST',
        mode: "no-cors",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify({ ingredients: sample })
      };
      try {
        // trace('amazon shop: %s', JSON.stringify(shop))
        const url = 'https://www.amazon.com/afx/ingredients/landing?tag=guustav-20';
        //const url = 'https://www.amazon.com/afx/ingredients/verify/landing?tag=guustav-20'
        const response = await fetch(url , options);
        trace('amazon response: %o', response.statusText);
        trace('amazon redirected: %o', response.redirected);
        const j = await response.text();
        trace('amazon json: %s', j);
      } catch (ex) {
        trace('error sending to amazon: %o', ex.message);
        trace('error sending to amazon: %o', ex.stackTrace);
      }
      */
      // This was our old way of doing it, but seemed to stop working
      postNativeEvent({ action: 'modalWebView', uri: amazonUrlWithParameters });
    } else {
      trace('submitting form: %o', { native: isNative(), nativeModal: isNativeModal() });
      document.getElementById('amazon-form').submit();
    }
  };

  const target = isNative() || isNativeModal() ? undefined : '_blank';
  const Page = () => (
    <Grid container item direction="column" alignItems="center" xs={10}>
      <h3>Your Shopping list has been sent to Amazon Fresh</h3>
      <p>Click the button below to finish shopping</p>
      <form id="amazon-form" target={target} method="post" action={amazonUrl}>
        <input
          type="textarea" hidden name="ingredients" value={shop} readOnly
        />
        <Button
          variant="contained" color="secondary" style={{ maxHeight: '10vh', maxWidth: '45vw' }} type="button" disabled={submitting}
          onClick={() => {
            setSubmitting(true);
            goToAmazon();
            setTimeout(() => {
              setSubmitting(false);
            }, 10000);
          }}
        >
          {submitting && <span>Loading Amazon...</span>}
          {!submitting && <span>Go to Amazon</span>}
        </Button>
      </form>

      <h4 style={{ marginBottom: 0 }}>Pro Tips:</h4>
      <ol>
        <li>Make sure that the quantities assigned to the items on Amazon match what is needed</li>
        <li>If some items are not available on Amazon fresh, you can also order them directly from Whole Foods section on Amazon</li>
        <li>Meats and seafood on Amazon Fresh are typically frozen or pre-packaged, you might want to order these from Whole Foods</li>
      </ol>
    </Grid>
  );

  return <Page />;
};

export default Online;

