import { gql } from '@apollo/client';

const RECIPE_FRAGMENT = `
  fragment recipeFields on MenuRecipe {
    id
    calories
    dietLabels
    fullMeal
    healthLabels
    image
    keyword
    label
    image
    slug
    source
    time
    uri
    url
    yield
    ingredients {
      food
      foodCategory
      department
      measure
      quantity
      slug
      text
      weight
    }
  }
`;

const GET_VERSION = gql`
  query gitVersion {
    gitVersion
  }
`;

const GET_EDAMAM_RECIPE_BY_URI = gql`
  query EdamamRecipeByURI($uri: String!) {
    getEdamamRecipeByURI(uri: $uri)
  }
`;

const GET_EDAMAM_IMAGE_BY_URI = gql`
  query EdamamImageByURI($uri: String!) {
    getEdamamImageByURI(uri: $uri)
  }
`;

const GET_GUUSTAV_RECIPE_BY_URI = gql`
  query GuustavRecipeByURI($uri: String!) {
    getGuustavRecipeByURI(uri: $uri) {
      ...recipeFields
    }
  }
  ${RECIPE_FRAGMENT}
`;

const SEARCH_RECIPES = gql`
  query searchRecipes($mealType: String, $query: String, $trending: Boolean,
    $suggested: Boolean, $page: Int, $pageSize: Int, $recipePreferences: RecipePreferences) {
    searchRecipes(searchParams: { mealType: $mealType, query: $query, trending: $trending,
        suggested: $suggested, page: $page, pageSize: $pageSize, recipePreferences: $recipePreferences }) {
      count
      more
      recipes {
        ...recipeFields
      }
    }
  }
  ${RECIPE_FRAGMENT}
`;

const SEARCH_GENERIC_PRODUCTS = gql`
  query searchGenericProducts($searchParams: GenericProductSearchParams!) {
    searchGenericProducts(searchParams: $searchParams) {
      hits
      products {
        id
        slug
        name
        department
        aisle
        defaultMeasureName
        defaultMeasureUnit
        defaultQuantity
      }
    }
  }
`;

const DELETE_GENERIC_PRODUCT = gql`
  mutation deleteGenericProduct($id: ID!) {
    deleteGenericProduct(id: $id)
  }
`;

const UPDATE_GENERIC_PRODUCT = gql`
  mutation updateGenericProduct($id: ID!, $product: GenericProductInput!) {
    updateGenericProduct(id: $id, product: $product) {
      slug
      name
      department
      aisle
      defaultMeasureName
      defaultMeasureUnit
      defaultQuantity
    }
  }
`;

const ADMIN_LIST_USERS = gql`
  query adminListUsers {
    adminListUsers {
      uid
      displayName
      email
      metadata {
        creationTime
        lastSignInTime
      }
      providerData {
        providerId
      }
    }
  }
`;

const ADMIN_DELETE_USER = gql`
  mutation adminDeleteUser($uid: String!) {
    adminDeleteUser(uid: $uid)
  }
`;

const LOG_CLIENT_ERROR = gql`
  mutation adminLogError($message: String!, $uid: String!, $pathname: String!) {
    logClientError(message: $message, uid: $uid, pathname: $pathname)
  }
`;

export {
  ADMIN_DELETE_USER,
  ADMIN_LIST_USERS,
  DELETE_GENERIC_PRODUCT,
  GET_EDAMAM_IMAGE_BY_URI,
  GET_EDAMAM_RECIPE_BY_URI,
  GET_GUUSTAV_RECIPE_BY_URI,
  GET_VERSION,
  LOG_CLIENT_ERROR,
  SEARCH_GENERIC_PRODUCTS,
  SEARCH_RECIPES,
  UPDATE_GENERIC_PRODUCT,
};
