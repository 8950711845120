import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as dayjs from 'dayjs';

import useStyles from 'assets/useStyles';
import RecipeCard from 'components/recipes/RecipeCard';
import RecipeModal from 'components/recipes/RecipeModal';
import useAuth from 'hooks/useAuth';
import dbLoader from 'hooks/utils/dbLoader';
import { trace } from 'utils';

const feedback = {
  skipped: 'Did not have/skip',
  disliked: 'Never show it again',
  liked: 'Show similar recipes',
  repeated: 'Repeat periodically',
};

const History = ({ ...props }) => {
  const classes = useStyles();
  const [recipes, setRecipes] = useState('loading');
  const [preview, setPreview] = useState();
  const { uid } = useAuth();

  useEffect(() => {
    const fn = async () => {
      const recipes = await dbLoader({ uid, path: 'planHistory', key: 'planHistory' });
      Object.keys(recipes.data).forEach((d) => {
        if (!recipes.data[d].dinners && !recipes.data[d].lunches && !recipes.data[d].breakfasts) {
          delete recipes.data[d];
        }
      });
      setRecipes(recipes.data);
    };
    fn();
  }, [uid, setRecipes]);

  const footer = (date, course, meal) => {
    let fb = '';
    if (recipes[date].feedback && recipes[date].feedback[course]) {
      fb = recipes[date].feedback[course][meal.recipe.slug];
      if (fb) {
        fb = feedback[fb];
      }
    }
    return <div style={{ textAlign: 'center', paddingTop: 5, paddingBottom: 5 }}>{fb || 'No Feedback'}</div>;
  };

  if (recipes === 'loading') {
    return <LinearProgress style={{ width: '100%' }} />;
  }
  trace('recipes: %o', recipes);

  return (
    <>
      <RecipeModal key="recipe-modal" readonly={!preview || !preview.uri.includes(uid)} recipe={preview} close={() => setPreview(null)} />
      <Grid container direction="column" style={{ marginBottom: 100, paddingLeft: 20, paddingRight: 20 }}>
        <Grid item container direction="column">
          {Object.keys(recipes).sort((a, b) => b.localeCompare(a)).map((date) => (
            <Grid item key={date}>
              <h3>{dayjs(date).format('YYYY-MM-DD')}</h3>
              <Grid container item spacing={3} justifyContent="flex-start" alignItems="center" className={classes.centerOnXs} style={{ paddingTop: 10, border: '0px solid orange' }}>
                {recipes[date].dinners && (
                  <Grid item xs={12} style={{ border: '0px solid red', padding: 0, margin: 0, fontWeight: 'bold', paddingLeft: 15 }}>
                    Dinner
                  </Grid>
                )}
                {recipes[date].dinners && recipes[date].dinners.map((meal) => (
                  <Grid id={`sr-${meal.recipe.slug}`} key={`sr-${meal.recipe.slug}`} container justifyContent="center" item xs={11} sm={4} md={3} lg={2} style={{ border: '0px solid blue' }}>
                    <RecipeCard recipe={meal.recipe} preview={setPreview} footer={footer(date, 'dinner', meal)} />
                  </Grid>
                ))}
                {recipes[date].lunches && (
                  <Grid item xs={12} style={{ border: '0px solid red', padding: 0, margin: 0, fontWeight: 'bold', paddingLeft: 15 }}>
                    Lunch
                  </Grid>
                )}
                {recipes[date].lunches && recipes[date].lunches.map((meal) => (
                  <Grid id={`sr-${meal.recipe.slug}`} key={`sr-${meal.recipe.slug}`} container justifyContent="center" item xs={11} sm={4} md={3} lg={2} style={{ border: '0px solid blue' }}>
                    <RecipeCard recipe={meal.recipe} preview={setPreview} footer={footer(date, 'lunch', meal)} />
                  </Grid>
                ))}
                {recipes[date].breakfasts && (
                  <Grid item xs={12} style={{ border: '0px solid red', padding: 0, margin: 0, fontWeight: 'bold', paddingLeft: 15 }}>
                    Breakfast
                  </Grid>
                )}
                {recipes[date].breakfasts && recipes[date].breakfasts.map((meal) => (
                  <Grid id={`sr-${meal.recipe.slug}`} key={`sr-${meal.recipe.slug}`} container justifyContent="center" item xs={11} sm={4} md={3} lg={2} style={{ border: '0px solid blue' }}>
                    <RecipeCard recipe={meal.recipe} preview={setPreview} footer={footer(date, 'breakfast', meal)} />
                  </Grid>
                ))}
              </Grid>
              <hr style={{ marginTop: 30 }} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default History;
