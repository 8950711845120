import React, { useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Cancel from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import { listBadImages } from 'hooks/utils/imageUtils';

const BadImages = ({ ...props }) => {
  const history = useHistory();
  const [images, setImages] = useState('loading');
  const { admin } = useAuth();

  useEffect(() => {
    const fn = async () => {
      if (admin) {
        setImages(await listBadImages());
      }
    };
    fn();
  }, [admin]);

  if (!admin) {
    alert('You are not allowed to do this.');
    history.push('/');
    return null;
  }

  const onDelete = (slug) => {
    // deleteImageFromStorage(slug);
  };

  console.log(images);

  return (
    <div>
      <h3>Bad Images</h3>
      <p>These are images where the Edamam recipe no longer exists. If you think it does, you can delete it from here, and it will try to load it the next it encounters it.</p>
      {images === 'loading' && (
        <LinearProgress />
      )}
      {images !== 'loading' && images.length === 0 && (
        <div>No bad images</div>
      )}
      {images !== 'loading' && images.length > 0 && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Slug</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {images.map((image) => (
                <TableRow key={`image-${image.slug}`}>
                  <TableCell>{image.slug}</TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => onDelete(image.slug)}>
                      <Cancel style={{ color: '#c80000' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default BadImages;
