import React, { useState, useEffect } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Cancel from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router-dom';

import ConfirmActionDialog from 'components/ConfirmActionDialog';
import fc from 'data/firebaseConfig';
import { ADMIN_DELETE_USER, ADMIN_LIST_USERS } from 'hooks/queries';
import useAuth from 'hooks/useAuth';
import { traceError } from 'utils';
import { Error } from 'utils/errorInfo';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import load from 'hooks/utils/dbLoader';



const baseUrl = 'https://console.firebase.google.com/u/1/project';

const url = (uid) => (
  `${baseUrl}/${fc.projectId}/database/${fc.projectId}-default-rtdb/data/~2Fusers~2F${uid}`
);

const providerMap = {
  'google.com': 'Google',
  'facebook.com': 'Facebook',
  'apple.com': 'Apple',
  password: 'Email',
};

const provider = (providerData) => {
  let p;
  if (providerData && providerData.length && (p = providerData[0].providerId)) {
    return providerMap[p];
  }
  return '';
};


const processLogsForDisplay = (logs) => {
  // Extract an array from the object
  const logArray = Object.values(logs);

  // Find the most recent date
  const mostRecentDate = logArray.reduce((latest, log) => {
    const logDate = new Date(log.time);
    return logDate > latest ? logDate : latest;
  }, new Date(0));

  // Filter logs for the most recent date
  const filteredLogs = logArray.filter(log => {
    const logDate = new Date(log.time);
    return logDate.toDateString() === mostRecentDate.toDateString();
  });

  const groupedLogs = filteredLogs.reduce((acc, log) => {
    const stepValue = log.data && typeof log.data.step !== 'undefined' && log.data.step !== null ? log.data.step : 'N/A';
    const step = `-Step ${stepValue}`;
    const key = `${log.action}-${log.category}${step}`;
    acc[key] = acc[key] || { count: 0, action: log.action, category: log.category, step: stepValue };
    acc[key].count += 1;
    return acc;
  }, {});

  // Return the grouped logs and the most recent date
  return {
    logs: Object.values(groupedLogs),
    date: mostRecentDate
  };
};




const sortUsers = (users, sort) => {
  // not doing anything with sort yet
  // sorting by last signin
  const sortedUsers = [];
  users.forEach((user) => { sortedUsers.push(user); });
  if (sort === 'last') {
    return sortedUsers.sort((a, b) => {
      const aDate = new Date(a.metadata.lastSignInTime);
      const bDate = new Date(b.metadata.lastSignInTime);
      let result = 1;
      if (aDate > bDate) result = -1;
      return result;
    });
  }
  if (sort === 'id') {
    return sortedUsers.sort((a, b) => (a.email || 'Ω').localeCompare(b.email || 'Ω'));
  }

  return sortedUsers.sort((a, b) => (a.email || 'Ω').localeCompare(b.email || 'Ω'));
};

const filterUsers = (users, date) => {
  if (!date) return users;
  const filterDateObj = new Date(date);
  return users.filter(user => new Date(user.metadata.creationTime) > filterDateObj);
};


const Users = ({ ...props }) => {
  const history = useHistory();
  const { admin, uid } = useAuth();
  const [deleting, setDeleting] = useState();
  const { data, loading, error } = useQuery(ADMIN_LIST_USERS);
  const [sortType, setSortType] = useState('last');
  const [filterDate, setFilterDate] = useState(null);

  const [showSummary, setShowSummary] = useState(false);
  const [currentSummary, setCurrentSummary] = useState({ logs: [], date: null });
  const [currentEmail, setCurrentEmail] = useState(null);


  const [summaryUID, setSummaryUID] = useState(null);  // state to hold uid for which summary is to be fetched

  const showUserSummary = (user) => {
    setSummaryUID(user.uid);
    setCurrentEmail(user.email || 'N/A'); // Storing email in state. Use 'N/A' if email is undefined or null.
  };


  const [deleteUser, { error: deleteError, loading: deleteLoading }] = useMutation(ADMIN_DELETE_USER, {
    refetchQueries: [{ query: ADMIN_LIST_USERS }],
  });
  const defaultValue = {};

  useEffect(() => {
    if (summaryUID) {
      load({ path: 'events/log', uid: summaryUID, defaultValue }).then((result) => {
        const { logs, date } = processLogsForDisplay(result);
        setCurrentSummary({ logs, date });
        setShowSummary(true);
      });
    }
  }, [summaryUID]);



  if (!admin) {
    alert('You are not allowed to do this.');
    history.push('/');
    return null;
  }

  if (loading) {
    return null;
  }

  if (error) {
    return <Error error={error} />;
  }


  const onDelete = (id, email) => {
    if (id === uid) {
      alert("No matter how bad things are, don't delete yourself");
      return;
    }
    const dev = /francois@guustav.com|brian@guustav.com/i.test(email);
    if (dev) {
      alert("Developers don't like to be deleted!");
      return;
    }
    setDeleting({ uid: id, email });
  };

  const performDelete = () => {
    deleteUser({ variables: { uid: deleting.uid } }).then((result) => {
      setDeleting(null);
    }).catch((err) => {
      traceError(err);
    });
  };

  const users = data.adminListUsers;
  //const sortedUsers = sortUsers(users, 'last');

  return (
    <div>
      <h3>Users</h3>

      {/* Dropdowns for sorting and filtering */}
      <div>
        <label>Sort By: </label>
        <select value={sortType} onChange={e => setSortType(e.target.value)}>
          <option value="last">Last Login</option>
          <option value="id">User ID</option>
        </select>

        <label>Filter Creation Date (after): </label>
        <input type="date" value={filterDate} onChange={e => setFilterDate(e.target.value)} />
      </div>
      {deleteLoading && (
        <LinearProgress />
      )}
      {deleteError && (
        <Error error={deleteError} />
      )}
      {!users.length && (
        <div>No users (ha)</div>
      )}
      {!!users.length && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Last Signed In</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {[...users].sort((a, b) => (a.email || 'Ω').localeCompare(b.email || 'Ω')).map((u) => ( */}
              {filterUsers(sortUsers(users, sortType), filterDate).map((u) => (
                <TableRow key={`user-${u.uid}`}>
                  <TableCell><Link target="_blank" href={url(u.uid)}>{u.email || 'anonymous'}</Link></TableCell>
                  <TableCell>{u.displayName}</TableCell>
                  <TableCell>{provider(u.providerData)}</TableCell>
                  <TableCell>{u.metadata.creationTime}</TableCell>
                  <TableCell>{u.metadata.lastSignInTime}</TableCell>
                  <TableCell>
                    <IconButton size="small" onClick={() => onDelete(u.uid, u.email)}>
                      <Cancel style={{ color: '#c80000' }} />
                    </IconButton>
                    <IconButton size="small" onClick={() => showUserSummary(u)}>
                      <InfoOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!!deleting && (
        <ConfirmActionDialog
          noAction={() => setDeleting(null)} open={!!deleting}
          text={<span style={{ color: '#c80000' }}>Are you sure you want to delete {deleting.email}?<br />This cannot be undone.</span>}
          title={`Delete ${deleting.email}`}
          yesAction={performDelete}
        />
      )}
      <Dialog open={showSummary} onClose={() => setShowSummary(false)}>
        <DialogTitle>Summary for {currentEmail} on {currentSummary.date ? new Date(currentSummary.date).toDateString() : ''}</DialogTitle>
        <DialogContent>
          {currentSummary.logs.map(({ action, category, count, step }) => (
            <div key={`${action}-${category}-${step}`}>
              {count} {step !== 'N/A' ? `${step}` : ''} {action} in {category}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSummary(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Users;
