import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import load from 'hooks/utils/dbLoader';
import grocers from 'data/grocers';

import save from './save';

import useAuth from 'hooks/useAuth';
// import { isNativeModal } from 'utils';

/* const mapping = {
  teaspoon: 'tsp',
  tablespoon: 'tbsp',
  'fluid ounce': 'fl_oz',
  each: 'count',
  cup: 'cups',
  pint: 'pints',
  quart: 'quarts',
  gallon: 'gallons',
  milliliter: 'milliliters',
  liter: 'liters',
  ounce: 'ounces',
  ounces: 'ounces',
  pound: 'pounds',
  gram: 'grams',
  kilogram: 'kilograms',
  centiliter: ['milliliters', 10],
  deciliter: ['milliliters', 100],
  milligram: ['grams', 0.1],
}; */

// const amazonUrl = 'https://www.amazon.com/afx/ingredients/landing?tag=guustav-20';

/* const convertToAmazonUnit = (measure, amount) => {
  const unit = mapping[measure.toLowerCase()];
  if (typeof unit === 'undefined') {
    console.log('measure: %o, mapping: %o', measure, mapping);
  }
  if (typeof unit === 'string') {
    return {
      unit,
      amount,
    };
  }

  return {
    unit: unit[0],
    amount: amount * unit[1],
  };
}; */

const Summary = ({ close, step, state, setState, uid, email, recipeEvents, finishStep }) => {
  // const [submitting, setSubmitting] = useState(false);
  const { anonymous } = useAuth();
  const [onlineGrocers, setOnlineGrocers] = useState();
  useEffect(() => {
    if (step.id === 'summary' && step.exiting) {
      finishStep();
    }
  }, [step, finishStep]);

   // loading up what grocery stores they have selected for online shopping
   useEffect(() => {
    if (uid && uid !== 'visitor') {
      load({ path: 'household/onlinePreferences', uid }).then((result) => {
        if (result) {
          const enabled = Object.keys(result).filter((id) => !!result[id]);
          const og = enabled.map((id) => grocers[id]).filter((g) => !!g).sort((a, b) => a.name.localeCompare(b.name));
          setOnlineGrocers(og);
        }
      });
    }
  }, [setOnlineGrocers, uid]);

  if (step.id !== 'summary') {
    return null;
  }

  const dinnerEaters = state.quantityPreferences.dinnerEaters || state.quantityPreferences.eaters;
  const lunchEaters = state.quantityPreferences.lunchEaters || state.quantityPreferences.eaters;
  const breakfastEaters = state.quantityPreferences.breakfastEaters || state.quantityPreferences.eaters;

  const onFinish = (path) => () => {
    save({ close, path, state, setState, uid, recipeEvents, source: 'wizard.summary' });
  };



  return (
    <Grid container direction="column" style={{ paddingLeft: 20 }} >
      <Grid item>
        <h3>Summary</h3>
        <ul>
          <li>Items in Shopping List: {Object.values(state.items || {}).concat(Object.values(state.picks || {})).filter((i) => !!i.needed).length}</li>
          {state.plan && state.plan.dinners && !!state.plan.dinners.length && (
            <li>Dinners (for {dinnerEaters})
              <ul>
                {state.plan.dinners.map((meal) => (
                  <li key={`dinner-${meal.recipe.slug}`}>{meal.recipe.label}</li>
                ))}
              </ul>
            </li>
          )}
          {state.plan && state.plan.lunches && !!state.plan.lunches.length && (
            <li>Lunches (for {lunchEaters})
              <ul>
                {state.plan.lunches.map((meal) => (
                  <li key={`lunch-${meal.recipe.slug}`}>{meal.recipe.label}</li>
                ))}
              </ul>
            </li>
          )}
          {state.plan && state.plan.breakfasts && !!state.plan.breakfasts.length && (
            <li>Breakfasts (for {breakfastEaters})
              <ul>
                {state.plan.breakfasts.map((meal) => (
                  <li key={`breakfast-${meal.recipe.slug}`}>{meal.recipe.label}</li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      </Grid>

      {!anonymous && (
      <Grid>
        <div>You have finished planning your groceries, what would you like to do next?</div>
        <Grid container item spacing={2} xs={12} md={8} justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
          <Grid item style={{ marginTop: 10 }}>
            <Button onClick={onFinish('/shopping')} variant="contained" color="primary" style={{ width: '150px' }}>Shopping List</Button>
          </Grid>
          {(onlineGrocers&&onlineGrocers.length > 0) && (
              <Grid item style={{ marginTop: 10, left: 20 }}>
                <Button onClick={onFinish('/shopping#launchOnline')} variant="contained" color="primary" style={{ width: '150px' }}>Shop Online</Button>
              </Grid>
            )}
          <Grid item style={{ marginTop: 10, left: 20 }}>
            <Button onClick={onFinish('/menu')} variant="contained" color="primary" style={{ width: '150px' }}>Menu</Button>
          </Grid>
        </Grid>
      </Grid>
      )}

      {anonymous && (
      <Grid style={{ marginTop: 20 }}>
        <div style={{fontWeight:'bold'}}>You have finished planning your groceries, click the button below to login or create a free account to save your menu and grocery list</div>
        <Grid container item spacing={2} xs={12} md={8} justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
          <Grid item style={{ marginTop: 10 }}>
            <Button onClick={onFinish('/login')} variant="contained" color="secondary">Signup/Login</Button>
          </Grid>
        </Grid>
      </Grid>
      )}

    </Grid>
  );
};

export default Summary;
