import React, { useEffect, useState } from 'react';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import RadioButtonCheckedIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/DeleteOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SearchIcon from '@material-ui/icons/Search';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { keywords } from 'guustav-shared';

import MealSkeleton from './MealSkeleton';
import useStyles from './menuCardStyles';
import {useMediaQuery} from '@material-ui/core';

import { ReactComponent as WizardIcon } from 'assets/wizard_icon_purple.svg';
import QuarantineRecipeDialog from 'components/recipes/QuarantineRecipeDialog';
import RecipeImage from 'components/recipes/RecipeImage';
import useAuth from 'hooks/useAuth';
import { Button,Typography } from '@material-ui/core';


const keywordsByProduct = keywords.reduce((h, k) => Object.assign(h, { [k.product]: k }), {});
const k = (product) => {
  if (product && keywordsByProduct[product]) {
    return keywordsByProduct[product].keyword;
  }
  return '';
};

const Meal = ({ meal, uid, email, count, lg, change, preview, startSearch, readonly, cook }) => {
  const classes = useStyles();
  const { admin } = useAuth();
  const [skeleton, setSkeleton] = useState(true);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [cookedButton, setCookedButton] = useState();
  const [cookingAction, setCookingAction] = useState(false);

  const hideAboveMd = useMediaQuery((theme) => theme.breakpoints.up('md'));


  const changeMeal = () => {
    setSkeleton(true);
    change({ slug: meal.recipe.slug });
  };

  const reviewMeal = () => {
    setSkeleton(true);
    change({ slug: meal.recipe.slug });
  };

  const cookMeal = () => {
    cook({ slug: meal.recipe.slug });
    setCookingAction(!cookingAction);
  };

  useEffect(() => {
    if (meal.cooked) { setCookedButton(<RadioButtonCheckedIcon className={classes.navIcon} style={{ color: '#483D8B' }} />); } else { setCookedButton(<RadioButtonUncheckedIcon className={classes.navIcon} style={{ color: '#483D8B' }} />); }
  }, [cookingAction, meal.cooked, classes.navIcon]);

  useEffect(() => {
    if (meal.status && meal.status.action && meal.status.action === 'pick') {
      setSkeleton(true);
    } else {
      setSkeleton(false);
    }
  }, [meal]);

  const loading = false;
  let width;
  let lgSize = 3;
  if (count > 4 && lg) {
    lgSize = 2;
    width = '20%';
  }

  if (skeleton) {
    return <MealSkeleton lg={lg} count={count} change={change} action={!!change} />;
  }
  let readytoeatText = null;
  if (!!change && meal.status.action !== 'not found') readytoeatText = '';
  if (meal.recipe.label === 'Meatloaf and Buttermilk Mashed Potatoes') {
    readytoeatText = <div style={{ color: 'white', background: 'green' }}>Ready to eat Meal</div>;
  }
  if (email !== 'retaildemo@guustav.com') readytoeatText = null;

  return (
    <Grid
      item key={`meal-${meal.recipe.slug}`} xs={6} sm={4} md={3}
      lg={lgSize}
      style={{ maxWidth: width, flexBasis: width, paddingLeft: 2, paddingRight: 2, paddingBottom: 2, paddingTop: 3 }}
    >
      <QuarantineRecipeDialog recipe={meal.recipe} open={reviewOpen} onClose={() => setReviewOpen(false)} onSave={reviewMeal} />

      <Card
        id={`meal-${meal.course}-${meal.recipe.slug}`} className={lgSize === 1 ? classes.card : classes.cardSmall}
        raised elevation={2} style={{ border: '0px solid red', borderRadius: 15 }}
      >
        <CardActionArea onClick={() => (meal.recipe.slug === 'none' ? null : preview(meal.recipe))}>
          {meal.new && (
            <div className={[classes.banner, classes.bannerRed].join(' ')}>
              New!
            </div>
          )}
          {meal.recipe.uri.includes(uid) && (
            <div className={[classes.banner, classes.bannerGreen].join(' ')}>
              Mine
            </div>
          )}
          <RecipeImage recipe={meal.recipe} cooked={meal.cooked} />

          <IconButton
            className={classes.deleteIconButton}
            disabled={loading}
            onClick={() => change({ slug: meal.recipe.slug, recipe: 'delete' })}
            style={{
              display: change ? '' : 'none',
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.85)', // White background with some transparency
            }}
            size='small'
          >
            <Tooltip arrow title="Remove this meal from your menu.">
              <HighlightOffIcon
                aria-label={`remove ${meal.course}`}
                className={classes.deleteIcon}
                style={{ marginLeft: 'auto' }}
              />
            </Tooltip>
          </IconButton>

        
        <CardHeader
          classes={
            {
              root: classes.cardHeader,
              content: `${classes.headerContent} ${lgSize === 2 ? classes.headerContentSmall : classes.headerContentNormal}`,
              action: classes.action,
              avatar: classes.avatar,
            }
          }
          title={
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ height: '100%' }}
            >
              <Grid item>
                {`${meal.recipe.label}`}
              </Grid>
            </Grid>
          }

          subheader={readytoeatText}
        />
        </CardActionArea>
        {!!change && meal.status.action === 'not found' && (
          <CardActions disableSpacing>
            <Grid container justifyContent="center" alignItems="center" direction="column">
              <Grid item style={{ paddingBottom: 2 }}><div style={{ margin: 0 }}>&nbsp;</div></Grid>
              <Grid container justifyContent="center" alignItems="center" direction="row">
                <Grid item xs={12}>
                  <BottomNavigation
                    style={{ marginLeft: 8 }} showLabels
                    className={`${classes.bottomNav} ${lgSize === 2 ? classes.bottomNavSmall : classes.bottomNavNormal}`}
                  >
                    <BottomNavigationAction label="&nbsp;" disabled />
                  </BottomNavigation>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        )}

        {!!change && meal.status.action !== 'not found' && (
          <CardActions disableSpacing>
            <Grid container justifyContent="center" alignItems="center" direction="column">
              {!hideAboveMd&&(<Typography variant="subtitle1" align="center" color='secondary' style={{ margin: 0, fontSize: '0.9rem' , fontWeight:450}}>Replace Recipe</Typography> )}
              <Grid container justifyContent="space-around" alignItems="center" direction="row">
                {meal.course !== 'breakfast' && (
                  <Grid item xs={6} container justifyContent="center" alignItems="center">
                   {!hideAboveMd?(<Button size='small'  onClick={changeMeal} data-tour="pick-for-me" disabled={loading || readonly || meal.recipe.slug === 'none'} color='secondary' style={{ textTransform: 'none', backgroundColor:'#E6E6FA', borderRadius:10 }}><SvgIcon className={classes.navIcon} ><WizardIcon /></SvgIcon></Button>)
                   :(<Button size='small'  onClick={changeMeal} data-tour="pick-for-me" disabled={loading || readonly || meal.recipe.slug === 'none'} color='secondary' style={{ textTransform: 'none', borderRadius:10 }} startIcon={<SvgIcon className={classes.navIcon} ><WizardIcon /></SvgIcon>}>Suggest Other</Button>)}
                  </Grid>
                )}
                {false && admin && (
                  <Button size='small' startIcon={<ThumbDownIcon className={classes.navIcon} />} onClick={() => setReviewOpen(true)} disabled={loading || readonly || meal.recipe.slug === 'none'} style={{ textTransform: 'none' }}>Yuk!</Button>

                )}
                <Grid item xs={6} container justifyContent="center" alignItems="center">
                {!hideAboveMd?(<Button size='small' disabled={loading || readonly || meal.recipe.slug === 'none'} onClick={() => startSearch({ recipe: meal.recipe })} color='secondary' data-tour="let-me-pick" style={{ textTransform: 'none',backgroundColor:'#E6E6FA', borderRadius:10 }}><SearchIcon className={classes.navIcon} style={{ color: '#483D8B' }}/></Button>)
                  :(<Button size='small' disabled={loading || readonly || meal.recipe.slug === 'none'} onClick={() => startSearch({ recipe: meal.recipe })} color='secondary' data-tour="let-me-pick" style={{ textTransform: 'none', borderRadius:10 }} startIcon={<SearchIcon className={classes.navIcon} style={{ color: '#483D8B' }}/>}>Pick Manually</Button>)}
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
        )}
        {!!cook && meal.status.action !== 'not found' && (
          <CardActions disableSpacing>
            <Grid container justifyContent="center" alignItems="center" direction="row">
              <Button size='small' color='secondary' startIcon={cookedButton} onClick={cookMeal} data-tour="cooked it" disabled={loading || readonly || meal.recipe.slug === 'none'} style={{ textTransform: 'none' }}>Cooked it</Button>
            </Grid>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

export default Meal;
