import React from 'react';

import { useHistory } from 'react-router-dom';

import AdminResults from './AdminResults';

import Products from '.';

import useAuth from 'hooks/useAuth';

const AdminProducts = () => {
  const history = useHistory();
  const { loading, admin } = useAuth();
  if (loading) {
    return null;
  }
  if (!admin) {
    alert('You are not allowed to do this.');
    history.push('/');
    return null;
  }
  return <Products Results={AdminResults} />;
};

export default AdminProducts;
