import React, { useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import get from 'lodash/get';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';

import useAuth from 'hooks/useAuth';
import { isNative, postNativeEvent, trace, traceError } from 'utils';

const priorEmail = localStorage.getItem('guustav.email');
const priorProvider = localStorage.getItem('guustav.provider');

const LoginPage = ({ invitation, redirectUrl, ...props }) => {
  const history = useHistory();
  const { anonymous, loading, uid } = useAuth();
  const [error, setError] = useState();
  const [loggingIn, setLoggingIn] = useState(false);

  const referrer = get(history, 'location.state.referrer');
  trace('LoginPage: invitation: %o, redirectUrl: %o, referrer: %o', invitation, redirectUrl, referrer);

  useEffect(() => {
    if (isNative()) {
      postNativeEvent({ action: 'login', path: redirectUrl, invitation, referrer });
    }
  }, [invitation, redirectUrl, referrer]);

  if (loading || isNative()) {
    return null;
  }

  const uiConfig = {
    autoupgradeanonymoususers: true,
    credentialHelper: 'none',
    signInFlow: 'redirect',
    signInSuccessUrl: redirectUrl,
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      { provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID, fullLabel: 'Sign in with Google' },
      {
        provider: 'apple.com',
        scopes: ['email', 'name'],
      },
    ],
    tosUrl: null, // need to define a terms of service page
    privacyPolicyUrl: null, // need to define a privacy policy page
    callbacks: {
      uiChanged: (f, t) => { if (t) { setLoggingIn(true); } },
      uiShown: null,
      signInFailure: async (authError) => {
        const { code, credential } = authError;
        traceError('Login: Error signing in for %o: %o: %o', uid, authError, code);
        if (code === 'firebaseui/anonymous-upgrade-merge-conflict') {
          history.push({ pathname: '/account/merge', state: { uid, credential, redirectUrl } });
        } else {
          setLoggingIn(false);
          setError('An error occurred while signing in. Please contact support@guustav.com');
        }
      },
    },
  };

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ border: '0px solid red' }}>
      <Grid item>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
        {uid === 'visitor' && !!priorEmail && !loggingIn && (
          <Alert severity="warning" style={{ marginTop: 10 }}>
            It looks like you have used Guustav before as {priorEmail} {priorProvider ? `(${priorProvider}) ` : ''}but are not currently logged in.
          </Alert>
        )}
{/*         {anonymous && !invitation && (
          <Alert severity="info" style={{ marginTop: 10 }}>
            Please create an account to continue using Guustav. You will automatically start your 1 month free trial, no credit cards needed.
          </Alert>
        )} */}
        {invitation && (
          <Alert severity="info" style={{ marginTop: 10 }}>
            You are logging in as a member of the {invitation.household}.
          </Alert>
        )}
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </Grid>
      <Grid item style={{ marginTop: 20, fontSize: 10 }}>
        By continuing, you are accepting our <a href="https://www.guustav.com/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy </a> and <a href="https://www.guustav.com/tos" target="_blank" rel="noopener noreferrer"> Terms of use</a>
      </Grid>

    </Grid>
  );
};

export default LoginPage;
