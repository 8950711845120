import React, { useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';

const Tip = ({ message, title, icon, iconStyles, paperStyles, placement }) => {
  const [get, set] = useState();
  const [open, setOpen] = useState(false);

  const I = icon || Info;
  return [
    <Tooltip
      key="icon" title={title || message} arrow disableTouchListener={!!(title && message)}
      open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}
    >
      <I
        size="small"
        onClick={(ev) => { setOpen(false); set(get ? null : ev.currentTarget); }}
        style={{ cursor: 'pointer', marginLeft: 8, verticalAlign: 'middle', ...iconStyles }}
      />
    </Tooltip>,
    <Popper
      key="popper"
      open={!!get}
      anchorEl={get}
      placement={placement || 'bottom'}
      style={{ zIndex: 2000, maxWidth: 300, padding: 8 }}
    >
      <ClickAwayListener onClickAway={() => set(null)}>
        <Paper elevation={7} style={{ padding: 10, ...paperStyles }}>
          {message}
        </Paper>
      </ClickAwayListener>
    </Popper>
  ];
};

export default Tip;
