import React from 'react';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import AttachMoney from '@material-ui/icons/AttachMoney';

const Simple = ({ classes, state, setState }) => (
  <Grid container item alignItems="center">
    <Grid item xs={1}>
      <AttachMoney className={classes.icon} />
    </Grid>
    <Grid container item justifyContent="space-between" alignItems="center" xs={11} sm={8} md={6}>
      <Grid item>
        <Typography className={classes.label}>
          Include gourmet ingredients?
        </Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={state.recipePreferences.gourmet || false}
          onChange={(ev) => setState({ ...state, recipePreferences: { ...state.recipePreferences, gourmet: ev.target.checked } })}
          inputProps={{ 'aria-label': 'toggle recipes with gourmet ingredients' }}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default Simple;
