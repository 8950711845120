import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Container, Typography, Grid, useMediaQuery } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SearchIcon from '@material-ui/icons/Search';
import MealImage from 'assets/MealplanningDrawing.png';
import InventoryImage from 'assets/EasyshoppingDrawing.png';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    fullweek: {
      main: '#4B0082', // Vibrant Blue
    },
    inventory: {
      main: '#483D8B', // Vibrant Teal
    },
    browse: {
      main: '#6a057f', // Classy Purple
    },
  },
});

const useStyles = makeStyles((theme) => ({
  image: {
    position: 'absolute',
    '&.top-left': {
      left: 50,
    },
    '&.bottom-right': {
      right: 50,
      bottom: 50,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block', // Show the image on sm screens and larger
      maxHeight: '30%', // Adjust the maxHeight for screens with breakpoint 'sm' and larger
      maxWidth: '20%', // Adjust the maxWidth for screens with breakpoint 'sm' and larger
      marginLeft: 20, // Move the image to the right for screens with breakpoint 'sm' and larger
      height: 'auto', // Adjust the height proportionally when changing the width
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: '40%', // Adjust the maxHeight for screens with breakpoint 'sm' and larger
      maxWidth: '50%', // Adjust the maxWidth for screens with breakpoint 'sm' and larger
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block', // Show the image on XS screens
      bottom: 0, // Position the image at the bottom of the screen
      left: '50%', // Position the image in the center of the screen horizontally
      transform: 'translateX(-50%)', // Correct the position to the center from the left edge
      maxHeight: '30%', // Adjust the size of the image
      maxWidth: '80%', // Adjust the size of the image
      marginLeft: 0, // No margin on the left
    },
  },
}));


const InitialChoice = ({ onUserChoice }) => {
  const topButtonRef = useRef(null);
  const bottomButtonRef = useRef(null);
  const [imageTop, setImageTop] = useState(0);
  const [imageBottom, setImageBottom] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs')); // Check if the screen is XS

  useEffect(() => {
    const handleResize = () => {
      if (topButtonRef.current) {
        setImageTop(topButtonRef.current.getBoundingClientRect().top - 100);
      }

      if (bottomButtonRef.current) {
        setImageBottom(
          window.innerHeight - bottomButtonRef.current.getBoundingClientRect().bottom - 220
        );
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChoice = (choice) => {
    onUserChoice(choice);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Typography variant="h3" align="center" gutterBottom style={{ color: '#483D8B' }}>
          Welcome to Guustav!
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          What would you like to do?
        </Typography>
        <img src={MealImage} alt="Top Left" className={`${classes.image} top-left`} />
        {!isXS && (
          <img
            src={InventoryImage}
            alt="Bottom Right"
            className={`${classes.image} bottom-right`}
          />
        )}
        <Grid container justifyContent="center" alignItems="center" style={{ height: '70vh' }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="60vh"
            width={{ xs: '80vw', sm: 420 }}
            sx={{ gap: 50 }}
          >
            <Button
              ref={topButtonRef}
              fullWidth
              variant="contained"
              startIcon={<MenuBookIcon />}
              onClick={() => handleChoice('fullWeekPlanning')}
              style={{
                minHeight: '5em',
                fontSize: '1.2em',
                backgroundColor: '#483D8B',
                color: '#fff',
                borderRadius: 15,
              }}
            >
              The Full Monty: Instant Menu and Shopping List
            </Button>
            <Button
              fullWidth
              variant="contained"
              startIcon={<HomeWorkIcon />}
              onClick={() => handleChoice('manageInventory')}
              style={{
                minHeight: '5em',
                fontSize: '1.2em',
                backgroundColor: '#483D8B',
                color: '#fff',
                borderRadius: 15,
              }}
            >
              Automate My Shopping List
            </Button>
            <Button
              ref={bottomButtonRef}
              fullWidth
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => handleChoice('browseRecipes')}
              style={{
                minHeight: '5em',
                fontSize: '1.2em',
                backgroundColor: '#483D8B',
                color: '#fff',
                borderRadius: 15,
              }}
            >
              Just Browse Recipes/ Explore
            </Button>
          </Box>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default InitialChoice;
