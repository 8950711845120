import React, { useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

import useAccount from 'hooks/useAccount';
import useAuth from 'hooks/useAuth';
import dbLoad from 'hooks/utils/dbLoader';
import { dbUpdate } from 'hooks/utils/dbUpdater';
import { timeStamp, trace } from 'utils';

const AccountLeave = ({ ...props }) => {
  const auth = useAuth();
  const { account, loading } = useAccount();
  const history = useHistory();
  const [error, setError] = useState('');
  const [leaving, setLeaving] = useState(false);

  const leave = async () => {
    setLeaving(true);
    const { accessKey } = auth.primary;
    if (!accessKey || auth.uid === auth.ruid) {
      setLeaving(false);
      return;
    }
    const updates = {};
    updates[`/users/${auth.ruid}/account/primary`] = null;
    const invitation = await dbLoad({ path: `/invitations/${accessKey}`, source: 'account/leave' });
    if (invitation) {
      updates[`/invitations/${accessKey}`] = { ...invitation, ruid: null, allowed: 'off', leftAt: timeStamp() };
    }
    try {
      const member = await dbLoad({ uid: auth.uid, path: `household/members/${accessKey}`, source: 'account/leave' });
      trace('account/leave: member: %o', member);
      if (member) {
        updates[`/users/${auth.uid}/household/members/${accessKey}`] = {
          ...member, acceptedAt: null, uid: null, allowed: 'off', leftAt: timeStamp()
        };
      }
    } catch (ex) {
      // Probably were no longer allowed so nothing to do
    }
    trace('account/leave: updates: %o', updates);
    await dbUpdate({ path: '/', updates, source: 'account/leave' });
    // This forces an auth state change since we are using onIdTokenChanged in useAuth
    firebase.auth().currentUser.getIdTokenResult(true);
    setError(`You have successfully left the ${account.primary.household}. You will be redirected to the home page.`);
    setTimeout(() => {
      history.push('/');
    }, 3000);
  };

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={8}>
          <LinearProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <h2>Join Household</h2>
      {error && (
        <Grid item xs={10} sm={8} md={6}>
          <Alert severity="warning" style={{ marginTop: 10 }}>{error}</Alert>
        </Grid>
      )}
      {leaving && (
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <LinearProgress />
          </Grid>
        </Grid>
      )}
      {auth.uid === auth.ruid && !leaving && (
        <Grid item xs={10} sm={8} md={6}>
          You are not a member of another household so you can't leave.
        </Grid>
      )}
      {auth.uid !== auth.ruid && (
        <Grid item xs={10} sm={8} md={6}>
          You are a member of the {account.primary.household}. If you leave, you will not be able to manage the shared menu, recipes, and shopping list. You will be able to manage your own household and invite other members to join you.
        </Grid>
      )}
      <Grid item>
        <Button onClick={leave} variant="contained" disabled={leaving}>Leave</Button>
      </Grid>
    </Grid>
  );
};

export default AccountLeave;
