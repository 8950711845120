import React, { useEffect, useMemo, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';

import { calculateIncrement } from './utils';


const MeasureInput = ({ mobile, product, setQuantity }) => {
  const [qty, setQty] = useState(product.actual);

  const increment = useMemo(() => calculateIncrement(product), [product]);

  useEffect(() => {
    setQty(product.actual);
  }, [product.actual]);

  const handleIncrement = () => {
    const newQty = Number(qty) + increment;
    setQty(newQty);
    setQuantity({ product, value: newQty });
  };

  const handleDecrement = () => {
    if (Number(qty) > increment) {
      const newQty = Number(qty) - increment;
      setQty(newQty);
      setQuantity({ product, value: newQty });
    }
  };

  return (
      <Box style={{width:120, maxHeight:25}}>
        <Grid item container direction='row'>
          <Button variant="outlined" color='secondary' onClick={handleDecrement} size='small' style={{minWidth:30,maxHeight:25,fontSize: '1rem', backgroundColor:'#E6E6FA', borderTopLeftRadius:15, borderBottomLeftRadius:15}}>-</Button>
          <Button variant="outlined" color='secondary' onClick={handleIncrement} style={{minWidth:8,maxWidth:30,maxHeight:25, fontSize: '1rem', backgroundColor:'#E6E6FA', borderTopRightRadius:15,borderBottomRightRadius:15}} >+</Button>
        </Grid>
      </Box>
  );
};

export default MeasureInput;
