import React, { useEffect, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import { eventsByCategorySince } from 'hooks/utils/events';

const Events = ({ ...props }) => {
  const history = useHistory();
  const { admin, email, uid } = useAuth();
  const [events, setEvents] = useState('loading');

  useEffect(() => {
    if (uid) {
      eventsByCategorySince({ category: 'recipe', date: dayjs().subtract(1, 'y').toDate(), uid }).then((result) => {
        setEvents(result);
      });
    }
  }, [uid]);

  if (!admin) {
    alert('You are not allowed to do this.');
    history.push('/');
    return null;
  }

  if (events === 'loading') {
    return null;
  }

  return (
    <div>
      <h3>Events</h3>
      {!events.length && (
        <div>No events for {email}</div>
      )}
      {!!events.length && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Key</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.sort((a, b) => b.time.localeCompare(a.time)).map((e) => (
                <TableRow key={`${e.time}-${e.action}-${e.key}`}>
                  <TableCell>{e.time}</TableCell>
                  <TableCell>{e.category}</TableCell>
                  <TableCell>{e.action}</TableCell>
                  <TableCell>{e.key}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Events;
