const volumes = {
  each: { name: 'Each', symbols: '', unitType: 'Each' },

  teaspoon: { name: 'Teaspoon', symbols: 'tsp', unitType: 'Volume', upUnit: 'tablespoon', upRatio: 3, system: 'Imperial', sort: 10 },
  tablespoon: { name: 'Tablespoon', symbols: 'Tbsp', unitType: 'Volume', upUnit: 'cup', upRatio: 16, downUnit: 'Teaspoon', downRatio: 1 / 3, system: 'Imperial', sort: 20 },
  fluidounce: { name: 'Fluid Ounce', symbols: 'fl oz', unitType: 'Volume', upUnit: 'cup', upRatio: 8, downUnit: 'tablespoon', downRatio: 0.5, system: 'Imperial', sort: 30 },
  cup: { name: 'Cup', symbols: 'c', unitType: 'Volume', upUnit: 'quart', upRatio: 4, downUnit: 'tablespoon', downRatio: 1 / 16, system: 'Imperial', sort: 40 },
  pint: { name: 'Pint', symbols: 'pt', unitType: 'Volume', upUnit: 'quart', upRatio: 2, downUnit: 'cup', downRatio: 0.5, system: 'Imperial', sort: 50 },
  quart: { name: 'Quart', symbols: 'qt', unitType: 'Volume', upUnit: 'gallon', upRatio: 4, downUnit: 'pint', downRatio: 0.5, system: 'Imperial', sort: 60 },
  gallon: { name: 'Gallon', symbols: 'gal', unitType: 'Volume', downUnit: 'quart', downRatio: 0.25, system: 'Imperial', sort: 70 },

  milliliter: { name: 'Milliliter', symbols: 'ml', unitType: 'Volume', upUnit: 'centiliter', upRatio: 10, system: 'Metric', sort: 10 },
  centiliter: { name: 'Centiliter', symbols: 'cl', unitType: 'Volume', upUnit: 'deciliter', upRatio: 10, downUnit: 'milliliter', downRatio: 0.1, system: 'Metric', sort: 20 },
  deciliter: { name: 'Deciliter', symbols: 'dl', unitType: 'Volume', upUnit: 'liter', upRatio: 10, downUnit: 'centiliter', downRatio: 0.1, system: 'Metric', sort: 30 },
  liter: { name: 'Liter', symbols: 'l', unitType: 'Volume', downUnit: 'deciliter', downRatio: 0.1, system: 'Metric', sort: 40 },
};

const weights = {
  ounce: { name: 'Ounce', symbols: 'oz', unitType: 'Weight', upUnit: 'pound', upRatio: 16, system: 'Imperial', sort: 10 },
  ounces: { name: 'Ounce', symbols: 'oz', unitType: 'Weight', upUnit: 'pound', upRatio: 16, system: 'Imperial', sort: 10 },
  pound: { name: 'Pound', symbols: 'lb', unitType: 'Weight', downUnit: 'ounce', downRatio: 1 / 16, system: 'Imperial', sort: 20 },

  milligram: { name: 'Milligram', symbols: 'mg', unitType: 'Weight', upUnit: 'gram', upRatio: 1000, system: 'Metric', sort: 10 },
  gram: { name: 'Gram', symbols: 'g', unitType: 'Weight', upUnit: 'kilogram', upRatio: 1000, downUnit: 'milligram', downRatio: 1 / 1000, system: 'Metric', sort: 20 },
  kilogram: { name: 'Kilogram', symbols: 'kg', unitType: 'Weight', downUnit: 'gram', downRatio: 1 / 1000, system: 'Metric', sort: 30 },
};

const imperialConversions = {
  gram: {
    conversionRatio: 0.035274,
    imperialUnit: 'ounce'
  },
  milligram: {
    conversionRatio: 0.000035274,
    imperialUnit: 'ounce'
  },
  kilogram: {
    conversionRatio: 2.2,
    imperialUnit: 'pound'
  },
  liter: {
    conversionRatio: 1.05669,
    imperialUnit: 'quart'
  },
  deciliter: {
    conversionRatio: 0.422675,
    imperialUnit: 'cup'
  },
  centiliter: {
    conversionRatio: 0.67628,
    imperialUnit: 'tablespoon'
  },
  milliliter: {
    conversionRatio: 0.202884,
    imperialUnit: 'teaspoon'
  }
};

// Ratios to convert all volumes to teaspoons
const teaspoonRatios = {
  teaspoon: 1,
  tablespoon: 3,
  fluidounce: 2 * 3,
  cup: 8 * (2 * 3),
  pint: 2 * (8 * 2 * 3),
  quart: 2 * (2 * 8 * 2 * 3),
  gallon: 4 * (2 * 2 * 8 * 2 * 3),
};

// Ratios to convert all weights to ounces
const ounceRatios = {
  ounce: 1,
  pound: 16,

  /* Will use weights for now if both volume and weight are specified */
  teaspoon: 1,
  tablespoon: 3,
  fluidounce: 2 * 3,
  cup: 8 * (2 * 3),
  pint: 2 * (8 * 2 * 3),
  quart: 2 * (2 * 8 * 2 * 3),
  gallon: 4 * (2 * 2 * 8 * 2 * 3),
};

// The minimum of each unit we will display
// before going down to the smaller unit.
const mins = {
  teaspoon: 1,
  tablespoon: 1,
  cup: 0.25,
  quart: 1,
  gallon: 0.5,

  ounce: 1,
  pound: 0.25,
};

/* Given a list of units and quantities, figure out the sum, using the most
 * reasonable unit. For example, we wouldn't say 10 teaspoons, we would say
 * 4 tablespoons. And we wouldn't say 20 ounces, we would say 1.25 pounds.
 *
 * I skipped pints and fluid ounces becuase that's what Wix seemed to do.
 *
 * in:  [ { measure: 'teaspon/etc', quantity: n }, { measure: 'cup/etc', quantity: n } ]
 * out: { measure: 'quart/etc', quantity: n }
 */
const normalizeVolumes = (measurementCounts) => {
  const imperial = measurementCounts.map((m) => {
    const i = imperialConversions[m.measure];
    if (i) {
      return { measure: i.imperialUnit, quantity: (100 * m.quantity * i.conversionRatio) / 100 };
    }
    return m;
  });
  const teaspoons = imperial.map((m) => m.quantity * teaspoonRatios[m.measure]);
  const sumInTeaspoons = Math.round(teaspoons.reduce((sum, q) => sum += (100 * q), 0)) / 100;

  const quantity = sumInTeaspoons;
  if (!quantity || isNaN(quantity)) {
    if (quantity !== 0) {
      console.warn('measurements: no quantity for %o', imperial);
    }
    return ({ measure: 'each', quantity: 1, exact: 1 });
  }
  if (quantity < teaspoonRatios.tablespoon / 2) {
    return { measure: 'teaspoon', quantity: Math.ceil(quantity), exact: quantity, ...volumes.teaspoon };
  }
  if (quantity < teaspoonRatios.cup * mins.cup) {
    const exact = quantity / teaspoonRatios.tablespoon;
    return { measure: 'tablespoon', quantity: Math.ceil(exact / mins.tablespoon) * mins.tablespoon, exact, ...volumes.tablespoon };
  }
  // skip fluid ounces
  if (quantity < teaspoonRatios.quart * mins.quart) {
    const exact = quantity / teaspoonRatios.cup;
    return { measure: 'cup', quantity: Math.ceil(exact / mins.cup) * mins.cup, exact, ...volumes.cup };
  }
  // skip pints
  if (quantity < teaspoonRatios.gallon * mins.gallon) {
    const exact = quantity / teaspoonRatios.quart;
    return { measure: 'quart', quantity: Math.ceil(exact / mins.quart) * mins.quart, exact, ...volumes.quart };
  }
  const exact = quantity / teaspoonRatios.gallon;
  return { measure: 'gallon', quantity: Math.ceil(exact / mins.gallon) * mins.gallon, exact, ...volumes.gallon };
};

const normalizeWeights = (measurementCounts) => {
  const imperial = measurementCounts.map((m) => {
    const i = imperialConversions[m.measure];
    if (i) {
      return { measure: i.imperialUnit, quantity: Math.round(100 * m.quantity * i.conversionRatio) / 100 };
    }
    return m;
  });
  const ounces = imperial.map((m) => m.quantity * ounceRatios[m.measure]);
  const sumInOunces = Math.round(ounces.reduce((sum, q) => sum += (100 * q), 0)) / 100;
  const quantity = sumInOunces;
  if (!quantity || isNaN(quantity)) {
    if (quantity !== 0) {
      console.warn('measurements: no quantity for %o', imperial);
    }
    return ({ measure: 'each', quantity: 1, exact: 1 });
  }
  if (quantity < ounceRatios.pound * mins.pound) {
    return { measure: 'ounce', quantity: Math.ceil(quantity), exact: quantity, ...weights.ounce };
  }
  const exact = quantity / ounceRatios.pound;
  return { measure: 'pound', quantity: Math.ceil(exact / mins.pound) * mins.pound, exact, ...weights.pound };
};

const normalizeQuantities = (quantities) => {
  if (quantities.some((m) => m.measure === 'each')) {
    const sum = Math.round(quantities.reduce((sum, m) => sum += (100 * m.quantity), 0)) / 100;
    const result = { measure: 'each', quantity: sum, exact: sum };
    return result;
  }
  if (quantities.some((q) => weights[q.measure])) {
    const result = normalizeWeights(quantities);
    return result;
  } if (quantities.some((q) => volumes[q.measure])) {
    const result = normalizeVolumes(quantities);
    return result;
  }
  const result = { ...quantities[0], name: 'Unknown' };
  return result;
};

export { normalizeQuantities, volumes, weights };
