import React, { useEffect, useLayoutEffect, useState } from 'react';

import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import { addGlobalEvent } from 'hooks/utils/events';
import { getSubscription } from 'hooks/utils/subscriptionUtils';
import {
  About,
  Account,
  AccountAccept,
  AccountInitialize,
  AccountJoin,
  AccountLeave,
  AccountMerge,
  Admin,
  AdminBadImages,
  AdminChatGPT,
  AdminProducts,
  AdminUsers,
  Events,
  Filtered,
  History,
  Landing,
  Menu,
  PlanShoppingPrintView,
  Preferences,
  Products,
  Recipes,
  RecipePreferences,
  Review,
  ReviewSuggested,
  Shopping,
  ShoppingPrintView,
  ShopAmazon,
  Styles,
  Subscribe,
  Trending,
} from 'pages';
import LoginPage from 'pages/Login';
import { trace } from 'utils';

const withTitle = (component, title) => {
  document.title = `Guustav: ${title}`;
  return component;
};

const Router = ({ setPub, ...props }) => {
  const history = useHistory();
  const { loading, uid, anonymous } = useAuth();
  trace('in route: %s', history.location.pathname);

  if (loading) {
    trace('loading in router');
    return null;
  }

/*   if (anonymous && !history.location.pathname.match(/landing|merge|login|accept|about|styles/)) {
    trace('Redirect to login because anonymous and not on public page: %s', history.location.pathname);
    // TODO: need to send custom flutter login event thing to get app to do login
    return <Redirect to={{ pathname: '/login', state: { referrer: history.location.pathname } }} />;
  } */

  return (
    <Switch>
      <Route
        exact path="/accept/:accessKey/:uid/:household"
        render={(routeProps) => {
          const { accessKey } = routeProps.match.params;
          const household = atob(routeProps.match.params.household);
          const { uid } = routeProps.match.params;
          const invitation = { accessKey, household, uid };
          const redirectUrl = `/account/accept?a=${accessKey}`;
          return <LoginPage invitation={invitation} redirectUrl={redirectUrl} {...props} {...routeProps} />;
        }}
      />
      <Route
        exact path="/login"
        render={(routeProps) => {
          let path = history.location.pathname;
          if (!path || path === '/login') {
            path = '/';
          }
          trace('Load login page due to exact path /login');
          const redirectUrl = `/account/initialize?r=${encodeURI(path)}`;
          return <LoginPage redirectUrl={redirectUrl} {...props} {...routeProps} />;
        }}
      />
      <Route
        exact path="/about"
        render={(routeProps) => <About {...props} {...routeProps} />}
      />
      <Route
        exact path="/landing"
        render={(routeProps) => {
          
          return <Landing {...props} {...routeProps} />
        }}
      />
      <Route
        exact path="/styles"
        render={(routeProps) => <Styles {...props} {...routeProps} />}
      />
      <Route
        exact path="/"
        render={(routeProps) => {
          if (uid === 'visitor') { // first time here
            return <Redirect to="/landing" {...props} {...routeProps} />;
          }
/*           if (anonymous) { // already gone through landing
            return <Redirect to={{ pathname: '/login', state: { referrer: history.location.pathname } }} />;
          }
 */          return <Redirect to={{ pathname: '/menu' }} />;
        }}
      />
      <Route
        path="/" render={(routeProps) => {
          if (uid === 'visitor') {
            return <Redirect to="/landing" {...props} {...routeProps} />;
          }
/*           if (anonymous && !history.location.pathname.match(/merge/)) {
            return <Redirect to={{ pathname: '/login', state: { referrer: history.location.pathname } }} />;
          } */
          return <ProtectedRoutes setPub={setPub} {...props} {...routeProps} />;
        }}
      />
    </Switch>
  );
};

const ProtectedRoutes = ({ setPub, ...props }) => {
  const { loading, uid, email, anonymous } = useAuth();
  const [subscription, setSubscription] = useState({ loading: true });
  const history = useHistory();

  trace('in protected routes: %s', history.location.pathname);

  useLayoutEffect(() => {
    setPub(false);
    return () => {
      setPub(true);
    };
  }, [setPub]);

  useEffect(() => {
    setSubscription({ loading: false });
    /* 
    if (loading) {
      return;
    }
    
    if (!uid || uid === 'visitor' || anonymous) {
      addGlobalEvent({ action: 'BeforeSubscriptionNoUser', category: 'router', data: { uid, anonymous, path: history.location.pathname }, uid, email });
      setSubscription({ loading: false });
      return;
    }
    if (/paid/.test(history.location.pathname)) {
      addGlobalEvent({ action: 'BeforeSubscriptionPaid', category: 'router', data: { uid, anonymous, path: history.location.pathname }, uid, email });
      setSubscription({ loading: false });
      trace('On paid page so not checkout subscription');
      setSubscription({ loading: false });
      return;
    }
    addGlobalEvent({ action: 'BeforeSubscription', category: 'router', data: { uid, anonymous, path: history.location.pathname }, uid, email });
    const fn = async () => {
      trace('Fetching subscriber info');
      addGlobalEvent({ action: 'GetSubscription', category: 'router', data: { path: history.location.pathname }, uid, email });
      const entitlement = await getSubscription({ uid });
      trace('Entitlement: %o', entitlement);
      addGlobalEvent({ action: 'GotSubscription', category: 'router', data: { entitlement }, uid, email });
      trace('Path: %o', history.location.pathname);
      if (entitlement) {
        setSubscription({ ...entitlement, loading: false, active: true }); */
        /*
        if (history.location.pathname === '/subscribe') {
          history.push('/');
        }
        */
      /* } else {
        addGlobalEvent({ action: 'NoSubscription', category: 'router', data: 'Navingating to /subscribe', uid, email });
        setSubscription({ active: false, loading: false });
        // history.push('/subscribe', { referrer: history.location.pathname });
      }
    };
    fn(); */
  }, [loading, uid, email, anonymous, history]);

  if (loading || subscription.loading) {
    trace('loading in protected');
    return null;
  }

  /*
  if (uid === 'visitor') {
    return <Redirect to={{ pathname: '/' }}/>
  }

  if (anonymous && !history.location.pathname.match(/landing|merge|login/)) {
    trace('Redirect to login because anonymous and not on landing or merge');
    // TODO: need to send custom flutter login event thing to get app to do login
    return <Redirect to={{ pathname: '/login', state: { referrer: history.location.pathname }}} />;
  }
  */

  return (
    <Switch>
      <Route
        exact path="/menu"
        render={(routeProps) => withTitle(<Menu {...props} {...routeProps} />, 'Menu')}
      />
      <Route
        exact path="/products"
        render={(routeProps) => withTitle(<Products {...props} {...routeProps} />, 'Product Search')}
      />
      <Route
        exact path="/shopping/print"
        render={(routeProps) => withTitle(<ShoppingPrintView {...props} {...routeProps} />, 'Shopping List')}
      />
      <Route
        exact path="/print"
        render={(routeProps) => withTitle(<ShoppingPrintView {...props} {...routeProps} />, 'Shopping List')}
      />
      <Route
        exact path="/printplan"
        render={(routeProps) => withTitle(<PlanShoppingPrintView {...props} {...routeProps} />, 'Plan Shopping printing')}
      />

      <Route
        exact path="/shop/amazon"
        render={(routeProps) => withTitle(<ShopAmazon {...props} {...routeProps} />, 'Shop Online')}
      />
      <Route
        path="/shopping/:recipe?"
        render={(routeProps) => withTitle(<Shopping {...props} {...routeProps} />, 'Shopping List')}
      />
      <Route
        path="/recipes/:course?/:slug?"
        render={(routeProps) => withTitle(
          <Recipes
            course={routeProps.match.params.course}
            slug={routeProps.match.params.slug}
            {...props} {...routeProps}
          />,
          'Recipes'
        )}
      />
      <Route
        path="/account/initialize"
        render={(routeProps) => {
          trace('in initialize route');
          return withTitle(<AccountInitialize {...props} {...routeProps} />, 'Setup');
        }}
      />
      <Route
        exact path="/account/merge"
        render={(routeProps) => withTitle(<AccountMerge {...props} {...routeProps} />, 'Merge Accounts')}
      />
      <Route
        exact path="/account/join"
        render={(routeProps) => withTitle(<AccountJoin {...props} {...routeProps} />, 'Join Household')}
      />
      <Route
        exact path="/account/leave"
        render={(routeProps) => withTitle(<AccountLeave {...props} {...routeProps} />, 'Leave Household')}
      />
      <Route
        exact path="/account/accept"
        render={(routeProps) => withTitle(<AccountAccept {...props} {...routeProps} />, 'Join Household')}
      />
      <Route
        exact path="/account"
        render={(routeProps) => withTitle(<Account {...props} {...routeProps} />, 'Account Settings')}
      />
      <Route
        exact path="/paid/:checkout_id"
        render={(routeProps) => withTitle(
          <Subscribe {...props} {...routeProps} subscription={subscription} setSubscription={setSubscription} checkoutId={routeProps.match.params.checkout_id} />,
          'Subscribe to Guustav'
        )}
      />
      <Route
        exact path="/subscribe"
        render={(routeProps) => withTitle(
          <Subscribe {...props} {...routeProps} subscription={subscription} setSubscription={setSubscription} />,
          'Subscribe to Guustav'
        )}
      />
      <Route
        exact path="/preferences/recipe"
        render={(routeProps) => withTitle(<RecipePreferences {...props} {...routeProps} />, 'Recipe Preferences')}
      />
      <Route
        exact path="/preferences"
        render={(routeProps) => withTitle(<Preferences {...props} {...routeProps} />, 'Preferences')}
      />
      <Route
        exact path="/admin/events"
        render={(routeProps) => withTitle(<Events {...props} {...routeProps} />, 'Admin: My Events')}
      />
      <Route
        exact path="/admin/trending"
        render={(routeProps) => withTitle(<Trending {...props} {...routeProps} />, 'Admin: Trending Recipes')}
      />
      <Route
        exact path="/admin/filtered"
        render={(routeProps) => withTitle(<Filtered {...props} {...routeProps} />, 'Admin: Filtered Recipes')}
      />
      <Route
        exact path="/admin/history"
        render={(routeProps) => withTitle(<History {...props} {...routeProps} />, 'Admin: My Plan History')}
      />
      <Route
        exact path="/admin/review"
        render={(routeProps) => withTitle(<Review {...props} {...routeProps} />, 'Admin: Review Recipes')}
      />
      <Route
        exact path="/admin/suggested"
        render={(routeProps) => withTitle(<ReviewSuggested {...props} {...routeProps} />, 'Admin: Review Recipes')}
      />
      <Route
        exact path="/admin/products"
        render={(routeProps) => withTitle(<AdminProducts {...props} {...routeProps} />, 'Admin: Products')}
      />
      <Route
        exact path="/admin/users"
        render={(routeProps) => withTitle(<AdminUsers {...props} {...routeProps} />, 'Admin: Users')}
      />
      <Route
        exact path="/admin/badImages"
        render={(routeProps) => withTitle(<AdminBadImages {...props} {...routeProps} />, 'Admin: Bad Images')}
      />
      <Route
      exact path="/admin/chatGPT"
      render={(routeProps) => withTitle(<AdminChatGPT {...props} {...routeProps} />, 'Admin: Chat GPT')}
    />
      <Route
        exact path="/admin"
        render={(routeProps) => withTitle(<Admin {...props} {...routeProps} />, 'Admin: Home')}
      />
    </Switch>
  );
};

export default Router;
