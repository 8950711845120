import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import { recipeConflictsWithPreferences } from 'guustav-shared/utils';
import startCase from 'lodash/startCase';

import Tip from 'components/Tip';
import { GET_EDAMAM_RECIPE_BY_URI } from 'hooks/queries';

const friendlyPrefs = (key) => {
  const parts = key.split('&').map((part) => part.split('=').map((p) => p.split(',').map((pp) => (pp.match(/false|true/i) ? pp : startCase(pp))).join(', ')).join(': '));
  return parts.join('; ');
};

const PlanRecipeFooter = ({ recipe, profiles }) => {
  const [currentRecipe, setCurrentRecipe] = useState(recipe);
  const [isOpen, setIsOpen] = useState(false);
  const [bad, setBad] = useState({});
  const [good, setGood] = useState({});
  const client = useApolloClient();

  useEffect(() => {
    const fn = async () => {
      if (client && isOpen && !recipe.ingredients) {
        const { data } = await client.query({ query: GET_EDAMAM_RECIPE_BY_URI, variables: { uri: recipe.uri } });
        if (data && data.getEdamamRecipeByURI) {
          const r = JSON.parse(data.getEdamamRecipeByURI);
          setCurrentRecipe((current) => ({ ...current, ...r }));
        }
      }
    };
    fn();
  }, [client, isOpen, recipe, setCurrentRecipe]);

  useEffect(() => {
    const b = {}; const
      g = {};
    Object.keys(profiles).forEach((key) => {
      const mismatches = recipeConflictsWithPreferences({ recipePreferences: key, recipe: currentRecipe });
      if (mismatches) {
        b[key] = mismatches;
      } else {
        g[key] = true;
      }
    });
    setBad(b);
    setGood(g);
  }, [currentRecipe, profiles]);

  return (
    <div style={{ paddingLeft: 4, paddingBottom: 4 }}>
      <small>
        Profile Matches: {Object.keys(good).length}/{Object.keys(profiles).length}
      </small>
      <IconButton size="small" style={{ fontSize: '1.0rem' }} onClick={() => setIsOpen(true)}>
        <InfoIcon style={{ fontSize: '1.0rem' }} />
      </IconButton>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="customized-dialog-title">
        <DialogTitle onClose={() => setIsOpen(false)}>
          <div>{currentRecipe.label}</div>
          <small>Preference Matches</small>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" alignItems="flex-start">
            {Object.entries(bad).map(([key, mismatches]) => (
              <Grid item key={key} container wrap="nowrap">
                <Grid item xs={1}><Tip message={<div>Mismatches:<br />&nbsp;&nbsp;{mismatches.join(', ')}</div>} icon={BlockIcon} iconStyles={{ color: '#c80000' }} /></Grid>
                <Grid item>{friendlyPrefs(key)}</Grid>
              </Grid>
            ))}
            {Object.keys(good).map((key) => (
              <Grid item container key={key} wrap="nowrap">
                <Grid item xs={1}><Tip message="All good" icon={CheckIcon} iconStyles={{ color: 'green' }} /></Grid>
                <Grid item>{friendlyPrefs(key)}</Grid>
              </Grid>
            ))}
            <Grid item>
              <h4>Recipe</h4>
              <div>Main Ingredient: {currentRecipe.product || 'N/A'}</div>
              {currentRecipe.time && (
              <div>Time: {currentRecipe.time}</div>
              )}
              {currentRecipe.ingredients && typeof currentRecipe.ingredients === 'number' && (<div>Ingredients: {currentRecipe.ingredients}</div>)}
              {currentRecipe.ingredients && typeof currentRecipe.ingredients === 'object' && (<div>Ingredients: {Object.keys(currentRecipe.ingredients).length}</div>)}
              <div>Gourmet: {currentRecipe.gourmet ? 'true' : 'false'}</div>
              {currentRecipe.dietLabels && (
              <div>Diet Labels: {currentRecipe.dietLabels.join(', ')}</div>
              )}
              {currentRecipe.healthLabels && (
              <div>Health Labels: {currentRecipe.healthLabels.join(', ')}</div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PlanRecipeFooter;
