import React from 'react';

import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  stepper: {
    /* TODO: shrink it on smaller screen heights */
  },
}));

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient(95deg, rgb(147, 112, 219) 0%, rgb(72, 61, 139) 50%, rgb(72, 61, 139) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,rgb(145,231,113) 0%,rgb(62,211,7) 50%,rgb(48,163,5) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-height: 600px)': {
      width: 32,
      height: 32,
    },
  },
  active: {
    backgroundImage: 'linear-gradient(45deg, rgb(147, 112, 219) 30%, rgb(72, 61, 139) 90%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: 'linear-gradient( 136deg, rgb(145,231,113) 0%, rgb(62,211,7) 50%, rgb(48,163,5) 100%)',
    cursor: 'pointer',
  },
}));

let icons;

const getColorlibStepIcon = ({ step, onClickGoto }) => (props) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      onClick={completed ? () => onClickGoto(step.id) : null}
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[props.icon - 1]}
    </div>
  );
};

const WizardProgressTop = ({ onClickGoto, step, steps }) => {
  const classes = useStyles();
  icons = steps.map((s) => s.icon);
  return (
    <Stepper className={`${classes.stepper} wizard-plan-nav`} activeStep={step.current} connector={<ColorlibConnector />} style={{ width: '80%', margin: 20, padding: 0 }}>
      {steps.map((s, index) => {
        const stepProps = { active: index === step.current, completed: index < step.current };
        return (
          <Step key={s.id} {...stepProps} className="wizard-plan-step-icon">
            <StepLabel StepIconComponent={getColorlibStepIcon({ step: s, onClickGoto })}>{s.label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default WizardProgressTop;
